define("parro-web/models/guardian", ["exports", "ember-data", "parro-web/models/person", "ember-inflector"], function (_exports, _emberData, _person, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _person.default.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'identity.RGuardian'
    }),
    account: _emberData.default.belongsTo('account', {
      async: true
    }),
    cachedAvatarUrl: _emberData.default.attr('string'),
    identityId: _emberData.default.attr('number'),
    linkedIdentityHash: _emberData.default.attr('string'),
    isGuardian: true,
    isTeacher: false
  });
  /* ember-data vertellen wat de meervoudsvorm van de modelnaam is */


  _exports.default = _default;

  _emberInflector.default.inflector.irregular('guardian', 'guardian');
});