define("parro-web/templates/components/loader/infinity-loader-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless infinityModel.reachedInfinity}}
    <div class="infinity-loader--contents">
      <div class="loader--spinner"></div>
    </div>
  {{/unless}}
  
  
  */
  {
    "id": "MkRLIuPX",
    "block": "[[[41,[51,[33,1,[\"reachedInfinity\"]]],[[[1,\"  \"],[10,0],[14,0,\"infinity-loader--contents\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"loader--spinner\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[],false,[\"unless\",\"infinityModel\"]]",
    "moduleName": "parro-web/templates/components/loader/infinity-loader-text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});