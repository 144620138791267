define("parro-web/routes/yearbook/cover-images", ["exports", "ember", "parro-web/mixins/routeMixin"], function (_exports, _ember, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP,
    Route,
    inject: {
      service
    },
    isEmpty
  } = _ember.default;

  var _default = Route.extend(_routeMixin.default, {
    yearbookAjaxService: service(),
    yearbookService: service(),
    groupsService: service(),
    session: service(),

    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },

    model(params) {
      const codes = this.get('yearbookService').getAllowedOrganisationCodes();
      const args = {
        codes: codes,
        scope: 'ACTIVE',
        excludeSchoolwide: 'FALSE'
      };
      return this.get('yearbookAjaxService').getYearbook(params.yearbook).then(yearbook => {
        return RSVP.hash({
          navConfig: this._getConfig(),
          yearbook: yearbook,
          groups: this.get('groupsService').getGroupsWithArgs(args),
          selection: this.get('yearbookAjaxService').getContentSelection(yearbook.get('id')),
          prices: this.get('yearbookAjaxService').getYearbookPrices(yearbook)
        });
      });
    },

    afterModel(model, transition) {
      if (isEmpty(model) || isEmpty(model.yearbook)) {
        return this.refresh();
      }

      const routeGuard = this.get('yearbookService').getRouteGuard(this.get('routeName'), model.yearbook);
      this.useRouteGuard(routeGuard, transition);
    },

    _getConfig() {
      return _ember.default.Object.create({
        title: 'yearbook_cover.title',
        headerClass: 'header-toolbar--left',
        icon: 'icon-previous',
        toNavChoice: () => this.toNavChoice()
      });
    },

    toNavChoice() {
      const id = this.get('controller.model.yearbook.id');
      this.transitionTo('yearbook.front', id);
    }

  });

  _exports.default = _default;
});