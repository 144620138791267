define("parro-web/templates/components/yearbook/yearbook-filter-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li>{{form/input-checkbox checked=isSelected description=description onChange=onSelect}}</li>
  
  */
  {
    "id": "fSNYM4Ha",
    "block": "[[[10,\"li\"],[12],[1,[28,[35,0],null,[[\"checked\",\"description\",\"onChange\"],[[33,1],[33,2],[33,3]]]]],[13],[1,\"\\n\"]],[],false,[\"form/input-checkbox\",\"isSelected\",\"description\",\"onSelect\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-filter-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});