define("parro-web/templates/components/navigation/nav-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if onClickAction}}
    <div class="header-toolbar--container is-clickable" {{action onClickAction}}>
  
      {{#if description}}
        <div class="header-toolbar--title">
          {{#if isNavSubtitle}}
            <h1>{{t-exists description}} {{#if icon}}<i class={{icon}}></i>{{/if}}</h1>
            <div class="header-toolbar--subtitle">{{currentAccount}}</div>
          {{else}}
            <h1>{{t-exists description}} {{#if icon}}<i class={{icon}}></i>{{/if}}</h1>
          {{/if}}
        </div>
      {{/if}}
  
      {{yield}}
    </div>
  
  {{else }}
  
    <div class="header-toolbar--container">
  
      {{#if description}}
        <div class="header-toolbar--title">
          {{#if isNavSubtitle}}
            <h1>{{t-exists description}} {{#if icon}}<i class={{icon}}></i>{{/if}}</h1>
            <div class="header-toolbar--subtitle">{{currentAccount}}</div>
          {{else}}
            <h1>{{t-exists description}} {{#if icon}}<i class={{icon}}></i>{{/if}}</h1>
          {{/if}}
        </div>
      {{/if}}
  
      {{yield}}
    </div>
  {{/if}}
  
  
  */
  {
    "id": "sn1kV3zh",
    "block": "[[[41,[33,1],[[[1,\"  \"],[11,0],[24,0,\"header-toolbar--container is-clickable\"],[4,[38,2],[[30,0],[33,1]],null],[12],[1,\"\\n\\n\"],[41,[33,3],[[[1,\"      \"],[10,0],[14,0,\"header-toolbar--title\"],[12],[1,\"\\n\"],[41,[33,4],[[[1,\"          \"],[10,\"h1\"],[12],[1,[28,[35,5],[[33,3]],null]],[1,\" \"],[41,[33,6],[[[10,\"i\"],[15,0,[36,6]],[12],[13]],[]],null],[13],[1,\"\\n          \"],[10,0],[14,0,\"header-toolbar--subtitle\"],[12],[1,[34,7]],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,\"h1\"],[12],[1,[28,[35,5],[[33,3]],null]],[1,\" \"],[41,[33,6],[[[10,\"i\"],[15,0,[36,6]],[12],[13]],[]],null],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]],[[[1,\"\\n  \"],[10,0],[14,0,\"header-toolbar--container\"],[12],[1,\"\\n\\n\"],[41,[33,3],[[[1,\"      \"],[10,0],[14,0,\"header-toolbar--title\"],[12],[1,\"\\n\"],[41,[33,4],[[[1,\"          \"],[10,\"h1\"],[12],[1,[28,[35,5],[[33,3]],null]],[1,\" \"],[41,[33,6],[[[10,\"i\"],[15,0,[36,6]],[12],[13]],[]],null],[13],[1,\"\\n          \"],[10,0],[14,0,\"header-toolbar--subtitle\"],[12],[1,[34,7]],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,\"h1\"],[12],[1,[28,[35,5],[[33,3]],null]],[1,\" \"],[41,[33,6],[[[10,\"i\"],[15,0,[36,6]],[12],[13]],[]],null],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"onClickAction\",\"action\",\"description\",\"isNavSubtitle\",\"t-exists\",\"icon\",\"currentAccount\",\"yield\"]]",
    "moduleName": "parro-web/templates/components/navigation/nav-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});