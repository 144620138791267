define("parro-web/helpers/is-guardian", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Convenience helper to check if the current user (session) is of type guardian.
   */
  var _default = _ember.default.Helper.extend({
    account: _ember.default.inject.service(),

    compute() {
      return this.get('account.isGuardian');
    }

  });

  _exports.default = _default;
});