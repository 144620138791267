define("parro-web/objects/DataProvider", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Object.extend({
    hasNext: () => false,
    next: () => undefined
  });

  _exports.default = _default;
});