define("parro-web/initializers/override-jQuery", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Add jQuery to window when it's not defined.
  var _default = {
    name: 'override-jQuery',
    initialize: function () {
      window.$ = _jquery.default;
    }
  };
  _exports.default = _default;
});