define("parro-web/initializers/ajaxprogressbar", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ajax-progress-bar-trigger',

    initialize() {
      // Shows the loading-spinner when page is loading. Like when logging in.
      let timer;
      $(document).ajaxSend((event, jqXHR, ajaxOptions) => {
        // AJAX option to NEVER show loader or show loader after specified interval.
        const disable = ajaxOptions.disableAjaxLoader;

        if (!timer && disable !== true) {
          const interval = $.isNumeric(disable) ? Math.max(0, disable) : 0;
          timer = _ember.default.run.later(this, () => $('#loading').addClass('ajaxactive'), interval);
        }
      });
      $(document).ajaxStop(function () {
        _ember.default.run.cancel(timer);

        $('#loading').removeClass('ajaxactive');
        timer = undefined;
      });
    }

  };
  _exports.default = _default;
});