define("parro-web/services/dataprovider", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Service,
    isNone,
    isPresent
  } = _ember.default;

  var _default = Service.extend({
    findId(object, type) {
      const link = this.findLink(object, type, 'id');
      return link ? link.id : undefined;
    },

    findHref(object, type) {
      const link = this.findLink(object, type, 'href');
      return link ? link.href : undefined;
    },

    findLink(object, type, field) {
      if (isNone(object) || typeof object !== 'object' || isNone(object.links) || isNone(type)) {
        return undefined;
      }

      return object.links.find(link => link.rel === type && !isNone(link[field]));
    },

    getAdditionalObject(hash, property) {
      if (isPresent(hash) && isPresent(hash.additionalObjects) && isPresent(hash.additionalObjects[property])) {
        return hash.additionalObjects[property];
      }

      return undefined;
    }

  });

  _exports.default = _default;
});