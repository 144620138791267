define("parro-web/templates/components/button/button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <button {{action onClickAction}} class="{{className}} {{if isDisabled 'is-inactive' ''}} {{if isNotClickable 'is-not-clickable' ''}}" type="submit" disabled={{isNotClickable}}>
    {{#if isLoading}}<div class="loader--spinner-button"></div>{{/if}}
    {{#if icon}}<i class={{icon}}></i>{{/if}}
    {{#if title}}<span>{{t-exists title}}</span>{{/if}}
  </button>
  */
  {
    "id": "DZwoMheb",
    "block": "[[[11,\"button\"],[16,0,[29,[[36,0],\" \",[52,[33,2],\"is-inactive\",\"\"],\" \",[52,[33,3],\"is-not-clickable\",\"\"]]]],[16,\"disabled\",[36,3]],[24,4,\"submit\"],[4,[38,4],[[30,0],[33,5]],null],[12],[1,\"\\n  \"],[41,[33,6],[[[10,0],[14,0,\"loader--spinner-button\"],[12],[13]],[]],null],[1,\"\\n  \"],[41,[33,7],[[[10,\"i\"],[15,0,[36,7]],[12],[13]],[]],null],[1,\"\\n  \"],[41,[33,8],[[[10,1],[12],[1,[28,[35,9],[[33,8]],null]],[13]],[]],null],[1,\"\\n\"],[13]],[],false,[\"className\",\"if\",\"isDisabled\",\"isNotClickable\",\"action\",\"onClickAction\",\"isLoading\",\"icon\",\"title\",\"t-exists\"]]",
    "moduleName": "parro-web/templates/components/button/button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});