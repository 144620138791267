define("parro-web/templates/components/yearbook/templates/template-back", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPreview}}<div class="yearbook--shadow"></div>{{/if}}
  <article>
    <figure class="figure--plane" style={{figure}}></figure>
  </article>
  <footer>
    <span>talk.parro.com</span>
    <figure class="figure--parro" style={{parro}}></figure>
  </footer>
  */
  {
    "id": "2F7h82TG",
    "block": "[[[41,[33,1],[[[10,0],[14,0,\"yearbook--shadow\"],[12],[13]],[]],null],[1,\"\\n\"],[10,\"article\"],[12],[1,\"\\n  \"],[10,\"figure\"],[14,0,\"figure--plane\"],[15,5,[36,2]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"footer\"],[12],[1,\"\\n  \"],[10,1],[12],[1,\"talk.parro.com\"],[13],[1,\"\\n  \"],[10,\"figure\"],[14,0,\"figure--parro\"],[15,5,[36,3]],[12],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"isPreview\",\"figure\",\"parro\"]]",
    "moduleName": "parro-web/templates/components/yearbook/templates/template-back.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});