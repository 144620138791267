define("parro-web/templates/components/flashmessage/flash-message-queue", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if flashMessages.queue}}
    {{#each flashMessages.queue as |flash|}}
      {{#flash-message flash=flash as |component flash|}}
        {{#if (eq flash.type 'error')}}
          <i class="icon-circle-cross"></i>
        {{else if (eq flash.type 'warning')}}
          <i class="icon-warning"></i>
        {{else if (eq flash.type 'info')}}
          <i class="icon-info-solid"></i>
        {{else if (eq flash.type 'success')}}
          <i class="icon-circle-check"></i>
        {{/if}}
        <span>{{flash.message}}</span>
        <a class="icon-cross-thin"></a>
      {{/flash-message}}
    {{/each}}
  {{/if}}
  */
  {
    "id": "vBfdPsW0",
    "block": "[[[41,[33,1,[\"queue\"]],[[[42,[28,[37,3],[[28,[37,3],[[33,1,[\"queue\"]]],null]],null],null,[[[6,[39,4],null,[[\"flash\"],[[30,1]]],[[\"default\"],[[[[41,[28,[37,5],[[30,3,[\"type\"]],\"error\"],null],[[[1,\"        \"],[10,\"i\"],[14,0,\"icon-circle-cross\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,5],[[30,3,[\"type\"]],\"warning\"],null],[[[1,\"        \"],[10,\"i\"],[14,0,\"icon-warning\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,5],[[30,3,[\"type\"]],\"info\"],null],[[[1,\"        \"],[10,\"i\"],[14,0,\"icon-info-solid\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,5],[[30,3,[\"type\"]],\"success\"],null],[[[1,\"        \"],[10,\"i\"],[14,0,\"icon-circle-check\"],[12],[13],[1,\"\\n      \"]],[]],null]],[]]]],[]]]],[]]],[1,\"      \"],[10,1],[12],[1,[30,3,[\"message\"]]],[13],[1,\"\\n      \"],[10,3],[14,0,\"icon-cross-thin\"],[12],[13],[1,\"\\n\"]],[2,3]]]]]],[1]],null]],[]],null]],[\"flash\",\"component\",\"flash\"],false,[\"if\",\"flashMessages\",\"each\",\"-track-array\",\"flash-message\",\"eq\"]]",
    "moduleName": "parro-web/templates/components/flashmessage/flash-message-queue.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});