define("parro-web/services/yearbook-service", ["exports", "ember", "parro-web/objects/DataProvider", "parro-web/objects/YearbookTemplate", "parro-web/objects/YearbookImage", "parro-web/models/services/dialogs", "parro-web/models/services/comparators", "@twemoji/api"], function (_exports, _ember, _DataProvider, _YearbookTemplate, _YearbookImage, _dialogs, _comparators, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    RSVP: {
      Promise
    },
    isPresent,
    isEmpty
  } = _ember.default;
  const assign = _ember.default.assign;
  const DEFAULT_WIDTH = 216;
  const DEFAULT_HEIGHT = 216;
  const HARDCOVER_WIDTH = 466;
  const HARDCOVER_HEIGHT = 252;
  const SOFTCOVER_WIDTH = 426;
  const STYLESHEETS = ['_main.css', '_components.css', '_fonts.css', '_icons.css', '_front.css', '_back.css', '_cover.css', '_intro.css', '_outro-1.css', '_outro-2.css', '_template-1.css', '_template-2.css', '_template-3.css', '_template-4.css', '_template-5.css', '_template-6.css', '_template-7.css', '_template-8.css', '_template-9.css', '_template-10.css'];

  var _default = _ember.default.Service.extend({
    ajaxService: service('ajax'),
    promiseService: service(),
    fileService: service(),
    yearbookAjaxService: service(),
    store: service(),
    historyRoute: undefined,
    beforeTemplates: [_ember.default.Object.create({
      template: {
        name: 'template-front',
        className: 'front'
      }
    }), _ember.default.Object.create({
      template: {
        name: 'template-blank',
        className: 'blank'
      }
    }), _ember.default.Object.create({
      template: {
        name: 'template-intro',
        className: 'intro'
      }
    })],
    replacementTemplates: [_ember.default.Object.create({
      template: {
        name: 'template-outro-1',
        className: 'outro'
      },
      content: 'yearbook_templates.outro_1'
    }), _ember.default.Object.create({
      template: {
        name: 'template-outro-1',
        className: 'outro'
      },
      content: 'yearbook_templates.outro_2'
    }), _ember.default.Object.create({
      template: {
        name: 'template-outro-2',
        className: 'outro2'
      }
    })],
    afterTemplates: [_ember.default.Object.create({
      template: {
        name: 'template-blank',
        className: 'blank'
      }
    }), _ember.default.Object.create({
      template: {
        name: 'template-blank',
        className: 'blank'
      }
    }), _ember.default.Object.create({
      template: {
        name: 'template-back',
        className: 'back'
      }
    })],
    templates: [_YearbookTemplate.default.create({
      id: 1,
      used: 0,
      name: 'template-1',
      className: 'template-1',
      hasContent: false,
      images: ['PORTRAIT'],
      width: 106,
      height: 106
    }), _YearbookTemplate.default.create({
      id: 2,
      used: 0,
      name: 'template-2',
      className: 'template-2',
      hasContent: false,
      images: ['PORTRAIT'],
      width: 158,
      height: 210
    }), _YearbookTemplate.default.create({
      id: 3,
      used: 0,
      name: 'template-3',
      className: 'template-3',
      hasContent: true,
      images: ['PORTRAIT'],
      width: 106,
      height: 184
    }), _YearbookTemplate.default.create({
      id: 4,
      used: 0,
      name: 'template-4',
      className: 'template-4',
      hasContent: true,
      images: ['SQUARE'],
      width: 106,
      height: 106
    }), _YearbookTemplate.default.create({
      id: 5,
      used: 0,
      name: 'template-5',
      className: 'template-5',
      hasContent: false,
      images: ['LANDSCAPE'],
      width: 180,
      height: 106
    }), _YearbookTemplate.default.create({
      id: 6,
      used: 0,
      name: 'template-6',
      className: 'template-6',
      hasContent: true,
      images: ['LANDSCAPE'],
      width: 180,
      height: 106
    }), _YearbookTemplate.default.create({
      id: 7,
      used: 0,
      name: 'template-7',
      className: 'template-7',
      hasContent: false,
      images: ['SQUARE'],
      width: 135,
      height: 135
    }), _YearbookTemplate.default.create({
      id: 8,
      used: 0,
      name: 'template-8',
      className: 'template-8',
      hasContent: false,
      images: ['SQUARE'],
      width: 180,
      height: 180
    }), _YearbookTemplate.default.create({
      id: 9,
      used: 0,
      name: 'template-9',
      className: 'template-9',
      hasContent: true,
      images: ['PORTRAIT'],
      width: 121,
      height: 210
    }), _YearbookTemplate.default.create({
      id: 10,
      used: 0,
      name: 'template-10',
      className: 'template-10',
      hasContent: true,
      images: ['SQUARE'],
      width: 143,
      height: 143
    })],
    figures: [_YearbookImage.default.create({
      type: 'IMAGE',
      used: 0,
      className: 'figure--selfie',
      fileName: 'selfie.png'
    }), _YearbookImage.default.create({
      type: 'IMAGE',
      used: 0,
      className: 'figure--bottle',
      fileName: 'help.png'
    }), _YearbookImage.default.create({
      type: 'IMAGE',
      used: 0,
      className: 'figure--kids',
      fileName: 'kids.png'
    }), _YearbookImage.default.create({
      type: 'IMAGE',
      used: 0,
      className: 'figure--computer',
      fileName: 'computer.png'
    }), _YearbookImage.default.create({
      type: 'IMAGE',
      used: 0,
      className: 'figure--kid',
      fileName: 'kid-baloon.png'
    }), _YearbookImage.default.create({
      type: 'IMAGE',
      used: 0,
      className: 'figure--diploma',
      fileName: 'diploma.png'
    }), _YearbookImage.default.create({
      type: 'IMAGE',
      used: 0,
      className: 'figure--excursie',
      fileName: 'excursie.png'
    }), _YearbookImage.default.create({
      type: 'IMAGE',
      used: 0,
      className: 'figure--wandelen',
      fileName: 'walk.png'
    }), _YearbookImage.default.create({
      type: 'IMAGE',
      used: 0,
      className: 'figure--digibord',
      fileName: 'digibord.png'
    })],
    shapes: [_YearbookImage.default.create({
      type: 'SHAPE',
      used: 0,
      className: 'figure--square',
      fileName: 'square.png'
    }), _YearbookImage.default.create({
      type: 'SHAPE',
      used: 0,
      className: 'figure--triangle',
      fileName: 'triangle.png'
    }), _YearbookImage.default.create({
      type: 'SHAPE',
      used: 0,
      className: 'figure--circle',
      fileName: 'circle.png'
    })],
    icons: [_YearbookImage.default.create({
      type: 'ICON',
      used: 0,
      className: 'icon--shape',
      fileName: 'ps-icon-lab-fish.svg'
    }), _YearbookImage.default.create({
      type: 'ICON',
      used: 0,
      className: 'icon--shape',
      fileName: 'ps-icon-lab-ghost.svg'
    }), _YearbookImage.default.create({
      type: 'ICON',
      used: 0,
      className: 'icon--shape',
      fileName: 'ps-icon-lab-toucan2.svg'
    }), _YearbookImage.default.create({
      type: 'ICON',
      used: 0,
      className: 'icon--shape',
      fileName: 'ps-icon-lab-dog.svg'
    }), _YearbookImage.default.create({
      type: 'ICON',
      used: 0,
      className: 'icon--shape',
      fileName: 'ps-icon-lab-cat.svg'
    }), _YearbookImage.default.create({
      type: 'ICON',
      used: 0,
      className: 'icon--shape',
      fileName: 'ps-icon-lab-rabbit.svg'
    }), _YearbookImage.default.create({
      type: 'ICON',
      used: 0,
      className: 'icon--shape',
      fileName: 'ps-icon-lab-duck.svg'
    })],

    getSelectionPages(yearbook) {
      const selection = this.get('store').peekAll('yearbookcontentselection').toArray().filter(s => parseInt(s.get('yearbookId')) === parseInt(yearbook.get('id')));
      const selectionPages = selection.sort((a, b) => _comparators.default.yearbookPages(a, b)).map(s => s.get('pages'));
      return selectionPages.reduce((acc, current) => acc.concat(current), []);
    },

    getAmountOfChosenImages(yearbook) {
      const records = this.getSelectionPages(yearbook);
      return records.toArray().reduce((acc, current) => acc + current.get('images.length'), 0);
    },

    getAmountOfContentPages(yearbook) {
      const amountOfStaticPages = 2;
      return this.getAmountOfChosenImages(yearbook) + amountOfStaticPages;
    },

    getAmountOfReplacementPages(yearbook) {
      const amountOfPages = this.getAmountOfContentPages(yearbook);
      const min = yearbook.get('minPages');

      if (amountOfPages > min && amountOfPages % 4 !== 0) {
        const amountOfReplacementPages = 4;
        return amountOfReplacementPages - amountOfPages % 4;
      }

      return 0;
    },

    getAmountOfPlaceholderPages(yearbook) {
      const amountOfPages = this.getAmountOfContentPages(yearbook);
      const min = yearbook.get('minPages');

      if (amountOfPages < min) {
        return min - amountOfPages;
      }

      return 0;
    },

    getAmountOfGeneratedPages(yearbook) {
      const amountOfPages = this.getAmountOfContentPages(yearbook);
      const min = yearbook.get('minPages');

      if (amountOfPages > min) {
        if (amountOfPages % 4 !== 0) {
          const amountOfReplacementPages = 4;
          return amountOfPages + (amountOfReplacementPages - amountOfPages % 4);
        }

        return amountOfPages;
      }

      return min;
    },

    getAmountOfBadImages(yearbook) {
      const pages = this.getSelectionPages(yearbook);
      let amountOfBadContent = pages.reduce((acc, current) => {
        const template = current.get('template');
        const images = current.get('images');
        const badImages = images.filter(img => this.determineBadQuality(template.get('width'), template.get('height'), img.get('largestImage')));
        return acc.concat(badImages);
      }, []).length;
      const frontCover = yearbook.get('frontCover.largestImage');

      if (this.determineBadQuality(210, 161.683, frontCover)) {
        amountOfBadContent = amountOfBadContent + 1;
      }

      return amountOfBadContent;
    },

    determineBadQuality(width, height, image) {
      if (isEmpty(image) || isEmpty(width) || isEmpty(height)) {
        return false;
      }

      const convertToPixels = mm => mm * 120 / 25.4;

      const widthTreshhold = convertToPixels(width) * 0.66;
      const heightTreshhold = convertToPixels(height) * 0.66;
      const imageWidth = image.get('width') || image.get('w');
      const imageHeight = image.get('height') || image.get('h');
      return imageWidth < widthTreshhold || imageHeight < heightTreshhold;
    },

    getBackWidth(yearbook) {
      if (yearbook.get('isSquareFold')) {
        return 0;
      }

      if (yearbook.get('isHardCover')) {
        return 8;
      }

      const amountOfPages = this.getAmountOfContentPages(yearbook);
      const width = amountOfPages / 2 * (1.08 * 170 / 1000) + 1;
      return parseFloat(width.toFixed(1));
    },

    getTotalPrice(yearbook, prices) {
      const filteredPrices = Array.orEmpty(prices).filter(p => p.get('type') === yearbook.get('type'));

      if (isEmpty(filteredPrices)) {
        return undefined;
      }

      const pages = this.getAmountOfGeneratedPages(yearbook);
      const minPages = yearbook.get('minPages');

      if (pages >= minPages) {
        return filteredPrices[Math.floor((pages - minPages) / 4)]?.price;
      }

      return prices[0]?.price;
    },

    convertPriceToDisplay(price) {
      const fixedPrice = isPresent(price) && !isNaN(price) ? '€ ' + price.toFixed(2) : '';
      return fixedPrice.replace('.', ',');
    },

    assignFigure() {
      const getLessUsed = list => list.reduce((min, p) => p.used < min.used ? p : min, list[0]);

      const figure = getLessUsed(this.get('figures'));
      figure.incrementProperty('used');
      return figure;
    },

    assignShape() {
      const getLessUsed = list => list.reduce((min, p) => p.used < min.used ? p : min, list[0]);

      const figure = getLessUsed(this.get('shapes'));
      const icon = getLessUsed(this.get('icons'));
      figure.incrementProperty('used');
      icon.incrementProperty('used');

      const copyFigure = _ember.default.Object.create(figure);

      copyFigure.set('icon', icon);
      return copyFigure;
    },

    getYearbookPages(yearbook, pages) {
      this._resetProperties();

      const amountOfPlaceholders = this.getAmountOfPlaceholderPages(yearbook);
      const amountOfReplacements = this.getAmountOfReplacementPages(yearbook);

      if (amountOfPlaceholders > 0) {
        this._addPlaceholderPages(pages, amountOfPlaceholders);
      }

      if (amountOfReplacements > 0) {
        this._addReplacementPages(pages, amountOfReplacements);
      }

      pages.unshiftObjects(this.get('beforeTemplates'));
      pages.pushObjects(this.get('afterTemplates'));
      return pages;
    },

    _addReplacementPages(templates, amount) {
      const replacementTemplates = this.get('replacementTemplates');
      const startIndex = replacementTemplates.length - amount;
      const sliced = replacementTemplates.slice(startIndex, replacementTemplates.length);

      for (let i = 0; i < amount; i++) {
        templates.pushObject(sliced[i]);
      }
    },

    _addPlaceholderPages(templates, amount) {
      const placeholderTemplate = _ember.default.Object.create({
        template: {
          name: 'template-placeholder',
          className: 'placeholder'
        }
      });

      for (let i = 0; i < amount; i++) {
        templates.pushObject(placeholderTemplate);
      }
    },

    _resetProperties() {
      this.get('figures').forEach(figure => figure.set('used', 0));
      this.get('shapes').forEach(figure => figure.set('used', 0));
      this.get('icons').forEach(figure => figure.set('used', 0));
    },

    assignTemplate(item) {
      const schema = {
        content: (value, template) => {
          return !isPresent(value) && !template.get('hasContent') || isPresent(value) && isPresent(value) && template.get('hasContent');
        },
        images: (value, template) => {
          const formats = Array.orEmpty(value).map(image => image.get('largestImage.format'));
          return Array.isEqual(formats, template.get('images'));
        }
      };

      const onFilter = template => this._validateTemplate(item, template, schema);

      const onMap = template => ({
        id: template.id,
        className: template.className,
        name: template.name,
        used: template.used,
        width: template.width,
        height: template.height
      });

      const getLessUsed = list => list.reduce((min, p) => p.used < min.used ? p : min, list[0]);

      const validatedTemplates = this.get('templates').filter(onFilter).map(onMap);
      const filtered = this.get('templates').filter(t => isPresent(validatedTemplates.find(vt => vt.id === t.id)));

      if (Array.isSimilarToArray(filtered) && filtered.length > 0) {
        const chosen = getLessUsed(filtered);
        chosen.incrementProperty('used');
        return chosen;
      }

      return undefined;
    },

    _validateTemplate(item, template, schema) {
      const onMap = property => {
        const validator = schema[property];
        const response = {};
        response[property] = validator(item[property], template);
        return response;
      };

      const validation = Object.keys(schema).map(onMap).reduce((a, b) => assign(a, b), {});
      return validation.content && validation.images;
    },

    _getCssFile(file) {
      return new Promise((resolve, reject) => {
        const request = {
          type: 'GET',
          url: window.location.origin + '/assets/yearbook/' + file,
          dataType: 'text',
          disableAjaxLoader: true,
          beforeSend: () => {}
        };
        this.get('ajaxService').executeRequest(request).then(css => resolve(css)).catch(() => reject('error.failed_css_file'));
      });
    },

    getCss() {
      const promiseService = this.get('promiseService');
      return new Promise((resolve, reject) => {
        const _this = this;

        let index = 0;

        const provider = _DataProvider.default.create({
          hasNext: function () {
            return index < STYLESHEETS.length;
          },
          next: function () {
            const stylesheet = STYLESHEETS[index];
            index++;
            return _this._getCssFile(stylesheet);
          }
        });

        promiseService.processSequentially(provider, [], resolve, reject);
      });
    },

    createIframe() {
      return new Promise((resolve, reject) => {
        try {
          const files = $('.yearbook--generate');
          const iframes = [];

          if (files.length === 0) {
            return reject('error.failed_iframe');
          }

          files.each(index => {
            const className = 'iframe-' + index;
            $('<iframe id="' + className + '"/>').appendTo('body');
            const iframe = $('#' + className + '');
            iframes.push(iframe);
          });
          resolve(iframes);
        } catch (e) {
          console.log(e);
          reject('error.failed_iframe');
        }
      });
    },

    getContent(yearbook) {
      return new Promise((resolve, reject) => {
        try {
          const files = $('.yearbook--generate');
          const backWidth = this.getBackWidth(yearbook) + 'mm';
          const cover = [];
          const templates = [];

          if (files.length === 0 || isPresent($('.yearbook--generate').find('.undefined'))) {
            return reject('error.failed_iframe');
          }

          files.each((index, element) => {
            const clones = $(element).find('section.yearbook--template').clone();
            const back = $(element).find('section.yearbook--cover-back').clone();
            clones.removeClass('yearbook--cover-preview');
            clones.addClass('bleed');

            if (yearbook.get('isHardCover') && index === 0) {
              clones.removeClass('is-hard-cover');
              clones.addClass('hard-cover');
              back.addClass('hard-cover');
              back.width(backWidth);
            } else if (yearbook.get('isSoftCover') && index === 0) {
              clones.removeClass('is-soft-cover');
              clones.addClass('soft-cover');
              back.width(backWidth);
            }

            if (files.length === 2 && index === 0) {
              clones.each((_, element) => cover.push(element));

              if (isPresent(back)) {
                cover.insertAt(1, back[0]);
              }
            } else {
              clones.each((_, element) => templates.push(element));
            }
          });
          resolve({
            templates: templates,
            cover: cover
          });
        } catch (e) {
          console.log(e);
          reject('error.failed_get_body');
        }
      });
    },

    addStyling(iframes, css) {
      return new Promise((resolve, reject) => {
        try {
          const emojiCDN = 'https://cdn.jsdelivr.net/npm/@twemoji/api@15.0.2/dist/twemoji.min.js';
          const utfMeta = $('<meta charset="utf-8">');
          const emojiScript = $('<script src=' + emojiCDN + '></script>');
          const onloadScript = $('<script>window.onload = function () {twemoji.parse(document.body);}</script>');
          iframes.forEach(iframe => {
            const elements = [];
            css.forEach(c => {
              const style = $('<style>' + c + '</style>');
              elements.push(style);
            });
            iframe.contents().find('head').append(elements);
            iframe.contents().find('head').append(utfMeta);
            iframe.contents().find('head').append(emojiScript);
            iframe.contents().find('head').append(onloadScript);
          });
          resolve(iframes);
        } catch (e) {
          console.log(e);
          reject('error.failed_styling');
        }
      });
    },

    addContent(iframes, content) {
      return new Promise((resolve, reject) => {
        try {
          iframes.forEach((iframe, index) => {
            if (index === 0 && isPresent(content.cover)) {
              iframe.contents().find('body').append(content.cover);
            } else {
              iframe.contents().find('body').append(content.templates);
            }
          });
          resolve(iframes);
        } catch (e) {
          console.log(e);
          reject('error.failed_add_body');
        }
      });
    },

    createDocuments(iframes, yearbook) {
      return new Promise((resolve, reject) => {
        try {
          const documents = [];
          const isSquareFold = yearbook.get('isSquareFold');
          const numberOfFiles = isSquareFold ? 1 : 2;
          const backWidth = this.getBackWidth(yearbook);
          iframes.forEach((iframe, index) => {
            if (isEmpty(iframe.contents().find('body').html())) {
              return reject('error.failed_empty_body');
            }

            const blob = new Blob([iframe.contents().find('html')[0].outerHTML], {
              type: 'text/html; charset=utf-8'
            });
            const file = this.get('fileService').convertBlobToFile(blob, 'yearbook.html');
            let pageWidth = DEFAULT_WIDTH;
            let pageHeight = DEFAULT_HEIGHT;

            if (yearbook.get('isHardCover') && index === 0) {
              pageWidth = HARDCOVER_WIDTH + backWidth;
              pageHeight = HARDCOVER_HEIGHT;
            } else if (yearbook.get('isSoftCover') && index === 0) {
              pageWidth = SOFTCOVER_WIDTH + backWidth;
            }

            file.pageWidth = pageWidth;
            file.pageHeight = pageHeight;
            file.numberOfFiles = numberOfFiles;
            documents.push(file);
            iframe.remove();
          });
          resolve(documents);
        } catch (e) {
          console.log(e);
          reject('error.failed_blob');
        }
      });
    },

    getRouteGuard(currentRoute, yearbook) {
      const allowedRoutes = ['yearbook.type', 'yearbook.cover-images', 'yearbook.drawing', 'yearbook.schoolyear', 'yearbook.children'];
      const allowedPromoRoutes = ['yearbook.preview', 'yearbook.shopping-cart', 'yearbook.order'];
      const type = yearbook.get('type');
      const id = yearbook.get('id');

      if (yearbook.get('isPromo') && isPresent(allowedPromoRoutes.find(route => route === currentRoute))) {
        return {
          allowed: true,
          route: currentRoute,
          params: [id]
        };
      }

      if (!yearbook.get('isEditing')) {
        return {
          allowed: false,
          route: 'yearbook.overview'
        };
      }

      if (isEmpty(type)) {
        return {
          allowed: false,
          route: 'yearbook.type',
          params: [id]
        };
      }

      if (isPresent(allowedRoutes.find(route => route === currentRoute))) {
        return {
          allowed: true,
          route: currentRoute,
          params: [id]
        };
      }

      const name = yearbook.get('name');
      const image = yearbook.get('frontCover');
      const color = yearbook.get('color');

      if (isEmpty(name) || isEmpty(image) || isEmpty(color)) {
        const route = 'yearbook.front';
        return {
          allowed: currentRoute === route,
          route: route,
          params: [id]
        };
      }

      return {
        allowed: true,
        route: currentRoute,
        params: [id]
      };
    },

    getDialogConfig(yearbook, amount) {
      const isSquarefold = yearbook.get('type') === 'SQUARE_FOLD';

      const typeDialogConfig = _dialogs.default.yearbookTypeDialogConfig();

      const amountDialogConfig = _dialogs.default.yearbookAmountDialogConfig();

      const amountOfPages = this.getAmountOfContentPages(yearbook);

      if (isSquarefold && amountOfPages >= yearbook.get('maxPages') || isSquarefold && amount >= yearbook.get('maxPages')) {
        return typeDialogConfig;
      }

      if (amountOfPages >= yearbook.get('maxPages') || amount >= yearbook.get('maxPages')) {
        return amountDialogConfig;
      }

      return undefined;
    },

    isYearbookEnabled() {
      return new Promise((resolve, reject) => {
        const onSuccess = settings => {
          const enabled = isPresent(settings.find(s => s.get('isEnabled')));

          if (enabled) {
            return resolve();
          }

          reject('error.unknown_error');
        };

        const onError = () => reject('error.unknown_error');

        this.get('yearbookAjaxService').getYearbookSettings().then(onSuccess, onError);
      });
    },

    getAllowedOrganisationCodes() {
      const onFilter = setting => setting.get('isEnabled');

      const onMap = setting => setting.get('organisation.code');

      return Array.orEmpty(this.store.peekAll('yearbooksetting')).filter(onFilter).map(onMap);
    },

    addEmoticonStyling(context, container) {
      if (typeof container === 'object') {
        _api.default.parse(container);
      } else if (typeof container === 'string') {
        _ember.default.run.schedule('afterRender', context, () => _api.default.parse($(container)[0]));
      }
    }

  });

  _exports.default = _default;
});