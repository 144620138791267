define("parro-web/templates/components/yearbook/templates/template-intro", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPreview}}<div class="yearbook--shadow"></div>{{/if}}
  <article class="bg bg--text-small" style={{image}}>
    <h2>{{t 'yearbook_templates.intro'}}</h2>
    <div class="logo"></div>
  </article>
  
  */
  {
    "id": "1AgRATV1",
    "block": "[[[41,[33,1],[[[10,0],[14,0,\"yearbook--shadow\"],[12],[13]],[]],null],[1,\"\\n\"],[10,\"article\"],[14,0,\"bg bg--text-small\"],[15,5,[36,2]],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,3],[\"yearbook_templates.intro\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"logo\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"isPreview\",\"image\",\"t\"]]",
    "moduleName": "parro-web/templates/components/yearbook/templates/template-intro.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});