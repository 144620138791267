define("parro-web/templates/components/modal/account-choice-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if config.visible}}
    <section class="modal is-dynamic" id="modal-start">
      <form class="modal--container">
        <div class="modal--header">
          <h1>{{t-exists config.title}}</h1>
        </div>
  
        <div class="modal--content">
          <ul class="list--choices scrollable">
            {{#each config.items as |item|}}
              {{settings/account-choice choice=item onClick=(action 'selectChoice' item)}}
              <hr>
            {{/each}}
          </ul>
        </div>
      </form>
    </section>
  
    <div id="modal--overlay" class="modal--overlay"></div>
  {{/if}}
  */
  {
    "id": "/J0ZkDBf",
    "block": "[[[41,[33,1,[\"visible\"]],[[[1,\"  \"],[10,\"section\"],[14,0,\"modal is-dynamic\"],[14,1,\"modal-start\"],[12],[1,\"\\n    \"],[10,\"form\"],[14,0,\"modal--container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"modal--header\"],[12],[1,\"\\n        \"],[10,\"h1\"],[12],[1,[28,[35,2],[[33,1,[\"title\"]]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"modal--content\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"list--choices scrollable\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,1,[\"items\"]]],null]],null],null,[[[1,\"            \"],[1,[28,[35,5],null,[[\"choice\",\"onClick\"],[[30,1],[28,[37,6],[[30,0],\"selectChoice\",[30,1]],null]]]]],[1,\"\\n            \"],[10,\"hr\"],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,1,\"modal--overlay\"],[14,0,\"modal--overlay\"],[12],[13],[1,\"\\n\"]],[]],null]],[\"item\"],false,[\"if\",\"config\",\"t-exists\",\"each\",\"-track-array\",\"settings/account-choice\",\"action\"]]",
    "moduleName": "parro-web/templates/components/modal/account-choice-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});