define("parro-web/utils/browserUtil", ["exports", "ember", "js-cookie", "@twemoji/api"], function (_exports, _ember, _jsCookie, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = BrowserUtil;
  const {
    isPresent
  } = _ember.default;

  function BrowserUtil() {}

  BrowserUtil.detectIE = function () {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE');
    return msie > 0 || !!ua.match(/Trident.*rv\:11\./);
  };

  BrowserUtil.setItem = function (property, value, expiresAT) {
    const val = typeof value === 'object' ? JSON.stringify(value) : value;

    if (BrowserUtil.isLocalStorageSupported()) {
      BrowserUtil.setLocalStorageItem(property, val);
    } else if (BrowserUtil.isCookiesSupported()) {
      BrowserUtil.setCookie(property, val, {
        expires: expiresAT
      });
    }
  };

  BrowserUtil.getItem = function (property) {
    if (BrowserUtil.isLocalStorageSupported()) {
      return BrowserUtil.getLocalStorageItem(property);
    } else if (BrowserUtil.isCookiesSupported()) {
      return BrowserUtil.getCookie(property);
    }
  };

  BrowserUtil.removeItem = function (property) {
    if (BrowserUtil.isLocalStorageSupported()) {
      BrowserUtil.removeLocalStorageItem(property);
    } else if (BrowserUtil.isCookiesSupported()) {
      BrowserUtil.removeCookie(property);
    }
  };

  BrowserUtil.setLocalStorageItem = function (property, value) {
    window.localStorage.setItem(property, value);
  };

  BrowserUtil.getLocalStorageItem = function (property) {
    return window.localStorage.getItem(property);
  };

  BrowserUtil.removeLocalStorageItem = function (property) {
    window.localStorage.removeItem(property);
  };

  BrowserUtil.setCookie = function (property, value, expiresAT) {
    const val = typeof value === 'object' ? JSON.stringify(value) : value;

    if (isPresent(expiresAT)) {
      _jsCookie.default.set(property, val, {
        expires: expiresAT
      });
    } else {
      _jsCookie.default.set(property, val);
    }
  };

  BrowserUtil.getCookie = function (property) {
    return _jsCookie.default.get(property);
  };

  BrowserUtil.removeCookie = function (property) {
    _jsCookie.default.remove(property);
  }; // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage


  BrowserUtil.isLocalStorageSupported = function () {
    try {
      const storage = window.localStorage;
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  };

  BrowserUtil.isCookiesSupported = function () {
    try {
      const x = '__cookie_test__';

      _jsCookie.default.set(x, x);

      _jsCookie.default.remove(x);

      return true;
    } catch (e) {
      return false;
    }
  };

  BrowserUtil.placeCaretAtEnd = function (element) {
    element.focus();

    if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
      const range = document.createRange();
      range.selectNodeContents(element);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(element);
      textRange.collapse(false);
      textRange.select();
    }
  };

  BrowserUtil.insertEmoji = function (element, emoji) {
    const div = document.createElement('div');
    div.innerHTML = emoji;

    _api.default.parse(div);

    element.appendChild(div.firstChild);
  };

  BrowserUtil.mutationObserver = function (element, callback) {
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

    if (isPresent(MutationObserver) && isPresent(element)) {
      const observer = new MutationObserver(callback);
      observer.observe(element, {
        childList: true,
        subtree: true,
        characterData: true
      });
    }
  };
});