define("parro-web/routes/application", ["exports", "ember", "parro-web/mixins/metricsMixin", "parro-web/mixins/LoginMixin", "parro-web/mixins/routeMixin"], function (_exports, _ember, _metricsMixin, _LoginMixin, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    isPresent,
    isEmpty
  } = _ember.default;

  var _default = _ember.default.Route.extend(_metricsMixin.default, _LoginMixin.default, _routeMixin.default, {
    session: service(),
    router: service(),
    EventBusService: service(),
    scheduledLoader: undefined,

    beforeModel(transition) {
      const target = transition.targetName;

      if (!this.get('session.isAuthenticated')) {
        if (target === 'logoff' || target === 'invalidate') {
          transition.abort();
          return this.transitionTo('login');
        }

        if (target === 'yearbook.index' || target === 'yearbook' || target === 'jaarboek') {
          transition.abort();
          return this.transitionTo('login.yearbook');
        }
      }
    },

    getContent() {
      const name = isPresent(this.get('router.currentRouteName')) ? this.get('router.currentRouteName') : undefined;
      const routeController = isPresent(name) ? this.controllerFor(name) : undefined;
      return isPresent(routeController) ? routeController.get('model') : undefined;
    },

    _showContent() {
      $('body').addClass('is-showcontent');
      $('#back-to-menu-button').removeClass('is-hidden');
      this.EventBusService.emit('show-content', this);
    },

    _hideContent() {
      $('body').removeClass('is-showcontent');
      $('#back-to-menu-button').addClass('is-hidden');
      this.EventBusService.emit('hide-content', this);
    },

    _showLoader(isLoading) {
      if (this.controller) {
        const scheduledLoader = this.get('scheduledLoader'); // HIDE LOADER

        if (!(isLoading === true || $.isNumeric(isLoading) && isLoading >= 0)) {
          _ember.default.run.cancel(scheduledLoader);

          this.set('controller.isLoading', false);
          this.set('scheduledLoader', undefined); // SHOW LOADER IF THERE'S NONE CURRENTLY (SCHEDULED)
        } else if (!scheduledLoader) {
          const runLater = () => {
            this.set('controller.isLoading', true);
            this.set('scheduledLoader', undefined);
          };

          const interval = isLoading >= 0 ? isLoading : 0;
          this.set('scheduledLoader', _ember.default.run.later(this, runLater, interval));
        }
      }
    },

    _resetController() {
      // Reset properties op de controller (singleton)
      const controller = this.controllerFor('application');

      if (isPresent(controller)) {
        controller.set('displayFlashMessages', true);
      }
    },

    actions: {
      requestConfirmation(config, ok, cancel) {
        if (this.get('controller.confirmationDialog')) {
          this.get('controller.confirmationDialog').confirm(config, ok, cancel);
        }
      },

      closeConfirmation() {
        if (this.get('controller.confirmationDialog')) {
          this.get('controller.confirmationDialog').close();
        }
      },

      loading(transition) {
        this._showLoader(true);

        this._resetController();

        transition.promise.finally(() => {
          this._showLoader(false);
        });
      },

      willTransition() {
        // Reset unsaved changes.
        const content = this.getContent();

        if (content && content.hasDirtyAttributes && content.get('hasDirtyAttributes')) {
          content.rollbackAttributes();
        }

        this._resetController();

        this.flashClear();
      },

      error(error) {
        if (isEmpty(error) || error.message === 'PAGE_NOT_FOUND' || error.message === 'SERVER_ERROR') {
          this.transitionTo('/oops');
        }

        const controller = this.controllerFor('application');

        if (isPresent(controller) && isPresent(controller.handleError)) {
          controller.handleError(error);
        }

        this._showContent();
      },

      toNavChoice(route, id, hideContent) {
        if (route) {
          if (id) {
            this.transitionTo(route, id);
          } else {
            this.transitionTo(route);
          }

          if (this.get('router.currentRouteName') !== route) {
            this._resetController();
          }

          if (hideContent) {
            this._hideContent();
          } else {
            this._showContent();
          }
        }
      },

      transitionTo() {
        this.transitionTo(...arguments);
      },

      showContent() {
        this._showContent();
      },

      hideContent() {
        this._hideContent();
      },

      showLoader(isLoading) {
        this._showLoader(isLoading);
      }

    }
  });

  _exports.default = _default;
});