define("parro-web/components/modal/yearbook/yearbook-dialog", ["exports", "ember", "parro-web/utils/browserUtil", "parro-web/models/navigation/navchoicemodel"], function (_exports, _ember, _browserUtil, _navchoicemodel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isPresent,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    account: service(),

    didInsertElement() {
      this._super(...arguments);

      _ember.default.run.schedule('routerTransitions', this, () => this.set('register', this));
    },

    actions: {
      show(config) {
        this.set('config', config);
        const name = config.get('name');

        if (!_browserUtil.default.getItem(name)) {
          _browserUtil.default.setItem(name, true);

          this.set('visible', true);
        }
      },

      close() {
        this.set('visible', false);

        const guardianTab = _navchoicemodel.default.create({
          name: 'yearbook_filter.tabs_child',
          route: 'CHILDREN'
        });

        const teacherTab = _navchoicemodel.default.create({
          name: 'yearbook_filter.tabs_group',
          route: 'GROUP'
        });

        const choiceModel = this.get('account.isGuardian') ? guardianTab : teacherTab;

        if (isPresent(this.onShowFilter)) {
          this.onShowFilter(choiceModel);
        }
      }

    }
  });

  _exports.default = _default;
});