define("parro-web/components/yearbook/yearbook-overview", ["exports", "ember", "parro-web/mixins/routeMixin"], function (_exports, _ember, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: '',
    store: service(),
    yearbookAjaxService: service(),
    yearbookService: service(),

    didInsertElement() {
      this._super(...arguments);

      this.get('yearbookService').addEmoticonStyling(this, $('.list--yearbook')[0]);
    },

    _resetProperties() {
      const yearbooks = this.store.peekAll('yearbook').toArray();
      yearbooks.forEach(yearbook => {
        const events = Array.orEmpty(yearbook.get('events'));
        events.forEach(event => {
          const images = event.get('getImages');
          images.forEach(image => image.set('isSelected', false));
        });
        yearbook.set('events', undefined);
      });
    },

    init() {
      this._super(...arguments);

      this._resetProperties();
    },

    actions: {
      createYearbook() {
        const onSuccess = data => {
          this.store.pushPayload({
            yearbook: data.items[0]
          });
          this.transitionTo('yearbook.type', data.items[0].id);
        };

        const onError = () => this.flashError('error.unknown_error');

        this.get('yearbookAjaxService').createYearbook().then(onSuccess, onError);
      },

      selectYearbook(yearbook) {
        this.get('yearbookDialogOptions').show(yearbook);
      }

    }
  });

  _exports.default = _default;
});