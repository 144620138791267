define("parro-web/components/yearbook/yearbook-filter-item", ["exports", "ember", "parro-web/mixins/routeMixin"], function (_exports, _ember, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: '',
    isSelected: computed('selected.[]', function () {
      return isPresent(this.get('selected').find(s => s === this.get('item.id')));
    })
  });

  _exports.default = _default;
});