define("parro-web/routes/yearbook/preview", ["exports", "parro-web/models/navigation/navchoicemodel", "ember", "parro-web/models/services/dialogs", "parro-web/mixins/routeMixin"], function (_exports, _navchoicemodel, _ember, _dialogs, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP,
    Route,
    inject: {
      service
    },
    isEmpty
  } = _ember.default;

  var _default = Route.extend(_routeMixin.default, {
    yearbookAjaxService: service(),
    yearbookService: service(),
    session: service(),

    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },

    model(params) {
      return this.get('yearbookAjaxService').getYearbook(params.yearbook).then(yearbook => {
        return RSVP.hash({
          navConfig: this._getConfig(),
          tabs: this._getTabs(yearbook),
          yearbook: yearbook,
          selection: this.get('yearbookAjaxService').getContentSelection(yearbook.get('id')),
          prices: this.get('yearbookAjaxService').getYearbookPrices(yearbook)
        });
      });
    },

    afterModel(model, transition) {
      if (isEmpty(model) || isEmpty(model.yearbook)) {
        return this.refresh();
      }

      const routeGuard = this.get('yearbookService').getRouteGuard(this.get('routeName'), model.yearbook);
      this.useRouteGuard(routeGuard, transition);
    },

    _getTabs(yearbook) {
      return [_navchoicemodel.default.create({
        name: 'yearbook_editor.title',
        model: yearbook,
        route: 'yearbook.editor'
      }), _navchoicemodel.default.create({
        name: 'yearbook_preview.title',
        model: yearbook,
        route: 'yearbook.preview'
      })];
    },

    _getConfig() {
      return _ember.default.Object.create({
        showMenu: () => this.showMenu(),
        onFinish: () => this.finishYearbook()
      });
    },

    showMenu() {
      this.get('controller.navMenuOverlay').show();
    },

    finishYearbook() {
      const yearbook = this.get('controller.model.yearbook');
      const amountOfPages = this.get('yearbookService').getAmountOfContentPages(yearbook);

      if (amountOfPages < yearbook.get('minPages')) {
        const amount = yearbook.get('minPages') - amountOfPages;
        const text = this.intl.t('yearbook_dialogs.limit_min_text', {
          amount: amount
        });

        const dialog = _dialogs.default.yearbookMinAmountDialogConfig(text);

        this.send('requestConfirmation', dialog, () => {
          this.transitionTo('yearbook.editor', yearbook.get('id'));
        });
      } else {
        this.transitionTo('yearbook.shopping-cart', yearbook.get('id'));
      }
    }

  });

  _exports.default = _default;
});