define("parro-web/utils/ticks", ["exports", "ember", "parro-web/models/time"], function (_exports, _ember, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = Ticks;
  const {
    assert,
    isPresent
  } = _ember.default;

  function Ticks() {}

  Ticks.min = 0; // 00:00

  Ticks.max = 23 * 60 + 59; // 23:59

  Ticks.toTicks = function (time) {
    assert('Must provide time', typeof time === 'object');
    assert('Time must have hours', isPresent(time.get('hours')));
    assert('Time must have minutes', isPresent(time.get('minutes')));
    return parseInt(time.get('hours') * 60) + parseInt(time.get('minutes'));
  };

  Ticks.toTime = function (ticks) {
    assert('Must provide ticks', isPresent(ticks));
    const hours = Math.floor(ticks / 60);
    const minutes = ticks % 60;
    return _time.default.create({}).setValues(hours, minutes, 0, 0);
  };

  Ticks.formatTicks = function (ticks, locale) {
    let hours = Math.floor(ticks / 60);
    const minutes = ticks % 60; // Convert to AM/PM - based on https://www.mathsisfun.com/time.html

    if (locale === 'en') {
      if (hours === 0 && minutes === 0) {
        return 'Midnight';
      }

      if (hours === 12 && minutes === 0) {
        return 'Noon';
      }

      if (hours === 24 && minutes === 0) {
        return 'Midnight';
      }

      let suffix = ' AM';

      if (hours >= 12) {
        suffix = ' PM';
        hours = hours - 12;
      }

      return hours + ':' + String.pad(minutes, 2) + suffix;
    } else if (hours === 0 && minutes === 0) {
      return 'Middernacht';
    } else if (hours === 24 && minutes === 0) {
      return 'Middernacht';
    }

    return String.pad(hours, 2) + ':' + String.pad(minutes, 2);
  };
});