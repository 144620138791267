define("parro-web/components/navigation/nav-menu-tabs", ["exports", "ember", "parro-web/models/services/dialogs"], function (_exports, _ember, _dialogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    router: service(),
    selected: undefined,

    init() {
      this._super(...arguments);

      _ember.default.run.schedule('render', this, () => {
        const path = this.router.currentRouteName;
        const tabs = Array.orEmpty(this.get('tabs'));

        if (!this.get('selected')) {
          this.set('selected', tabs.find(tab => tab.get('route') === path));
        }
      });
    },

    actions: {
      selectTab(tab, routeAction) {
        const model = isPresent(tab.get('model')) ? tab.get('model') : undefined;

        if (isPresent(model) && model.get('isPromo')) {
          return this.requestConfirmation(_dialogs.default.yearbookPromoDialogConfig());
        }

        this.set('selected', tab);

        if (isPresent(model)) {
          routeAction.apply(this, [tab.get('route'), model.get('id')]);
        }
      }

    }
  });

  _exports.default = _default;
});