define("parro-web/templates/components/yearbook/yearbook-payment-method", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{action onClickAction method.method }} class="yearbook--order-method-option {{if isActive 'is-active' ''}}">
    {{form/input-radio-button checked=isActive name='payment'}}
  
    {{#if image}}<figure class={{image}}></figure>{{/if}}
    <span>{{t-exists name}}</span>
    <span>{{t-exists paymentFee}}</span>
  </div>
  
  */
  {
    "id": "QxrZqfm3",
    "block": "[[[11,0],[16,0,[29,[\"yearbook--order-method-option \",[52,[33,1],\"is-active\",\"\"]]]],[4,[38,2],[[30,0],[33,3],[33,4,[\"method\"]]],null],[12],[1,\"\\n  \"],[1,[28,[35,5],null,[[\"checked\",\"name\"],[[33,1],\"payment\"]]]],[1,\"\\n\\n  \"],[41,[33,6],[[[10,\"figure\"],[15,0,[36,6]],[12],[13]],[]],null],[1,\"\\n  \"],[10,1],[12],[1,[28,[35,7],[[33,8]],null]],[13],[1,\"\\n  \"],[10,1],[12],[1,[28,[35,7],[[33,9]],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"isActive\",\"action\",\"onClickAction\",\"method\",\"form/input-radio-button\",\"image\",\"t-exists\",\"name\",\"paymentFee\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-payment-method.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});