define("parro-web/helpers/previous-value", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isPresent
  } = _ember.default;

  var _default = _ember.default.Helper.extend({
    /**
     * Returns previous value in array. Usages:
     * • previous-value <array> <index>
     * • previous-value <array> <index> <property>
     */
    compute(values) {
      const array = values[0];
      const index = values[1];
      const newArray = array.get && array.get('content') ? array.map(i => i) : array;
      const previous = newArray instanceof Array && index > 0 ? newArray[index - 1] : undefined;

      if (values.length === 3 && previous instanceof Object && isPresent(values[2])) {
        return isPresent(previous.get) ? previous.get(values[2]) : previous[values[2]];
      }

      return previous;
    }

  });

  _exports.default = _default;
});