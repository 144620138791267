define("parro-web/models/groupchild", ["exports", "ember-data", "ember", "parro-web/models/person", "ember-inflector"], function (_exports, _emberData, _ember, _person, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _person.default.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'identity.RGroupChildPrimer'
    }),
    cachedAvatarUrl: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    enrolledSince: _emberData.default.attr('date'),
    gender: _emberData.default.attr('string'),
    groupName: _emberData.default.attr('string'),
    guardians: _emberData.default.hasMany('groupguardian', {
      async: false
    }),
    inviteStatus: _emberData.default.attr('string'),
    inviteNumberOfGuardians: _emberData.default.attr('number'),
    parnassysGuardians: _emberData.default.hasMany('parnassysguardian'),
    identityId: _emberData.default.attr('number'),
    koppelingId: _emberData.default.attr('number'),
    isGuardian: false,
    isTeacher: false,
    isUsingParro: computed.gt('inviteNumberOfGuardians', 0),
    isAccepted: computed.equal('inviteStatus', 'ACCEPTED'),
    isInvited: computed.equal('inviteStatus', 'INVITED')
  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('groupchild', 'groupchild');
});