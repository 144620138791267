define("parro-web/components/yearbook/templates/template-main", ["exports", "ember", "parro-web/config/environment", "parro-web/models/services/dialogs"], function (_exports, _ember, _environment, _dialogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    String: {
      htmlSafe
    },
    isEmpty,
    isPresent,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: 'section',
    classNames: ['yearbook--template', 'yearbook--cover-preview'],
    classNameBindings: ['isBlue', 'isMagenta'],
    yearbookService: service(),
    isBlue: computed.equal('yearbook.color', '#2499BA'),
    isMagenta: computed.equal('yearbook.color', '#C9496A'),

    didInsertElement() {
      this._super(...arguments);

      this.get('yearbookService').addEmoticonStyling(this, $(this.element)[0]);
    },

    determineBadQuality(width, height, img) {
      const image = this.get('content.image.largestImage') || img;
      return this.get('yearbookService').determineBadQuality(width, height, image);
    },

    image: computed('content.image.msrc', 'content.image.src', 'isPreview', function () {
      const isPreview = this.get('isPreview');
      const imageAttachment = this.get('content.image');

      if (isEmpty(imageAttachment) && isPreview) {
        const url = _environment.default.externalUrls.amazonStaticImages + '/placeholder-no-image.jpg';
        return htmlSafe('background-image: url(' + url + ')');
      }

      const image = isPreview ? imageAttachment.get('smallestImage') : imageAttachment.get('largestImage');
      return htmlSafe('background-image: url(' + image.get('url') + ')');
    }),
    figure: computed('content.figure.fileName', 'content.figure.className', function () {
      const figure = this.get('content.figure');

      if (isEmpty(figure)) {
        return undefined;
      }

      const url = _environment.default.externalUrls.amazonStaticImages + '/' + figure.get('fileName');
      return _ember.default.Object.create({
        className: figure.get('className'),
        style: isPresent(url) ? htmlSafe('background-image: url(' + url + ')') : undefined
      });
    }),
    icon: computed('content.figure.icon', function () {
      const icon = this.get('content.figure.icon');

      if (isEmpty(icon)) {
        return undefined;
      }

      const url = _environment.default.externalUrls.amazonStaticImages + '/' + icon.get('fileName');
      return _ember.default.Object.create({
        className: icon.get('className'),
        style: htmlSafe('background-image: url(' + url + ')')
      });
    }),
    actions: {
      showWarning() {
        this.requestConfirmation(_dialogs.default.yearbookBadQualityDialogConfig(), () => this.selectTemplate());
      }

    }
  });

  _exports.default = _default;
});