define("parro-web/components/yearbook/yearbook-type-item", ["exports", "ember", "parro-web/utils/mobileUtil"], function (_exports, _ember, _mobileUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject: {
      service
    },
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    yearbookService: service(),
    yearbookAjaxService: service(),

    _getImages(type) {
      let images = [];

      switch (type) {
        case 'HARDCOVER':
          images = this.get('hardCoverImages');
          break;

        case 'SOFTCOVER':
          images = this.get('softCoverImages');
          break;

        case 'SQUARE_FOLD':
          images = this.get('squareFoldImages');
          break;
      }

      const previewImage = this._getPreviewImage(type);

      if (isPresent(previewImage)) {
        images.unshift(previewImage);
      }

      return images;
    },

    _getPreviewImage(type) {
      if (_mobileUtil.default.isMobileDevice()) {
        switch (type) {
          case 'HARDCOVER':
            return '/assets/img/preview-hardcover.png';

          case 'SOFTCOVER':
            return '/assets/img/preview-softcover.png';

          case 'SQUARE_FOLD':
            return '/assets/img/preview-square-fold.png';
        }
      }

      return undefined;
    },

    images: computed('type.type', function () {
      return this._getImages(this.get('type.type'));
    }),
    isSelected: computed('yearbook.type', 'type.type', function () {
      return this.get('yearbook.type') === this.get('type.type');
    }),
    isPopular: computed.equal('type.type', 'HARDCOVER'),
    isInactive: computed('type.type', function () {
      const type = this.get('type');
      const isSquarefold = type.type === 'SQUARE_FOLD';
      const amountOfPages = this.get('yearbookService').getAmountOfContentPages(this.get('yearbook'));
      return isSquarefold && amountOfPages > type.maxPageSize;
    }),
    price: computed('type.price', function () {
      return this.get('yearbookService').convertPriceToDisplay(this.get('type.basePrice'));
    }),
    title: computed('type.type', function () {
      const type = this.get('type.type');

      switch (type) {
        case 'SQUARE_FOLD':
          return 'yearbook_type.type_squarefold';

        case 'SOFTCOVER':
          return 'yearbook_type.type_softcover';

        case 'HARDCOVER':
          return 'yearbook_type.type_hardcover';
      }

      return undefined;
    }),
    image: computed('type.type', function () {
      const type = this.get('type.type');

      switch (type) {
        case 'SQUARE_FOLD':
          return 'figure--yearbook-square-fold';

        case 'SOFTCOVER':
          return 'figure--yearbook-softcover';

        case 'HARDCOVER':
          return 'figure--yearbook-hardcover';
      }

      return undefined;
    }),
    displayMinPageSize: computed('type.maxPageSize', function () {
      return parseInt(this.get('type.defaultPageSize')) - 2;
    }),
    displayMaxPageSize: computed('type.maxPageSize', function () {
      return parseInt(this.get('type.maxPageSize')) - 2;
    }),
    actions: {
      selectType(yearbook, type) {
        if (this.get('isSelected')) {
          return;
        }

        yearbook.set('type', type.type);
        this.set('showFirstText', true);
        this.set('amountOfSlides', 0);

        const onSuccess = () => {};

        const onError = () => this.flashError('error.unknown_error');

        this.get('yearbookAjaxService').editYearbook(yearbook).then(onSuccess, onError);
      }

    }
  });

  _exports.default = _default;
});