define("parro-web/mixins/fileInputMixin", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Mixin.create({
    clearFileInput(inputContainerId) {
      assert('Must provide inputContainerId', isPresent(inputContainerId));
      const input = $('#' + inputContainerId).find('input')[0];
      assert('Container must contain an input component', isPresent(input));

      try {
        if (/MSIE/.test(navigator.userAgent)) {
          $(input).replaceWith($(input).clone(true));
        } else {
          $(input).val('');
        }
      } catch (e) {}
    }

  });

  _exports.default = _default;
});