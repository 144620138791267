define("parro-web/models/attachment", ["exports", "parro-web/models/_attachment", "ember-data"], function (_exports, _attachment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _attachment.default.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'attachment.RAnnouncementAttachment'
    }),
    // can also be RChatMessageAttachment
    index: _emberData.default.attr('number'),
    entries: _emberData.default.hasMany('attachmententry', {
      async: false
    })
  });

  _exports.default = _default;
});