define("parro-web/models/yearbookprice", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'yearbook.RYearbookPrices'
    }),
    priceId: _emberData.default.attr('string'),
    pages: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    type: _emberData.default.attr('string')
  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('yearbookprice', 'yearbookprice');
});