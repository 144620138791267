define("parro-web/initializers/override-Date", ["exports", "ember", "parro-web/utils/timeUtil", "moment"], function (_exports, _ember, _timeUtil, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    isNone
  } = _ember.default;
  var _default = {
    name: 'override-Date',
    initialize: function () {
      /* Extend Date with extra functions. Based on http://stackoverflow.com/a/15289883 */
      assert('Date.isValid already present', isNone(Date.isValid));

      Date.isValid = function (date) {
        return date instanceof Date && !isNaN(date.getTime());
      };

      assert('Date.at0000 already present', isNone(Date.at0000));

      Date.at0000 = function (date) {
        if (!Date.isValid(date)) {
          return undefined;
        }

        const newDate = new Date(date.getTime());
        newDate.setHours(0, 0, 0, 0);
        return newDate;
      };

      assert('Date.at2359 already present', isNone(Date.at2359));

      Date.at2359 = function (date) {
        if (!Date.isValid(date)) {
          return undefined;
        }

        const time = _timeUtil.default.createTime(23, 59);

        return Date.timeAsDate(date, time);
      };

      assert('Date.addDays already present', isNone(Date.addDays));

      Date.addDays = function (date, days) {
        const newDate = new Date(date.getTime());
        newDate.setDate(newDate.getDate() + days);
        return newDate;
      };

      assert('Date.subtractDays already present', isNone(Date.subtractDays));

      Date.subtractDays = function (date, days) {
        const newDate = new Date(date.getTime());
        newDate.setDate(newDate.getDate() - days);
        return newDate;
      };

      assert('Date.addMinutes already present', isNone(Date.addMinutes));

      Date.addMinutes = function (date, minutes) {
        const newDate = (0, _moment.default)(date).add(minutes, 'm').toDate();
        newDate.setSeconds(0);
        return newDate;
      };

      assert('Date.subtractMinutes already present', isNone(Date.subtractMinutes));

      Date.subtractMinutes = function (date, minutes) {
        const newDate = (0, _moment.default)(date).subtract(minutes, 'm').toDate();
        newDate.setSeconds(0);
        return newDate;
      }; // FIXME How to make these readonly?


      Date._MS_PER_DAY = 1000 * 60 * 60 * 24;
      Date._MS_PER_MINUTE = 1000 * 60;
      Date._MS_PER_SECOND = 1000;
      Date.today = Date.at0000(new Date());
      Date.yesterday = Date.addDays(Date.today, -1);
      Date.tomorrow = Date.addDays(Date.today, +1);

      Date.isToday = date => Date.isValid(date) && Date.differenceInDays(new Date(), date) === 0;

      Date.isYesterday = date => Date.isValid(date) && Date.differenceInDays(new Date(), date) === -1;

      Date.isTomorrow = date => Date.isValid(date) && Date.differenceInDays(new Date(), date) === 1;

      Date.isThisYear = date => Date.isValid(date) && new Date().getUTCFullYear() === date.getUTCFullYear();

      Date.isWeekend = date => Date.isValid(date) && !(date.getDay() % 6);

      assert('Date.differenceInDays already present', isNone(Date.differenceInDays));

      Date.differenceInDays = function (a, b) {
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / Date._MS_PER_DAY);
      };

      assert('Date.differenceInMinutes already present', isNone(Date.differenceInMinutes));

      Date.differenceInMinutes = function (a, b) {
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes());
        return Math.floor((utc2 - utc1) / Date._MS_PER_MINUTE);
      };

      assert('Date.differenceInSeconds already present', isNone(Date.differenceInSeconds));

      Date.differenceInSeconds = function (a, b) {
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes(), a.getSeconds());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes(), b.getSeconds());
        return Math.floor((utc2 - utc1) / Date._MS_PER_SECOND);
      };

      assert('Date.getMonday already present', isNone(Date.getMonday));

      Date.getMonday = function (date) {
        // Based on http://stackoverflow.com/a/27887308
        date = new Date(+date);
        date.setDate(date.getDate() - (date.getDay() || 7) + 1);
        return date;
      };

      assert('Date.timeAsDate already present', isNone(Date.timeAsDate));

      Date.timeAsDate = function (date, time) {
        if (!Date.isValid(date) || !time) {
          return undefined;
        }

        const hours = _timeUtil.default.getHours(time);

        const minutes = _timeUtil.default.getMinutes(time);

        if (isNaN(hours) || isNaN(minutes)) {
          return undefined;
        }

        const datetime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        datetime.setHours(hours, minutes, 0, 0);
        return datetime;
      };

      assert('Date.dateAsTime already present', isNone(Date.dateAsTime));

      Date.dateAsTime = function (date) {
        const hours = Date.isValid(date) ? date.getHours() : undefined;
        const minutes = Date.isValid(date) ? date.getMinutes() : undefined;
        return _timeUtil.default.createTime(hours, minutes);
      };

      assert('Date.copy already present', isNone(Date.copy));

      Date.copy = function (date) {
        return Date.isValid(date) ? new Date(date.getTime()) : undefined;
      };

      assert('Date.isHistoricalSchoolyear already present', isNone(Date.isHistoricalSchoolyear));

      Date.isHistoricalSchoolyear = function (date) {
        return date && date < Date.getCurrentSchoolyearStart();
      };

      assert('Date.getCurrentSchoolyearStart already present', isNone(Date.getCurrentSchoolyearStart));

      Date.getCurrentSchoolyearStart = function () {
        const today = new Date();
        const year = today.getFullYear();
        const start = new Date(year, 7, 1); // Return schoolyear were are currently in. Might've started last year.

        return today < start ? new Date(year - 1, 7, 1) : start;
      };

      assert('Date.getNextSchoolyearStart already present', isNone(Date.getNextSchoolyearStart));

      Date.getNextSchoolyearStart = function () {
        const startNextSchoolyear = Date.getCurrentSchoolyearStart();
        startNextSchoolyear.setFullYear(startNextSchoolyear.getFullYear() + 1);
        return startNextSchoolyear;
      };

      assert('Date.getPreviousSchoolyearStart already present', isNone(Date.getPreviousSchoolyearStart));

      Date.getPreviousSchoolyearStart = function () {
        const startNextSchoolyear = Date.getCurrentSchoolyearStart();
        startNextSchoolyear.setFullYear(startNextSchoolyear.getFullYear() - 1);
        return startNextSchoolyear;
      };

      assert('Date.isAfter already present', isNone(Date.isAfter));

      Date.isAfter = function (date1, date2) {
        return Date.isValid(date1) && Date.isValid(date2) && date1 > date2;
      };

      assert('Date.isAfterOrEqual already present', isNone(Date.isAfterOrEqual));

      Date.isAfterOrEqual = function (date1, date2) {
        return Date.isValid(date1) && Date.isValid(date2) && Date.differenceInSeconds(date2, date1) >= 0;
      };

      assert('Date.roundDate already present', isNone(Date.roundDate));

      Date.roundDate = function (date, minutes) {
        const ms = 1000 * 60 * minutes; // convert minutes to ms

        return new Date(Math.ceil(date.getTime() / ms) * ms);
      };

      assert('Date.relative already present', isNone(Date.relative));

      Date.relative = function (date, format) {
        return (0, _moment.default)(date).fromNow();
      };

      assert('Date.nextWeekDay already present', isNone(Date.nextWeekDay));

      Date.nextWeekDay = function (date) {
        const nextDay = Date.addDays(date, 1);

        if (Date.isWeekend(nextDay)) {
          return (0, _moment.default)(nextDay).startOf('isoWeek').add(1, 'week').toDate();
        }

        return nextDay;
      };

      assert('Date.excelDateAsJsDate already present', isNone(Date.excelDateAsJsDate));

      Date.excelDateAsJsDate = function (excelDate) {
        if (!Number(excelDate) && Number(excelDate) !== 0) {
          return undefined;
        }

        const secondsInDay = 24 * 60 * 60;
        const missingLeapYearDay = secondsInDay * 1000;
        const delta = excelDate - (25567 + 2);
        const parsed = delta * missingLeapYearDay;
        const date = new Date(parsed);

        if (Object.prototype.toString.call(date) === '[object Date]') {
          if (isNaN(date.getTime())) {
            return undefined;
          } else {
            const userTimezoneOffset = date.getTimezoneOffset() * 60000;
            return new Date(date.getTime() + userTimezoneOffset);
          }
        }
      };

      assert('Date.getLatestDate already present', isNone(Date.getLatestDate));

      Date.getLatestDate = function (array, property) {
        return new Date(Math.max.apply(null, array.map(a => {
          return new Date(a.get(property));
        })));
      };
    }
  };
  _exports.default = _default;
});