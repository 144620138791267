define("parro-web/templates/oauth2", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{login/transition-loader}}
  {{modal/account-choice-dialog config=choiceConfig}}
  */
  {
    "id": "2PdUrBeW",
    "block": "[[[1,[34,0]],[1,\"\\n\"],[1,[28,[35,1],null,[[\"config\"],[[33,2]]]]]],[],false,[\"login/transition-loader\",\"modal/account-choice-dialog\",\"choiceConfig\"]]",
    "moduleName": "parro-web/templates/oauth2.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});