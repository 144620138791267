define("parro-web/serializers/guardian", ["exports", "parro-web/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    _parseLinkables(hash) {
      // Store hash.links 'identiteit' as 'identityId' in the model.
      hash.identityId = this._getLinkableIdFromSelfLink(hash, 'identiteit');

      this._parseAndFlattenLinkables(hash, false);
    },

    serializeAttribute(snapshot, json, key, attribute) {
      // The 'identiteit' link was parsed to 'identityId' (REST -> model).
      // However, the backend doesn't know 'identityId' (model -> REST).
      if (attribute.name !== 'identityId') {
        return this._super(...arguments);
      }
    }

  });

  _exports.default = _default;
});