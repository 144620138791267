define("parro-web/components/yearbook/yearbook-cover-images", ["exports", "ember", "ember-infinity/lib/infinity-model", "parro-web/mixins/routeMixin"], function (_exports, _ember, _infinityModel, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    isEmpty,
    isPresent
  } = _ember.default;

  const ExtendedInfinityModel = _infinityModel.default.extend({
    afterInfinityModel(results) {
      this.set('canLoadMore', results.get('length') > 0 && results.get('query.page') < results.get('meta.total_pages'));
      return results;
    }

  });

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: '',
    yearbookAjaxService: service(),
    yearbookService: service(),
    fileService: service(),
    store: service(),
    infinity: service(),
    ScrollService: service(),
    selectedGroup: undefined,

    didInsertElement() {
      this._super(...arguments);

      this.ScrollService.setScrollTop($('html'), false);

      this._getAttachments();
    },

    _getAttachments() {
      const selectedGroup = this.get('selectedGroup');
      const groups = isPresent(selectedGroup) ? [selectedGroup.get('id')] : this.get('groups').map(group => group.get('id'));

      if (isPresent(groups) && groups.length > 0) {
        const request = {
          groups: groups,
          sort: 'asc-stream',
          startingPage: 1,
          perPage: 20,
          attachmentType: 'IMAGE',
          modelPath: 'attachments'
        };
        this.infinity.model('attachment', request, ExtendedInfinityModel.extend()).then(infinityModel => {
          this.set('attachments', infinityModel);
        });
      } else {
        this.set('showPlaceholder', true);
      }
    },

    attachmentObserver: _ember.default.observer('attachments.@each', function () {
      if (isEmpty(this.get('attachments'))) {
        return;
      }

      const onFilter = imageAttachment => isPresent(imageAttachment) && imageAttachment.get('smallestImage') && imageAttachment.get('largestImage');

      const onMap = imageAttachment => {
        const small = imageAttachment.get('smallestImage');
        const large = imageAttachment.get('largestImage');
        return _ember.default.Object.create({
          original: imageAttachment,
          id: imageAttachment.get('id'),
          msrc: small.get('url'),
          src: large.get('url'),
          w: large.get('width'),
          h: large.get('height')
        });
      };

      this.set('images', Array.orEmpty(this.get('attachments').filter(onFilter).map(onMap)));
    }),
    actions: {
      selectGroup(group) {
        this.set('selectedGroup', group);
        this.set('images', undefined);
        this.set('attachments', undefined);

        this._getAttachments();
      },

      selectImage(image) {
        const yearbook = this.get('yearbook');
        const service = this.get('yearbookAjaxService');

        if (image instanceof File || image instanceof Blob) {
          const onError = error => this.flashError(error);

          const onSuccess = () => this.transitionTo('yearbook.front', yearbook.get('id'));

          const onFinal = () => this.showLoader(false);

          this.showLoader(true);
          service.uploadImage(yearbook, [image], 'YEARBOOK_FRONT').then(onSuccess, onError).finally(onFinal);
        } else {
          yearbook.set('frontCover', image.get('original'));

          const onSuccess = () => this.transitionTo('yearbook.front', yearbook.get('id'));

          const onError = () => this.flashError('error.unknown_error');

          this.get('yearbookAjaxService').editYearbook(yearbook).then(onSuccess, onError);
        }
      }

    }
  });

  _exports.default = _default;
});