define("parro-web/components/photo/yearbook-photo-swipe-item", ["exports", "ember", "parro-web/components/photo/photo-swipe-item"], function (_exports, _ember, _photoSwipeItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _photoSwipeItem.default.extend({
    classNameBindings: ['isSelected', 'isBadQuality'],
    yearbookService: service(),
    isSelected: computed('selected', 'item.isSelected', function () {
      if (this.get('selected')) {
        return this.get('selected.id') === this.get('item.original.id');
      }

      return this.get('item.isSelected');
    }),
    isBadQuality: computed('item.w', 'item.h', function () {
      return this.get('yearbookService').determineBadQuality(106, 161.683, this.get('item'));
    })
  });

  _exports.default = _default;
});