define("parro-web/adapters/schoolyear", ["exports", "ember", "parro-web/adapters/application", "parro-web/config/environment"], function (_exports, _ember, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;
  const {
    embrah: {
      namespace
    }
  } = _environment.default;

  var _default = _application.default.extend({
    versionService: service(),
    host: computed.readOnly('versionService.host'),

    buildURL(modelName, id, snapshot, requestType, query) {
      if (modelName === 'schoolyear' && requestType === 'findAll') {
        return this.get('host') + '/' + namespace + '/identity/schoolyears';
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});