define("parro-web/templates/components/yearbook/yearbook-preview-spread", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li>
    <div>
      {{#each chunk as |item itemIndex|}}
        {{yearbook/yearbook-preview-item yearbook=yearbook itemIndex=itemIndex chunkIndex=chunkIndex item=item requestConfirmation=requestConfirmation}}
      {{/each}}
    </div>
    <p>{{t-exists pageText}}</p>
  </li>
  */
  {
    "id": "2AKQ7R9I",
    "block": "[[[10,\"li\"],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"      \"],[1,[28,[35,3],null,[[\"yearbook\",\"itemIndex\",\"chunkIndex\",\"item\",\"requestConfirmation\"],[[33,4],[30,2],[33,5],[30,1],[33,6]]]]],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,7],[[33,8]],null]],[13],[1,\"\\n\"],[13]],[\"item\",\"itemIndex\"],false,[\"each\",\"-track-array\",\"chunk\",\"yearbook/yearbook-preview-item\",\"yearbook\",\"chunkIndex\",\"requestConfirmation\",\"t-exists\",\"pageText\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-preview-spread.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});