define("parro-web/adapters/application", ["exports", "ember-data", "ember", "parro-web/config/environment", "parro-web/mixins/routeMixin"], function (_exports, _emberData, _ember, _environment, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed,
    RSVP: {
      Promise
    },
    isPresent
  } = _ember.default;
  const MATCH_CONTENT_TYPE = new RegExp('content-range', 'i');

  var _default = _emberData.default.RESTAdapter.extend(_routeMixin.default, {
    account: service(),
    versionService: service(),
    authentication: service('authenticationService'),
    session: service(),
    host: computed.readOnly('versionService.host'),
    namespace: _environment.default.embrah.namespace,
    headers: computed('session.data.authenticated.access_token', 'session.data.authenticated.psystoken', 'session.data.authenticated.authrole', function () {
      const headers = {};

      if (this.session.isAuthenticated) {
        const {
          access_token,
          psystoken,
          authrole
        } = this.get('session.data.authenticated');

        if (access_token) {
          headers.Authorization = 'Bearer ' + access_token;
        }

        if (psystoken) {
          headers['X-psys-token'] = psystoken;
        }

        if (authrole) {
          headers['parro-Authorization-Role'] = authrole;
        }
      }

      headers.Accept = 'application/vnd.topicus.geon+json;version=' + this.versionService.restVersion;
      headers['Content-Type'] = 'application/vnd.topicus.geon+json;version=' + this.versionService.restVersion;
      headers['Cache-Control'] = 'no-store';
      return headers;
    }),

    // Deprecation fix nav http://discuss.emberjs.com/t/how-to-remove-adapter-serializer-depreciation-due-to-upgrade-to-latest-ember-ember-data/8485/6
    shouldReloadAll(store, snapshot) {
      return store.peekAll(snapshot.type.modelName).get('length') <= 0;
    },

    shouldBackgroundReloadRecord(store, snapshot) {
      return store.peekAll(snapshot.type.modelName).get('length') <= 0;
    },

    shouldReloadRecord(store, snapshot) {
      // If the item has 'lastLoaded' then force reload after a certain interval has expired
      const lastLoaded = snapshot.attributes().lastLoaded;
      const reloadInterval = 1000 * 2; // seconds

      return reloadInterval && new Date() - lastLoaded > reloadInterval;
    },

    ajax(url, type, options) {
      return new Promise((resolve, reject) => {
        const onSuccess = () => {
          const hash = this.ajaxOptions(url, type, options);

          hash.success = (json, _, jqXHR) => {
            if (isPresent(json)) {
              json.meta = this._getTotalPages(jqXHR, options.data.per_page);
            }

            resolve(json);
          };

          hash.error = jqXHR => {
            const status = jqXHR.status;

            switch (status) {
              case 400:
                return reject('PAGE_NOT_FOUND');

              case 401:
                this.transitionTo('logoff');
                return reject('UNAUTHORIZED');

              case 403:
                return reject('FORBIDDEN');

              case 404:
                return reject('PAGE_NOT_FOUND');

              case 503:
                return reject('SERVER_ERROR');
            }

            reject(jqXHR);
          };

          $.ajax(hash);
        };

        this.get('authentication').checkForExpiredTokens().then(onSuccess, reject);
      });
    },

    ajaxOptions(url, type, options) {
      const hash = this._super(...arguments);

      if (options && options.data) {
        if (options.data.page > 1) {
          hash.disableAjaxLoader = true;
        }

        if (options.data.disableAjaxLoader !== undefined) {
          hash.disableAjaxLoader = options.data.disableAjaxLoader;
          delete options.data.disableAjaxLoader;
        }
      }

      return hash;
    },

    _getTotalPages(jqXHR, itemsPerPage) {
      const contentRange = this._getHeader(jqXHR, MATCH_CONTENT_TYPE);

      if (contentRange) {
        const totalItems = parseInt(contentRange.substring(contentRange.indexOf('/') + 1));
        return {
          total_pages: Math.ceil(totalItems / (itemsPerPage || _environment.default.itemsPerPage))
        };
      }

      return 0;
    },

    _getHeader(jqXHR, regex) {
      if (jqXHR && jqXHR.getAllResponseHeaders) {
        const headers = jqXHR.getAllResponseHeaders();
        const header = headers.match(regex);
        return isPresent(header) ? header.input : undefined;
      }

      return undefined;
    }

  });

  _exports.default = _default;
});