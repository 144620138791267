define("parro-web/templates/components/photo/photo-slider", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  */
  {
    "id": "oqkOIAmf",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "parro-web/templates/components/photo/photo-slider.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});