define("parro-web/components/yearbook/yearbook-editor", ["exports", "ember", "parro-web/models/services/dialogs", "parro-web/mixins/routeMixin", "ember-infinity/lib/infinity-model"], function (_exports, _ember, _dialogs, _routeMixin, _infinityModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    isPresent,
    isEmpty,
    computed
  } = _ember.default;

  const ExtendedInfinityModel = _infinityModel.default.extend({
    afterInfinityModel(results) {
      this.set('canLoadMore', results.get('length') > 0 && results.get('query.page') < results.get('meta.total_pages'));
      return results;
    }

  });

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: '',
    yearbookAjaxService: service(),
    yearbookService: service(),
    account: service(),
    store: service(),
    infinity: service(),
    ScrollService: service(),

    didInsertElement() {
      this._super(...arguments);

      _ember.default.run.schedule('afterRender', this, () => {
        this.get('yearbookDialog').send('show', _dialogs.default.yearbookEditorDialogConfig());

        const onDebounce = () => {
          if (!this.isDestroying && !this.isDestroyed) {
            this.set('yearbook.scrollPosition', window.scrollY);
          }
        };

        const onScroll = () => {
          _ember.default.run.debounce(this, onDebounce, 200);

          if (!this.isDestroying && !this.isDestroyed) {
            this.set('isScrolling', window.scrollY > 100);
          }
        };

        document.addEventListener('scroll', onScroll);

        this.ScrollService._scroll($('html'), this.get('yearbook.scrollPosition'), false);
      });

      this._getAnnouncements();
    },

    filterObserver: _ember.default.observer('yearbook.selectedSchoolYears.[]', 'yearbook.selectedChildren.[]', 'yearbook.selectedGroups.[]', function () {
      this.set('didFilter', true);

      _ember.default.run.debounce(this, this._getAnnouncements, 1000);
    }),
    selectedChildren: computed.alias('yearbook.selectedChildren'),
    selectedSchoolYears: computed.alias('yearbook.selectedSchoolYears'),
    selectedGroups: computed.alias('yearbook.selectedGroups'),
    amountOfSelectedChildren: computed('selectedChildren.@each', function () {
      return this.get('selectedChildren.length');
    }),
    amountOfSelectedSchoolYears: computed('selectedSchoolYears.@each', function () {
      return this.get('selectedSchoolYears.length');
    }),
    amountOfSelectedGroups: computed('selectedGroups.@each', function () {
      return this.get('selectedGroups.length');
    }),
    schoolYearRange: computed('selectedSchoolYears.@each', function () {
      const selectedSchoolYears = Array.orEmpty(this.get('selectedSchoolYears')).sort((a, b) => a - b);

      if (selectedSchoolYears.length > 1) {
        const first = selectedSchoolYears[0];
        const last = parseInt(selectedSchoolYears[selectedSchoolYears.length - 1]) + 1;
        return "'" + first.toString().slice(-2) + ' - ' + "'" + last.toString().slice(-2);
      }

      if (selectedSchoolYears.length === 1) {
        const first = selectedSchoolYears[0];
        const last = parseInt(first) + 1;
        return "'" + first.toString().slice(-2) + ' - ' + "'" + last.toString().slice(-2);
      }

      return undefined;
    }),
    showFilterOptions: computed('selectedChildren.@each', 'selectedSchoolYears.@each', function () {
      return isEmpty(this.selectedChildren) && isEmpty(this.selectedSchoolYears);
    }),

    _getAnnouncements() {
      const id = this.get('account.identity.id');
      const schoolYears = this.get('yearbook.selectedSchoolYears');
      const children = this.get('yearbook.selectedChildren');
      const groups = this.get('yearbook.selectedGroups');
      const codes = this.get('yearbookService').getAllowedOrganisationCodes();
      const request = {
        identity: id,
        schoolYears: schoolYears,
        children: children,
        groups: groups,
        codes: codes,
        hasImages: 'TRUE',
        sort: 'desc-stream',
        startingPage: 1,
        perPage: 20,
        yearbook: this.get('yearbook.id'),
        modelPath: 'yearbook.events'
      };
      this.infinity.model('event', request, ExtendedInfinityModel.extend()).then(infinityModel => {
        if (this.get('didFilter') || !this.get('yearbook.events') && !this.isDestroying && !this.isDestroyed) {
          this.set('yearbook.events', undefined);
          this.set('yearbook.events', infinityModel);

          if (!this.get('didFilter')) {
            const lastSelected = this._getLastSelected();

            if (isPresent(lastSelected)) {
              this.requestConfirmation(_dialogs.default.yearbookScrollToDialogConfig(), () => this.set('cancelNav', true));
              this.send('toNavDate', undefined, lastSelected.get('announcement.id'));
            }
          }

          this.set('didFilter', false);
        }
      });
    },

    _getLastSelected() {
      return Array.orEmpty(this.get('selection')).reduce((acc, current) => {
        if (isEmpty(acc) || current.get('lastModifiedAt') > acc.get('lastModifiedAt')) {
          return current;
        }

        return acc;
      }, []);
    },

    _findAnnouncement(date, id) {
      const announcements = Array.orEmpty(this.get('yearbook.events')).map(e => e);
      announcements.forEach(announcement => announcement.set('toNavHere', false));

      if (isPresent(id)) {
        return announcements.find(announcement => announcement.get('id') === id);
      }

      const exact = announcements.find(announcement => date === announcement.get('sortDate'));
      const closestSameDay = announcements.find(announcement => !Date.differenceInDays(date, announcement.get('sortDate')));
      const closestOtherDay = announcements.find(announcement => Date.differenceInDays(date, announcement.get('sortDate')) > 0);
      return exact || closestSameDay || closestOtherDay;
    },

    actions: {
      showTypeDialog(config) {
        this.requestConfirmation(config, () => {
          this.transitionTo('yearbook.type', this.get('yearbook.id'));
        });
      },

      toNavDate(date, id) {
        if (this.get('cancelNav')) {
          return;
        }

        this.set('yearbook.toNavDate', date);
        this.showLoader(true);

        const announcement = this._findAnnouncement(date, id);

        if (isPresent(announcement)) {
          announcement.set('toNavHere', true);
        }

        if (isEmpty(announcement) && this.get('yearbook.events.reachedInfinity')) {
          this.closeConfirmation();
        }

        _ember.default.run.later(this, () => {
          const container = $('html');
          const element = $('.to-nav-here');

          if (isEmpty(element.position()) && !this.get('yearbook.events.reachedInfinity')) {
            this.infinity.infinityLoad(this.get('yearbook.events')).then(() => {
              return this.send('toNavDate', date, id);
            });
          } else if (isPresent(element.position())) {
            this.ScrollService.scrollToTarget(container, element, true);
            this.closeConfirmation();
          }

          this.showLoader(false);
        }, 100);
      },

      showFilter(model) {
        this.get('filterOptions').show(model);
      }

    }
  });

  _exports.default = _default;
});