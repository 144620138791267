define("parro-web/controllers/application", ["exports", "ember", "parro-web/mixins/metricsMixin", "parro-web/utils/browserUtil"], function (_exports, _ember, _metricsMixin, _browserUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    isNone,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Controller.extend(_metricsMixin.default, {
    session: service(),
    account: service(),
    router: service(),
    versionService: service(),
    isLoading: false,

    init() {
      this._super(...arguments);

      this.setupGoogleAnalytics();

      _ember.default.RSVP.on('error', error => this.handleError(error));

      _ember.default.RSVP.on('reject', error => this.handleError(error));

      _ember.default.onerror = error => this.handleError(error);

      $('#preloader').remove();
    },

    handleError(error) {
      if (isNone(error) || _ember.default.get(error, 'name') === 'TransitionAborted') {
        return;
      }

      if (error.message === 'The adapter operation was aborted') {
        return this.flashError('error.default');
      } else if (isPresent(error) && error.getAllResponseHeaders) {
        const description = error.getAllResponseHeaders();
        const recoveryCode = error.getResponseHeader('Parro-Account-Recovery-Code');
        const deletionDate = error.getResponseHeader('Parro-Account-Deletion-Date');
        const errors = ['no_organisation_for_user', 'parnassys_guardian_account_not_allowed', 'parnassys_not_available', 'account_is_forgotten', 'account_not_active', 'parnassys_lab_account_not_allowed'];
        const match = errors.find(e => isPresent(description.match(e)));

        if (isPresent(match) && match === 'account_is_forgotten' && isPresent(recoveryCode) && isPresent(deletionDate)) {
          return this.transitionToRoute('oops.recovery', match, recoveryCode, deletionDate);
        } else if (isPresent(match)) {
          return this.transitionToRoute('oops.error', match);
        }
      } else if (error === 'NO_CHOICE_MADE') {
        return this.transitionToRoute('login');
      }

      this.logError(error);
      return this.transitionToRoute('oops.default');
    },

    _parseError(error) {
      if (typeof error === 'string') {
        return new Error(error);
      } else if (isPresent(error) && isPresent(error.stack)) {
        return error;
      }

      return undefined;
    },

    logError(error) {
      console.log(error);

      try {
        const exception = this._parseError(error);

        if (isPresent(exception)) {
          const exception = typeof error === 'string' ? new Error(error) : error;
          const logException = typeof error === 'object' ? error.stack : error;
          const {
            accountType,
            avgId
          } = this.session.data?.authenticated;
          bugsnagClient.addMetadata('meta', {
            log: logException,
            type: accountType,
            avgId: avgId
          });
          bugsnagClient.notify(exception);
        }
      } catch (e) {}

      $('#loading').removeClass('ajaxactive');
      $('#loading').removeClass('active');
    },

    onPathChange: _ember.default.observer('currentPath', function () {
      this.get('versionService').checkVersion();

      _ember.default.run.schedule('afterRender', this, () => {
        if (this.get('session.isAuthenticated') && _browserUtil.default.detectIE()) {
          return this.transitionToRoute('logoff');
        } else if (_browserUtil.default.detectIE()) {
          return this.transitionToRoute('oops.error', 'browser_not_supported');
        }

        $('#loading').removeClass('ajaxactive');
      });

      this.flashClear();
    })
  });

  _exports.default = _default;
});