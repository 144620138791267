define("parro-web/components/person/filter-child-item", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    isSelected: computed('selected.[]', function () {
      return isPresent(this.get('selected').find(s => s === this.get('child.id')));
    })
  });

  _exports.default = _default;
});