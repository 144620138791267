define("parro-web/templates/environment", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @model.isAuthenticated}}
  
    <section class="authenticator--wrapper">
      <div class="authenticator--body l-xsmall">
        <figure class="figure--casa-logo"></figure>
        <div class="panel">
          {{development/environment-select}}
          <hr class="text--divider">
          <button {{action (route-action 'back')}} class="btn--destructive">{{t 'button.back'}}</button>
        </div>
      </div>
    </section>
  
  {{else}}
  
    <figure class="figure--casa-logo"></figure>
    <div class="panel">
      {{development/environment-select}}
      <hr class="text--divider">
      <button {{action (route-action 'back')}} class="btn--destructive">{{t 'button.back'}}</button>
    </div>
  
  {{/if}}
  */
  {
    "id": "JJAKTJa9",
    "block": "[[[41,[30,1,[\"isAuthenticated\"]],[[[1,\"\\n  \"],[10,\"section\"],[14,0,\"authenticator--wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"authenticator--body l-xsmall\"],[12],[1,\"\\n      \"],[10,\"figure\"],[14,0,\"figure--casa-logo\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"panel\"],[12],[1,\"\\n        \"],[1,[34,1]],[1,\"\\n        \"],[10,\"hr\"],[14,0,\"text--divider\"],[12],[13],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn--destructive\"],[4,[38,2],[[30,0],[28,[37,3],[\"back\"],null]],null],[12],[1,[28,[35,4],[\"button.back\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]],[[[1,\"\\n  \"],[10,\"figure\"],[14,0,\"figure--casa-logo\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"panel\"],[12],[1,\"\\n    \"],[1,[34,1]],[1,\"\\n    \"],[10,\"hr\"],[14,0,\"text--divider\"],[12],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn--destructive\"],[4,[38,2],[[30,0],[28,[37,3],[\"back\"],null]],null],[12],[1,[28,[35,4],[\"button.back\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]]]],[\"@model\"],false,[\"if\",\"development/environment-select\",\"action\",\"route-action\",\"t\"]]",
    "moduleName": "parro-web/templates/environment.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});