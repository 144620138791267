define("parro-web/components/yearbook/yearbook-image-select", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    computed,
    inject: {
      service
    },
    isNone,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    validationService: service(),

    didInsertElement() {
      this._super(...arguments);

      const input = document.getElementById('fileInput');
      input.addEventListener("change", event => this.handleFiles(event));
    },

    config: {
      accept: ['image/jpg', 'image/jpeg', 'image/png'],
      max_filesize: 5120 // in kb

    },

    handleFiles(event) {
      const image = event.currentTarget.files[0];

      if (!this.isValidType(image)) {
        this.flashError('error.invalid_picturetype');
      } else if (!this.isValidMinSize(image)) {
        this.flashError('error.invalid_min_picturesize');
      } else if (!this.isValidMaxSize(image)) {
        this.flashError('error.invalid_max_picturesize');
      } else {
        this.addImage(image);
      }
    },

    isValidType(image) {
      assert('Must provide image', !isNone(image));
      const config = this.get('config');
      const service = this.get('validationService');
      return isPresent(config.accept.find(type => service.isValidFile(image, type)));
    },

    isValidMinSize(image) {
      assert('Must provide image', !isNone(image));
      const minSize = this.get('config.min_filesize') * 1024 || 1;
      return image.size >= minSize;
    },

    isValidMaxSize(image) {
      assert('Must provide image', !isNone(image));
      const maxSize = this.get('config.max_filesize') * 1024 || Number.MAX_VALUE;
      return image.size <= maxSize;
    }

  });

  _exports.default = _default;
});