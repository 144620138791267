define("parro-web/templates/components/yearbook/templates/template-8", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPreview}}
    <div class="yearbook--shadow"></div>
    {{#if (and showQualityOverlay isBadQuality)}}<div {{action 'showWarning' bubbles=false}}class="yearbook--quality-overlay"><i class="icon-warning"></i></div>{{/if}}
  {{/if}}
  <article>
    <div class="bg--main"></div>
    <div class="bg--side"></div>
    <div class="image" style={{image}}></div>
  </article>
  
  */
  {
    "id": "uFLQTXWy",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"yearbook--shadow\"],[12],[13],[1,\"\\n  \"],[41,[28,[37,2],[[33,3],[33,4]],null],[[[11,0],[24,0,\"yearbook--quality-overlay\"],[4,[38,5],[[30,0],\"showWarning\"],[[\"bubbles\"],[false]]],[12],[10,\"i\"],[14,0,\"icon-warning\"],[12],[13],[13]],[]],null],[1,\"\\n\"]],[]],null],[10,\"article\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"bg--main\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"bg--side\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"image\"],[15,5,[36,6]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"isPreview\",\"and\",\"showQualityOverlay\",\"isBadQuality\",\"action\",\"image\"]]",
    "moduleName": "parro-web/templates/components/yearbook/templates/template-8.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});