define("parro-web/components/form/ios8-switch", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    tagName: 'span',
    classNames: ['ios8-switch']
  });

  _exports.default = _default;
});