define("parro-web/routes/jaarboek", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class JaarboekRoute extends _route.default {
    beforeModel() {
      this.transitionTo('yearbook');
    }

  }

  _exports.default = JaarboekRoute;
});