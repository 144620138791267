define("parro-web/router", ["exports", "ember", "parro-web/config/environment"], function (_exports, _ember, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = _ember.default;

  const Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    rootUrl: _environment.default.rootUrl,
    metrics: service(),

    init() {
      this._super(...arguments);

      this.on('routeDidChange', () => this._trackPage());
    },

    _trackPage() {
      _ember.default.run.scheduleOnce('afterRender', this, () => {
        const regexNumbers = /^\d+$/;
        const regexNumbersLetters = /((?:[-=a-zA-Z]+[0-9]|[0-9]+[-=a-zA-Z])[a-zA-Z0-9-=]*)/g;
        const page = this.get('url');
        const urlSegments = page.split('/');
        let newUrl = page;
        urlSegments.forEach(segment => {
          if (segment.match(regexNumbers) || segment.match(regexNumbersLetters)) {
            newUrl = newUrl.replace(segment, ':id');
          }
        });
        this.metrics.trackPage({
          page: newUrl
        });
      });
    }

  }); // eslint-disable-next-line array-callback-return


  Router.map(function () {
    this.route('environment', {
      path: '/environment'
    });
    this.route('index', {
      path: '/index'
    });
    this.route('oops/error', {
      path: '/*path'
    });
    this.route('oops', {
      path: '/oops'
    }, function () {
      this.route('default', {
        path: '/'
      });
      this.route('error', {
        path: '/:error'
      });
    });
    this.route('invalidate', {
      path: '/invalidate'
    });
    this.route('oauth2', {
      path: '/oauth2'
    });
    this.route('logoff', {
      path: '/logoff'
    });
    this.route('login/login', {
      path: '/'
    });
    this.route('login', {
      path: '/login'
    }, function () {
      this.route('login', {
        path: '/'
      });
      this.route('yearbook', {
        path: '/yearbook'
      });
    });
    this.route('jaarboek', {
      path: '/jaarboek'
    });
    this.route('yearbook', {
      path: '/yearbook'
    }, function () {
      this.route('overview', {
        path: '/overview'
      });
      this.route('type', {
        path: '/:yearbook/type'
      });
      this.route('drawing', {
        path: '/:yearbook/drawing'
      });
      this.route('front', {
        path: '/:yearbook/front'
      });
      this.route('cover-images', {
        path: '/:yearbook/cover/images'
      });
      this.route('editor', {
        path: '/:yearbook/editor'
      });
      this.route('editor-images', {
        path: '/:yearbook/editor/:announcement/group/:group/images'
      });
      this.route('preview', {
        path: '/:yearbook/preview'
      });
      this.route('shopping-cart', {
        path: '/:yearbook/shopping-cart'
      });
      this.route('order', {
        path: '/:yearbook/order'
      });
      this.route('order-status', {
        path: '/:yearbook/order/status'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});