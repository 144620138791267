define("parro-web/services/s3-upload", ["exports", "parro-web/models/services/CanvasTransformations", "parro-web/objects/DataProvider", "ember", "parro-web/objects/FileAttachment", "parro-web/objects/FileUploadInfo", "parro-web/objects/ImageDimensions"], function (_exports, _CanvasTransformations, _DataProvider, _ember, _FileAttachment, _FileUploadInfo, _ImageDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    inject: {
      service
    },
    isPresent,
    RSVP: {
      Promise
    }
  } = _ember.default;
  const assign = _ember.default.assign;
  const {
    CORRECT_ORIENTATION,
    LARGE_SIZE,
    THUMBNAIL_SIZE
  } = _CanvasTransformations.default;

  var _default = _ember.default.Service.extend({
    intl: service(),
    fileService: service(),
    promiseService: service(),
    ajaxService: service('ajax'),
    metricsService: service('metrics'),
    attachmentsService: service(),

    uploadFiles(images, documents, dtype, extraUploadInfo) {
      return new Promise((resolve, reject) => {
        assert('images must be of type Array', Array.isSimilarToArray(images));
        assert('documents must be of type Array', Array.isSimilarToArray(documents));
        assert('dtype must be of type string', typeof dtype === 'string'); // -- Never mind, no images or documents to upload.

        if (images.length === 0 && documents.length === 0) {
          return resolve([]);
        } // -- Other services used


        const attachmentsService = this.get('attachmentsService');
        const promiseService = this.get('promiseService');
        const fileService = this.get('fileService');

        const pass = value => Promise.resolve(value); // -- Batch retrieve FileUploadInfo array. Retrieve 2x for each image - normal and thumbnail upload.


        const imageContentTypes = images.map(() => 'image/jpeg');
        const documentContentTypes = documents.map(document => fileService.getContentType(document));
        const contentTypes = imageContentTypes.concat(imageContentTypes).concat(documentContentTypes);
        const cutPoint = contentTypes.length - documentContentTypes.length;
        const uploadUrl = isPresent(extraUploadInfo) ? extraUploadInfo.url : undefined; // -- Create & sequentially process each image 2x - for normal and thumbnail upload.

        const processImages = fileUploadInfos => new Promise((resolve, reject) => {
          const _this = this; // need to preserve 'this' context for later use in DataProvider


          let index = 0;

          const provider = _DataProvider.default.create({
            hasNext: function () {
              return index < images.length;
            },
            next: function () {
              const image = images[index];
              const attachmentType = fileService.getAttachmentType(image);
              const fuiNormal = fileUploadInfos[index * 2];
              const fuiThumbnail = fileUploadInfos[index * 2 + 1];

              const attachment = _FileAttachment.default.create({
                dtype: dtype,
                attachmentType: attachmentType
              });

              const uploadImage = () => _this.uploadImageFile(image, fuiNormal, LARGE_SIZE, CORRECT_ORIENTATION);

              const uploadThumbnail = () => _this.uploadImageFile(image, fuiThumbnail, THUMBNAIL_SIZE, CORRECT_ORIENTATION);

              index++;
              return uploadImage().then(fue => Promise.all([pass(fue), uploadThumbnail()])).then(entries => pass(attachment.set('entries', entries))).then(() => pass(attachment));
            }
          });

          promiseService.processSequentially(provider, [], resolve, reject);
        }); // -- Create & sequentially process each document - just one normal upload.


        const processDocuments = fileUploadInfos => new Promise((resolve, reject) => {
          const _this = this; // need to preserve 'this' context for later use in DataProvider


          let index = 0;

          const provider = _DataProvider.default.create({
            hasNext: function () {
              return index < documents.length;
            },
            next: function () {
              let fileUploadInfo = fileUploadInfos[index];
              const document = documents[index];
              const attachmentType = fileService.getAttachmentType(document, true);

              const attachment = _FileAttachment.default.create({
                dtype: dtype,
                attachmentType: attachmentType
              });

              if (isPresent(extraUploadInfo) && isPresent(fileUploadInfo)) {
                fileUploadInfo = assign(fileUploadInfo, extraUploadInfo);
              }

              index++;
              return _this.uploadDocumentForm(fileUploadInfo, document).then(entry => pass(attachment.set('entries', [entry]))).then(() => pass(attachment));
            }
          });

          promiseService.processSequentially(provider, [], resolve, reject);
        });

        attachmentsService.getFileUploadInfo(uploadUrl, contentTypes).then(infos => Promise.all([pass(infos.slice(0, cutPoint)), pass(infos.slice(cutPoint))])).then(([fuiImages, fuiDocuments]) => Promise.all([pass(fuiDocuments), processImages(fuiImages)])).then(([fuiDocuments, faImages]) => Promise.all([pass(faImages), processDocuments(fuiDocuments)])).then(([faImages, faDocuments]) => resolve(faImages.concat(faDocuments))).catch(error => reject(error));
      });
    },

    uploadImageFile(file, fileUploadInfo, sizeTransformation, orientationTransformation) {
      return new Promise((resolve, reject) => {
        assert('file must be of type File or Blob', file instanceof File || file instanceof Blob);
        assert('file.type must be of type image', file.type.match(/image.*/));
        assert('fileUploadInfo must be of type FileUploadInfo', fileUploadInfo instanceof _FileUploadInfo.default);
        assert('sizeTransformation must be of type Function', sizeTransformation instanceof Function);
        assert('orientationTransformation must be of type Function', orientationTransformation instanceof Function); // -- Using other services

        const fileService = this.get('fileService');
        const attachmentsService = this.get('attachmentsService'); // -- Functions for the different steps.

        const pass = value => Promise.resolve(value);

        const convertToBlob = imageEntry => fileService.convertCanvasToBlob(imageEntry.canvas, fileUploadInfo.contentType);

        const convertToFile = blob => pass(fileService.convertBlobToFile(blob, file.name));

        const upload = _file => this.uploadImageForm(fileUploadInfo, _file);

        fileService.transformImageFile(file, sizeTransformation, orientationTransformation).then(imageEntry => Promise.all([pass(imageEntry), convertToBlob(imageEntry)])).then(([imageEntry, blob]) => Promise.all([pass(imageEntry), convertToFile(blob)])).then(([imageEntry, _file]) => Promise.all([pass(imageEntry), pass(_file), upload(_file)])).then(([imageEntry, _file]) => {
          const dimensions = _ImageDimensions.default.create({
            width: imageEntry.width,
            height: imageEntry.height
          });

          const entry = attachmentsService.createImageEntry(_file, fileUploadInfo, dimensions);
          resolve(entry);
        }).catch(error => reject(error));
      });
    },

    uploadDocumentForm(fileUploadInfo, file) {
      return new Promise((resolve, reject) => {
        assert('fileUploadInfo must be of type FileUploadInfo', fileUploadInfo instanceof _FileUploadInfo.default);
        assert('file must be of type File or Blob', file instanceof File || file instanceof Blob);
        const attachmentsService = this.get('attachmentsService');

        const onResolve = () => resolve(attachmentsService.createDocumentEntry(file, fileUploadInfo));

        const formData = this._createFormData(fileUploadInfo, file);

        formData.append('file', file);

        this._uploadFormData(formData, fileUploadInfo.baseUrl, onResolve, reject);
      });
    },

    uploadImageForm(fileUploadInfo, file) {
      return new Promise((resolve, reject) => {
        assert('fileUploadInfo must be of type FileUploadInfo', fileUploadInfo instanceof _FileUploadInfo.default);
        assert('file must be of type File or Blob', file instanceof File || file instanceof Blob);

        const formData = this._createFormData(fileUploadInfo, file);

        formData.append('file', file);

        this._uploadFormData(formData, fileUploadInfo.baseUrl, resolve, reject);
      });
    },

    /**
     * Translates {FileUploadInfo} to baseline {FormData}.
     *
     * @param fileUploadInfo
     * @param file
     * @return {FormData}
     * @private
     */
    _createFormData(fileUploadInfo, file) {
      assert('fileUploadInfo must be of type FileUploadInfo', fileUploadInfo instanceof _FileUploadInfo.default);
      const formData = new FormData();
      formData.append('acl', fileUploadInfo.acl);
      formData.append('content-type', fileUploadInfo.contentType);
      formData.append('key', fileUploadInfo.key);
      formData.append('policy', fileUploadInfo.policy);
      formData.append('success_action_status', '200');
      formData.append('x-amz-algorithm', fileUploadInfo.algorithm);
      formData.append('x-amz-credential', fileUploadInfo.credential);
      formData.append('x-amz-date', fileUploadInfo.date);
      formData.append('x-amz-signature', fileUploadInfo.signature);
      formData.append('x-amz-meta-upload-id', fileUploadInfo.uploadId);
      formData.append('Content-Disposition', fileUploadInfo.contentDisposition);

      if (fileUploadInfo.dataBaseId) {
        formData.append('x-amz-meta-object-id', fileUploadInfo.dataBaseId);

        if (file.pageWidth) {
          formData.append('x-amz-meta-page-width', file.pageWidth);
        }

        if (file.pageHeight) {
          formData.append('x-amz-meta-page-height', file.pageHeight);
        }

        if (file.numberOfFiles) {
          formData.append('x-amz-meta-number-files', file.numberOfFiles);
        }

        if (fileUploadInfo.callbackUrl) {
          formData.append('x-amz-meta-callback-url', fileUploadInfo.callbackUrl);
        }
      }

      return formData;
    },

    /**
     * Performs the actual upload. Don't forget to add {File} or {Blob} to formdata!
     *
     * @param formData
     * @private
     */
    _uploadFormData(formData, url, resolve, reject) {
      assert('formData must be of type FormData', formData instanceof FormData);
      assert('Must provide url', isPresent(url));
      assert('Must provide resolve', isPresent(resolve));
      assert('Must provide reject', isPresent(reject));
      $.ajax({
        type: 'POST',
        url: url,
        contentType: false,
        processData: false,
        data: formData,
        async: true,
        disableAjaxLoader: true
      }).then(() => resolve()).catch(() => reject('error.amazon_upload_failure'));
    }

  });

  _exports.default = _default;
});