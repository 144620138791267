define("parro-web/routes/oauth2", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    RSVP
  } = _ember.default;

  var _default = _ember.default.Route.extend({
    session: service(),
    authCode: {},

    titleToken() {
      return this.intl.t('pagetitle.authenticate');
    },

    beforeModel(transition) {
      const queryParams = transition.to.queryParams;
      this.set('authCode.code', queryParams.code);
      this.set('authCode.state', queryParams.state);
      this.set('authCode.error', queryParams.error);
    },

    model() {
      return RSVP.hash({
        code: this.get('authCode.code'),
        state: this.get('authCode.state'),
        error: this.get('authCode.error')
      });
    }

  });

  _exports.default = _default;
});