define("parro-web/services/authentication-service", ["exports", "ember", "parro-web/mixins/LoginMixin", "parro-web/config/environment", "parro-web/mixins/metricsMixin", "moment", "parro-web/utils/browserUtil", "parro-web/mixins/routeMixin", "text-encoding-shim"], function (_exports, _ember, _LoginMixin, _environment, _metricsMixin, _moment, _browserUtil, _routeMixin, _textEncodingShim) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const assign = _ember.default.assign;
  const {
    isPresent,
    inject: {
      service
    },
    RSVP: {
      Promise
    },
    computed
  } = _ember.default;

  var _default = _ember.default.Service.extend(_metricsMixin.default, _LoginMixin.default, _routeMixin.default, {
    session: service(),
    ajaxService: service('ajax'),
    account: service(),
    dataprovider: service(),
    versionService: service(),
    store: service(),
    loginUrl: computed.readOnly('versionService.loginUrl'),
    parnassysRestUrl: computed.readOnly('versionService.parnassysRestUrl'),
    host: computed.readOnly('versionService.host'),
    access_token: computed.readOnly('session.data.authenticated.access_token'),
    refresh_token: computed.readOnly('session.data.authenticated.refresh_token'),
    tokenPromise: undefined,

    loginOAuth(options, resolve, reject) {
      const accountService = this.get('account'); // After sync (which will always return ok) get the account/me

      accountService.getAccount(options).then(json => {
        this.getAuthorizationRole(options, json).then(authRole => {
          json.expires_in = options.tokens.expires_in;
          json.access_token = options.tokens.access_token;
          json.refresh_token = options.tokens.refresh_token;
          json.token_expires_at = options.tokens.token_expires_at ? options.tokens.token_expires_at : (0, _moment.default)().add(options.tokens.expires_in, 's').toDate();
          json.id_token = options.tokens.id_token;
          json.authrole = authRole;
          resolve(json);
        }, jqXHR => reject(jqXHR));
      }, jqXHR => reject(jqXHR));
    },

    getAuthorizationRole(options, json) {
      // The desired authRole was already supplied.
      if (isPresent(options.authRole)) {
        return new Promise(resolve => resolve(options.authRole));
      }

      let persons = [];

      if (json.accountType === 'TEACHER') {
        persons = json.identity.teachers;
      } else if (json.accountType === 'GUARDIAN') {
        persons = json.identity.guardians;
      }

      const toAuthRole = person => json.accountType + ':' + this.get('dataprovider').findId(person, 'self'); // Only one choice? Then use that choice.


      if (isPresent(persons) && persons.length === 1) {
        const authRole = toAuthRole(persons[0]);
        return new Promise(resolve => resolve(authRole));
      } // Multiple choices? Ugh, ask the user for input via choiceCallback.


      if (Array.isSimilarToArray(persons) && persons.length > 1 && isPresent(options.choiceCallback)) {
        const schools = persons.map(person => _ember.default.Object.create({
          id: person.organisation.id,
          name: person.organisation.name,
          description: person.dtype === 'identity.RTeacher' ? 'parro.teacher' : 'parro.guardian',
          authRole: toAuthRole(person)
        }));
        return new Promise((resolve, reject) => {
          const onChoice = school => resolve(school.get('authRole'));

          const onCancel = () => reject('NO_CHOICE_MADE');

          options.choiceCallback.apply(this, [schools, onChoice, onCancel]);
        });
      } // Fail. No persons or choiceCallback.


      return Promise(reject => reject());
    },

    isTokenExpired(session) {
      const {
        expires_in,
        token_expires_at
      } = session;
      const limit = expires_in / 6;
      return (0, _moment.default)(token_expires_at).diff(new Date(), 'seconds') < limit;
    },

    startAuthorize(config, resolve, reject) {
      const oauthConfig = this.getOauthConfig(config);

      const onSuccess = url => {
        window.location.assign(url);
      };

      _browserUtil.default.removeItem('ember_simple_auth-session');

      this.authorize(oauthConfig).then(onSuccess, reject);
    },

    _updateTokens(json) {
      if (isPresent(json)) {
        this.set('session.data.authenticated.expires_in', json.expires_in);
        this.set('session.data.authenticated.access_token', json.access_token);
        this.set('session.data.authenticated.refresh_token', json.refresh_token);
        this.set('session.data.authenticated.token_expires_at', (0, _moment.default)().add(json.expires_in, 's').toDate());
        this.set('session.data.authenticated.id_token', json.id_token);
        this.get('session.store').persist(this.get('session.data')); // meSync always resolves. No need for error handling.

        this.get('account').meSync(json.access_token);
      }
    },

    checkForExpiredTokens(data) {
      return new Promise((resolve, reject) => {
        const session = isPresent(data) ? data : this.get('session.data.authenticated');
        const {
          access_token,
          refresh_token
        } = session;

        if (refresh_token && access_token && this.isTokenExpired(session)) {
          const onSuccess = json => {
            this._updateTokens(json);

            resolve();
          };

          const onFinal = () => this.set('tokenPromise', undefined);

          if (!this.get('tokenPromise')) {
            const promise = this.refreshToken(refresh_token).then(onSuccess, reject).finally(onFinal);
            this.set('tokenPromise', promise);
          } else {
            this.get('tokenPromise').then(resolve, reject).finally(onFinal);
          }
        } else {
          resolve();
        }
      });
    },

    getOauthConfig(config = {}) {
      let oauthConfig = _environment.default.oauth;
      oauthConfig = assign(oauthConfig, {
        loginUrl: this.loginUrl,
        idToken: this.session.data?.authenticated?.id_token,
        redirectUri: window.location.origin + '/oauth2'
      });
      return oauthConfig;
    },

    logout(idToken) {
      return new Promise(resolve => {
        const redirectUrl = '?post_logout_redirect_uri=' + window.location.origin;
        const idTokenHint = isPresent(idToken) ? '&id_token_hint=' + idToken : '';
        const url = this.loginUrl + '/oauth2/logout' + redirectUrl + idTokenHint;
        return resolve(url);
      });
    },

    refreshToken(token) {
      return new Promise((resolve, reject) => {
        const suffix = 'refresh_token=' + token + '&client_id=' + _environment.default.APP.clientId + '&grant_type=refresh_token';

        const config = _ember.default.Object.create({
          ignoreRestVersion: true
        });

        const request = {
          type: 'POST',
          url: this.loginUrl + '/oauth2/token?' + suffix,
          contentType: 'application/x-www-form-urlencoded',
          disableAjaxLoader: true,
          ignoreRefresh: true,
          beforeSend: xhr => this.get('ajaxService').addDefaultHeaders(xhr, undefined, config)
        };
        this.get('ajaxService').executeRequest(request).then(json => resolve(json)).catch(jqXHR => {
          const error = jqXHR && jqXHR.responseText ? JSON.parse(jqXHR.responseText).error : undefined;

          if (jqXHR.status === 401 || jqXHR.status === 400 && error === 'invalid_grant') {
            return this.transitionTo('logoff');
          }

          reject('error.refresh_error');
        });
      });
    },

    exchangeToken(options, config) {
      return new Promise((resolve, reject) => {
        if (!options.code || options.state !== _browserUtil.default.getCookie('state')) {
          return reject('error.unknown_error');
        }

        const loginUrl = config.loginUrl + '/oauth2/token';

        const codeVerifier = _browserUtil.default.getCookie('codeVerifier');

        const ajaxConfig = _ember.default.Object.create({
          ignoreRestVersion: true
        });

        const request = {
          type: 'POST',
          url: loginUrl + '?code=' + options.code + '&client_id=' + config.clientId + '&grant_type=authorization_code' + '&code_verifier=' + codeVerifier,
          contentType: 'application/x-www-form-urlencoded',
          disableAjaxLoader: true,
          beforeSend: xhr => this.get('ajaxService').addDefaultHeaders(xhr, undefined, ajaxConfig)
        };
        this.get('ajaxService').executeRequest(request).then(json => resolve(json)).catch(jqXHR => {
          if (jqXHR.status === 401 || jqXHR.status === 400) {
            return this.transitionTo('logoff');
          }

          reject('error.unknown_error');
        }).finally(() => {
          _browserUtil.default.removeCookie('codeVerifier');

          _browserUtil.default.removeCookie('state');
        });
      });
    },

    authorize(config) {
      return new Promise((resolve, reject) => {
        const codeVerifier = String.generateRandomString(32);
        const params = {
          state: String.generateRandomString(25),
          redirectUri: encodeURI(config.redirectUri),
          loginUrl: config.loginUrl + '/oauth2/authorize',
          idToken: config.idToken ? '&id_token_hint=' + config.idToken : ''
        };

        const onSuccess = codeChallenge => {
          const expiresAt = Date.addMinutes(new Date(), 60);

          _browserUtil.default.setCookie('codeVerifier', codeVerifier, expiresAt);

          _browserUtil.default.setCookie('state', params.state, expiresAt);

          params.codeChallenge = '&code_challenge_method=S256&code_challenge=' + codeChallenge;
          const url = this.createAuthorizeUrl(config, params);
          resolve(url);
        };

        const onError = () => reject();

        this.encrypt(codeVerifier).then(onSuccess, onError);
      });
    },

    encrypt(codeVerifier) {
      return new Promise((resolve, reject) => {
        const crypto = window.crypto || window.msCrypto;
        const subtle = crypto.subtle || crypto.webkitSubtle;
        const uint8array = new _textEncodingShim.default.TextEncoder('utf-8').encode(codeVerifier);
        const encrypt = subtle.digest('SHA-256', uint8array);

        const onSuccess = codeChallenge => resolve(String.b64EncodeUrl(codeChallenge));

        const onError = () => reject();

        encrypt.then(onSuccess, onError);
      });
    },

    createAuthorizeUrl(config, params) {
      return params.loginUrl + '?client_id=' + config.clientId + '&redirect_uri=' + encodeURIComponent(params.redirectUri) + '&response_type=' + config.responseType + '&state=' + params.state + '&scope=' + config.scope + '&oauth2=authorize' + params.codeChallenge + params.idToken;
    }

  });

  _exports.default = _default;
});