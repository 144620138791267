define("parro-web/templates/components/yearbook/templates/template-4", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPreview}}
    <div class="yearbook--shadow"></div>
    {{#if (and showQualityOverlay isBadQuality)}}<div {{action 'showWarning' bubbles=false}}class="yearbook--quality-overlay"><i class="icon-warning"></i></div>{{/if}}
  {{/if}}
  <article>
    <div class="bg--main"></div>
    <div class="bg--side"></div>
    <div class="image" style={{image}}></div>
    <div class="text">
      <p>{{content.text}}</p>
    </div>
    {{#if (and icon content.sortDate)}}
      <figure class={{figure.className}} style={{figure.style}}>
        <i class={{icon.className}} style={{icon.style}}></i>
        <h2>{{format-date-polyfill content.sortDate day='2-digit' month='2-digit' year='2-digit'}}</h2>
      </figure>
    {{else if (and figure yearbook.parroDrawingsEnabled)}}
      <figure class={{figure.className}} style={{figure.style}}></figure>
    {{/if}}
  </article>
  
  */
  {
    "id": "qVCyV0eR",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"yearbook--shadow\"],[12],[13],[1,\"\\n  \"],[41,[28,[37,2],[[33,3],[33,4]],null],[[[11,0],[24,0,\"yearbook--quality-overlay\"],[4,[38,5],[[30,0],\"showWarning\"],[[\"bubbles\"],[false]]],[12],[10,\"i\"],[14,0,\"icon-warning\"],[12],[13],[13]],[]],null],[1,\"\\n\"]],[]],null],[10,\"article\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"bg--main\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"bg--side\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"image\"],[15,5,[36,6]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"text\"],[12],[1,\"\\n    \"],[10,2],[12],[1,[33,7,[\"text\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,2],[[33,8],[33,7,[\"sortDate\"]]],null],[[[1,\"    \"],[10,\"figure\"],[15,0,[33,9,[\"className\"]]],[15,5,[33,9,[\"style\"]]],[12],[1,\"\\n      \"],[10,\"i\"],[15,0,[33,8,[\"className\"]]],[15,5,[33,8,[\"style\"]]],[12],[13],[1,\"\\n      \"],[10,\"h2\"],[12],[1,[28,[35,10],[[33,7,[\"sortDate\"]]],[[\"day\",\"month\",\"year\"],[\"2-digit\",\"2-digit\",\"2-digit\"]]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[33,9],[33,11,[\"parroDrawingsEnabled\"]]],null],[[[1,\"    \"],[10,\"figure\"],[15,0,[33,9,[\"className\"]]],[15,5,[33,9,[\"style\"]]],[12],[13],[1,\"\\n  \"]],[]],null]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"isPreview\",\"and\",\"showQualityOverlay\",\"isBadQuality\",\"action\",\"image\",\"content\",\"icon\",\"figure\",\"format-date-polyfill\",\"yearbook\"]]",
    "moduleName": "parro-web/templates/components/yearbook/templates/template-4.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});