define("parro-web/services/file-service", ["exports", "ember", "parro-web/objects/ImageEntry"], function (_exports, _ember, _ImageEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FILE_DEFINITIONS = [{
    extension: '.jpg',
    attachment: 'IMAGE',
    mime: 'image/jpeg'
  }, {
    extension: '.jpeg',
    attachment: 'IMAGE',
    mime: 'image/jpeg'
  }, {
    extension: '.png',
    attachment: 'IMAGE',
    mime: 'image/png'
  }];
  const {
    assert,
    isPresent,
    RSVP: {
      Promise
    },
    isEmpty
  } = _ember.default;

  var _default = _ember.default.Service.extend({
    getAttachmentType(file, forceDocument = false) {
      const type = file?.attachmentType || this.getFileDefinition(file).attachment;
      return type === 'IMAGE' && forceDocument ? 'DOCUMENT' : type;
    },

    getContentType(file) {
      if (isEmpty(file.type)) {
        const fileDefinition = this.getFileDefinition(file);
        return fileDefinition.mime;
      }

      return file.type;
    },

    getFileDefinition(file) {
      return FILE_DEFINITIONS.find(definition => {
        const type = file.type;
        const name = file?.name?.toLowerCase();
        const mime = definition.mime.replace('*', '');
        return type ? type.startsWith(mime) : name?.endsWith(definition.extension);
      }) || {};
    },

    transformImageFile(file, sizeTransformation, orientationTransformation) {
      return new Promise((resolve, reject) => {
        assert('file must be of type File or Blob', file instanceof File || file instanceof Blob);
        assert('file.type must be of type image', file.type.match(/image.*/));
        assert('sizeTransformation must be of type Function', sizeTransformation instanceof Function);
        assert('orientationTransformation must be of type Function', orientationTransformation instanceof Function);

        const onAbort = () => reject({
          event: 'onabort'
        });

        const onError = error => reject({
          event: 'onerror',
          error: error
        }); // -- Read as arraybuffer to obtain orientation information.


        const arrayReader = new FileReader();
        arrayReader.onabort = onAbort;
        arrayReader.onerror = onError;

        arrayReader.onload = e => {
          const orientation = orientationTransformation(e); // -- Read as dataurl to obtain image and transform it to canvas.

          const urlReader = new FileReader();
          urlReader.onabort = onAbort;
          urlReader.onerror = onError;

          urlReader.onload = () => {
            const img = new Image();

            img.onload = () => {
              const imageDimensions = sizeTransformation(img);
              return this.convertImageToCanvas(img, imageDimensions, orientation).then(resolve, reject);
            };

            img.src = urlReader.result;
          };

          urlReader.readAsDataURL(file);
        };

        arrayReader.readAsArrayBuffer(file);
      });
    },

    convertImageToCanvas(image, imageDimensions, orientation) {
      return new Promise(resolve => {
        assert('image must be of type Image', image instanceof Image);
        assert('imageDimensions must have width', $.isNumeric(imageDimensions.width));
        assert('imageDimensions must have height', $.isNumeric(imageDimensions.height));
        assert('orientation must be numeric', $.isNumeric(orientation));
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#FFF';
        const width = imageDimensions.width;
        const height = imageDimensions.height;
        canvas.width = width;
        canvas.height = height;
        canvas.originalWidth = image.width;
        canvas.originalHeight = image.height; // Draw the image in the desired dimensions - http://stackoverflow.com/a/24015367

        ctx.drawImage(image, 0, 0, width, height);
        resolve(_ImageEntry.default.create({
          canvas: canvas,
          width: canvas.width,
          height: canvas.height
        }));
      });
    },

    convertCanvasToBlob(canvas, contentType) {
      return new Promise(resolve => {
        assert('canvas must be of type HTMLCanvasElement', canvas instanceof HTMLCanvasElement);
        assert('Must provide contentType', isPresent(contentType));
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#fff';
        const dataURI = canvas.toDataURL('images/jpg');
        const binary = atob(dataURI.split(',')[1]);
        const array = [];

        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }

        resolve(new Blob([new Uint8Array(array)], {
          type: contentType
        }));
      });
    },

    getImageFormat(image) {
      const width = image.get('width');
      const height = image.get('height');

      if (width === height) {
        return 'SQUARE';
      } else if (width > height) {
        return 'LANDSCAPE';
      } else if (height > width) {
        return 'PORTRAIT';
      }

      return 'UNKNOWN';
    },

    convertBlobToFile(blob, fileName) {
      blob.lastModifiedDate = new Date();

      if (isPresent(fileName)) {
        blob.name = fileName;
      }

      return blob;
    }

  });

  _exports.default = _default;
});