define("parro-web/templates/components/submenu/item-options-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if icon}}
    <i class="{{icon}}" {{on 'click' (stop-propagation (action 'toggle'))}}></i>
  {{else}}
    <div {{on 'click' (stop-propagation (action 'toggle'))}} class="item-options-menu-overlay"></div>
  {{/if}}
  
  {{#if optionsVisible}}
    <div class="item-options-menu--popover">
      {{yield}}
    </div>
  {{/if}}
  
  */
  {
    "id": "sQpT2ysB",
    "block": "[[[41,[33,1],[[[1,\"  \"],[11,\"i\"],[16,0,[29,[[36,1]]]],[4,[38,2],[\"click\",[28,[37,3],[[28,[37,4],[[30,0],\"toggle\"],null]],null]],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[24,0,\"item-options-menu-overlay\"],[4,[38,2],[\"click\",[28,[37,3],[[28,[37,4],[[30,0],\"toggle\"],null]],null]],null],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[33,5],[[[1,\"  \"],[10,0],[14,0,\"item-options-menu--popover\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"icon\",\"on\",\"stop-propagation\",\"action\",\"optionsVisible\",\"yield\"]]",
    "moduleName": "parro-web/templates/components/submenu/item-options-menu.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});