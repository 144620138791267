define("parro-web/components/modal/base-modal", ["exports", "ember", "parro-web/models/services/dialogs", "@ember/utils"], function (_exports, _ember, _dialogs, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    String: {
      htmlSafe
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    closeConfirmationConfig: _dialogs.default.closeConfirmationConfig(),
    visibilityObserver: _ember.default.observer('showModal', function () {
      this.send(this.get('showModal') ? 'onShow' : 'onClose');
    }),
    zIndexStyle: computed('zIndex', function () {
      return (0, _utils.isPresent)(this.zIndex) ? htmlSafe('z-index: ' + this.zIndex) : undefined;
    }),
    actions: {
      show() {
        this.set('showModal', true);
      },

      close() {
        this.flashClear();
        this.set('showModal', false);
      },

      closeOnConfirm(request) {
        if (request) {
          const config = this.get('closeConfirmationConfig');
          request.apply(this, [config, () => this.send('close')]);
        }
      },

      onShow: function () {},
      onClose: function () {}
    }
  });

  _exports.default = _default;
});