define("parro-web/templates/logoff", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{login/transition-loader isLogout=true}}
  */
  {
    "id": "Ldx7dOtw",
    "block": "[[[1,[28,[35,0],null,[[\"isLogout\"],[true]]]]],[],false,[\"login/transition-loader\"]]",
    "moduleName": "parro-web/templates/logoff.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});