define("parro-web/objects/TimeRange", ["exports", "ember", "parro-web/utils/timeUtil"], function (_exports, _ember, _timeUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Object.extend({
    start: undefined,
    end: undefined,

    contains(date) {
      assert('date must be of type Date', date instanceof Date);

      const dateTicks = _timeUtil.default.toTicks(date);

      const startTicks = _timeUtil.default.toTicks(this.get('start'));

      const endTicks = _timeUtil.default.toTicks(this.get('end'));

      return dateTicks >= startTicks && dateTicks <= endTicks;
    },

    overlaps(range) {
      assert('Must provide range', isPresent(range));

      const start = _timeUtil.default.toTicks(this.get('start'));

      const end = _timeUtil.default.toTicks(this.get('end'));

      const otherStart = _timeUtil.default.toTicks(range.get('start'));

      const otherEnd = _timeUtil.default.toTicks(range.get('end'));

      return otherStart < end && otherEnd > start;
    }

  });

  _exports.default = _default;
});