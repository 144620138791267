define("parro-web/templates/components/yearbook/yearbook-announcement", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless announcement.deleted}}
    <h4 class="header--normal mb-0">{{#if childNames}}{{childNames}} - {{/if}}{{announcement.group.name}}</h4>
  
    <time>{{format-date-polyfill announcement.sortDate day='numeric' month='long' year='numeric'}}</time>
    {{#if (gt images.length 1)}}<small>{{t 'yearbook_editor.selected_images' amount1=selectedImages.length amount2=images.length}}</small>{{/if}}
  
    {{#if announcement.title}}<h3 class="header--section mb-0">{{announcement.title}}</h3>{{/if}}
  
    {{#if announcement.hasImages}}
      <div class="suite-stream-gallery">
        {{photo/yearbook-photo-swipe items=images selectedImages=selectedImages selectImage=(action 'toggleImage') toNavImages=(action 'toNavImages') requestConfirmation=requestConfirmation}}
      </div>
    {{/if}}
  
    {{yearbook/yearbook-announcement-contents yearbook=yearbook announcement=announcement contents=announcement.contents includeText=includeText isUpdating=isUpdating requestConfirmation=requestConfirmation onUpdateText=(action 'onUpdateText')}}
  {{else}}
    <p>{{t 'group_announcements.deleted_announcement_other'}}</p>
  {{/unless}}
  
  
  */
  {
    "id": "37v06DPg",
    "block": "[[[41,[51,[33,1,[\"deleted\"]]],[[[1,\"  \"],[10,\"h4\"],[14,0,\"header--normal mb-0\"],[12],[41,[33,3],[[[1,[34,3]],[1,\" - \"]],[]],null],[1,[33,1,[\"group\",\"name\"]]],[13],[1,\"\\n\\n  \"],[10,\"time\"],[12],[1,[28,[35,4],[[33,1,[\"sortDate\"]]],[[\"day\",\"month\",\"year\"],[\"numeric\",\"long\",\"numeric\"]]]],[13],[1,\"\\n  \"],[41,[28,[37,5],[[33,6,[\"length\"]],1],null],[[[10,\"small\"],[12],[1,[28,[35,7],[\"yearbook_editor.selected_images\"],[[\"amount1\",\"amount2\"],[[33,8,[\"length\"]],[33,6,[\"length\"]]]]]],[13]],[]],null],[1,\"\\n\\n  \"],[41,[33,1,[\"title\"]],[[[10,\"h3\"],[14,0,\"header--section mb-0\"],[12],[1,[33,1,[\"title\"]]],[13]],[]],null],[1,\"\\n\\n\"],[41,[33,1,[\"hasImages\"]],[[[1,\"    \"],[10,0],[14,0,\"suite-stream-gallery\"],[12],[1,\"\\n      \"],[1,[28,[35,9],null,[[\"items\",\"selectedImages\",\"selectImage\",\"toNavImages\",\"requestConfirmation\"],[[33,6],[33,8],[28,[37,10],[[30,0],\"toggleImage\"],null],[28,[37,10],[[30,0],\"toNavImages\"],null],[33,11]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[1,[28,[35,12],null,[[\"yearbook\",\"announcement\",\"contents\",\"includeText\",\"isUpdating\",\"requestConfirmation\",\"onUpdateText\"],[[33,13],[33,1],[33,1,[\"contents\"]],[33,14],[33,15],[33,11],[28,[37,10],[[30,0],\"onUpdateText\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,2],[12],[1,[28,[35,7],[\"group_announcements.deleted_announcement_other\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[],false,[\"unless\",\"announcement\",\"if\",\"childNames\",\"format-date-polyfill\",\"gt\",\"images\",\"t\",\"selectedImages\",\"photo/yearbook-photo-swipe\",\"action\",\"requestConfirmation\",\"yearbook/yearbook-announcement-contents\",\"yearbook\",\"includeText\",\"isUpdating\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-announcement.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});