define("parro-web/templates/components/modal/modal-overlay", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if action}}
    <div {{action action}} {{action action on='touchEnd'}} id="modal--overlay" class="modal--overlay {{if submodal 'modal--overlay-sub' ''}}" style="{{zIndexStyle}}"></div>
  {{else}}
    <div id="modal--overlay" class="modal--overlay {{if submodal 'modal--overlay-sub' ''}}" style="{{zIndexStyle}}"></div>
  {{/if}}
  
  
  */
  {
    "id": "0jTzwBrU",
    "block": "[[[41,[33,1],[[[1,\"  \"],[11,0],[24,1,\"modal--overlay\"],[16,0,[29,[\"modal--overlay \",[52,[33,2],\"modal--overlay-sub\",\"\"]]]],[16,5,[36,3]],[4,[38,1],[[30,0],[33,1]],null],[4,[38,1],[[30,0],[33,1]],[[\"on\"],[\"touchEnd\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,1,\"modal--overlay\"],[15,0,[29,[\"modal--overlay \",[52,[33,2],\"modal--overlay-sub\",\"\"]]]],[15,5,[36,3]],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[],false,[\"if\",\"action\",\"submodal\",\"zIndexStyle\"]]",
    "moduleName": "parro-web/templates/components/modal/modal-overlay.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});