define("parro-web/templates/components/yearbook/yearbook-announcement-images", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yearbook/yearbook-image-selection images=images yearbook=yearbook selectImage=(action 'toggleImage') toNavChoice=toNavChoice requestConfirmation=requestConfirmation}}
  
  <div class="panel--footer-wrapper">
  
    <div class="panel yearbook--image-selection-all">
      {{t 'yearbook_editor.select_all'}}
      {{form/ios8-switch checked=selected toggle=(action 'toggleAll')}}
    </div>
  
    <div class="panel--footer">
      {{button/button title='button.next2' onClickAction=(action 'next') isLoading=isLoading}}
    </div>
  </div>
  
  */
  {
    "id": "vHwA3iR/",
    "block": "[[[1,[28,[35,0],null,[[\"images\",\"yearbook\",\"selectImage\",\"toNavChoice\",\"requestConfirmation\"],[[33,1],[33,2],[28,[37,3],[[30,0],\"toggleImage\"],null],[33,4],[33,5]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"panel--footer-wrapper\"],[12],[1,\"\\n\\n  \"],[10,0],[14,0,\"panel yearbook--image-selection-all\"],[12],[1,\"\\n    \"],[1,[28,[35,6],[\"yearbook_editor.select_all\"],null]],[1,\"\\n    \"],[1,[28,[35,7],null,[[\"checked\",\"toggle\"],[[33,8],[28,[37,3],[[30,0],\"toggleAll\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"panel--footer\"],[12],[1,\"\\n    \"],[1,[28,[35,9],null,[[\"title\",\"onClickAction\",\"isLoading\"],[\"button.next2\",[28,[37,3],[[30,0],\"next\"],null],[33,10]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"yearbook/yearbook-image-selection\",\"images\",\"yearbook\",\"action\",\"toNavChoice\",\"requestConfirmation\",\"t\",\"form/ios8-switch\",\"selected\",\"button/button\",\"isLoading\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-announcement-images.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});