define("parro-web/components/yearbook/yearbook-status-bar", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: 'div',
    classNames: ['content--top-yearbook'],
    yearbookService: service(),
    yearbookAjaxService: service(),
    store: service(),
    EventBusService: service(),

    init() {
      this._super(...arguments);

      this.get('yearbookService');
      this.EventBusService.on('update-yearbook-selection', () => this._onUpdate());
    },

    _onUpdate() {
      this.notifyPropertyChange('amount');
    },

    amount: computed('selection.@each', 'selection.@each.images', function () {
      return this.get('yearbookService').getAmountOfChosenImages(this.get('yearbook'));
    }),
    displayPrice: computed('amount', 'yearbook.type', function () {
      if (this.get('yearbook.isPromo')) {
        return this.get('yearbookService').convertPriceToDisplay(this.get('yearbook.price'));
      }

      const price = this.get('yearbookService').getTotalPrice(this.get('yearbook'), this.get('prices'));
      return this.get('yearbookService').convertPriceToDisplay(price);
    }),
    displayMaxPages: computed('yearbook.maxPages', function () {
      return this.get('yearbook.maxPages') - 2;
    }),
    displayType: computed('yearbook.type', function () {
      switch (this.get('yearbook.type')) {
        case 'HARDCOVER':
          return 'yearbook_type.type_hardcover';

        case 'SOFTCOVER':
          return 'yearbook_type.type_softcover';

        case 'SQUARE_FOLD':
          return 'yearbook_type.type_squarefold';
      }

      return undefined;
    })
  });

  _exports.default = _default;
});