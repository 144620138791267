define("parro-web/templates/yearbook/drawing", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{navigation/nav-header-yearbook config=model.navConfig }}
  
  {{navigation/nav-menu-overlay register=navMenuOverlay yearbook=model.yearbook requestConfirmation=(route-action 'requestConfirmation')}}
  
  <section class="content-wrapper">
    <div class="content">
      {{yearbook/yearbook-drawing yearbook=model.yearbook }}
    </div>
  </section>
  */
  {
    "id": "2G0gVDfW",
    "block": "[[[1,[28,[35,0],null,[[\"config\"],[[33,1,[\"navConfig\"]]]]]],[1,\"\\n\\n\"],[1,[28,[35,2],null,[[\"register\",\"yearbook\",\"requestConfirmation\"],[[33,3],[33,1,[\"yearbook\"]],[28,[37,4],[\"requestConfirmation\"],null]]]]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"content-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"yearbook\"],[[33,1,[\"yearbook\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"navigation/nav-header-yearbook\",\"model\",\"navigation/nav-menu-overlay\",\"navMenuOverlay\",\"route-action\",\"yearbook/yearbook-drawing\"]]",
    "moduleName": "parro-web/templates/yearbook/drawing.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});