define("parro-web/initializers/oauth-authenticator", ["exports", "ember-simple-auth/authenticators/base", "ember", "parro-web/mixins/metricsMixin", "parro-web/utils/browserUtil"], function (_exports, _base, _ember, _metricsMixin, _browserUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    RSVP: {
      Promise
    }
  } = _ember.default;

  const ParroAuthenticator = _base.default.extend(_metricsMixin.default, {
    authenticationService: service(),
    EventBusService: service(),

    restore(data) {
      return new Promise((resolve, reject) => {
        if (data && data.invalidated) {
          return reject();
        }

        if (this.authenticationService.isTokenExpired(data)) {
          const config = {
            authRole: data.authrole
          };
          return this.authenticationService.startAuthorize(config, resolve, reject);
        }

        resolve(data);
      });
    },

    authenticate(options) {
      return new Promise((resolve, reject) => {
        this.authenticationService.loginOAuth(options, resolve, reject);
      });
    },

    invalidate() {
      this.EventBusService.emit('logoff', this);
      this.trackEvent('SETTINGS', 'LOGOUT');

      _browserUtil.default.removeCookie('redirect-info');

      return this._super(...arguments);
    }

  });

  var _default = {
    name: 'OAuth-authentication',
    before: ['ember-simple-auth'],
    initialize: application => application.register('authenticator:OAuth-authenticator', ParroAuthenticator)
  };
  _exports.default = _default;
});