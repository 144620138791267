define("parro-web/routes/yearbook/index", ["exports", "ember", "parro-web/mixins/indexRedirectRouteMixin"], function (_exports, _ember, _indexRedirectRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Route.extend(_indexRedirectRouteMixin.default, {
    session: service(),

    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },

    afterModelTransitionToFallback: 'yearbook.overview'
  });

  _exports.default = _default;
});