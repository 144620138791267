define("parro-web/templates/components/yearbook/templates/template-blank", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPreview}}<div class="yearbook--shadow"></div>{{/if}}
  
  */
  {
    "id": "L5On8sXU",
    "block": "[[[41,[33,1],[[[10,0],[14,0,\"yearbook--shadow\"],[12],[13]],[]],null],[1,\"\\n\"]],[],false,[\"if\",\"isPreview\"]]",
    "moduleName": "parro-web/templates/components/yearbook/templates/template-blank.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});