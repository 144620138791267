define("parro-web/components/navigation/nav-menu-overlay", ["exports", "ember", "parro-web/models/services/dialogs", "parro-web/config/environment", "parro-web/mixins/routeMixin", "parro-web/utils/mobileUtil"], function (_exports, _ember, _dialogs, _environment, _routeMixin, _mobileUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: '',
    yearbookService: service(),

    didInsertElement() {
      this._super(...arguments);

      _ember.default.run.schedule('routerTransitions', this, () => this.set('register', this));
    },

    show() {
      this.set('visible', true);
      document.body.classList.add('disable-scroll');
    },

    actions: {
      close() {
        this.set('visible', false);
        document.body.classList.remove('disable-scroll');
      },

      navigate(route, yearbook, historyRoute) {
        const id = isPresent(yearbook) ? yearbook.get('id') : undefined;

        if (route === 'groups') {
          _mobileUtil.default.terminateYearbookWebview();
        }

        this.send('close');
        const routeName = this.get('router.currentRouteName');

        if (historyRoute && this.get('yearbookService.historyRoute') !== routeName) {
          this.set('yearbookService.historyRoute', routeName);
        }

        if (id) {
          if (yearbook.get('isPromo')) {
            this.requestConfirmation(_dialogs.default.yearbookPromoDialogConfig());
          } else {
            this.transitionTo(route, id);
          }
        } else {
          this.transitionTo(route);
        }
      },

      information() {
        window.open(_environment.default.externalUrls.yearbookSupport, '_blank');
      },

      feedback() {
        window.open(_environment.default.externalUrls.yearbookFeedback, '_blank');
      },

      returnToParro() {
        this.requestConfirmation(_dialogs.default.returnToParroDialogConfig(), () => {
          _mobileUtil.default.terminateYearbookWebview();

          window.open('https://talk.parro.com', '_self');
        });
      }

    }
  });

  _exports.default = _default;
});