define("parro-web/templates/yearbook/editor", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <header class="header" id="content-header">
    {{navigation/nav-header-yearbook config=model.navConfig }}
    {{#unless model.isScrolling}}{{yearbook/yearbook-status-bar yearbook=model.yearbook selection=model.selection prices=model.prices}}{{/unless}}
    {{navigation/nav-menu-tabs tabs=model.tabs}}
  </header>
  
  {{navigation/nav-menu-overlay register=navMenuOverlay yearbook=model.yearbook requestConfirmation=(route-action 'requestConfirmation')}}
  
  <section class="content-wrapper">
  
    <div class="content">
      {{yearbook/yearbook-editor tabs=model.tabs yearbook=model.yearbook groups=model.groups selection=model.selection isScrolling=model.isScrolling isLoading=model.isLoading requestConfirmation=(route-action 'requestConfirmation') closeConfirmation=(route-action 'closeConfirmation') showLoader=(route-action 'showLoader')}}
    </div>
  </section>
  */
  {
    "id": "CPBIqTYE",
    "block": "[[[10,\"header\"],[14,0,\"header\"],[14,1,\"content-header\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"config\"],[[33,1,[\"navConfig\"]]]]]],[1,\"\\n  \"],[41,[51,[33,1,[\"isScrolling\"]]],[[[1,[28,[35,3],null,[[\"yearbook\",\"selection\",\"prices\"],[[33,1,[\"yearbook\"]],[33,1,[\"selection\"]],[33,1,[\"prices\"]]]]]]],[]],null],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"tabs\"],[[33,1,[\"tabs\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,5],null,[[\"register\",\"yearbook\",\"requestConfirmation\"],[[33,6],[33,1,[\"yearbook\"]],[28,[37,7],[\"requestConfirmation\"],null]]]]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"content-wrapper\"],[12],[1,\"\\n\\n  \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n    \"],[1,[28,[35,8],null,[[\"tabs\",\"yearbook\",\"groups\",\"selection\",\"isScrolling\",\"isLoading\",\"requestConfirmation\",\"closeConfirmation\",\"showLoader\"],[[33,1,[\"tabs\"]],[33,1,[\"yearbook\"]],[33,1,[\"groups\"]],[33,1,[\"selection\"]],[33,1,[\"isScrolling\"]],[33,1,[\"isLoading\"]],[28,[37,7],[\"requestConfirmation\"],null],[28,[37,7],[\"closeConfirmation\"],null],[28,[37,7],[\"showLoader\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"navigation/nav-header-yearbook\",\"model\",\"unless\",\"yearbook/yearbook-status-bar\",\"navigation/nav-menu-tabs\",\"navigation/nav-menu-overlay\",\"navMenuOverlay\",\"route-action\",\"yearbook/yearbook-editor\"]]",
    "moduleName": "parro-web/templates/yearbook/editor.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});