define("parro-web/services/guardian-service", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;
  const assign = _ember.default.assign;

  var _default = _ember.default.Service.extend({
    store: service(),
    versionService: service(),
    host: computed.readOnly('versionService.host'),

    getChildren(args) {
      args = assign(args, {
        disableAjaxLoader: true
      });
      return this.store.query('child', args);
    }

  });

  _exports.default = _default;
});