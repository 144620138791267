define("parro-web/objects/AttachmentsUploadConfig", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Object.extend({
    maxTotal: undefined,
    // files and pictures combined
    maxPictures: undefined,
    dtype: undefined,
    addIndex: undefined,
    recycler: undefined,
    extraUploadInfo: undefined
  });

  _exports.default = _default;
});