define("parro-web/models/navigation/confirmationdialogconfig", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Object.extend({
    title: undefined,
    text: undefined,
    buttonOkClass: 'btn--primary',
    buttonOkText: undefined,
    buttonCancelClass: 'btn--destructive',
    buttonCancelText: undefined,
    checkboxText: undefined,
    checked: false
  });

  _exports.default = _default;
});