define("parro-web/routes/oops/index", ["exports", "ember", "parro-web/mixins/indexRedirectRouteMixin"], function (_exports, _ember, _indexRedirectRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Route.extend(_indexRedirectRouteMixin.default, {
    afterModelTransitionToFallback: 'oops.default'
  });

  _exports.default = _default;
});