define("parro-web/services/ajax", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    inject: {
      service
    },
    isEmpty,
    isPresent,
    RSVP: {
      Promise
    }
  } = _ember.default;

  var _default = _ember.default.Service.extend({
    session: service(),
    authenticationService: service(),
    versionService: service(),

    executeRequest(request) {
      return new Promise((resolve, reject) => {
        assert('Must provide request', request);
        assert('Must provide request.type', request.type);
        assert('Must provide request.url', request.url); // Stringify to JSON data if present

        if (isPresent(request.data)) {
          request.data = JSON.stringify(request.data);
        } // Set default values if empty


        if (isEmpty(request.contentType)) {
          request.contentType = 'application/json';
        }

        if (isEmpty(request.dataType)) {
          request.dataType = 'json';
        }

        if (isEmpty(request.async)) {
          request.async = true;
        } // Add default headers


        if (typeof request.beforeSend !== 'function') {
          const session = this.get('session');
          assert('Must provide session', session);

          request.beforeSend = xhr => this.addDefaultHeaders(xhr, session);
        }

        if (request.ignoreRefresh) {
          return resolve($.ajax(request));
        }

        const onSuccess = () => resolve($.ajax(request));

        const onError = jqXHR => reject(Promise.reject(jqXHR));

        this.get('authenticationService').checkForExpiredTokens().then(onSuccess, onError);
      });
    },

    addDefaultHeaders(xhr, data, config) {
      if (isPresent(data)) {
        if (typeof data === 'string') {
          xhr.setRequestHeader('Authorization', 'Bearer ' + data);
        } else {
          if (data.get('data.authenticated.access_token')) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + data.get('data.authenticated.access_token'));
          }

          if (data.get('access_token')) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + data.get('access_token'));
          }

          if (data.get('data.authenticated.authrole')) {
            xhr.setRequestHeader('parro-Authorization-Role', data.get('data.authenticated.authrole'));
          }

          if (data.get('authrole')) {
            xhr.setRequestHeader('parro-Authorization-Role', data.get('authrole'));
          }
        }
      }

      xhr.setRequestHeader('Cache-Control', 'no-store');

      if (isPresent(config) && config.get('version')) {
        xhr.setRequestHeader('Accept', 'application/vnd.topicus.geon+json;version=' + config.get('version'));
        xhr.setRequestHeader('Content-Type', 'application/vnd.topicus.geon+json;version=' + config.get('version'));
      }

      if (isPresent(config) && config.get('ignoreRestVersion')) {
        return;
      }

      xhr.setRequestHeader('Accept', 'application/vnd.topicus.geon+json;version=' + this.versionService.restVersion);
      xhr.setRequestHeader('Content-Type', 'application/vnd.topicus.geon+json;version=' + this.versionService.restVersion);
    }

  });

  _exports.default = _default;
});