define("parro-web/templates/components/photo/photo-swipe-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a {{action launchGallery item}} data-width={{item.w}} data-height={{item.h}}>
    {{#if (gt items.length 1)}}
      <div class="gallery--counter-mobile"><span>{{items.length}}</span></div>
      <div class="gallery--counter-desktop"><span>{{items.length}}</span></div>
    {{/if}}
    <div class="suite-gallery-item" style={{style}}></div>
  </a>
  
  */
  {
    "id": "k0/mTMKy",
    "block": "[[[11,3],[16,\"data-width\",[33,0,[\"w\"]]],[16,\"data-height\",[33,0,[\"h\"]]],[4,[38,1],[[30,0],[33,2],[33,0]],null],[12],[1,\"\\n\"],[41,[28,[37,4],[[33,5,[\"length\"]],1],null],[[[1,\"    \"],[10,0],[14,0,\"gallery--counter-mobile\"],[12],[10,1],[12],[1,[33,5,[\"length\"]]],[13],[13],[1,\"\\n    \"],[10,0],[14,0,\"gallery--counter-desktop\"],[12],[10,1],[12],[1,[33,5,[\"length\"]]],[13],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"suite-gallery-item\"],[15,5,[36,6]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"item\",\"action\",\"launchGallery\",\"if\",\"gt\",\"items\",\"style\"]]",
    "moduleName": "parro-web/templates/components/photo/photo-swipe-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});