define("parro-web/objects/FileAttachment", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Object.extend({
    dtype: undefined,
    attachmentType: undefined,
    index: undefined,
    entries: []
  });

  _exports.default = _default;
});