define("parro-web/components/modal/yearbook/yearbook-filter-options", ["exports", "ember", "parro-web/mixins/routeMixin", "parro-web/models/navigation/navchoicemodel", "parro-web/mixins/dateFormatPolyfillMixin"], function (_exports, _ember, _routeMixin, _navchoicemodel, _dateFormatPolyfillMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, _dateFormatPolyfillMixin.default, {
    tagName: '',
    yearbookAjaxService: service(),
    yearbookService: service(),
    accountService: service('account'),
    guardianService: service(),
    groupsService: service(),
    yearbook: undefined,
    visible: false,
    selected: _navchoicemodel.default.create({
      name: 'yearbook_filter.tabs_date',
      route: 'DATE'
    }),
    showNavToDate: computed.equal('selected.route', 'DATE'),
    showFilterChild: computed.equal('selected.route', 'CHILDREN'),
    showFilterYear: computed.equal('selected.route', 'SCHOOLYEAR'),
    showFilterGroup: computed.equal('selected.route', 'GROUP'),
    guardianTabs: [_navchoicemodel.default.create({
      name: 'yearbook_filter.tabs_date',
      route: 'DATE'
    }), _navchoicemodel.default.create({
      name: 'yearbook_filter.tabs_child',
      route: 'CHILDREN'
    }), _navchoicemodel.default.create({
      name: 'yearbook_filter.tabs_schoolyear',
      route: 'SCHOOLYEAR'
    })],
    teacherTabs: [_navchoicemodel.default.create({
      name: 'yearbook_filter.tabs_date',
      route: 'DATE'
    }), _navchoicemodel.default.create({
      name: 'yearbook_filter.tabs_group',
      route: 'GROUP'
    }), _navchoicemodel.default.create({
      name: 'yearbook_filter.tabs_schoolyear',
      route: 'SCHOOLYEAR'
    })],
    tabs: computed('accountService.isGuardian', function () {
      return this.get('accountService.isGuardian') ? this.get('guardianTabs') : this.get('teacherTabs');
    }),
    toNavDate: computed('yearbook.toNavDate', function () {
      const date = this.formatDate(this.get('yearbook.toNavDate'), {
        month: 'long',
        year: 'numeric'
      });
      return isPresent(date) ? String.capitalizeFirstLetter(date) : undefined;
    }),

    _createMonths(years) {
      const array = [];
      const start = 7;
      const amountOfMonths = 12;
      const end = start + amountOfMonths;

      for (let j = 0; j < years.length; j++) {
        for (let i = start; i < end; ++i) {
          const date = new Date(years[j], i, 1);
          const format = this.formatDate(date, {
            month: 'long',
            year: 'numeric'
          });
          const data = {
            display: String.capitalizeFirstLetter(format),
            value: date
          };
          array.push(data);
        }
      }

      return array;
    },

    didInsertElement() {
      this._super(...arguments);

      _ember.default.run.schedule('routerTransitions', this, () => this.set('register', this));
    },

    months: computed('yearbook.selectedSchoolYears.@each', 'schoolYears.[]', function () {
      const savedSchoolYears = Array.orEmpty(this.get('yearbook.selectedSchoolYears')).map(year => parseInt(year));
      const allSchoolYears = Array.orEmpty(this.get('schoolYears')).map(year => parseInt(year.get('schoolyear')));

      if (isPresent(savedSchoolYears)) {
        return this._createMonths(savedSchoolYears);
      } else if (isPresent(allSchoolYears)) {
        return this._createMonths(allSchoolYears);
      }

      const currentSchoolYear = Date.getCurrentSchoolyearStart().getFullYear();
      return this._createMonths([currentSchoolYear]);
    }),

    _getSchoolYears() {
      const onSuccess = schoolYears => {
        const onFilter = y => y.get('schoolyear') !== 0;

        const onSort = (a, b) => a.get('schoolyear') - b.get('schoolyear');

        this.set('schoolYears', schoolYears.map(y => y).filter(onFilter).sort(onSort));
      };

      const onError = () => {};

      this.get('accountService').getSchoolYears().then(onSuccess, onError);
    },

    _getChildren() {
      const accountId = this.get('accountService.identity.currentPersona.id');

      const onSuccess = children => this.set('children', children);

      const onError = () => {};

      this.get('guardianService').getChildren({
        childrenRelatedToGuardian: accountId
      }).then(onSuccess, onError);
    },

    _getGroups() {
      const onSuccess = groups => this.set('groups', groups);

      const onError = () => {};

      this.get('groupsService').getGroups(false).then(onSuccess, onError);
    },

    _update() {
      const yearbook = this.get('yearbook');
      const filters = yearbook.get('filters');
      filters.selectedSchoolYears = yearbook.get('selectedSchoolYears');
      filters.selectedChildren = yearbook.get('selectedChildren');
      filters.selectedGroups = yearbook.get('selectedGroups');
      yearbook.set('activeFilters', JSON.stringify(filters));

      const onSuccess = () => {};

      const onFinal = () => this.showLoader(false);

      const onError = () => this.flashError('error.unknown_error');

      this.showLoader(1000);
      this.get('yearbookAjaxService').editYearbook(yearbook).then(onSuccess, onError).finally(onFinal);
    },

    filterObserver: _ember.default.observer('yearbook.selectedSchoolYears.[]', 'yearbook.selectedChildren.[]', 'yearbook.selectedGroups.[]', function () {
      _ember.default.run.debounce(this, this._update, 1000);
    }),

    show(model) {
      if (isPresent(model)) {
        this.set('selected', model);
      }

      this.set('visible', true);

      this._getSchoolYears();

      if (this.get('accountService.isGuardian')) {
        this._getChildren();
      } else {
        this._getGroups();
      }
    },

    actions: {
      close() {
        this.flashClear();
        this.set('visible', false);
      },

      selectYear(year) {
        const selected = this.get('yearbook.selectedSchoolYears').find(y => y === year.get('id'));

        if (isPresent(selected)) {
          this.get('yearbook.selectedSchoolYears').removeObject(year.get('id'));
        } else {
          this.get('yearbook.selectedSchoolYears').pushObject(year.get('id'));
        }
      },

      selectChild(child) {
        const selected = this.get('yearbook.selectedChildren').find(c => c === child.get('id'));

        if (isPresent(selected)) {
          this.get('yearbook.selectedChildren').removeObject(child.get('id'));
        } else {
          this.get('yearbook.selectedChildren').pushObject(child.get('id'));
        }
      },

      selectGroup(group) {
        const selected = this.get('yearbook.selectedGroups').find(g => g === group.get('id'));

        if (isPresent(selected)) {
          this.get('yearbook.selectedGroups').removeObject(group.get('id'));
        } else {
          this.get('yearbook.selectedGroups').pushObject(group.get('id'));
        }
      },

      selectMonth(month) {
        this.set('visible', false);
        this.onNavAction(month);
      }

    }
  });

  _exports.default = _default;
});