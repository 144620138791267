define("parro-web/templates/components/divider/list-divider", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if hasBlock}}
    <div class="{{if isInactive 'is-inactive' ''}}">
      {{#if name}}<h3 class="header--section">{{t-exists name}}</h3>{{/if}}
      {{yield}}
    </div>
  {{else}}
    <div class="{{if isInactive 'is-inactive' ''}}"><h3 class="header--section">{{t-exists name}}</h3></div>
  {{/if}}
  */
  {
    "id": "GrGFDnok",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[10,0],[15,0,[29,[[52,[33,2],\"is-inactive\",\"\"]]]],[12],[1,\"\\n    \"],[41,[33,3],[[[10,\"h3\"],[14,0,\"header--section\"],[12],[1,[28,[35,4],[[33,3]],null]],[13]],[]],null],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[29,[[52,[33,2],\"is-inactive\",\"\"]]]],[12],[10,\"h3\"],[14,0,\"header--section\"],[12],[1,[28,[35,4],[[33,3]],null]],[13],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"isInactive\",\"name\",\"t-exists\",\"yield\"]]",
    "moduleName": "parro-web/templates/components/divider/list-divider.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});