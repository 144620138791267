define("parro-web/models/yearbook", ["exports", "ember-data", "ember-inflector", "ember"], function (_exports, _emberData, _emberInflector, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'yearbook.RYearbook'
    }),
    identity: _emberData.default.belongsTo('identity', {
      async: false
    }),
    yearbookOrder: _emberData.default.belongsTo('yearbookorder', {
      async: false
    }),
    createdAt: _emberData.default.attr('date'),
    name: _emberData.default.attr('string'),
    pages: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    basePrice: _emberData.default.attr('number'),
    extraPages: _emberData.default.attr('number'),
    incrementalPagePrice: _emberData.default.attr('number'),
    additionalPagesPrice: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    color: _emberData.default.attr('string', {
      defaultValue: '#C9496A'
    }),
    lastModifiedAt: _emberData.default.attr('date'),
    copies: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    frontCover: _emberData.default.belongsTo('attachment', {
      async: false
    }),
    parroDrawingsEnabled: _emberData.default.attr('boolean'),
    activeFilters: _emberData.default.attr('string'),
    pdfGenerated: _emberData.default.attr('boolean'),
    filters: computed('sessionSelection', function () {
      return this._getActiveFilters() || _ember.default.Object.create();
    }),
    selectedSchoolYears: computed('sessionSelection', function () {
      return this.get('filters.selectedSchoolYears') || [];
    }),
    selectedChildren: computed('sessionSelection', function () {
      return this.get('filters.selectedChildren') || [];
    }),
    selectedGroups: computed('sessionSelection', function () {
      return this.get('filters.selectedGroups') || [];
    }),
    isSquareFold: computed.equal('type', 'SQUARE_FOLD'),
    isSoftCover: computed.equal('type', 'SOFTCOVER'),
    isHardCover: computed.equal('type', 'HARDCOVER'),
    isEditing: computed.equal('status', 'EDITING'),
    isPaid: computed.equal('status', 'PAID'),
    isOnOrder: computed.equal('status', 'ON_ORDER'),
    isOrdered: computed.equal('status', 'ORDERED'),
    isProblem: computed.equal('status', 'PROBLEM'),
    isSent: computed.equal('status', 'SENT'),
    isCanceled: computed.equal('status', 'CANCELED'),
    isDelivered: computed.equal('status', 'DELIVERED'),
    isPendingPayment: computed.equal('status', 'PENDING_PAYMENT'),
    isRefunded: computed.equal('status', 'REFUNDED'),
    isRefunding: computed.equal('status', 'REFUNDING'),
    isRefundRequested: computed.equal('status', 'REFUND_REQUESTED'),
    isPromo: computed.equal('status', 'PROMO'),
    minPages: computed('type', function () {
      switch (this.get('type')) {
        case 'HARDCOVER':
          return 24;

        case 'SOFTCOVER':
          return 24;

        case 'SQUARE_FOLD':
          return 16;
      }

      return 0;
    }),
    maxPages: computed('type', function () {
      switch (this.get('type')) {
        case 'HARDCOVER':
          return 64;

        case 'SOFTCOVER':
          return 64;

        case 'SQUARE_FOLD':
          return 24;
      }

      return 0;
    }),

    _getActiveFilters() {
      try {
        const activeFilters = this.get('activeFilters');
        return typeof activeFilters === 'string' ? JSON.parse(activeFilters) : activeFilters;
      } catch (e) {
        return {};
      }
    }

  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('yearbook', 'yearbook');
});