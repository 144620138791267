define("parro-web/components/flashmessage/flash-message-queue", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    classNames: ['flash-message-container'],
    actions: {
      refresh() {
        window.location.reload();
      }

    }
  });

  _exports.default = _default;
});