define("parro-web/templates/components/person/filter-child-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li {{action onSelect}} class="{{if isSelected '' 'is-inactive'}} is-clickable">
    <div>
      {{person/profile-picture size='small' name=child.name avatar=guardian.cachedAvatarUrl}}
      <span class="{{if description 'heeftOndertitel' ''}}">{{child.name}}</span>
      {{#if description}}<small>{{t-exists description}}</small>{{/if}}
    </div>
    {{form/input-checkbox checked=isSelected onChange=onSelect}}
  </li>
  */
  {
    "id": "sBm1MXkd",
    "block": "[[[11,\"li\"],[16,0,[29,[[52,[33,1],\"\",\"is-inactive\"],\" is-clickable\"]]],[4,[38,2],[[30,0],[33,3]],null],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"size\",\"name\",\"avatar\"],[\"small\",[33,5,[\"name\"]],[33,6,[\"cachedAvatarUrl\"]]]]]],[1,\"\\n    \"],[10,1],[15,0,[29,[[52,[33,7],\"heeftOndertitel\",\"\"]]]],[12],[1,[33,5,[\"name\"]]],[13],[1,\"\\n    \"],[41,[33,7],[[[10,\"small\"],[12],[1,[28,[35,8],[[33,7]],null]],[13]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,9],null,[[\"checked\",\"onChange\"],[[33,1],[33,3]]]]],[1,\"\\n\"],[13]],[],false,[\"if\",\"isSelected\",\"action\",\"onSelect\",\"person/profile-picture\",\"child\",\"guardian\",\"description\",\"t-exists\",\"form/input-checkbox\"]]",
    "moduleName": "parro-web/templates/components/person/filter-child-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});