define("parro-web/templates/components/yearbook/yearbook-templates-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>{{component templateName content=content yearbook=yearbook classNames=templateClassNames }}</div>
  
  */
  {
    "id": "We5YiJEC",
    "block": "[[[10,0],[12],[46,[33,1],null,[[\"content\",\"yearbook\",\"classNames\"],[[33,2],[33,3],[33,4]]],null],[13],[1,\"\\n\"]],[],false,[\"component\",\"templateName\",\"content\",\"yearbook\",\"templateClassNames\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-templates-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});