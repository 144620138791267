define("parro-web/components/yearbook/yearbook-announcement-images-main", ["exports", "ember", "parro-web/mixins/routeMixin"], function (_exports, _ember, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    isPresent,
    computed,
    isEmpty
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: 'div',
    classNames: [],
    yearbookAjaxService: service(),
    yearbookService: service(),
    fileService: service(),
    store: service(),

    init() {
      this._super(...arguments);

      this.set('selectedImages', Array.orEmpty(this.get('announcement.yearbook.selectedImages')));
    },

    images: computed(function () {
      const selected = Array.orEmpty(this.get('announcement.yearbook.images'));

      const onMap = imageAttachment => {
        const isSelected = isPresent(selected.find(id => parseInt(id) === parseInt(imageAttachment.get('id'))));
        const small = imageAttachment.get('smallestImage');
        const large = imageAttachment.get('largestImage');
        const id = parseInt(this.get('announcement.eventId'));
        const sortDate = this.get('announcement.sortDate');
        const format = this.get('fileService').getImageFormat(small);
        return _ember.default.Object.create({
          original: imageAttachment,
          isSelected: isSelected,
          id: imageAttachment.get('id'),
          msrc: small.get('url'),
          src: large.get('url'),
          w: large.get('width'),
          h: large.get('height'),
          announcementId: id,
          sortDate: sortDate,
          format: format
        });
      };

      return this.get('announcement.getImages').map(onMap);
    }),
    selectedObserver: _ember.default.observer('selectedImages.[]', 'includeText', function () {
      this.set('isLoading', true);

      _ember.default.run.debounce(this, this.updateSelected, 1000);
    }),

    updateSelected() {
      const yearbook = this.get('yearbook');
      const announcement = this.get('announcement');
      const selected = this.get('selectedImages');
      const yearbookId = this.get('announcement.yearbook.id');

      if (isEmpty(yearbookId) && isEmpty(selected)) {
        return;
      }

      const onError = error => this.flashError(error);

      const onSuccess = () => {};

      const onFinal = () => {
        if (!this.isDestroying && !this.isDestroyed) {
          this.set('isLoading', false);
          this.set('isUpdating', false);
        }
      };

      if (isPresent(yearbookId) && isEmpty(selected)) {
        this.get('yearbookAjaxService').deleteContentSelection(yearbook, announcement).then(onSuccess, onError).finally(onFinal);
      } else {
        if (!this.isDestroying && !this.isDestroyed) {
          this.set('isUpdating', true);
        }

        const includeText = this.get('announcement.yearbook.includeText');
        this.get('yearbookAjaxService').updateContentSelection(yearbook, announcement, selected, includeText).then(onSuccess, onError).finally(onFinal);
      }
    },

    _createYearbook() {
      if (!this.get('announcement.yearbook.images')) {
        const yearbook = this.get('store').createRecord('yearbookcontentselection', {
          dtype: 'yearbook.RYearbookContentSelection',
          lastModifiedAt: new Date(),
          announcement: this.get('announcement'),
          images: [],
          includeText: false,
          yearbookId: this.get('yearbook.id')
        });
        this.set('announcement.yearbook', yearbook);
      }
    },

    actions: {
      toggleImage(image) {
        if (!image.get('isSelected')) {
          const dialogConfig = this.get('yearbookService').getDialogConfig(this.get('yearbook'));

          if (isPresent(dialogConfig)) {
            return this.onShowDialog(dialogConfig);
          }
        }

        image.set('isSelected', !image.get('isSelected'));

        if (image.get('isSelected')) {
          this.get('selectedImages').pushObject(image.get('original'));
        } else {
          this.get('selectedImages').removeObject(image.get('original'));
        }
      }

    }
  });

  _exports.default = _default;
});