define("parro-web/controllers/oauth2", ["exports", "ember", "parro-web/mixins/metricsMixin"], function (_exports, _ember, _metricsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const assign = _ember.default.assign;
  const {
    inject: {
      service
    },
    isEmpty
  } = _ember.default;

  var _default = _ember.default.Controller.extend(_metricsMixin.default, {
    session: service(),
    account: service(),
    versionService: service(),
    authenticationService: service(),
    choiceConfig: {
      visible: false,
      itemColored: true,
      title: 'login.choose_login_as'
    },

    _exchangeToken(options) {
      const oauthConfig = this.get('authenticationService').getOauthConfig();

      const onSuccess = tokens => {
        const data = {
          tokens: tokens
        };

        data.choiceCallback = (schools, onChoice, onCancel) => {
          assign(this.get('choiceConfig'), {
            items: schools,
            onChoice: onChoice,
            onCancel: onCancel
          });
          this.set('choiceConfig.visible', true);
        };

        this.get('session').authenticate('authenticator:OAuth-authenticator', data).then(() => {
          this.transitionToRoute('index');
        });
      };

      const onError = () => this.transitionToRoute('login');

      this.get('authenticationService').exchangeToken(options, oauthConfig).then(onSuccess, onError);
    },

    init() {
      this._super(...arguments);

      _ember.default.run.schedule('afterRender', this, function () {
        const options = {
          code: this.get('model.code'),
          state: this.get('model.state'),
          error: this.get('model.error')
        };

        if (isEmpty(options.code) || isEmpty(options.state)) {
          return this.transitionToRoute('login');
        }

        this._exchangeToken(options);
      });
    }

  });

  _exports.default = _default;
});