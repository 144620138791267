define("parro-web/initializers/override-Ember", ["exports", "ember", "parro-web/mixins/flashMessageMixin"], function (_exports, _ember, _flashMessageMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'override-Ember',
    initialize: function () {
      _ember.default.Route.reopen(_flashMessageMixin.default, {});

      _ember.default.Component.reopen(_flashMessageMixin.default, {});

      _ember.default.Controller.reopen(_flashMessageMixin.default, {});
    }
  };
  _exports.default = _default;
});