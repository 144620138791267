define("parro-web/templates/oops/default", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{error/application-error model=model}}
  */
  {
    "id": "/P1VcbiV",
    "block": "[[[1,[28,[35,0],null,[[\"model\"],[[33,1]]]]]],[],false,[\"error/application-error\",\"model\"]]",
    "moduleName": "parro-web/templates/oops/default.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});