define("parro-web/initializers/override-String", ["exports", "base64url", "uuid"], function (_exports, _base64url, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'override-String',
    initialize: function () {
      // Polyfill string methods for IE10
      // -- startsWith: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
      if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
          position = position || 0;
          return this.substr(position, searchString.length) === searchString;
        };
      } // -- endsWith: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith


      if (!String.prototype.endsWith) {
        String.prototype.endsWith = function (searchString, position) {
          const subjectString = this.toString();

          if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
            position = subjectString.length;
          }

          position -= searchString.length;
          const lastIndex = subjectString.indexOf(searchString, position);
          return lastIndex !== -1 && lastIndex === position;
        };
      } // -- includes: https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/String/includes


      if (!String.prototype.includes) {
        String.prototype.includes = function (search, start) {
          'use strict';

          if (typeof start !== 'number') {
            start = 0;
          }

          if (start + search.length > this.length) {
            return false;
          } else {
            return this.indexOf(search, start) !== -1;
          }
        };
      } // -- Pad: http://stackoverflow.com/a/10073788


      String.pad = function (n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
      }; // -- Fix charAt for non-BMP characters (Basic Multilingual Plane)
      // -- https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/charAt


      String.nonBmpCharAt = function (str, idx) {
        let ret = '';
        str += '';
        const end = str.length;
        const surrogatePairs = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

        while (surrogatePairs.exec(str) != null) {
          const li = surrogatePairs.lastIndex;

          if (li - 2 < idx) {
            idx++;
          } else {
            break;
          }
        }

        if (idx >= end || idx < 0) {
          return '';
        }

        ret += str.charAt(idx);

        if (/[\uD800-\uDBFF]/.test(ret) && /[\uDC00-\uDFFF]/.test(str.charAt(idx + 1))) {
          // Go one further, since one of the "characters" is part of a surrogate pair
          ret += str.charAt(idx + 1);
        }

        return ret;
      }; // -- Source: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding


      String.b64EncodeUnicode = function (input) {
        try {
          return btoa(encodeURIComponent(input).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode('0x' + p1);
          }));
        } catch (e) {
          console.log('Failed to encode');
        }
      };

      String.b64DecodeUnicode = function (input) {
        try {
          return decodeURIComponent(Array.prototype.map.call(atob(input), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
        } catch (e) {
          console.log('Failed to decode');
        }
      };

      String.b64EncodeUrl = function (input) {
        return _base64url.default.encode(input);
      };

      String.b64DecodeUrl = function (input) {
        return _base64url.default.decode(input);
      };

      String.addProtocol = function (url) {
        if (!/^https?:\/\//i.test(url)) {
          return 'https://' + url;
        }

        return url;
      };

      String.generateRandomString = function (amount) {
        const arr = new Uint8Array(amount);
        const arr2 = new Array(amount);
        const crypto = window.crypto || window.msCrypto;
        crypto.getRandomValues(arr);

        for (let i = 0; i < amount; i++) {
          arr2[i] = ('0' + arr[i].toString(16)).substr(-2);
        }

        return arr2.join('');
      };

      String.isValid = function (string) {
        return typeof string === 'string';
      };

      String.capitalizeFirstLetter = function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      String.generateUUID = function () {
        return (0, _uuid.v4)();
      };
    }
  };
  _exports.default = _default;
});