define("parro-web/adapters/event", ["exports", "ember", "parro-web/adapters/application", "parro-web/config/environment"], function (_exports, _ember, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;
  const {
    embrah: {
      namespace
    }
  } = _environment.default;

  var _default = _application.default.extend({
    versionService: service(),
    host: computed.readOnly('versionService.host'),

    buildURL(modelName, id, snapshot, requestType, query) {
      if (modelName === 'event' && requestType === 'query' && query.identity && query.hasImages) {
        const identity = query.identity;
        const hasImages = query.hasImages;
        const children = Array.orEmpty(query.children);
        const schoolYears = Array.orEmpty(query.schoolYears);
        const codes = Array.orEmpty(query.codes);
        const groups = Array.orEmpty(query.groups);
        const childArgs = children.length > 0 ? '&' + children.map(child => 'yearbookgroup.childgroup.child=' + child).join('&') : '';
        const yearArgs = schoolYears.length > 0 ? '&' + schoolYears.map(year => 'yearbookgroup.schoolyear=' + year).join('&') : '';
        const codeArgs = codes.length > 0 ? '&' + codes.map(code => 'yearbookgroup.organisation.code=' + code).join('&') : '';
        const groupArgs = groups.length > 0 ? '&' + groups.map(group => 'group=' + group).join('&') : '';
        delete query.hasImages;
        delete query.identity;
        delete query.children;
        delete query.schoolYears;
        delete query.codes;
        delete query.groups;
        return this.get('host') + '/' + namespace + '/identity/' + identity + '/event' + '?dtype=event.RYearbookAnnouncementEvent&event.hasImages=' + hasImages + childArgs + yearArgs + codeArgs + groupArgs;
      }

      if (modelName === 'event' && requestType === 'query' && query.identity) {
        const identity = query.identity;
        delete query.identity;
        return this.get('host') + '/' + namespace + '/identity/' + identity + '/event';
      }

      if (modelName === 'event' && requestType === 'queryRecord' && query.id) {
        const id = query.id;
        delete query.id;
        return this.get('host') + '/' + namespace + '/event/' + id + '?dtype=event.RYearbookAnnouncementEvent';
      }

      if (modelName === 'event' && requestType === 'findRecord' && id) {
        return this.get('host') + '/' + namespace + '/event/' + id + '?dtype=event.RYearbookAnnouncementEvent';
      }

      if (modelName === 'event' && requestType === 'findRecord' && query.id) {
        const id = query.id;
        delete query.id;
        return this.get('host') + '/' + namespace + '/event/' + id + '?dtype=event.RYearbookAnnouncementEvent';
      }

      return this._super(modelName, id, snapshot, requestType, query);
    }

  });

  _exports.default = _default;
});