define("parro-web/templates/components/loader/loading-mask-spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="loading" class="{{if isLoading 'active' ''}}">
    <span class="loading-spinner"></span>
  </div>
  */
  {
    "id": "m6xAZEft",
    "block": "[[[10,0],[14,1,\"loading\"],[15,0,[29,[[52,[33,1],\"active\",\"\"]]]],[12],[1,\"\\n  \"],[10,1],[14,0,\"loading-spinner\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"isLoading\"]]",
    "moduleName": "parro-web/templates/components/loader/loading-mask-spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});