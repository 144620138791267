define("parro-web/models/groupguardian", ["exports", "ember-data", "parro-web/models/person", "ember-inflector"], function (_exports, _emberData, _person, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _person.default.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'identity.RGroupGuardianPrimer'
    }),
    email: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    groupName: _emberData.default.attr('string'),
    identityId: _emberData.default.attr('number'),
    koppelingId: _emberData.default.attr('number'),
    cachedAvatarUrl: _emberData.default.attr('string'),
    isGuardian: true,
    isTeacher: false
  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('groupguardian', 'groupguardian');
});