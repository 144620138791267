define("parro-web/templates/yearbook/order", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{navigation/nav-header-yearbook config=model.navConfig }}
  
  <section class="content-wrapper">
    <div class="content">
      {{yearbook/yearbook-order yearbook=model.yearbook selection=model.selection requestConfirmation=(route-action 'requestConfirmation')}}
    </div>
  </section>
  
  */
  {
    "id": "nb/JRwFO",
    "block": "[[[1,[28,[35,0],null,[[\"config\"],[[33,1,[\"navConfig\"]]]]]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"content-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"yearbook\",\"selection\",\"requestConfirmation\"],[[33,1,[\"yearbook\"]],[33,1,[\"selection\"]],[28,[37,3],[\"requestConfirmation\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"navigation/nav-header-yearbook\",\"model\",\"yearbook/yearbook-order\",\"route-action\"]]",
    "moduleName": "parro-web/templates/yearbook/order.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});