define("parro-web/components/yearbook/yearbook-shopping-cart", ["exports", "ember", "parro-web/mixins/routeMixin", "parro-web/models/services/dialogs"], function (_exports, _ember, _routeMixin, _dialogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: '',
    yearbookService: service(),
    yearbookAjaxService: service(),
    ScrollService: service(),
    minAmountOfCopies: 1,
    maxAmountOfCopies: 3,

    didInsertElement() {
      this._super(...arguments);

      this.ScrollService.setScrollTop($('html'), false);
    },

    isSubmitDisabled: computed('yearbook.copies', function () {
      const yearbook = this.get('yearbook');
      let amountOfCopies = parseInt(yearbook.get('copies'));
      const minAmountOfCopies = parseInt(this.get('minAmountOfCopies'));
      const maxAmountOfCopies = parseInt(this.get('maxAmountOfCopies'));
      const amountOfPages = this.get('yearbookService').getAmountOfContentPages(yearbook);

      if (isNaN(amountOfCopies)) {
        amountOfCopies = 0;
      }

      return amountOfCopies < minAmountOfCopies || amountOfCopies > maxAmountOfCopies || amountOfPages < yearbook.get('minPages') || amountOfPages > yearbook.get('maxPages');
    }),
    showMaxAmountError: computed('selection.@each', function () {
      const yearbook = this.get('yearbook');
      const amountOfPages = this.get('yearbookService').getAmountOfContentPages(yearbook);
      return amountOfPages > yearbook.get('maxPages');
    }),
    amountOfPlaceholders: computed('selection.@each', function () {
      return this.get('yearbookService').getAmountOfPlaceholderPages(this.get('yearbook'));
    }),
    amountOfReplacements: computed('selection.@each', function () {
      return this.get('yearbookService').getAmountOfReplacementPages(this.get('yearbook'));
    }),
    displayMinPageSize: computed('yearbook.type', function () {
      return parseInt(this.get('yearbook.minPages')) - 2;
    }),
    displayBasePrice: computed('yearbook.basePrice', function () {
      if (this.get('yearbook.isPromo')) {
        return undefined;
      }

      return this.get('yearbookService').convertPriceToDisplay(this.get('yearbook.basePrice'));
    }),
    displayAdditionalPrice: computed('yearbook.additionalPagesPrice', function () {
      if (this.get('yearbook.isPromo')) {
        return undefined;
      }

      return this.get('yearbookService').convertPriceToDisplay(this.get('yearbook.additionalPagesPrice'));
    }),
    displayTotalPrice: computed('yearbook.copies', 'yearbook.price', 'yearbook.type', function () {
      const copies = this.get('yearbook.copies');

      if (this.get('yearbook.isPromo')) {
        const total = this.get('yearbook.price') * copies;
        return this.get('yearbookService').convertPriceToDisplay(total);
      }

      const price = this.get('yearbookService').getTotalPrice(this.get('yearbook'), this.get('prices'));
      const total = price * copies;
      return this.get('yearbookService').convertPriceToDisplay(total);
    }),
    displayType: computed('yearbook.type', function () {
      switch (this.get('yearbook.type')) {
        case 'HARDCOVER':
          return 'yearbook_type.type_hardcover';

        case 'SOFTCOVER':
          return 'yearbook_type.type_softcover';

        case 'SQUARE_FOLD':
          return 'yearbook_type.type_squarefold';
      }

      return undefined;
    }),
    actions: {
      toNavType() {
        if (this.get('yearbook.isPromo')) {
          return this.requestConfirmation(_dialogs.default.yearbookPromoDialogConfig());
        }

        const routeName = this.get('router.currentRouteName');

        if (this.get('yearbookService.historyRoute') !== routeName) {
          this.set('yearbookService.historyRoute', routeName);
        }

        this.transitionTo('yearbook.type', this.get('yearbook.id'));
      },

      next() {
        this.transitionTo('yearbook.order', this.get('yearbook.id'));
      }

    }
  });

  _exports.default = _default;
});