define("parro-web/models/yearbookorder", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'yearbook.RYearbookOrder'
    }),
    lastModifiedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    telephone: _emberData.default.attr('string'),
    streetName: _emberData.default.attr('string'),
    houseNumber: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    paymentMethod: _emberData.default.attr('string', {
      defaultValue: 'IDEAL'
    }),
    copies: _emberData.default.attr('number'),
    trackingUrl: _emberData.default.attr('string')
  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('yearbookorder', 'yearbookorder');
});