define("parro-web/models/navigation/yearbookdialogconfig", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Object.extend({
    text: undefined,
    image: undefined,
    step1Active: false,
    step1Done: false,
    step2Active: false,
    step2Done: false,
    buttonText: undefined
  });

  _exports.default = _default;
});