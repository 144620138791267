define("parro-web/templates/components/settings/account-choice", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if checkable}}
  
    <li {{action onClick}}>
      {{form/input-radio-button checked=choice.checked}}
      <div class="{{if choice.checked 'is-not-clickable' 'is-clickable'}}">
        <span>{{choice.name}}</span>
        {{#if choice.description}} <small>{{t-exists choice.description}}</small> {{/if}}
      </div>
      {{#if (and (gt choice.numberUnread 0) (not choice.checked))}} <label class="bullet">{{choice.numberUnread}}</label> {{/if}}
    </li>
  
  {{else}}
  
    <li {{action onClick}}>
      <a title="{{choice.name}}">
        <span class="{{if choice.checked 'is-selected' ''}}">
          <p>{{choice.name}}</p>
        </span>
        <small>{{t-exists choice.description}}</small>
      </a>
      {{#if (gt choice.numberUnread 0)}} <label class="bullet">{{choice.numberUnread}}</label> {{/if}}
    </li>
  
  {{/if}}
  */
  {
    "id": "bvDSEU5k",
    "block": "[[[41,[33,1],[[[1,\"\\n  \"],[11,\"li\"],[4,[38,2],[[30,0],[33,3]],null],[12],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"checked\"],[[33,5,[\"checked\"]]]]]],[1,\"\\n    \"],[10,0],[15,0,[29,[[52,[33,5,[\"checked\"]],\"is-not-clickable\",\"is-clickable\"]]]],[12],[1,\"\\n      \"],[10,1],[12],[1,[33,5,[\"name\"]]],[13],[1,\"\\n      \"],[41,[33,5,[\"description\"]],[[[1,\" \"],[10,\"small\"],[12],[1,[28,[35,6],[[33,5,[\"description\"]]],null]],[13],[1,\" \"]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[41,[28,[37,7],[[28,[37,8],[[33,5,[\"numberUnread\"]],0],null],[28,[37,9],[[33,5,[\"checked\"]]],null]],null],[[[1,\" \"],[10,\"label\"],[14,0,\"bullet\"],[12],[1,[33,5,[\"numberUnread\"]]],[13],[1,\" \"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]],[[[1,\"\\n  \"],[11,\"li\"],[4,[38,2],[[30,0],[33,3]],null],[12],[1,\"\\n    \"],[10,3],[15,\"title\",[29,[[33,5,[\"name\"]]]]],[12],[1,\"\\n      \"],[10,1],[15,0,[29,[[52,[33,5,[\"checked\"]],\"is-selected\",\"\"]]]],[12],[1,\"\\n        \"],[10,2],[12],[1,[33,5,[\"name\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"small\"],[12],[1,[28,[35,6],[[33,5,[\"description\"]]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[41,[28,[37,8],[[33,5,[\"numberUnread\"]],0],null],[[[1,\" \"],[10,\"label\"],[14,0,\"bullet\"],[12],[1,[33,5,[\"numberUnread\"]]],[13],[1,\" \"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]]]],[],false,[\"if\",\"checkable\",\"action\",\"onClick\",\"form/input-radio-button\",\"choice\",\"t-exists\",\"and\",\"gt\",\"not\"]]",
    "moduleName": "parro-web/templates/components/settings/account-choice.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});