define("parro-web/mixins/ClickElsewhereMixin", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin to detect if the user clicks outside the component using this mixin.
   * Used (for example) to close opened dropdowns.
   *
   * Original source: http://stackoverflow.com/a/32729210
   */
  var _default = _ember.default.Mixin.create({
    onOutsideClick: function () {},

    handleOutsideClick(event) {
      const $element = $(this.element);
      const $target = $(event.target);

      if (!$target.closest($element).length) {
        this.onOutsideClick();
      }
    },

    setupOutsideClickListener: _ember.default.on('didInsertElement', function () {
      this.set('clickHandler', _ember.default.run.bind(this, this.handleOutsideClick));
      $(document).click(this.get('clickHandler'));
    }),
    removeOutsideClickListener: _ember.default.on('willDestroyElement', function () {
      $(document).off('click', this.get('clickHandler'));
    })
  });

  _exports.default = _default;
});