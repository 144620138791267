define("parro-web/models/yearbooksetting", ["exports", "ember-data", "ember-inflector", "ember"], function (_exports, _emberData, _emberInflector, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'yearbook.RYearbookSetting'
    }),
    lastModifiedAt: _emberData.default.attr('date'),
    enabled: _emberData.default.attr('boolean'),
    lastEditedBy: _emberData.default.attr('string'),
    organisation: _emberData.default.belongsTo('organisation'),
    state: _emberData.default.attr('string'),
    isEnabled: computed.equal('state', 'ENABLED'),
    isScheduled: computed.equal('state', 'ENABLED_INACTIVE')
  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('yearbooksetting', 'yearbooksetting');
});