define("parro-web/components/yearbook/templates/template-front", ["exports", "ember", "parro-web/config/environment", "parro-web/components/yearbook/templates/template-main"], function (_exports, _ember, _environment, _templateMain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    String: {
      htmlSafe
    },
    isEmpty,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _templateMain.default.extend({
    yearbookService: service(),
    isBadQuality: computed('yearbook.frontCover.largestImage', function () {
      return this.determineBadQuality(210, 161.683, this.get('yearbook.frontCover.largestImage'));
    }),
    circle: computed(function () {
      const url = _environment.default.externalUrls.amazonStaticImages + '/circle.png';
      return htmlSafe('background-image: url(' + url + ')');
    }),
    footer: computed(function () {
      const url = _environment.default.externalUrls.amazonStaticImages + '/footer.png';
      return htmlSafe('background-image: url(' + url + ')');
    }),
    frontCover: computed('yearbook.frontCover.smallestImage', 'yearbook.frontCover.largestImage', 'isPreview', function () {
      const isPreview = this.get('isPreview');
      const frontCover = this.get('yearbook.frontCover');

      if (isEmpty(frontCover) && isPreview) {
        const url = _environment.default.externalUrls.amazonStaticImages + '/placeholder-no-image.jpg';
        return htmlSafe('background-image: url(' + url + ')');
      }

      const image = isPreview ? frontCover.get('smallestImage') : frontCover.get('largestImage');
      return htmlSafe('background-image: url(' + image.get('url') + ')');
    }),
    yearRange: computed('selection.@each', function () {
      const selection = this.get('yearbookService').getSelectionPages(this.get('yearbook'));

      const onFilter = s => !isNaN(s.get('schoolYear'));

      const onMap = s => s.get('schoolYear');

      const onSort = (a, b) => a - b;

      const years = Array.unique(selection.filter(onFilter).map(onMap).sort(onSort));

      if (years.length > 1) {
        const first = years[0];
        const last = parseInt(years[years.length - 1]) + 1;
        return _ember.default.Object.create({
          first,
          last
        });
      }

      if (years.length === 1) {
        const first = years[0];
        const last = parseInt(first) + 1;
        return _ember.default.Object.create({
          first,
          last
        });
      }

      const first = Date.getCurrentSchoolyearStart().getFullYear();
      const last = first + 1;
      return _ember.default.Object.create({
        first,
        last
      });
    })
  });

  _exports.default = _default;
});