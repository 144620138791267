define("parro-web/components/yearbook/yearbook-image-selection", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    ScrollService: service(),

    didInsertElement() {
      this._super(...arguments);

      this.ScrollService.setScrollTop($('html'), false);
    }

  });

  _exports.default = _default;
});