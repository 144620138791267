define("parro-web/models/services/colors", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = Colors;

  function Colors() {}

  Colors.getColor = function (name, type) {
    _ember.default.assert('Must provide a name', name); // -- background-color-1 t/m 15 in _colors.less


    const colors = 11;
    const a = name.toLowerCase().charCodeAt(0);
    const b = name.toLowerCase().charCodeAt(name.length - 1);
    const c = name.length;
    const choice = (a + b + c) % colors;
    const cssClass = type ? 'colored-item-' : 'background-colored-item-';
    return cssClass + (choice + 1);
  };
});