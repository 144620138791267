define("parro-web/templates/components/yearbook/templates/template-outro-2", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPreview}}<div class="yearbook--shadow"></div>{{/if}}
  <article>
    <div class="main">
      <div class="bg bg--text-thin" style={{image}}></div>
    </div>
    <div class="side"></div>
    <figure class="figure--triangle-parro" style={{figure}}></figure>
  </article>
  
  */
  {
    "id": "jOeC27Ul",
    "block": "[[[41,[33,1],[[[10,0],[14,0,\"yearbook--shadow\"],[12],[13]],[]],null],[1,\"\\n\"],[10,\"article\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"bg bg--text-thin\"],[15,5,[36,2]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"side\"],[12],[13],[1,\"\\n  \"],[10,\"figure\"],[14,0,\"figure--triangle-parro\"],[15,5,[36,3]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"isPreview\",\"image\",\"figure\"]]",
    "moduleName": "parro-web/templates/components/yearbook/templates/template-outro-2.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});