define("parro-web/templates/components/navigation/nav-menu-tabs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if tabs}}
    <div class="header-tabs">
      <div class="header-tabs--container">
        <ul>
          {{#each tabs as |tab|}}
            {{#if tab.onClick}}
            <a {{action 'selectTab' tab tab.onClick}} title="{{t-exists tab.name}}">
              {{navigation/nav-choice-tab tab=tab selected=selected}}
            </a>
            {{else if tab.isDisabled}}
              <a title="{{t-exists tab.name}}" class="is-disabled is-not-clickable">
                {{navigation/nav-choice-tab tab=tab selected=selected}}
              </a>
            {{else}}
            <a {{action 'selectTab' tab (route-action 'toNavChoice')}} title="{{t-exists tab.name}}">
              {{navigation/nav-choice-tab tab=tab selected=selected}}
            </a>
            {{/if}}
  
          {{/each}}
        </ul>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "3Y9mByAS",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"header-tabs\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"header-tabs--container\"],[12],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,1]],null]],null],null,[[[41,[30,1,[\"onClick\"]],[[[1,\"          \"],[11,3],[16,\"title\",[29,[[28,[37,4],[[30,1,[\"name\"]]],null]]]],[4,[38,5],[[30,0],\"selectTab\",[30,1],[30,1,[\"onClick\"]]],null],[12],[1,\"\\n            \"],[1,[28,[35,6],null,[[\"tab\",\"selected\"],[[30,1],[33,7]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"isDisabled\"]],[[[1,\"            \"],[10,3],[15,\"title\",[29,[[28,[37,4],[[30,1,[\"name\"]]],null]]]],[14,0,\"is-disabled is-not-clickable\"],[12],[1,\"\\n              \"],[1,[28,[35,6],null,[[\"tab\",\"selected\"],[[30,1],[33,7]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[11,3],[16,\"title\",[29,[[28,[37,4],[[30,1,[\"name\"]]],null]]]],[4,[38,5],[[30,0],\"selectTab\",[30,1],[28,[37,8],[\"toNavChoice\"],null]],null],[12],[1,\"\\n            \"],[1,[28,[35,6],null,[[\"tab\",\"selected\"],[[30,1],[33,7]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"]],[]]]],[]]],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"tab\"],false,[\"if\",\"tabs\",\"each\",\"-track-array\",\"t-exists\",\"action\",\"navigation/nav-choice-tab\",\"selected\",\"route-action\"]]",
    "moduleName": "parro-web/templates/components/navigation/nav-menu-tabs.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});