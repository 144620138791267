define("parro-web/templates/yearbook/preview", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <header class="header" id="content-header">
    {{navigation/nav-header-yearbook config=model.navConfig }}
    {{yearbook/yearbook-status-bar yearbook=model.yearbook selection=model.selection prices=model.prices}}
    {{navigation/nav-menu-tabs tabs=model.tabs}}
  </header>
  
  {{navigation/nav-menu-overlay register=navMenuOverlay yearbook=model.yearbook requestConfirmation=(route-action 'requestConfirmation')}}
  
  <section class="content-wrapper">
    <div class="content">
      {{yearbook/yearbook-preview yearbook=model.yearbook selection=model.selection requestConfirmation=(route-action 'requestConfirmation')}}
    </div>
  </section>
  
  */
  {
    "id": "N9Am9svm",
    "block": "[[[10,\"header\"],[14,0,\"header\"],[14,1,\"content-header\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"config\"],[[33,1,[\"navConfig\"]]]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"yearbook\",\"selection\",\"prices\"],[[33,1,[\"yearbook\"]],[33,1,[\"selection\"]],[33,1,[\"prices\"]]]]]],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"tabs\"],[[33,1,[\"tabs\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,4],null,[[\"register\",\"yearbook\",\"requestConfirmation\"],[[33,5],[33,1,[\"yearbook\"]],[28,[37,6],[\"requestConfirmation\"],null]]]]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"content-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n    \"],[1,[28,[35,7],null,[[\"yearbook\",\"selection\",\"requestConfirmation\"],[[33,1,[\"yearbook\"]],[33,1,[\"selection\"]],[28,[37,6],[\"requestConfirmation\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"navigation/nav-header-yearbook\",\"model\",\"yearbook/yearbook-status-bar\",\"navigation/nav-menu-tabs\",\"navigation/nav-menu-overlay\",\"navMenuOverlay\",\"route-action\",\"yearbook/yearbook-preview\"]]",
    "moduleName": "parro-web/templates/yearbook/preview.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});