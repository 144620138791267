define("parro-web/utils/timeUtil", ["exports", "ember", "parro-web/utils/ticks"], function (_exports, _ember, _ticks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = TimeUtil;
  const {
    isNone,
    isPresent
  } = _ember.default;

  function TimeUtil() {}

  TimeUtil.copy = function (timeOrDate) {
    const hours = this.getHours(timeOrDate);
    const minutes = this.getMinutes(timeOrDate);

    if (isNone(hours) || isNone(minutes)) {
      return undefined;
    }

    return _ember.default.Object.create({
      hours: hours,
      minutes: minutes
    });
  };

  TimeUtil.createTime = function (hours, minutes) {
    hours = parseInt(hours);
    minutes = parseInt(minutes);
    hours = isNaN(hours) ? undefined : hours;
    minutes = isNaN(minutes) ? undefined : minutes;
    return _ember.default.Object.create({
      hours: hours,
      minutes: minutes
    });
  };

  TimeUtil.isMidnight = function (date) {
    const time = Date.dateAsTime(date);
    const hours = this.getHours(time);
    const minutes = this.getMinutes(time);
    return hours === 0 && minutes === 0;
  };

  TimeUtil.isTime = function (time) {
    const hours = parseInt(this.getHours(time));
    const minutes = parseInt(this.getMinutes(time));
    const hasHours = hours >= 0 && hours <= 23;
    const hasMinutes = minutes >= 0 && minutes <= 59; // Does not allow empty time

    return hasHours && hasMinutes;
  };

  TimeUtil.isComplete = function (time) {
    const hours = this.getHours(time);
    const minutes = this.getMinutes(time);
    const hasHours = hours >= 0 && hours <= 23;
    const hasMinutes = minutes >= 0 && minutes <= 59; // Also allows empty time as 'complete'

    return hasHours === hasMinutes;
  };

  TimeUtil.toTicks = function (timeOrDate) {
    const hours = this.getHours(timeOrDate);
    const minutes = this.getMinutes(timeOrDate);

    if (isNone(hours) || isNone(minutes)) {
      return undefined;
    }

    return _ticks.default.toTicks(_ember.default.Object.create({
      hours: hours,
      minutes: minutes
    }));
  };

  TimeUtil.getHours = function (timeOrDate) {
    if (isPresent(timeOrDate) && isPresent(timeOrDate.getHours)) {
      const hours = timeOrDate.getHours();
      return isNaN(hours) ? undefined : hours;
    }

    const hours = this._getProperty(timeOrDate, 'hours');

    return isNaN(hours) ? undefined : hours;
  };

  TimeUtil.getMinutes = function (timeOrDate) {
    if (isPresent(timeOrDate) && isPresent(timeOrDate.getMinutes)) {
      const minutes = timeOrDate.getMinutes();
      return isNaN(minutes) ? undefined : minutes;
    }

    const minutes = this._getProperty(timeOrDate, 'minutes');

    return isNaN(minutes) ? undefined : minutes;
  };

  TimeUtil._getProperty = function (object, property) {
    if (object instanceof Object) {
      return isPresent(object.get) ? object.get(property) : object[property];
    }

    return undefined;
  };
});