define("parro-web/components/yearbook/yearbook-type", ["exports", "ember", "parro-web/mixins/routeMixin"], function (_exports, _ember, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    isEmpty,
    inject: {
      service
    },
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: '',
    yearbookService: service(),
    yearbookAjaxService: service(),
    ScrollService: service(),
    amountOfSlides: 0,
    showFirstText: true,
    sliderConfig: {
      autoPlay: true,
      autoPlaySpeed: 5000,
      arrows: true
    },
    hardCoverImages: ['/assets/img/yearbook_slider/Hardcover1.png', '/assets/img/yearbook_slider/Hardcover2.png', '/assets/img/yearbook_slider/Hardcover3.png', '/assets/img/yearbook_slider/Hardcover4.png'],
    softCoverImages: ['/assets/img/yearbook_slider/Softcover1.png', '/assets/img/yearbook_slider/Softcover2.png', '/assets/img/yearbook_slider/Softcover3.png', '/assets/img/yearbook_slider/Softcover4.png'],
    squareFoldImages: ['/assets/img/yearbook_slider/Squarefold1.png', '/assets/img/yearbook_slider/Squarefold2.png', '/assets/img/yearbook_slider/Squarefold3.png', '/assets/img/yearbook_slider/Squarefold4.png'],

    didInsertElement() {
      this._super(...arguments);

      this.ScrollService.setScrollTop($('html'), false);
    },

    isSubmitDisabled: computed('yearbook.type', function () {
      return isEmpty(this.get('yearbook.type'));
    }),
    sliderText: computed('showFirstText', 'yearbook.type', function () {
      const yearbook = this.get('yearbook');
      const type = yearbook.get('type');
      const showFirstText = this.get('showFirstText');

      switch (type) {
        case 'HARDCOVER':
          return showFirstText ? 'yearbook_type.hardcover_text1' : 'yearbook_type.hardcover_text2';

        case 'SOFTCOVER':
          return 'yearbook_type.softcover_text';

        case 'SQUARE_FOLD':
          return showFirstText ? 'yearbook_type.squarefold_text1' : 'yearbook_type.squarefold_text2';
      }

      return undefined;
    }),
    actions: {
      next() {
        const historyRoute = this.get('yearbookService.historyRoute');
        this.set('yearbookService.historyRoute', undefined);

        if (isPresent(historyRoute) && historyRoute !== this.get('router.currentRouteName')) {
          return this.transitionTo(historyRoute, this.get('yearbook.id'));
        }

        this.transitionTo('yearbook.front', this.get('yearbook.id'));
      },

      slide() {
        this.incrementProperty('amountOfSlides');

        if (this.get('amountOfSlides') % 4 === 0) {
          this.toggleProperty('showFirstText');
        }
      }

    }
  });

  _exports.default = _default;
});