define("parro-web/components/yearbook/yearbook-preview", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    yearbookService: service(),
    ScrollService: service(),

    didInsertElement() {
      this._super(...arguments);

      this.ScrollService.setScrollTop($('html'), false);
    },

    _createChunks(array, amount) {
      const chunks = _ember.default.A([]);

      const newArray = [...array];
      chunks.pushObject(newArray.splice(0, 1));

      for (let i = 0; i < newArray.length; i += amount) {
        chunks.pushObject(newArray.slice(i, i + amount));
      }

      return chunks;
    },

    templates: computed('selection[]', 'selection', function () {
      const pages = this.get('yearbookService').getSelectionPages(this.get('yearbook'));
      const templates = this.get('yearbookService').getYearbookPages(this.get('yearbook'), pages);
      return this._createChunks(templates, 2);
    })
  });

  _exports.default = _default;
});