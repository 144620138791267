define("parro-web/adapters/guardianchild", ["exports", "ember", "parro-web/adapters/application", "parro-web/config/environment"], function (_exports, _ember, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;
  const {
    embrah: {
      namespace
    }
  } = _environment.default;

  var _default = _application.default.extend({
    account: service(),
    versionService: service(),
    host: computed.readOnly('versionService.host'),

    buildURL(modelName, id, snapshot, requestType, query) {
      if (modelName === 'guardianchild' && requestType === 'findRecord' && id) {
        const guardian = this.get('account.identity.currentPersona.id');
        return this.get('host') + '/' + namespace + '/guardian/' + guardian + '/guardianchild/' + id;
      }

      if (modelName === 'guardianchild' && requestType === 'query' && query.guardian && query.privacy) {
        const guardian = query.guardian;
        delete query.guardian;
        delete query.privacy;
        return this.get('host') + '/' + namespace + '/guardian/' + guardian + '/guardianchild?additional=privacycentresetting';
      }

      if (modelName === 'guardianchild' && requestType === 'query' && query.guardian && query.event) {
        const guardian = query.guardian;
        delete query.guardian;
        return this.get('host') + '/' + namespace + '/guardian/' + guardian + '/guardianchild';
      }

      return this._super(modelName, id, snapshot, requestType, query);
    }

  });

  _exports.default = _default;
});