define("parro-web/templates/components/yearbook/yearbook-preview-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isClickable}}
    <div {{action 'selectTemplate'}} class="is-clickable">{{component templateName content=content yearbook=yearbook classNames=templateClassNames isPreview=true showQualityOverlay=true requestConfirmation=requestConfirmation selectTemplate=(action 'selectTemplate')}}</div>
  {{else}}
    <div>{{component templateName content=content yearbook=yearbook classNames=templateClassNames isPreview=true}}</div>
  {{/if}}
  */
  {
    "id": "/tZDrzFm",
    "block": "[[[41,[33,1],[[[1,\"  \"],[11,0],[24,0,\"is-clickable\"],[4,[38,2],[[30,0],\"selectTemplate\"],null],[12],[46,[33,4],null,[[\"content\",\"yearbook\",\"classNames\",\"isPreview\",\"showQualityOverlay\",\"requestConfirmation\",\"selectTemplate\"],[[33,5],[33,6],[33,7],true,true,[33,8],[28,[37,2],[[30,0],\"selectTemplate\"],null]]],null],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[12],[46,[33,4],null,[[\"content\",\"yearbook\",\"classNames\",\"isPreview\"],[[33,5],[33,6],[33,7],true]],null],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"isClickable\",\"action\",\"component\",\"templateName\",\"content\",\"yearbook\",\"templateClassNames\",\"requestConfirmation\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-preview-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});