define("parro-web/services/yearbook-ajax-service", ["exports", "ember", "parro-web/config/environment", "parro-web/mixins/routeMixin"], function (_exports, _ember, _environment, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject: {
      service
    },
    RSVP: {
      Promise
    },
    isPresent,
    isEmpty
  } = _ember.default;
  const {
    embrah: {
      namespace
    }
  } = _environment.default;
  const HOUSENUMBER_PATTERN = /^\d(.)*/;
  const POSTALCODE_PATTERN = /^\d{4}[A-Z]{2}$/;

  var _default = _ember.default.Service.extend(_routeMixin.default, {
    versionService: service(),
    ajaxService: service('ajax'),
    store: service(),
    s3Service: service('s3Upload'),
    account: service(),
    yearbookService: service(),
    attachmentsService: service(),
    dataprovider: service(),
    EventBusService: service(),
    host: computed.readOnly('versionService.host'),

    getYearbooks() {
      return this.store.findAll('yearbook');
    },

    getYearbook(id) {
      return this.store.findRecord('yearbook', id);
    },

    queryYearbook(id) {
      return this.store.queryRecord('yearbook', {
        id: id,
        disableAjaxLoader: true
      });
    },

    createYearbook() {
      return new Promise((resolve, reject) => {
        const data = {
          dtype: 'yearbook.RYearbook',
          name: '',
          status: 'EDITING',
          type: 'HARDCOVER',
          color: '#C9496A',
          pages: 24
        };
        const request = {
          type: 'POST',
          url: this.get('host') + '/' + namespace + '/yearbook',
          data: {
            items: [data]
          },
          disableAjaxLoader: true
        };
        this.get('ajaxService').executeRequest(request).then(data => resolve(data)).catch(() => reject('error.yearbook_unknown_error'));
      });
    },

    editYearbook(yearbook) {
      return new Promise((resolve, reject) => {
        const wrap = model => ({
          dtype: model.get('dtype'),
          links: [{
            id: model.get('id'),
            rel: 'self',
            type: model.get('dtype')
          }]
        });

        const data = {
          identity: wrap(yearbook.get('identity')),
          dtype: yearbook.get('dtype'),
          name: yearbook.get('name'),
          status: yearbook.get('status'),
          type: yearbook.get('type'),
          color: yearbook.get('color'),
          activeFilters: yearbook.get('activeFilters'),
          createdAt: yearbook.get('createdAt'),
          parroDrawingsEnabled: yearbook.get('parroDrawingsEnabled')
        };

        if (isPresent(yearbook.get('frontCover'))) {
          data.frontCover = wrap(yearbook.get('frontCover'));
        }

        const request = {
          type: 'PUT',
          url: this.get('host') + '/' + namespace + '/yearbook/' + yearbook.get('id'),
          data: data,
          disableAjaxLoader: true
        };
        this.get('ajaxService').executeRequest(request).then(data => resolve(this.get('store').pushPayload({
          yearbook: data
        }))).catch(() => {
          yearbook.rollbackAttributes();
          reject('error.yearbook_unknown_error');
        });
      });
    },

    getContentSelection(id) {
      return new Promise((resolve, reject) => {
        const onFilter = s => parseInt(s.get('yearbookId')) === parseInt(id);

        const records = this.store.peekAll('yearbookcontentselection').toArray().filter(onFilter);

        if (isPresent(records) && records.length > 0) {
          return resolve(records);
        }

        const request = {
          type: 'GET',
          url: this.get('host') + '/' + namespace + '/yearbook/' + id + '/contentselection',
          disableAjaxLoader: true
        };
        this.get('ajaxService').executeRequest(request).then(data => {
          const mapped = Array.orEmpty(data.items).map(item => {
            item.yearbookId = id;
            return item;
          });
          this.store.pushPayload({
            yearbookcontentselection: mapped
          });
          resolve(this.store.peekAll('yearbookcontentselection').toArray().filter(onFilter));
        }).catch(() => reject('error.yearbook_unknown_error'));
      });
    },

    updateContentSelection(yearbook, announcement, images, includeText) {
      return new Promise((resolve, reject) => {
        const isNew = isEmpty(announcement.get('yearbook.id'));
        const suffix = isNew ? '' : '/' + announcement.get('yearbook.id');
        const data = {
          dtype: 'yearbook.RYearbookContentSelectionPrimer',
          realisation: announcement.get('id'),
          images: Array.orEmpty(images).map(image => parseInt(image.get('id'))),
          includeText: includeText
        };
        const request = {
          type: isNew ? 'POST' : 'PUT',
          url: this.get('host') + '/' + namespace + '/yearbook/' + yearbook.get('id') + '/contentselection' + suffix,
          data: isNew ? {
            items: [data]
          } : data,
          disableAjaxLoader: true
        };
        this.get('ajaxService').executeRequest(request).then(jqXHR => {
          const data = isPresent(jqXHR) && isPresent(jqXHR.items) ? jqXHR.items[0] : jqXHR;
          data.yearbookId = yearbook.get('id');
          const id = data.id || this.get('dataprovider').findId(data, 'self');
          this.get('store').pushPayload({
            yearbookcontentselection: data
          });
          const record = this.store.peekRecord('yearbookcontentselection', id);

          if (record) {
            record.set('images', data.images);
            record.set('announcement', announcement);
            announcement.set('yearbook', record);
          }

          this.EventBusService.emit('update-yearbook-selection', this);
          resolve();
        }).catch(jqXHR => {
          const error = jqXHR.responseText ? JSON.parse(jqXHR.responseText).applicationError : undefined;

          if (error === 'YEARBOOKSETTING_DISABLED') {
            return this.transitionTo('oops.default');
          }

          reject('error.yearbook_unknown_error');
        });
      });
    },

    deleteContentSelection(yearbook, announcement) {
      return new Promise((resolve, reject) => {
        const contentSelection = announcement.get('yearbook');

        const onSuccess = () => {
          this.EventBusService.emit('update-yearbook-selection', this);
          resolve();
        };

        const onError = jqXHR => {
          const error = jqXHR.responseText ? JSON.parse(jqXHR.responseText) : {};

          if (error === '"YEARBOOKSETTING_DISABLED"') {
            return this.transitionTo('oops.default');
          }

          reject('error.yearbook_unknown_error');
        };

        const record = this.get('store').peekRecord('yearbookcontentselection', contentSelection.get('id'));
        record.destroyRecord({
          include: yearbook.get('id')
        }).then(onSuccess, onError);
      });
    },

    orderYearbook(yearbook, order) {
      return new Promise((resolve, reject) => {
        this.validateOrder(order).then(() => this.uploadHTML(yearbook)).then(() => this.getYearbookPaymentUrl(yearbook, order)).then(url => resolve(url)).catch(error => reject(error));
      });
    },

    validateOrder(order) {
      return new Promise((resolve, reject) => {
        const postalCode = order.get('postalCode').toUpperCase();
        const houseNumber = order.get('houseNumber');

        if (isEmpty(postalCode) || isEmpty(postalCode.match(POSTALCODE_PATTERN))) {
          return reject('error.yearbook_postalcode');
        }

        if (isEmpty(houseNumber) || isEmpty(houseNumber.match(HOUSENUMBER_PATTERN))) {
          return reject('error.yearbook_housenumber');
        }

        resolve();
      });
    },

    getYearbookPaymentUrl(yearbook, order) {
      return new Promise((resolve, reject) => {
        const isNew = isEmpty(order.get('id'));
        const orderId = isNew ? '' : '/' + order.get('id');
        const data = {
          dtype: 'yearbook.RYearbookOrder',
          createdAt: order.get('createdAt'),
          status: order.get('status'),
          firstName: order.get('firstName'),
          lastName: order.get('lastName'),
          country: order.get('country'),
          city: order.get('city'),
          postalCode: order.get('postalCode').toUpperCase(),
          telephone: '',
          streetName: order.get('streetName'),
          houseNumber: order.get('houseNumber'),
          paymentMethod: order.get('paymentMethod'),
          email: this.get('account.identity.email'),
          copies: isNew ? yearbook.get('copies') : order.get('copies')
        };
        const request = {
          type: isNew ? 'POST' : 'PUT',
          url: this.get('host') + '/' + namespace + '/yearbook/' + yearbook.get('id') + '/order' + orderId,
          data: isNew ? {
            items: [data]
          } : data,
          disableAjaxLoader: true
        };
        this.get('ajaxService').executeRequest(request).then(jqXHR => {
          if (isPresent(jqXHR) && isPresent(jqXHR.yearbookPayment) && isPresent(jqXHR.yearbookPayment.externalCheckoutUrl)) {
            return resolve(jqXHR.yearbookPayment.externalCheckoutUrl);
          }

          const order = isPresent(jqXHR) && isPresent(jqXHR.items) ? jqXHR.items[0] : undefined;
          const payment = isPresent(order) && isPresent(order.yearbookPayment) ? order.yearbookPayment : undefined;

          if (isPresent(payment) && isPresent(payment.externalCheckoutUrl)) {
            return resolve(payment.externalCheckoutUrl);
          }

          reject('error.yearbook_empty_url');
        }).catch(jqXHR => {
          const applicationError = jqXHR && jqXHR.responseText ? JSON.parse(jqXHR.responseText).applicationError : undefined;

          if (jqXHR.status === 400 && applicationError === 'YEARBOOK_ORDER_EXISTS') {
            return reject('error.yearbook_order_exists');
          }

          reject('error.yearbook_unknown_error');
        });
      });
    },

    deleteYearbook(id) {
      return new Promise((resolve, reject) => {
        const onSuccess = yearbook => resolve(yearbook.destroyRecord());

        const onError = () => reject('error.unknown_error');

        this.get('store').findRecord('yearbook', id, {
          backgroundReload: false
        }).then(onSuccess, onError);
      });
    },

    uploadImage(yearbook, images, type) {
      return new Promise((resolve, reject) => {
        const extraUploadInfo = {
          url: this.get('host') + '/' + namespace + '/yearbook/' + yearbook.get('id') + '/uploadinfo' + '?type=' + type + '&contentType=image/jpeg'
        };
        this.get('attachmentsService').createYearbookAttachments(yearbook, extraUploadInfo, [], images).then(([attachment]) => {
          attachment.attachmentType = type;
          return this.updateYearbookAttachment(yearbook, attachment);
        }).then(() => this.queryYearbook(yearbook.get('id'))).then(() => resolve()).catch(error => reject(error));
      });
    },

    updateYearbookAttachment(yearbook, attachment) {
      return new Promise((resolve, reject) => {
        const request = {
          type: 'PUT',
          url: this.get('host') + '/' + namespace + '/yearbook/' + yearbook.get('id') + '/attachment',
          data: attachment,
          disableAjaxLoader: true
        };
        this.get('ajaxService').executeRequest(request).then(() => resolve()).catch(() => reject('error.yearbook_unknown_error'));
      });
    },

    uploadHTML(yearbook) {
      return new Promise((resolve, reject) => {
        if (yearbook.get('isPromo') && yearbook.get('pdfGenerated')) {
          return resolve();
        }

        const service = this.get('yearbookService');

        const createIframe = () => service.createIframe();

        const addStyling = (iframes, css) => service.addStyling(iframes, css);

        const getContent = () => service.getContent(yearbook);

        const addContent = (iframes, content) => service.addContent(iframes, content);

        const getCss = () => service.getCss();

        const createDocuments = iframes => service.createDocuments(iframes, yearbook);

        const pass = value => Promise.resolve(value);

        const uploadHTML = documents => {
          const uploadInfo = {
            url: this.get('host') + '/' + namespace + '/yearbook/' + yearbook.get('id') + '/uploadinfo',
            dataBaseId: yearbook.get('id')
          };
          return this.get('s3Service').uploadFiles([], documents, 'yearbook.RYearbookAttachment', uploadInfo);
        };

        Promise.all([createIframe(), getContent(), getCss()]).then(([iframes, content, css]) => Promise.all([addStyling(iframes, css), pass(content)])).then(([iframes, content]) => addContent(iframes, content)).then(iframes => createDocuments(iframes)).then(documents => uploadHTML(documents)).then(jqXHR => resolve(jqXHR)).catch(error => {
          if (isPresent(error) && typeof error === 'string' && error.startsWith('error.')) {
            return reject(error);
          }

          reject('error.yearbook_unknown_error');
        });
      });
    },

    getYearbookPrices(yearbook) {
      return new Promise((resolve, reject) => {
        const records = this.store.peekAll('yearbookprice').toArray().filter(p => p.get('type') === yearbook.get('type'));

        if (isPresent(records) && records.length > 0) {
          return resolve(records);
        }

        const onSuccess = data => resolve(data);

        return this.store.findAll('yearbookprice', {
          include: yearbook.get('id')
        }).then(onSuccess, reject);
      });
    },

    getPaymentPrices(yearbook) {
      return new Promise((resolve, reject) => {
        const request = {
          type: 'GET',
          url: this.get('host') + '/' + namespace + '/yearbook/' + yearbook.get('id') + '/order/prices',
          disableAjaxLoader: true
        };
        this.get('ajaxService').executeRequest(request).then(data => resolve(data.items)).catch(() => reject('error.yearbook_unknown_error'));
      });
    },

    getTypes() {
      return new Promise((resolve, reject) => {
        const request = {
          type: 'GET',
          url: this.get('host') + '/' + namespace + '/yearbook/prices?sort=asc-basePrice',
          disableAjaxLoader: true
        };
        this.get('ajaxService').executeRequest(request).then(data => resolve(data.items.sort((a, b) => b.basePrice - a.basePrice))).catch(() => reject('error.yearbook_unknown_error'));
      });
    },

    queryYearbookSettings() {
      return this.get('store').query('yearbooksetting', {
        disableAjaxLoader: true
      });
    },

    getYearbookSettings() {
      return this.get('store').findAll('yearbooksetting');
    }

  });

  _exports.default = _default;
});