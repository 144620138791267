define("parro-web/components/yearbook/yearbook-announcement", ["exports", "ember", "parro-web/mixins/routeMixin", "parro-web/components/yearbook/yearbook-announcement-images-main"], function (_exports, _ember, _routeMixin, _yearbookAnnouncementImagesMain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _yearbookAnnouncementImagesMain.default.extend(_routeMixin.default, {
    classNames: ['yearbook--announcement'],
    classNameBindings: ['toNavHere'],
    toNavHere: computed.alias('announcement.toNavHere'),

    didInsertElement() {
      this._super(...arguments);

      this.get('yearbookService').addEmoticonStyling(this, $(this.element)[0]);
    },

    childNames: computed('announcement.childAvatars.[]', function () {
      const childAvatars = Array.orEmpty(this.get('announcement.childAvatars'));
      return childAvatars.map(child => child.get('firstname')).join(' , ');
    }),
    actions: {
      toNavImages() {
        if (this.get('isLoading')) {
          return;
        }

        const yearbookId = this.get('yearbook.id');
        const announcementId = this.get('announcement.eventId').toString();
        const groupId = this.get('announcement.group.id');
        this.transitionTo('yearbook.editor-images', yearbookId, announcementId, groupId);
      },

      onUpdateText(boolean) {
        this.set('includeText', boolean);
      }

    }
  });

  _exports.default = _default;
});