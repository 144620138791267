define("parro-web/serializers/group", ["exports", "parro-web/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      childavatars: {
        embedded: 'always'
      }
    },

    normalize(type, hash, prop) {
      // Groups retrieved from ParnasSys can have missing ID (not used by Parro).
      if (hash.externalId && hash.links.length === 0) {
        hash.id = 'ParnasSys:' + hash.externalId;
      }

      const koppelingId = this._getLinkableIdFromSelfLink(hash, 'koppeling');

      if (koppelingId) {
        hash.koppelingId = koppelingId;
      } // ChildAvatars are added to the additional object - IF the current user is a guardian.
      // ( BUT that doesn't happen every request - so this time ENSURE Ember Data retains the previous value! )


      const childavatars = this._getAdditionalObject(hash, 'childavatar');

      if (childavatars) {
        hash.childavatars = childavatars;
      }

      return this._super(type, hash, prop);
    },

    _parseLinkables(hash) {
      this._parseAndFlattenLinkables(hash, false);
    }

  });

  _exports.default = _default;
});