define("parro-web/transforms/time", ["exports", "ember", "ember-data", "parro-web/models/time"], function (_exports, _ember, _emberData, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    isPresent
  } = _ember.default;

  var _default = _emberData.default.Transform.extend({
    serialize(deserialized) {
      assert('Must provide deserialized', typeof deserialized === 'object');
      assert('deserialized must have get', typeof deserialized.get === 'function');
      assert('deserialized must have toString', typeof deserialized.toString === 'function');
      assert('deserialized must have hours', isPresent(deserialized.get('hours')));
      assert('deserialized must have minutes', isPresent(deserialized.get('minutes')));
      assert('deserialized must have seconds', isPresent(deserialized.get('seconds')));
      assert('deserialized must have milliseconds', isPresent(deserialized.get('milliseconds')));
      const string = deserialized.toString();

      const regex = _time.default.create({}).getTimeRegex();

      assert('Time regex unmatched for string: ' + string, isPresent(string.match(regex)));
      return string;
    },

    deserialize(serialized) {
      const time = _time.default.create({});

      const regex = time.getTimeRegex();
      let results;

      if (typeof serialized === 'string' && (results = serialized.match(regex))) {
        const hours = parseInt(results[1]);
        const minutes = parseInt(results[2]);
        const seconds = parseInt(results[3]);
        const milliseconds = parseInt(results[4]);
        return time.setValues(hours, minutes, seconds, milliseconds);
      }

      return undefined;
    }

  });

  _exports.default = _default;
});