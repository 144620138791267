define("parro-web/components/navigation/nav-breadcrumb", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: 'li',
    classNameBindings: ['isDone', 'isActive'],
    isDone: computed.alias('breadcrumb.isDone'),
    isActive: computed.alias('breadcrumb.isActive')
  });

  _exports.default = _default;
});