define("parro-web/services/version-service", ["exports", "ember", "parro-web/config/environment", "@ember/service", "@ember/utils", "parro-web/utils/browserUtil", "@glimmer/tracking"], function (_exports, _ember, _environment, _service, _utils, _browserUtil, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    RSVP: {
      Promise
    }
  } = _ember.default;
  const {
    embrah: {
      host
    }
  } = _environment.default;
  let VersionService = (_dec = (0, _service.inject)('ajax'), (_class = class VersionService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "ajaxService", _descriptor2, this);

      _initializerDefineProperty(this, "host$", _descriptor3, this);

      _initializerDefineProperty(this, "name$", _descriptor4, this);

      _initializerDefineProperty(this, "loginUrl$", _descriptor5, this);

      _initializerDefineProperty(this, "parnassysRestUrl$", _descriptor6, this);

      _initializerDefineProperty(this, "parnassysAccountUrl$", _descriptor7, this);

      _initializerDefineProperty(this, "ouderportaalUrl$", _descriptor8, this);

      _initializerDefineProperty(this, "restVersion$", _descriptor9, this);
    }

    get host() {
      const hasEnvironment = (0, _utils.isPresent)(_browserUtil.default.getItem('environment'));
      const environment = hasEnvironment ? JSON.parse(_browserUtil.default.getItem('environment')) : {};
      return this.host$ || environment?.rest || host;
    }

    get name() {
      const hasEnvironment = (0, _utils.isPresent)(_browserUtil.default.getItem('environment'));
      const environment = hasEnvironment ? JSON.parse(_browserUtil.default.getItem('environment')) : {};
      return this.name$ || environment?.name || _environment.default.APP.name;
    }

    get loginUrl() {
      const hasEnvironment = (0, _utils.isPresent)(_browserUtil.default.getItem('environment'));
      const environment = hasEnvironment ? JSON.parse(_browserUtil.default.getItem('environment')) : {};
      return this.loginUrl$ || environment?.loginUrl || _environment.default.APP.loginUrl;
    }

    get parnassysRestUrl() {
      const hasEnvironment = (0, _utils.isPresent)(_browserUtil.default.getItem('environment'));
      const environment = hasEnvironment ? JSON.parse(_browserUtil.default.getItem('environment')) : {};
      return this.parnassysRestUrl$ || environment?.parnassysRestUrl || _environment.default.APP.parnassysRestUrl;
    }

    get parnassysAccountUrl() {
      const hasEnvironment = (0, _utils.isPresent)(_browserUtil.default.getItem('environment'));
      const environment = hasEnvironment ? JSON.parse(_browserUtil.default.getItem('environment')) : {};
      return this.parnassysAccountUrl$ || environment?.parnassysAccountUrl || _environment.default.APP.parnassysAccountUrl;
    }

    get ouderportaalUrl() {
      const hasEnvironment = (0, _utils.isPresent)(_browserUtil.default.getItem('environment'));
      const environment = hasEnvironment ? JSON.parse(_browserUtil.default.getItem('environment')) : {};
      return this.ouderportaalUrl$ || environment?.ouderportaalUrl || _environment.default.APP.ouderportaalUrl;
    }

    get restVersion() {
      if (!(0, _utils.isNone)(this.restVersion$)) {
        return this.restVersion$;
      }

      return _browserUtil.default.getItem('rest-version') || _environment.default.REST_version;
    }

    get isDevelopment() {
      return window.location.host !== 'talk.parro.com';
    }

    checkVersion() {
      const date = new Date();
      date.setMinutes(date.getMinutes() - date.getMinutes() % 5);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const timestamp = date.getTime();
      const checkedVersionTimestamp = this.session.data.checkVersionTimestamp;
      this.session.data.checkVersionTimestamp = timestamp;

      if (checkedVersionTimestamp !== timestamp) {
        const request = {
          type: 'GET',
          url: window.location.origin + '/version.json?timestamp=' + timestamp,
          ignoreRefresh: true,
          beforeSend: () => {}
        };

        const onSuccess = json => {
          if (json.version !== _environment.default.APP.version) {
            window.location.reload();
          }
        };

        this.ajaxService.executeRequest(request).then(onSuccess, () => {});
      }
    }

    getEnvironments() {
      return new Promise((resolve, reject) => {
        const request = {
          type: 'GET',
          url: 'https://parro-service-discovery.parro.topicusonderwijs.build/restendpoints'
        };
        this.ajaxService.executeRequest(request).then(json => resolve(json)).catch(jqXHR => reject(jqXHR));
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajaxService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "host$", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "name$", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loginUrl$", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "parnassysRestUrl$", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "parnassysAccountUrl$", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "ouderportaalUrl$", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "restVersion$", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = VersionService;
});