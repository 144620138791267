define("parro-web/components/form/form-field-text", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: 'div',
    classNames: ['suite-form--fields'],
    classNameBindings: ['isError'],
    showAmount: false,
    placeholder: undefined,
    type: 'text',
    amountOfCharacters: computed('value.length', function () {
      return this.get('value.length') || 0;
    }),
    isError: computed('error', function () {
      return isPresent(this.get('error')) && this.get('error') !== false;
    }),
    inputClassName: computed('isDisabled', function () {
      return this.get('isDisabled') ? 'suite-form--input is-inactive' : 'suite-form--input';
    }),
    showLabel: computed('value', function () {
      return isPresent(this.get('value'));
    }),
    actions: {
      submit() {
        if (this.get('onSubmit')) {
          this.get('onSubmit').apply(this);
        }
      }

    }
  });

  _exports.default = _default;
});