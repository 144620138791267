define("parro-web/models/_attachmententry", ["exports", "ember-data", "ember"], function (_exports, _emberData, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _emberData.default.Model.extend({
    lastModifiedAt: _emberData.default.attr('date'),
    url: _emberData.default.attr('string'),
    size: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    contentType: _emberData.default.attr('string'),
    bucket: _emberData.default.attr('string'),
    format: computed('width', 'height', function () {
      const width = this.get('width');
      const height = this.get('height');

      if (width === height) {
        return 'SQUARE';
      } else if (width > height) {
        return 'LANDSCAPE';
      } else if (height > width) {
        return 'PORTRAIT';
      }

      return 'UNKNOWN';
    })
  });

  _exports.default = _default;
});