define("parro-web/templates/components/yearbook/templates/template-front", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPreview}}
    <div class="yearbook--shadow"></div>
    {{#if (and showQualityOverlay isBadQuality)}} <div {{action 'showWarning' bubbles=false}}class="yearbook--quality-overlay"><i class="icon-warning"></i></div>{{/if}}
  {{/if}}
  <header style={{color}}>
    <p>{{t 'yearbook_templates.my_yearbook'}}</p>
    <h1>{{yearbook.name}}</h1>
    <figure class="figure--circle" style={{circle}}>
      {{#unless hideSchoolYear}}
        <h2>{{yearRange.first}}</h2>
        <h2>{{yearRange.last}}</h2>
      {{/unless}}
    </figure>
  </header>
  
  <article class="image" style={{frontCover}}></article>
  
  */
  {
    "id": "1laGShrP",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"yearbook--shadow\"],[12],[13],[1,\"\\n  \"],[41,[28,[37,2],[[33,3],[33,4]],null],[[[1,\" \"],[11,0],[24,0,\"yearbook--quality-overlay\"],[4,[38,5],[[30,0],\"showWarning\"],[[\"bubbles\"],[false]]],[12],[10,\"i\"],[14,0,\"icon-warning\"],[12],[13],[13]],[]],null],[1,\"\\n\"]],[]],null],[10,\"header\"],[15,5,[36,6]],[12],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,7],[\"yearbook_templates.my_yearbook\"],null]],[13],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[33,8,[\"name\"]]],[13],[1,\"\\n  \"],[10,\"figure\"],[14,0,\"figure--circle\"],[15,5,[36,9]],[12],[1,\"\\n\"],[41,[51,[33,11]],[[[1,\"      \"],[10,\"h2\"],[12],[1,[33,12,[\"first\"]]],[13],[1,\"\\n      \"],[10,\"h2\"],[12],[1,[33,12,[\"last\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"article\"],[14,0,\"image\"],[15,5,[36,13]],[12],[13],[1,\"\\n\"]],[],false,[\"if\",\"isPreview\",\"and\",\"showQualityOverlay\",\"isBadQuality\",\"action\",\"color\",\"t\",\"yearbook\",\"circle\",\"unless\",\"hideSchoolYear\",\"yearRange\",\"frontCover\"]]",
    "moduleName": "parro-web/templates/components/yearbook/templates/template-front.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});