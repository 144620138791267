define("parro-web/utils/comparator", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Object.extend({
    a: undefined,
    b: undefined,
    comparison: 0,

    result() {
      return this.get('comparison') || 0;
    },

    compare(field, nullsFirst) {
      if (this.result() !== 0) {
        return this;
      }

      const asc = !field.endsWith(':desc');
      field = field.replace(':asc', '').replace(':desc', '');

      const valA = this._getValue(this.get('a'), field);

      const valB = this._getValue(this.get('b'), field);

      const noA = valA === undefined || valA === null;
      const noB = valB === undefined || valB === null;

      if (noA && noB) {
        this.set('comparison', 0);
        return this;
      }

      if (noA) {
        this.set('comparison', nullsFirst ? -1 : 1);
        return this;
      }

      if (noB) {
        this.set('comparison', nullsFirst ? 1 : -1);
        return this;
      }

      if (typeof valA === 'string') {
        this.set('comparison', asc ? valA.localeCompare(valB) : valB.localeCompare(valA));
        return this;
      }

      if (typeof valA === 'boolean') {
        if (valA && !valB) {
          this.set('comparison', asc ? -1 : 1);
          return this;
        }

        if (!valA && valB) {
          this.set('comparison', asc ? 1 : -1);
          return this;
        }
      }

      if (typeof valA === 'number' || valA.getDate) {
        if (valA < valB) {
          this.set('comparison', asc ? -1 : 1);
          return this;
        }

        if (valA > valB) {
          this.set('comparison', asc ? 1 : -1);
          return this;
        }
      }

      return this;
    },

    _getValue: function (source, field) {
      return source.get ? source.get(field) : source[field];
    }
  });

  _exports.default = _default;
});