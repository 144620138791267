define("parro-web/components/form/select-dropdown", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    selectClass: computed('class', 'disabled', function () {
      const selectClass = this.get('class') || '';
      return this.get('disabled') ? selectClass + ' is-inactive' : selectClass;
    })
  });

  _exports.default = _default;
});