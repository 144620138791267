define("parro-web/components/yearbook/yearbook-preview-spread", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    yearbookService: service(),
    pageText: computed('chunkIndex', function () {
      const index = this.get('chunkIndex');
      const amountOfChunks = this.get('amountOfChunks');

      if (index === 0) {
        return 'yearbook_preview.spread_front';
      } else if (index === 1) {
        return 'yearbook_preview.spread_inside';
      } else if (index === amountOfChunks - 2) {
        return 'yearbook_preview.spread_inside';
      } else if (index === amountOfChunks - 1) {
        return 'yearbook_preview.spread_back';
      }

      const first = index * 2 - 3;
      const second = first + 1;
      return `${first} / ${second}`;
    })
  });

  _exports.default = _default;
});