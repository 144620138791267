define("parro-web/templates/components/yearbook/yearbook-preview", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="panel">
    <h3 class="header--section">{{t 'yearbook_preview.header'}}</h3>
    <p>{{t 'yearbook_preview.text'}}</p>
  </div>
  
  <div class="panel--list yearbook--preview">
    <ul class="list--yearbook-preview">
  
      {{#each templates as |chunk chunkIndex|}}
        {{yearbook/yearbook-preview-spread amountOfChunks=templates.length yearbook=yearbook chunk=chunk chunkIndex=chunkIndex requestConfirmation=requestConfirmation}}
      {{/each}}
    </ul>
  </div>
  */
  {
    "id": "eeeKF19j",
    "block": "[[[10,0],[14,0,\"panel\"],[12],[1,\"\\n  \"],[10,\"h3\"],[14,0,\"header--section\"],[12],[1,[28,[35,0],[\"yearbook_preview.header\"],null]],[13],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,0],[\"yearbook_preview.text\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"panel--list yearbook--preview\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"list--yearbook-preview\"],[12],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"      \"],[1,[28,[35,4],null,[[\"amountOfChunks\",\"yearbook\",\"chunk\",\"chunkIndex\",\"requestConfirmation\"],[[33,3,[\"length\"]],[33,5],[30,1],[30,2],[33,6]]]]],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"chunk\",\"chunkIndex\"],false,[\"t\",\"each\",\"-track-array\",\"templates\",\"yearbook/yearbook-preview-spread\",\"yearbook\",\"requestConfirmation\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-preview.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});