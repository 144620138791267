define("parro-web/components/yearbook/templates/template-intro", ["exports", "ember", "parro-web/config/environment", "parro-web/components/yearbook/templates/template-main"], function (_exports, _ember, _environment, _templateMain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    String: {
      htmlSafe
    }
  } = _ember.default;

  var _default = _templateMain.default.extend({
    image: computed(function () {
      const baseUrl = _environment.default.externalUrls.amazonStaticImages;
      const url = this.get('isMagenta') ? baseUrl + '/intro.svg' : baseUrl + '/intro-blue.svg';
      return htmlSafe('background-image: url(' + url + ')');
    })
  });

  _exports.default = _default;
});