define("parro-web/templates/components/form/input-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless disabled}}
    {{input type="checkbox" checked=checked}}
    <span></span>
    {{#if description}}<p>{{t-exists description}}</p>{{/if}}
  {{else}}
    {{input type="checkbox" checked=checked disabled=disabled}}
    <span class="is-not-clickable"></span>
    {{#if description}}<p>{{t-exists description}}</p>{{/if}}
  {{/unless}}
  */
  {
    "id": "mka+vkIZ",
    "block": "[[[41,[51,[33,1]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"type\",\"checked\"],[\"checkbox\",[33,3]]]]],[1,\"\\n  \"],[10,1],[12],[13],[1,\"\\n  \"],[41,[33,5],[[[10,2],[12],[1,[28,[35,6],[[33,5]],null]],[13]],[]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[33,3],[33,1]]]]],[1,\"\\n  \"],[10,1],[14,0,\"is-not-clickable\"],[12],[13],[1,\"\\n  \"],[41,[33,5],[[[10,2],[12],[1,[28,[35,6],[[33,5]],null]],[13]],[]],null],[1,\"\\n\"]],[]]]],[],false,[\"unless\",\"disabled\",\"input\",\"checked\",\"if\",\"description\",\"t-exists\"]]",
    "moduleName": "parro-web/templates/components/form/input-checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});