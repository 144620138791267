define("parro-web/initializers/override-Bugsnag", ["exports", "@bugsnag/js", "parro-web/utils/browserUtil", "parro-web/config/environment"], function (_exports, _js, _browserUtil, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'override-Bugsnag',
    initialize: function () {
      window.bugsnagClient = _js.default.start({
        user: {
          id: _browserUtil.default.getItem('bugsnag-anonymous-id') || '-'
        },
        apiKey: _environment.default.APP.bugsnagApiKey,
        releaseStage: _environment.default.environment,
        appVersion: _environment.default.APP.version,
        enabledReleaseStages: ['production'],
        collectUserIp: false,
        networkBreadcrumbsEnabled: false
      });
    }
  };
  _exports.default = _default;
});