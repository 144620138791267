define("parro-web/templates/components/yearbook/yearbook-status-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if displayType}}<div><span>{{t-exists displayType}} {{t 'yearbook_status.amount_pages' amount=amount max=displayMaxPages}}</span><span>{{t-exists displayPrice}}</span></div>{{/if}}
  
  */
  {
    "id": "mpVHj8o7",
    "block": "[[[41,[33,1],[[[10,0],[12],[10,1],[12],[1,[28,[35,2],[[33,1]],null]],[1,\" \"],[1,[28,[35,3],[\"yearbook_status.amount_pages\"],[[\"amount\",\"max\"],[[33,4],[33,5]]]]],[13],[10,1],[12],[1,[28,[35,2],[[33,6]],null]],[13],[13]],[]],null],[1,\"\\n\"]],[],false,[\"if\",\"displayType\",\"t-exists\",\"t\",\"amount\",\"displayMaxPages\",\"displayPrice\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-status-bar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});