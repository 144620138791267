define("parro-web/models/yearbookcontentselection", ["exports", "ember", "ember-data", "ember-inflector"], function (_exports, _ember, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _emberData.default.Model.extend({
    yearbookService: service(),
    dtype: _emberData.default.attr('string', {
      defaultValue: 'yearbook.RYearbookContentSelection'
    }),
    lastModifiedAt: _emberData.default.attr('date'),
    announcement: _emberData.default.belongsTo('event'),
    images: _emberData.default.attr('array'),
    includeText: _emberData.default.attr('boolean'),
    yearbookId: _emberData.default.attr('number'),
    selectedImages: computed('images.[]', 'announcement', function () {
      const selected = Array.orEmpty(this.get('images'));
      const images = Array.orEmpty(this.get('announcement.getImages'));

      const onReduce = (acc, id) => acc.concat(images.filter(img => parseInt(id) === parseInt(img.get('id'))));

      return selected.reduce(onReduce, []);
    }),
    pages: computed('images.[]', 'announcement', function () {
      return this._getPages();
    }).readOnly(),

    _getPages() {
      const service = this.get('yearbookService');
      const selectedImages = this.get('selectedImages');
      const newArray = [...selectedImages];
      const pages = [];

      if (this.get('includeText')) {
        const spliced = newArray.splice(0, 1);
        const text = this.get('announcement.contents');
        pages.push(this._getPage(spliced, text));
      }

      newArray.forEach(image => pages.push(this._getPage([image])));
      pages.forEach((page, index) => {
        if (index === 0) {
          page.set('figure', service.assignShape());
        } else {
          page.set('figure', service.assignFigure());
        }
      });
      return pages;
    },

    _getPage(images, text) {
      const object = _ember.default.Object.create({
        images: images,
        content: text
      });

      return _ember.default.Object.create({
        template: this.get('yearbookService').assignTemplate(object),
        images: images,
        content: text,
        sortDate: this.get('announcement.sortDate'),
        schoolYear: this.get('announcement.group.schooljaar'),
        announcementId: this.get('announcement.eventId'),
        groupId: this.get('announcement.group.id')
      });
    }

  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('yearbookcontentselection', 'yearbookcontentselection');
});