define("parro-web/adapters/yearbookcontentselection", ["exports", "ember", "parro-web/adapters/application", "parro-web/config/environment"], function (_exports, _ember, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;
  const {
    embrah: {
      namespace
    }
  } = _environment.default;

  var _default = _application.default.extend({
    versionService: service(),
    host: computed.readOnly('versionService.host'),

    buildURL(modelName, id, snapshot, requestType, query) {
      if (modelName === 'yearbookcontentselection' && requestType === 'deleteRecord') {
        const yearbookId = snapshot.include;
        return this.get('host') + '/' + namespace + '/yearbook/' + yearbookId + '/contentselection/' + id;
      }

      if (modelName === 'yearbookcontentselection' && requestType === 'query' && query.id) {
        const yearbookId = query.id;
        delete query.id;
        return this.get('host') + '/' + namespace + '/yearbook/' + yearbookId + '/contentselection';
      }

      return this._super(modelName, id, snapshot, requestType, query);
    }

  });

  _exports.default = _default;
});