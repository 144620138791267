define("parro-web/components/yearbook/yearbook-front", ["exports", "ember", "parro-web/models/services/dialogs", "parro-web/mixins/routeMixin"], function (_exports, _ember, _dialogs, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed,
    isEmpty,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: '',
    yearbookService: service(),
    yearbookAjaxService: service(),
    ScrollService: service(),
    dialogConfig: _dialogs.default.yearbookCoverDialogConfig(),

    didInsertElement() {
      this._super(...arguments);

      _ember.default.run.schedule('afterRender', this, () => {
        this.get('yearbookDialog').send('show', this.get('dialogConfig'));
      });

      this.ScrollService.setScrollTop($('html'), false);
    },

    chosenRed: computed.equal('yearbook.color', '#C9496A'),
    chosenBlue: computed.equal('yearbook.color', '#2499BA'),
    isBadQuality: computed('yearbook.frontCover', function () {
      const image = this.get('yearbook.frontCover.largestImage');
      return this.get('yearbookService').determineBadQuality(210, 161.683, image);
    }),
    isSubmitDisabled: computed('yearbook.frontCover', 'yearbook.name', function () {
      const frontCover = this.get('yearbook.frontCover');
      const name = this.get('yearbook.name');
      return isEmpty(frontCover) || isEmpty(name);
    }),
    titleObserver: _ember.default.observer('yearbook.name', function () {
      if (this.get('yearbook.name.length') > 15) {
        this.set('yearbook.name', this.get('yearbook.name').slice(0, 15));
      }

      _ember.default.run.debounce(this, this._updateTitle, 2000);
    }),

    _updateTitle() {
      const yearbook = this.get('yearbook');

      const onSuccess = () => {};

      const onError = () => this.flashError('error.unknown_error');

      this.get('yearbookAjaxService').editYearbook(yearbook).then(onSuccess, onError);
    },

    actions: {
      chooseColor(yearbook, color) {
        yearbook.set('color', color);

        const onSuccess = () => {};

        const onError = () => this.flashError('error.unknown_error');

        this.get('yearbookAjaxService').editYearbook(yearbook).then(onSuccess, onError);
      },

      toNavImages() {
        this.transitionTo('yearbook.cover-images', this.get('yearbook.id'));
      },

      next() {
        const historyRoute = this.get('yearbookService.historyRoute');
        this.set('yearbookService.historyRoute', undefined);

        if (isPresent(historyRoute) && historyRoute !== this.get('router.currentRouteName')) {
          return this.transitionTo(historyRoute, this.get('yearbook.id'));
        }

        this.transitionTo('yearbook.editor', this.get('yearbook.id'));
      }

    }
  });

  _exports.default = _default;
});