define("parro-web/components/yearbook/yearbook-order", ["exports", "ember", "parro-web/models/services/dialogs", "parro-web/mixins/routeMixin"], function (_exports, _ember, _dialogs, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;
  const {
    inject: {
      service
    },
    isEmpty,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: '',
    yearbookAjaxService: service(),
    yearbookService: service(),
    ScrollService: service(),
    agreedDesign: false,
    agreedTerms: false,
    order: computed('yearbook.yearbookOrder', function () {
      const order = this.get('yearbook.yearbookOrder');
      return isPresent(order) ? order : _ember.default.Object.create({
        paymentMethod: 'IDEAL',
        country: 'Nederland'
      });
    }),
    breadcrumbs: [{
      title: 'yearbook_order.title',
      isDone: false,
      isActive: true
    }, {
      title: 'yearbook_order.pay',
      isDone: false,
      isActive: false
    }],

    init() {
      this._super(...arguments);

      const yearbook = this.get('yearbook');

      const onSuccess = methods => this.set('methods', methods);

      const onError = () => this.flashError('error.unknown_error');

      this.get('yearbookAjaxService').getPaymentPrices(yearbook).then(onSuccess, onError);
    },

    didInsertElement() {
      this._super(...arguments);

      this.ScrollService.setScrollTop($('html'), false);
    },

    isSubmitDisabled: computed('agreedDesign', 'agreedTerms', 'order.firstName', 'order.lastName', 'order.country', 'order.postalCode', 'order.city', 'order.streetName', 'order.houseNumber', function () {
      const order = this.get('order');
      const yearbook = this.get('yearbook');
      const amountOfPages = this.get('yearbookService').getAmountOfContentPages(this.get('yearbook'));
      return isEmpty(order) || isEmpty(order.get('firstName')) || isEmpty(order.get('lastName')) || isEmpty(order.get('country')) || isEmpty(order.get('postalCode')) || isEmpty(order.get('city')) || isEmpty(order.get('streetName')) || isEmpty(order.get('houseNumber')) || amountOfPages < yearbook.get('minPages') || !this.get('agreedDesign') || !this.get('agreedTerms');
    }),
    isIdeal: computed.equal('order.paymentMethod', 'IDEAL'),
    isKlarna: computed.equal('order.paymentMethod', 'KLARNA_PAY_LATER'),
    designText: computed('hasBadQualityImages', function () {
      return this.get('hasBadQualityImages') ? 'yearbook_order.text_design2' : 'yearbook_order.text_design';
    }),
    hasBadQualityImages: computed('selection.@each', function () {
      return this.get('yearbookService').getAmountOfBadImages(this.get('yearbook')) > 0;
    }),
    amountOfPlaceholders: computed('selection.@each', function () {
      return this.get('yearbookService').getAmountOfPlaceholderPages(this.get('yearbook'));
    }),
    displayTotalPrice: computed('yearbook.copies', 'yearbook.price', 'methods.[]', 'order.paymentMethod', function () {
      const method = Array.orEmpty(this.get('methods')).find(method => method.method === this.get('order.paymentMethod'));
      const paymentFee = isPresent(method) ? method.paymentFee : 0;
      const totalPrice = this.get('yearbook.price') * this.get('yearbook.copies') + paymentFee;
      return this.get('yearbookService').convertPriceToDisplay(totalPrice);
    }),
    actions: {
      order() {
        this.flashClear();

        if (this.get('isSubmitDisabled')) {
          return;
        }

        const yearbook = this.get('yearbook');
        const order = this.get('order');
        this.set('isLoading', true);

        _ember.default.run.later(this, () => {
          const onSuccess = url => {
            this.requestConfirmation(_dialogs.default.yearbookPaymentDialogConfig(), () => {
              window.location.assign(url);
            });
          };

          const onError = error => this.flashError(error);

          const onFinal = () => this.set('isLoading', false);

          this.get('yearbookAjaxService').orderYearbook(yearbook, order).then(onSuccess, onError).finally(onFinal);
        }, 1000);
      },

      navToPreview() {
        this.transitionTo('yearbook.preview', this.get('yearbook.id'));
      },

      choosePaymentMethod(method) {
        this.set('order.paymentMethod', method);
      }

    }
  });

  _exports.default = _default;
});