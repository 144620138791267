define("parro-web/routes/yearbook", ["exports", "ember", "parro-web/utils/mobileUtil"], function (_exports, _ember, _mobileUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Route,
    RSVP: {
      Promise
    },
    inject: {
      service
    }
  } = _ember.default;

  var _default = Route.extend({
    yearbookService: service(),
    session: service(),

    titleToken() {
      return this.intl.t('pagetitle.yearbook');
    },

    beforeModel() {
      if (this.get('session.isAuthenticated')) {
        _mobileUtil.default.hideWebviewHeader();
      } else {
        return this.transitionTo('login');
      }
    },

    model() {
      return new Promise((resolve, reject) => {
        this.get('yearbookService').isYearbookEnabled().then(resolve, reject);
      });
    },

    setupController() {
      this.send('showContent');
      document.body.classList.add('auto-height');
    },

    deactivate() {
      document.body.classList.remove('auto-height');
    }

  });

  _exports.default = _default;
});