define("parro-web/components/yearbook/yearbook-announcement-contents", ["exports", "ember", "parro-web/models/services/dialogs"], function (_exports, _ember, _dialogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    isEmpty
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    selected: computed.bool('announcement.yearbook.includeText'),
    disabled: computed('announcement.yearbook.selectedImages.[]', 'isUpdating', function () {
      const amountOfSelected = this.get('announcement.yearbook.selectedImages.length');
      return isEmpty(amountOfSelected) || amountOfSelected === 0 || this.get('isUpdating');
    }),
    actions: {
      toggle() {
        if (this.get('isUpdating')) {
          return;
        }

        if (this.get('disabled')) {
          return this.requestConfirmation(_dialogs.default.yearbookEnableTextDialogConfig());
        }

        this.toggleProperty('announcement.yearbook.includeText');
        this.onUpdateText(this.get('announcement.yearbook.includeText'));
      }

    }
  });

  _exports.default = _default;
});