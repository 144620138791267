define("parro-web/components/yearbook/yearbook-announcement-images", ["exports", "ember", "parro-web/mixins/routeMixin", "parro-web/components/yearbook/yearbook-announcement-images-main"], function (_exports, _ember, _routeMixin, _yearbookAnnouncementImagesMain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isPresent,
    computed
  } = _ember.default;

  var _default = _yearbookAnnouncementImagesMain.default.extend(_routeMixin.default, {
    _getSelectedTotal() {
      const filtered = this.get('selectedImages');
      const amountOfContent = this.get('announcement.getImages.length');
      const amountOfChosenImages = this.get('yearbookService').getAmountOfChosenImages(this.get('yearbook'));
      const amountOfAdded = amountOfContent - filtered.length;
      return amountOfChosenImages + amountOfAdded;
    },

    selected: computed('selectedImages.[]', function () {
      const selectedImages = this.get('selectedImages');
      const images = this.get('announcement.getImages');
      return selectedImages.length === images.length;
    }),
    actions: {
      toggleAll() {
        const images = this.get('images');

        if (this.get('selected')) {
          this.set('selectedImages', []);
          images.forEach(img => img.set('isSelected', false));
        } else {
          const amountOfChosenImages = this._getSelectedTotal();

          const dialogConfig = this.get('yearbookService').getDialogConfig(this.get('yearbook'), amountOfChosenImages);

          if (isPresent(dialogConfig)) {
            return this.onShowDialog(dialogConfig);
          }

          this.set('selectedImages', []);
          this.get('selectedImages').pushObjects(images.map(img => img.get('original')));
          images.forEach(img => img.set('isSelected', true));
        }
      },

      next() {
        return this.transitionTo('yearbook.editor', this.get('yearbook.id'));
      }

    }
  });

  _exports.default = _default;
});