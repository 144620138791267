define("parro-web/routes/oops/default", ["exports", "ember", "parro-web/utils/mobileUtil", "parro-web/config/environment"], function (_exports, _ember, _mobileUtil, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Route.extend({
    session: service(),

    titleToken() {
      return this.intl.t('pagetitle.404');
    },

    beforeModel() {
      _mobileUtil.default.showYearbookError();
    },

    model(params) {
      return RSVP.hash({
        error: params.error || '',
        titleToken: this.titleToken()
      });
    },

    actions: {
      toNavChoice(route, id) {
        if (route) {
          if (id) {
            this.transitionTo(route, id);
          } else {
            this.transitionTo(route);
          }
        }
      },

      willTransition() {
        this.send('hideContent');
      },

      toNavParnasSys() {
        window.location.assign(_environment.default.APP.loginUrl);
      }

    }
  });

  _exports.default = _default;
});