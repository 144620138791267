define("parro-web/models/eventgroup", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'identity.RGroupPrimer'
    }),
    name: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    schooljaar: _emberData.default.attr('number')
  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('eventgroup', 'eventgroup');
});