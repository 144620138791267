define("parro-web/serializers/groupchild", ["exports", "parro-web/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      guardians: {
        embedded: 'always'
      },
      parnassysGuardians: {
        embedded: 'always'
      }
    },

    normalize(type, hash, prop) {
      if (hash?.additionalObjects?.parnassysguardians?.items) {
        hash.parnassysGuardians = hash.additionalObjects.parnassysguardians.items;

        const groupChildId = this._getLinkableIdFromSelfLink(hash, 'self');

        hash.parnassysGuardians.forEach(g => g.childId = groupChildId);
        delete hash.additionalObjects.parnassysGuardians;
      }

      return this._super(type, hash, prop);
    },

    _parseLinkables(hash) {
      hash.identityId = this._getLinkableIdFromSelfLink(hash, 'identiteit');
      hash.koppelingId = this._getLinkableIdFromSelfLink(hash, 'koppeling');

      this._parseAndFlattenLinkables(hash, false);
    }

  });

  _exports.default = _default;
});