define("parro-web/models/person", ["exports", "ember-data", "ember"], function (_exports, _emberData, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _emberData.default.Model.extend({
    firstname: _emberData.default.attr('string'),
    surname: _emberData.default.attr('string'),
    accountActive: _emberData.default.attr('boolean'),
    isGuardian: false,
    isTeacher: false,
    name: computed('firstname', 'surname', function () {
      return (this.get('firstname') || '') + ' ' + (this.get('surname') || '');
    })
  });

  _exports.default = _default;
});