define("parro-web/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "404": {
      "button_logout": "log out",
      "button_report": "report this to Parro",
      "iAmStuck": "I am stuck and want to",
      "reportError1": "I keep getting this error and want to",
      "reportError2": "(Create a ticket with what went wrong + this error.)",
      "whoops": "Oops, something went wrong. Maybe the page is temporarily unavailable or no longer exists. Visit a different page or try again later!"
    },
    "absence": {
      "all_afternoon": "all afternoon",
      "all_day": "all day",
      "all_morning": "all morning",
      "child_report_disabled": "No absences via Parro",
      "confirm_delete_text": "Are you sure you want to delete this absence reason?",
      "confirm_delete_title": "Delete absence reason",
      "confirm_error_amount_text": "The maximum number of reasons (20) has been reached.",
      "confirm_error_amount_title": "Too many absence reasons",
      "enable_text": "Here you can set up whether parents are allowed to report absences via Parro, which conditions this must meet and which reasons they can give. The absence reports are automatically processed in ParnasSys.",
      "enable_title": "Reporting absences via Parro",
      "error_disallowed_child": "Report cannot be made. Action is not allowed for this child.",
      "error_endtime_required": "Report cannot be made. An end time is missing.",
      "error_endtime_starttime": "Report cannot be made. The end time of the period is before the start time.",
      "error_inactive_reason": "The reason is inactive in ParnasSys. Choose another one.",
      "error_missing_end_daypart": "Report cannot be made. There is no end of the period.",
      "error_missing_endtime": "Report cannot be made. An end time is missing.",
      "error_no_url": "No link has been configured. Please contact the teacher.",
      "error_not_authorized": "You cannot see detailed information, because you are not authorised in ParnasSys.",
      "error_overlap_report": "Report cannot be made. There is already an absence notice open within this period. Contact the school.",
      "error_parnassys": "Report cannot be made. The school administration cannot process the report. Contact the school.",
      "error_role_not_found": "Report cannot be made. Role is unknown.",
      "error_unenrolled": "Report cannot be made. The child is not registered within this period. Contact the school",
      "info_text": "Do you have any questions or do you want to know how it works? You can find all the information <a href=\"{url}\" target=\"_blank\">here</a>.",
      "info_title": "More information?",
      "menu_disabled": "Parents can't report absences",
      "menu_enabled": "Parents can report absences",
      "modal_absence_reason_title": "Absence reason",
      "modal_add_absence_text": "For planned absences. E.g. Doctors visit.",
      "modal_add_absence_title": "Absence report",
      "modal_add_end_required": "Parents must also provide an end time",
      "modal_add_external_text": "When you want to refer the parent to, for example, a leave form.",
      "modal_add_external_title": "Referral",
      "modal_add_sick_text": "If it's not clear when the child will return to school.",
      "modal_add_sick_title": "Reporting sick",
      "modal_children_title": "Who do you want to report absent?",
      "modal_contact_me": "I would like the teacher to contact me.",
      "modal_contact_person": "{name} would like to contact",
      "modal_description_title": "Give a short explanation to the teacher",
      "modal_external_text": "Click on the button below to go to your school's information.",
      "modal_external_title": "We ask you to report this absence in another way",
      "modal_filter_select_all": "Select all",
      "modal_filter_title": "Filter",
      "modal_reason_title": "What is the absence reason?",
      "modal_report_end": "Is {name} back at school? Fill in the end date to close the absence in ParnasSys.",
      "modal_summary_afternoon": "Absent in the afternoon.",
      "modal_summary_day": "Absent all day.",
      "modal_summary_header1": "When: ",
      "modal_summary_header2": "Reason: ",
      "modal_summary_header3": "Short explanation: ",
      "modal_summary_morning": "Absent in the morning.",
      "modal_summary_other": "{date}.",
      "modal_summary_title": "Summary",
      "modal_summary_today": "Today, {date}.",
      "modal_summary_tomorrow": "Tomorrow, {date}.",
      "modal_time_afternoon": "Afternoon",
      "modal_time_choose": "Choose a time",
      "modal_time_day": "All day",
      "modal_time_morning": "Morning",
      "modal_time_title": "How long does the absence take",
      "modal_time_today": "Today: {date}",
      "modal_time_tomorrow": "Tomorrow: {date}",
      "modal_title": "Absence",
      "modal_type_absence_text": "For planned absences. E.g. Doctors visit.",
      "modal_type_absence_title": "Absence report",
      "modal_type_external_text": "When you want to refer the parent to, for example, a leave form.",
      "modal_type_external_title": "Referral",
      "modal_type_sick_text": "If it's not clear when the child will return to school.",
      "modal_type_sick_title": "Sickness",
      "modal_type_title": "New reason",
      "modal_when_other": "Other day",
      "modal_when_title": "When is {name} absent?",
      "placeholder_ended_text": "At this time your school does not have an active Parro license, therefore this feature is not available. If you want to make full use of Parro, please renew your license. <a href=\"{url}\">Read more...</a>",
      "placeholder_ended_title": "Pilot expired",
      "placeholder_filter_text": "There are no absences based on your current filter. Edit your filter to see all absences.",
      "placeholder_no_children_text": "Ask the teacher to add you to a group.",
      "placeholder_no_children_title": "No children",
      "placeholder_no_reasons_text": "Add an absence reason, so parents can report their child absent via Parro",
      "placeholder_no_reasons_text2": "There are no absence reasons at the moment. Please check back at a later time.",
      "placeholder_no_reasons_title": "No reasons for absence",
      "placeholder_no_reports_text": "There are no absence reports at the moment. Please check again at a later time.",
      "placeholder_no_reports_title": "No absence reports",
      "reason_list_title": "Absence reasons",
      "reason_no_name": "No name configured",
      "success_send": "Absence report has been sent!",
      "toggle_setting": "Parents can report absences",
      "unknown": "unknown",
      "warning_url": "The url may be incorrect"
    },
    "account": {
      "contact_us": "Do you have any questions about Parro? Feel free to <a href={url}>contact</a> us!"
    },
    "actions": {
      "create": "Create"
    },
    "activity": {
      "activity_accept": "Accept",
      "activity_decline": "Decline",
      "activity_rsvp": "No choice has been made",
      "activity_tentative": "Tentative",
      "cancelled": "Calendar item has been cancelled",
      "cancelled_at": "Calendar item was cancelled at {date} {time}",
      "closed": "Registrations are closed",
      "committer": "Registered by: {name}",
      "concept": "This concept will only be visible for you and your colleagues",
      "confirm_cancellation": "Are you sure you want to cancel the calender item. <strong>Caution: The item will be cancelled for all receivers. You can <u>NOT</u> undo this.</strong>",
      "confirm_cancellation2": "Are you sure you want to cancel this calender item?",
      "confirm_direct_open": "You're about to open the registrations for everybody. Are you sure?",
      "confirm_registration_close": "Are you sure that you want to close the registrations? Parents won't be able to make any changes.",
      "confirm_registration_open": "You're about to open the registrations. If you want to let parents with multiple children register earlier, fill in the amount of hours below.",
      "edit_cancellation": "Delete calendar item",
      "edit_details": "Change details",
      "expired": "Calendar item is ongoing or has expired",
      "has_accepted": "Present",
      "has_declined": "Not present",
      "has_rsvp": "No choice yet",
      "has_tentative": "Might be present",
      "location": "Location",
      "modal_additional_info": "Additional information?",
      "modal_amount_invalid": "Number at volunteer or equipment must be between 1 and 100.",
      "modal_choice_activity": "Create an activity in the calendar of one or multiple groups.",
      "modal_choice_import": "Create multiple activities from an export file.",
      "modal_choice_timeslots": "Plan intervals for parents to enlist to.",
      "modal_choice_volunteers": "Create an activity and request for volunteers and equipment.",
      "modal_date_exceeds_year": "You can only create calendar items for the current school year. The school year starts on August 1st",
      "modal_date_too_long": "The calendar item can not last longer than 21 days",
      "modal_delete_title": "Delete calendar item",
      "modal_edit_calendaritem": "Edit calendar item",
      "modal_equipment": "Material",
      "modal_new_calendaritem": "New calendar item",
      "modal_organize": "Organize volunteers/equipment?",
      "modal_plan": "Plan your calendar item",
      "modal_request_rsvp": "Request participants presence",
      "modal_save_concept": "The scheduler will be added to your calendar as concept. When you're finished with evaluating, don't forget to open the registrations.",
      "modal_save_title": "Save change",
      "modal_send_info": "When you click on \"save\", the parents receive the item directly. Use the send options to edit the item.",
      "modal_send_info_time": "When you click on \"save\", the parents receive the item on {time}. Use the send options to edit the item.",
      "modal_send_reminder": "Automatically send reminder for pledged volunteers/equipment",
      "modal_send_request": "Automatically send message when there still are volunteer/equipment requests",
      "modal_title_activity": "Create activity",
      "modal_title_import": "Import calendar",
      "modal_title_timeslots": "Plan meetings",
      "modal_title_timeslots_v3": "Scheduler",
      "modal_title_volunteers": "Request volunteers",
      "modal_title_whole_day": "The whole day",
      "modal_volunteer": "Volunteers",
      "modal_warning_delete": "By signing out, your written message \"What would you like to discuss?\" will be deleted.",
      "modal_warning_recipients1": "Do you want to notify the recipients?",
      "modal_warning_recipients2": "Send notification to recipients",
      "modal_what_is_needed": "What do you need?",
      "modal_who_participates": "Recipients",
      "modified_at": "Modified at {date} {time}",
      "modified_by": "Last modified by: {name}",
      "open_scheduledate": "Sent to guardians with multiple children, other guardians will follow on {date}.",
      "organizer": "Organizer: {name}",
      "rsvp_expired": "RSVP can no longer be set",
      "seenby": "have seen this calendar item",
      "status_accepted": "You will be present",
      "status_declined": "You will not be present",
      "status_present": "{present, plural, other {#}}/{total, plural, other {#}}",
      "status_tentative": "You might be present",
      "volunteers_equipment": "Necessities"
    },
    "activity_credits": {
      "error_no_credits": "You have used up the maximum number of free calendar items for parents",
      "free_none": "You can no longer send calendar items to parents",
      "free_remaining": "You can send {number} more free calendar items to parents"
    },
    "activity_import": {
      "confirmation_text": "You are about to create {amount, plural, =1 {# calendar item} other {# calendar items}}. Check the calendar-items below.",
      "date_empty": "Date must be filled in",
      "description_empty_error": "Description must be a minimum of 1 character",
      "description_length_error": "Description can be a maximum of 512 characters",
      "download_text": "Download template to create your own list",
      "error_groups": "Could not find the group(s) in the current schoolyear. Add the groups to your account.",
      "error_import": "Something went wrong with the import. Check the dates and times.",
      "error_import_header": "Import error",
      "error_import_text": "Something went wrong while importing. Try again. If this error persists, please contact Parro support.",
      "finished_header": "The import was successful!",
      "finished_text": "The created calendar items have been sent and are shown in the agenda.",
      "help_text": "Need help or more information?",
      "import_exists_header": "Existing import",
      "import_exists_text": "You have already started importing on {date}. Continue where you left off or delete the file and start over.",
      "intro": "Parro makes it easy for you to create multiple calendar items simultaneously. You can either import the calendar from ParnasSys or make your own list using our template.",
      "label_date_from": "Date from",
      "label_date_till": "Date till",
      "label_description": "Description",
      "label_time_from": "Time from",
      "label_time_till": "Time till",
      "label_title": "Title",
      "no_import_header": "No calendar items",
      "no_import_text": "There are no calendar items. Go back to the previous screen.",
      "no_notification": "Recipients do not receive notification",
      "parnasSys_text": "Export the annual calendar from ParnasSys",
      "rule_text_1": "If you want to send a calendar item to colleagues, then the ‘Groepen’ field should be left empty",
      "rule_text_2": "The title of the calendar item is composed of the ‘Categorie’ and ‘Subcategorie’ fields.",
      "rule_text_3": "The informatie in the ‘Vak’ field will be placed in the description of calendar items.",
      "rules_header": "Here are some ground rules:",
      "time_empty": "Time must be filled in",
      "title": "Import calendar item",
      "title_empty_error": "Title must be a minimum of 1 character",
      "title_length_error": "Title can be a maximum of 255 characters",
      "upload_text": "Upload file (.xlsx)"
    },
    "activity_invitees": {
      "no_invitees": "There are no invitees - the groups are empty.",
      "no_invitees_yet": "Here you'll see all the invitees once all registrations have opened.",
      "no_invitees_yet1": "Here you'll see the first invitees once the first registrations have opened. The remaining invitees follow once all registrations have opened.",
      "no_invitees_yet2": "Here you see the first invitees. The remaining invitees follow once all registrations have opened.",
      "sending_invites1": "First registrations open at (parents with multiple children)",
      "sending_invites2": "All registrations open at"
    },
    "activity_organize": {
      "cannot_open_conversation1": "The organizer of the calendar item can start a conversation with all volunteers once there are volunteers.",
      "cannot_open_conversation2": "You can participate in this conversation once you volunteer.",
      "cannot_start_conversation": "You can start the conversation once there are volunteers. New volunteers are automatically added to the conversation.",
      "error_amount_too_small": "Entered number is too small; choose a larger number.",
      "error_commitment_full": "Maximum number has been reached; choose a lower number.",
      "error_item_expired": "Calendar item has expired, participation is no longer possible.",
      "new_volunteer_conversation": "New volunteers are automatically added to the conversation.",
      "no_commitments_yet": "There are no commitments yet",
      "select_amount": "Selecteer amount",
      "select_contribution": "Select your contribution",
      "your_contribution": "Your contribution"
    },
    "activity_plan": {
      "date_to_send": "Date to send",
      "deadline_deadline": "Deadline",
      "deadline_none": "None",
      "deadline_reminder": "Send reminder until response",
      "deadline_to_respond": "Registration deadline",
      "duration": "Duration {duration, plural, other {# minutes}}",
      "error_date": "Date can not be in the past",
      "error_duration": "Interval duration must be between 5 and 180 minutes",
      "error_number": "Number of intervals must be between 1 and 30",
      "error_pause": "Pause must be between 0 and 180 minutes",
      "invitees_all": "All registrations",
      "invitees_first": "First registrations",
      "invitees_large_families": "Start for parents with multiple children",
      "new_plan": "New plan",
      "planned_by_teacher": "Planned by {name}",
      "planned_by_you": "Planned by you",
      "please_deadline": "Dates must be before the deadline.",
      "please_future_date": "Dates must be in the future.",
      "please_interval": "Dates must be before the first interval.",
      "please_invitees_all": "'Registration opens' is required when using 'Registration parents with multiple children opens'.",
      "please_invitees_large": "'Registration opens' must be equal to or after 'Registration parents with multiple children opens'.",
      "please_no_overlap": "Please remove overlapping intervals",
      "please_no_overlap_dates": "Please change the overlapping dates.",
      "registration": "Send options",
      "select_timeslot": "Select interval",
      "send_immediately": "Immediately",
      "share_with_parents": "Share results with parents",
      "slot_duration": "Interval duration *",
      "slot_free": "Free",
      "slot_number": "Number of intervals *",
      "slot_pause": "Pause after an interval",
      "slot_reserved": "Reserved",
      "timeslots": "Intervals",
      "unplanned": "Not yet registered",
      "unplanned_no_response": "Unplanned and no response parents"
    },
    "activity_timeslot": {
      "already_reserved_child": "Sorry, this child is already registered.",
      "already_reserved_slot": "Sorry, this interval is reserved by now.",
      "button_close_information": "The registrations will be automatically closed when the first timeslot starts.",
      "commitment_changed": "A colleague has changed this calendar item.",
      "error_children_timeslots": "Pay Attention: there are not enough timeslots ({timeslots}) for the amount of children ({children})",
      "error_save_V1_V2": "Unfortunately, you can't edit this scheduler.",
      "guardians_what": "The parents want to discuss",
      "hint_children_group": "Tip: there are {amount} children in your selected group",
      "must_choose_timeslot": "You must choose an interval",
      "no_comment": "No comment",
      "no_guardians_found": "No parents found",
      "register_childs": "{amount, plural, =0 {All children are registered} other {# not registered}}",
      "register_for": "Registration for",
      "register_what": "What would you like to discuss?",
      "register_when": "Choose an interval",
      "registration": "Registration",
      "registration_closed": "Registrations are closed",
      "registration_closed2": "Registrations closed",
      "registration_only_teacher": "Registration can only be changed by the teacher",
      "registration_start": "Registration possible after",
      "success_children_timeslots": "Great, you have enough timeslots!"
    },
    "activity_v3": {
      "button_add_day": "Add day",
      "button_add_daypart": "+ Add daypart",
      "button_add_timeslot": "+ Add timeslot",
      "button_delete_day": "Delete day",
      "confirm_delete_day": "There are already children registered. Are you sure you want to delete this day?",
      "confirmation_reminder": "The reminder sent succesfully.",
      "error_add_timeslots": "Can't add anymore timeslots this day",
      "error_merged_timeslots": "Timeslots merged",
      "error_no_timeslots": "There are no timeslots",
      "error_same_day": "Date already exist",
      "error_time_daypart": "Dayparts must be in the future",
      "error_v2": "You can't edit older versions",
      "header_status": "{nChildren, plural, =0 {# children} =1 {# child} other {# children}} / {nTimeslots, plural, =0 {# timeslots} =1 {# timeslot} other {# timeslots}}",
      "header_status_registrations": "{nTimeslots, plural, =0 {# open timeslots} =1 {# open timeslot} other {# open timeslots}} / {nRegistrations} not registered",
      "label_choose_time": "Choose a new time",
      "label_edit": "Edit",
      "label_from": "From",
      "label_group": "{name}",
      "label_location": "Location",
      "label_location2": "Location {name}",
      "label_message": "Message",
      "label_multi_child_date": "On {date}",
      "label_no_group": "No group found",
      "label_opened_since": "Registrations opened at",
      "label_priority_multi": "Give parents with multiple children a head start",
      "label_subject": "Subject *",
      "label_subscribe": "Register child",
      "label_time": "Duration of the conversation *",
      "label_to": "To",
      "minutes": "{amount, plural, =1 {# minute} other {# minutes}}",
      "option_timeslot": "{date} {startTime} - {endTime}",
      "options_days": "{nDays, plural, =0 {# days before} =1 {# day before} other {# days before}}",
      "placeholder_all_registered": "All children are registered",
      "placeholder_location": "Enter a location",
      "placeholder_message": "Enter a message",
      "placeholder_no_description": "No message",
      "placeholder_no_location": "No location",
      "placeholder_no_timeslots_text": "Go back to the previous page to add new timeslots",
      "placeholder_no_timeslots_title": "No timeslots found",
      "placeholder_subject": "Enter a subject",
      "select_all_children": "(de)select all children",
      "select_children": "(de)select all children",
      "select_minutes": "{amount} minutes",
      "timeslot_available": "Available",
      "timeslot_paused": "Break",
      "title_available": "When are you available?",
      "title_children": "For which children?",
      "title_children_assign": "Register child",
      "title_children_unregistered": "Unregistered children",
      "title_deadline": "Close registrations",
      "title_deadline_edit": "Edit deadline",
      "title_deadline_new": "When do you want to close the registrations?",
      "title_deadline_reopen": "When do you want to reopen the registrations?",
      "title_default": "{name} - Parent meeting",
      "title_group": "For which group?",
      "title_move_child": "Move {child}",
      "title_options": "What do you want to do?",
      "title_overview": "Overview",
      "title_schedule": "Open registrations",
      "title_schedule_edit": "Edit opening date",
      "title_schedule_new": "When can parents register?",
      "title_subject": "What do you want to talk about?",
      "title_timeslot_info": "Information",
      "title_timeslots": "Timeslots",
      "warning_merged_timeslots": "There were already timeslots with the same start time. These timeslots are merged."
    },
    "activity_v3_status": {
      "banner_closed": "Registration closed",
      "subtext_cancelled": "Nobody can make changes.",
      "subtext_closed": "Only teachers can make changes.",
      "subtext_concept": "No openings date set.",
      "subtext_open": "Parents can register their children.",
      "subtext_planned": "The registrations are ready to sent.",
      "title_cancelled": "Status: {date} cancelled",
      "title_closed": "Status: Registration closed",
      "title_concept": "Status: Concept",
      "title_open": "Status: Registration opened",
      "title_planned": "Status: Registration planned"
    },
    "activity_v3_wizard": {
      "header_item1": "Parents with multiple children",
      "header_item2": "Open registrations",
      "header_item3": "Automatic reminder",
      "header_item4": "Close registrations",
      "header_item5": "Reopen registrations",
      "label_from": "From",
      "label_nvt": "n.a",
      "label_required": "*",
      "label_until": "Until",
      "option_text_item1": "Give parents a head start",
      "option_text_item3": "Send a automatic reminder",
      "text_item1": "Do you want to give parents with multiple children a head start?",
      "text_item2": "When are all parents allowed to register?",
      "text_item3": "You can set a date so that a reminder will automatically be sent to parents who have not yet registered for a certain time.",
      "text_item4": "You can set a time at which the registration period will close for parents. If you wish, you can reopen the registration manually at a later stage.",
      "text_item5": "The registration option will be opened immediately. When do you want to close it?",
      "title": "Open registrations"
    },
    "applicationtitle": "Parro",
    "attachments": {
      "accepts_file": "Accepts jpg, png, pdf, Word or Excel file of 5 Mb maximum",
      "accepts_picture": "Accepts jpg or png file of 5 Mb maximum",
      "attachments": "{amount, plural, =1 {Attachment } other {Attachments }}",
      "description": "{size}",
      "description_less": "Less than 1MB",
      "expires_in": "{amount, plural, =1 {expires} other {expire}} {time}",
      "has_expired": "download has expired"
    },
    "button": {
      "add": "Add",
      "add_account": "Add an account",
      "add_account_new": "Create new account",
      "add_first_reason": "Add the first reason",
      "add_group": "Connect with group",
      "add_timeslots": "Add intervals",
      "agreement": "Agree",
      "allow": "Allow",
      "back": "Back",
      "beta": "Try the beta",
      "buy": "Buy Parro",
      "cancel": "Cancel",
      "cancel2": "I understand",
      "cancel_activity": "Delete item",
      "cancel_activity_no": "No, keep item",
      "cancel_plural": "Cancel",
      "cancel_teacher": "Look around",
      "change_password": "Change password",
      "change_password_parnassys": "You can change your password in ParnasSys",
      "choose_image": "Choose photo",
      "choose_parro": "Choose Parro",
      "choose_plan": "Submit your choice",
      "close": "Close",
      "complete": "Complete",
      "configure": "Configure",
      "confirm": "Confirm",
      "continue": "Continue",
      "copy": "Copy",
      "create": "Create",
      "create_account": "Create an account",
      "create_new_account": "Create a new Parro-account",
      "deactivate": "Deactivate account",
      "decouple": "Decouple",
      "delete": "Remove",
      "delete_account": "Detach",
      "delete_account2": "Delete account",
      "delete_plural": "Remove",
      "disable": "Disable",
      "done": "Done",
      "edit": "Edit",
      "edit2": "Edit",
      "edit_data": "Edit data",
      "edit_deadline": "Edit deadline",
      "edit_schedule_date": "Edit opening date",
      "goto_login": "Go to log in",
      "goto_yearbook": "Go back to yearbook",
      "hide_conversation": "Hide this conversation",
      "i_am_guardian": "I am a parent",
      "i_am_teacher": "I am a teacher",
      "import": "Import ({amount})",
      "invite": "Invite",
      "invite_guardian": "Invite parents",
      "is_me": "Correct",
      "leave_conversation": "Leave conversation",
      "leave_group": "Leave group",
      "login": "Log in",
      "login_as_guardian": "Log in as parent",
      "login_as_teacher": "Log in as teacher",
      "login_existing_account": "Log in with your existing Parro account",
      "login_guardian": "Log in as parent",
      "login_microsoft": "Log in with Microsoft schoolportal",
      "login_ouderportaal": "Go to ouderportaal",
      "login_parnasSys": "Login with ParnasSys",
      "login_problem": "Login problems",
      "login_rety": "Log in again",
      "login_teacher": "Log in as teacher",
      "logout": "Log out",
      "mail_code": "Mail code",
      "mark_read": "Mark as read",
      "mark_unread": "Mark as unread",
      "more_info": "More info",
      "move": "Move",
      "next": "Next",
      "next2": "Continue",
      "no": "No",
      "no2": "Not now",
      "not_guardian_but_teacher": "You're not a parent but a teacher",
      "not_me": "Not me",
      "not_right": "Not right",
      "note": "Note",
      "ok": "Ok",
      "open": "Open",
      "open_conversation": "Open conversation with volunteers",
      "order": "Order Parro",
      "order2": "Order",
      "order_finish": "Finish order",
      "order_info": "Order information",
      "order_yearbook": "Order this yearbook",
      "parent": "Parent",
      "pilot": "Start a pilot",
      "pilot2": "Start a pilot",
      "plan_pilot": "Plan a pilot",
      "previous": "Previous",
      "privacy_always": "Always",
      "privacy_never": "Never",
      "privacy_period": "Period",
      "problem": "Report problem",
      "promo": "View promotion",
      "read_less": "read less",
      "read_more": "read more...",
      "read_more2": "Read more",
      "recover_account": "Recover",
      "registrations_close": "Close",
      "registrations_direct_open": "Open directly",
      "registrations_open": "Plan",
      "registrations_reopen": "Reopen registration",
      "reject": "Deny",
      "reminder": "Reminder",
      "remove_avatar": "Remove image",
      "report": "Report present",
      "request_access": "Request access",
      "resend": "Resend",
      "return_to_parro": "Return to Parro",
      "save": "Save",
      "save_concept": "Save concept",
      "schedule": "Schedule",
      "send": "Send",
      "skip": "Skip",
      "start": "Download the app",
      "start_as_guardian": "You're not a teacher but a parent",
      "start_as_teacher": "Log in as teacher",
      "start_conversation": "Start conversation with volunteers",
      "start_immediately": "Start immediately",
      "take_me": "Take me there",
      "teacher": "Teacher",
      "today": "Today",
      "try_app_decline": "Continue to use web",
      "try_app_success": "Try the app",
      "unsubscribe": "Unsubscribe",
      "upgrade": "Upgrade",
      "use_selection": "Use",
      "use_web": "Use web version",
      "yearbook_agreement": "Agreed to conditions",
      "yearbook_create": "Create new yearbook",
      "yearbook_disable": "Turn off yearbook",
      "yearbook_enable": "Enable yearbook",
      "yes": "Yes"
    },
    "conditional": {
      "and": "and",
      "comma": ",",
      "until": "until"
    },
    "contacts": {
      "choose_parent": "Choose parent",
      "converse_with_guardian": "Are you sure you want to start a conversation with {name}?",
      "couple_confirmation": "Are you sure you want to invite this parent? The parent will receive an email.",
      "couple_success": "An email has been sent to {email}",
      "couple_title": "Invite parents",
      "coupled_children": "{percentage, plural, other {#%}} of the families are reachable.",
      "coupled_to_child": "Linked parents",
      "decouple_confirmation": "Are you sure you want to decouple this parent? The parent will no longer receive announcements of this group.",
      "decouple_title": "Decouple parent",
      "invitation_expired": "Invitation expired",
      "invitation_sent": "Invitation sent",
      "invite": "Invite",
      "invited_none": "No linked parents",
      "invited_number": "{number, plural, =1 {# linked parent} other {# linked parents}}",
      "no_coupled": "There are no parents linked to {name} yet.",
      "no_parents": "No parents found",
      "number_of_guardians": "{number, plural, =1 {# Parent} other {# Parents}}",
      "participation": "Enrolled {date}",
      "send_invitation": "Send invitation",
      "start_conversation": "Are you sure you want to start a conversation?",
      "start_conversation_title": "Start conversation",
      "teacher_is_using_parro": "Coupled to Parro",
      "teacher_not_using_parro": "Is not yet using Parro"
    },
    "conversations": {
      "about_child": "Conversation about {name}",
      "add_chatroommember": "Add person",
      "already_chatroommember": "Already participates in this conversation",
      "and_leave_conversation": "Leave and then hide this conversation if you no longer want to participate.",
      "and_you": " and you",
      "bot_conversation": "App",
      "button_start_conversation": "Start conversation",
      "cannot_add_picture": "Sending pictures in a conversation is part of the paid plan. Pictures of parents can always be received. <a href={url} target='_blank'>More information.</a>",
      "change_title_header": "Change subject",
      "change_title_text": "Choose the subject of this conversation",
      "chatroom_about_child": "{value, plural, =1 {Parent} other {Parents}} {name}",
      "chatroom_about_child_short": "{value, plural, =1 {Teacher} other {Teachers}} {name}",
      "chatroom_admin_required": "There's no available admin for this conversation",
      "chatroom_exists_ask_permission": "<strong>There's already an existing child conversation about {child} with a teacher.</strong> Send a request to be added. A teacher will review your request.",
      "chatroom_exists_title": "Child conversation already exists",
      "chatroom_request_exists": "There's still an access request open",
      "chatroommember_admin": "admin",
      "chatroommember_already_present": "Person is already part of the conversation",
      "chatroommember_left": "left conversation",
      "chatroomname": "{name1} and {name2}",
      "chatroomname+": "{name1}, {name2} {remaining, plural, =0 {} =1 {and {name3}} other {and # others}}",
      "chatroomname+3": "{name1}, {name2} and {name3}",
      "chatroomname_group": "Group conversation",
      "chatroomname_you": "You",
      "child_conversation": "Child conversation",
      "child_conversation_continue": "Continue child conversation",
      "child_conversation_info": "In a child conversation you can contact multiple teacher of your child (together with your partner).",
      "child_conversation_tip": "Adding the other parents and teachers is possible.",
      "child_conversation_unavailable": "There is an existing child conversation, that doesn't include the both of you. Select the private conversation to continue.",
      "child_guardian_description": "Parent of {name}",
      "choose_persons_to_add": "Choose persons to add to the conversation",
      "chosen_group": "{group} chosen on previous window",
      "confirm_add_member": "Are you sure you want to add {name}? <br/><br/><strong>Warning:</strong> Participants who are added will see the chat history.",
      "confirm_add_members": "Are you sure you want to add these participants? <br/><br/><strong>Warning:</strong> Participants who are added will see the chat history.",
      "confirm_add_title": "Add",
      "confirm_archive": "Are you sure you want to hide this conversation?",
      "confirm_delete_member": "Are you sure you want to remove {name} from this conversation?",
      "confirm_delete_self": "Are you sure you want to leave this conversation?",
      "confirm_delete_title": "Remove",
      "confirm_delete_title_self": "Leave",
      "continue_and_add": "Add {suggestions} on the settings screen.",
      "continue_and_request_add": "Ask {admin} to add {suggestions} to this conversation.",
      "continue_conversation_about": "Do you want to continue with the existing conversation about {child}?",
      "continue_existing_conversation": "Child conversation already exists",
      "continue_existing_with": "Continue existing conversation, with the following participants: {members}",
      "continue_with_also_present": "Note: {participants} {value, plural, =1 {is also a member} other {are also members}} of this chat.",
      "conversation_about_short": "About {childname}",
      "conversation_continue": "Continue conversation",
      "conversation_with": "Conversation with",
      "conversation_with_short": "With {name}",
      "deactivated": "This conversation is inactive.&nbsp;<a>Start a new conversation.</a>",
      "deactivated_send": "The admins have left this conversation. Start a new conversation.",
      "error_left": "You are no longer part of this conversation.",
      "error_options": "Sending message failed",
      "error_options_delete": "Delete",
      "error_options_retry": "Resend",
      "error_paused": "Sending responses is disabled.",
      "existing_conversations": "You have existing conversations with these persons",
      "existing_conversations_list": "Continue existing {value, plural, =1 {conversation} other {conversations}}",
      "group_subject": "Group subject",
      "guardian_of": "Conversation with parent from",
      "hide_conversation_text": "Are you sure you want to hide this conversation? Once hidden, you cannot undo this.",
      "hide_conversation_title": "Hide conversation",
      "inactive": "Inactive",
      "inactive_guardian": "There are no active participants",
      "inactive_guardian_send": "You are viewing the archives of a deactivated conversation",
      "interaction_access_conflict": "A colleague already reviewed this request",
      "interaction_access_denied": "The request of {name} to participate in this conversation was rejected by {interactor}",
      "interaction_access_granted": "The request of {name} to participate in this conversation was allowed by {interactor}",
      "interaction_access_request_lk": "{name} wants to participate in this conversation",
      "interaction_access_request_ou": "{name} has requested access from the teachers in this conversation",
      "interaction_access_requested": "Your request has been sent!",
      "message_delete": "Delete message",
      "message_deleted_other": "This message has been removed",
      "message_deleted_you": "You have removed this message",
      "new_conversation": "New conversation",
      "new_member": "Add person",
      "new_message": "New message",
      "new_unread_messages": "{number, plural, =1 {# new message} other {# new messages}}",
      "no_conversation_participants": "No participants",
      "no_conversations": "There are no conversations with you. Once a conversation has started, you'll be able to see the messages and participants here!",
      "no_guardian_communication": "Do you want to start a conversation with these parents? Ask the teacher of the group to enable \"Contact other parents\".",
      "no_members_group_guardians": "Are the parents already connected? You can invite the parents in the group overview.",
      "no_members_guardian_children1": "No children",
      "no_members_guardian_children2": "No children were found. Ask the teacher couple you to a group.",
      "no_members_guardian_guardians1": "No parents",
      "no_members_guardian_guardians2": "No parents were found. They probably have to couple with Parro first.",
      "no_members_guardian_teachers1": "No teachers",
      "no_members_guardian_teachers2": "No teachers were found. They probably have to couple with Parro first.",
      "no_members_guardian_teachers3": "No teachers were found connected to {name}.",
      "no_members_teacher_guardians1": "No parents",
      "no_members_teacher_guardians2": "Invite the parents to use Parro and wait for them to create an account.",
      "no_members_teacher_teachers1": "No colleagues",
      "no_members_teacher_teachers2": "Do you have the Colleagues group? Then get your colleagues to start using Parro! Manage your groups in the groups overview.",
      "no_messages": "The teacher has yet to send you a message! Once the teacher sends you a message, this will be the place to look!",
      "no_teacher_communication": "This teacher has deactivated conversations",
      "no_teacher_communication2": "Do you want to start a conversation with this teacher? Ask the teacher to enable \"parents can contact me\".",
      "number_of_participants": "{number, plural, =1 {# participant} other {# participants}}",
      "number_of_volunteers": "{number, plural, =1 {# volunteer} other {# volunteers}}",
      "pause": "Replies from parents",
      "paused_teachers": "{number, plural, =1 {{name} isn't} other {# teachers aren't}} available at the moment.",
      "private_conversation": "Private conversation",
      "private_conversation_group": "Private conversation with parent from",
      "private_conversation_school": "Private conversation with staff",
      "private_conversation_with": "Private conversation with",
      "question_teacher": "I teach {childname}:",
      "school_staff": "School staff",
      "select_person": "Select a person",
      "select_persons": "Select persons",
      "selected_persons": "Selected: {persons}",
      "start_a_conversation": "Start a conversation",
      "start_a_conversation_footer": "Choose persons to start a conversation with",
      "start_a_conversation_guardian": "Do you want to start a conversation with these parents?",
      "start_a_conversation_n_others": "and {value, plural, =1 {# other…} other {# others…}}",
      "start_a_conversation_selected": "{selected} and {value, plural, =1 {# other…} other {# others…}}",
      "start_conversation": "Start conversation",
      "start_group": "Start group conversation",
      "start_one_to_one": "Start 1-on-1 conversation",
      "start_with_parent": "Start conversation with parent",
      "start_with_teacher": "Start conversation with teacher",
      "status_archived": "The conversation is archived. Send a message to reactivate the conversation.",
      "status_bot": "The replies are disabled",
      "status_changed_title": "{name} has changed the subject",
      "status_entered": "{name} now participates in this conversation",
      "status_left": "{name} has left the conversation",
      "status_left_bar": "You are no longer part of this conversation",
      "status_paused": "{name} has paused replies from parents",
      "status_paused_bar": "The replies are paused",
      "status_start": "This is the start of your conversation with {name}",
      "status_unknown": "» Oops, status {status} has yet to be translated «",
      "status_unpaused": "{name} has resumed replies from parents",
      "status_update_app": "In order to display this message, you'll need to update the app",
      "subtitle_group_default": "Click for participants",
      "subtitle_single_default": "Click for information",
      "superteam_complete": "Your superteam is complete!",
      "superteam_complete_subtext": "All teachers and parents of {child} are added",
      "teachers_about": "Conversation with teacher(s) about",
      "teachers_of": "Conversation with teacher(s) of",
      "write_message": "Write a message to {recipients}"
    },
    "conversations_dialog": {
      "contact_not_possible": "Sorry, it is not possible to start a conversation with this person.",
      "guardian_contact_not_allowed": "Would you like to start a conversation with {name}? Ask the teacher of the group of {name} to enable \"Start a conversation with another parent\".",
      "teacher_contact_not_allowed": "Would you like to start a conversation with this teacher? Ask {name} to enable \"Start a conversation with me\"."
    },
    "date": {
      "today": "Today",
      "tomorrow": "Tomorrow",
      "yesterday": "Yesterday"
    },
    "daysofweek": {
      "friday": "Fr",
      "monday": "Mo",
      "saturday": "Sa",
      "sunday": "Su",
      "thursday": "Th",
      "tuesday": "Tu",
      "wednesday": "We"
    },
    "delete": {
      "confirmation_text": "The account is deactivated and will be deleted within 30 days. In 5 seconds you will be logged out.",
      "header": "Permanent delete account",
      "header2": "Delete my account",
      "terms": "I understand that this process cannot be undone and that the access to my Parro account is permanently denied.",
      "text": "When you delete your account you will not be able to access Parro anymore at once. Also you will not receive any mails from Parro. Your data will be deleted after the period as specified in the user agreement.",
      "toggle_header": "What happens when I choose for permanent removal?",
      "toggle_text1": "Your personal data will be deleted. This includes your first and last name, e-mail address and profile picture (if you have set this up).",
      "toggle_text2": "You are no longer linked to your child (if you have a parent account), your groups or your school. As a result, your read statuses of announcements are no longer transparent to others.",
      "toggle_text3": "The removal process will start within 30 days. It can then take up to 30 days for this data to be removed from our backups.",
      "toggle_text4": "Information that you have explicitly sent to others will still exist. This includes, for example, messages in conversations. Your name will appear as 'Deleted user' in these places.",
      "toggle_text5": "You will no longer have access to photos and other important information shared via Parro. The photos you want to keep must first be downloaded and stored outside Parro for personal use.",
      "toggle_text6": "Please note, this process can not be undone and your access to your Parro account is permanently denied."
    },
    "developmentinfo": {
      "parroUrl": "Parro backend URL:",
      "title": "Development information"
    },
    "digest": {
      "option_1": "Straight away",
      "option_2": "Daily",
      "option_3": "Never",
      "text": "You receive a summary of missed notifications by e-mail.",
      "text_disable": "The option to disable the e-mail completely is available in the <a href=\"https://parro.com/android\" target=\"_blank\">Android</a> or <a href=\"https://parro.com/ios\" target=\"_blank\">iOS</a> app.",
      "title": "Reminder by e-mail"
    },
    "donotdisturb": {
      "benefit_1": "No pushnotifications: that means no sound, only a unread counter",
      "benefit_2": "Parents and colleagues can request the times of your silent mode",
      "benefit_title": "Outside these hours:",
      "days": "Days",
      "description": "You will receive notifications during the following hours.",
      "hours": "Hours",
      "no_days": "No days chosen",
      "show_availability": "Show availability",
      "what_is": "You can reach {name} during the following hours. Outside these hours your messages will still arrive, but notifications follow afterwards."
    },
    "emoji": {
      "activities": "Activities",
      "animals": "Animals",
      "flags": "Flags",
      "food": "Food",
      "not_found": "No emojis found",
      "objects": "Objects",
      "people": "People",
      "recents": "Recent",
      "smileys": "Smileys",
      "symbols": "Symbols",
      "travel": "Travel"
    },
    "error": {
      "absence_settings": "Something went wrong while retrieving the absence settings",
      "account": {
        "already_linked_invite_code": "You are already linked to the child and the associated group.",
        "email_already_in_use": "There is already an active account with this e-mail address.",
        "email_invalid": "The given e-mail address is invalid.",
        "email_required": "E-mail address is required.",
        "emails_not_the_same": "The e-mail adresses do not match.",
        "forgot_password": "An unexpected system error occurred.",
        "incomplete_account_data": "Account data is incomplete.",
        "no_valid_verification_code": "The verification code is invalid.",
        "parnassys_email_not_send": "Sending the email has failed. Please try again later.",
        "reset_password_code_invalid": "The code to reset your password is no longer valid.",
        "weak_password": "The entered password must consist of at least 8 characters."
      },
      "amazon_upload_failure": "Uploading file failed. Please check if the file is undamaged and try again.",
      "authentication": {
        "account_for_guardian_error": "You cannot use your parent-account to log in as teacher.",
        "account_for_teacher_error": "You cannot use your teacher-account to log in as parent.",
        "account_ie10_error": "Username or password incorrect. Did you try to log in for 3 consecutive tries? Then due to security concerns you won't be able to log in for 10 minutes.",
        "account_locked": "Your account is currently locked. Please try again in {minutes, plural, =1 {# minute} other {# minutes}} or use 'Did you forget your password?'",
        "account_retries": "Username or password incorrect, {number, plural, =1 {# retry} other {# retries}} left.",
        "not_authorized": "This account has insufficient privileges.",
        "parnassys_credentials_error": "ParnasSys-credentials are incorrect.",
        "parnassys_email_conflict": "Your e-mail address is already in use by an existing account. Change your e-mail address in ParnasSys.",
        "parnassys_not_available": "Cannot reach ParnasSys. Please try again later.",
        "parnassys_username_conflict": "Your username is already in use by an existing account.",
        "timeout": "Logging in took too long. Please try again later.",
        "token_error": "Unknown token / user not found. Please try again.",
        "token_unavailable": "(Parro)token unavailable",
        "user_unavailable": "Account was not found or account has been deactivated.",
        "username_password_error": "Username or password incorrect.",
        "username_password_not_available": "Username and password are both required."
      },
      "back_to_login": "Back to log in page",
      "browser_text": "This browser is no longer supported. This means that this browser is no longer safe and some functionalities will no longer work. Use <a href=\"https://www.google.com/intl/en_en/chrome/\"> Google Chrome </a> or <a href = \"microsoft-edge: https: //talk.parro.com\" > Microsoft Edge </a>.",
      "browser_title": "Browser is not supported",
      "commitment_status_deadline": "Remove the timeslots that are already expired.",
      "default": "Oops, something went wrong.",
      "failed_add_body": "Error when creating yearbook: ADD_CONTENT_ERROR.",
      "failed_blob": "Error when creating yearbook: HTML_ERROR.",
      "failed_css_file": "Error when creating yearbook: GET_CSS_ERROR",
      "failed_empty_body": "Error when creating yearbook: EMPTY_CONTENT_ERROR.",
      "failed_get_body": "Error when creating yearbook: GET_CONTENT_ERROR.",
      "failed_iframe": "Error when creating yearbook: TEMPLATE_ERROR",
      "failed_styling": "Error when creating yearbook: ADD_CSS_ERROR",
      "feature_disabled": "This functionality is currently disabled.",
      "import_groups_not_found": "The groups circled in red couldn’t be found. Click and remove these and select the correct groups",
      "import_no_groups": "This calendar item has no groups. Select the groups by using the plus icon.",
      "invalid_attachment_listsize": "No more than 30 attachments can be added.",
      "invalid_filetype": "Only accepts jpg, png, pdf, Word, Excel or video files.",
      "invalid_filetype_import": "Only accepts Excel files.",
      "invalid_filetype_new": "Only accepts jpg, png, pdf, Word, Excel, video or audio files.",
      "invalid_image": "An image cannot be added.",
      "invalid_max_filesize": "File is too large. ({amount})",
      "invalid_max_picturesize": "Image is too large. (max. 5MB)",
      "invalid_min_filesize": "File is empty.",
      "invalid_min_picturesize": "Image is empty.",
      "invalid_name_length": "Filename can be a maximum of 80 characters.",
      "invalid_picture_listsize": "No more than 30 images can be added.",
      "invalid_picturetype": "Only accepts jpg or png images.",
      "invalid_time": "Entered time is invalid",
      "localstorage": "This browser does not support the functions to login. Use another browser.",
      "maillength_exceeded": "Email address can be a maximum of 50 characters.",
      "maxlength_exceeded": "Input can be a maximum of 80 characters.",
      "no_internet": "It looks like you don't have an internet connection.",
      "nonunique_file": "File has already been added.",
      "nonunique_picture": "Image has already been added.",
      "privacy_settings": "Something went wrong while retrieving the privacy settings",
      "refresh": "Refresh the page",
      "startdate_enddate": "Ending date must be on or after starting date",
      "startdate_now": "Starting date must be in the future",
      "starttime_endtime": "Ending time must be on or after starting time",
      "starttime_endtime2": "Ending time must be after starting time",
      "starttime_now": "Starting time must be in the future",
      "support_xlsx": "Import is not supported in this browser.",
      "support_xlsx_populate": "Downloading .xlsx files is not supported in this browser.",
      "timeslot_date_now": "Starting time of the conversations must be in the future.",
      "too_many_attachments": "You can send a maximum of {maxPictures, plural, =1 {# picture} other {# pictures}} each time.",
      "unauthorized": "Oops, it looks like you don't have the permission to perform this action.",
      "unknown_error": "Unexpected error occurred.",
      "userdata": {
        "notfound": "User data not found"
      },
      "validation": {
        "all_fields_required": "All fields marked with '*' are required",
        "current_password_incorrect": "The current password is incorrect.",
        "email_too_long": "The email address is too long.",
        "invalid_values": "Invalid value(s) were entered",
        "old_password_incorrect": "The old password is incorrect.",
        "password_incorrect": "The password is incorrect.",
        "passwords_new_not_the_same": "The entered new passwords do not match.",
        "passwords_not_the_same": "The entered passwords do not match.",
        "value_too_long": "The value is too long."
      },
      "yearbook_housenumber": "Check your house number. A house number should start with a numerical value.",
      "yearbook_order_exists": "The order already exists. Refresh the page.",
      "yearbook_postalcode": "Check your postal code. A postal code consists of 4 numbers and 2 letters.",
      "yearbook_unknown_error": "Unexpected error occurred. Try again later."
    },
    "free_dialog": {
      "paragraph1": "Dear Parro user,",
      "paragraph2": "From August 1 2018 there is going to be a change in the use of Parro. The current free licence will end at that date. You have probably already been informed about this via one of our service messages.",
      "paragraph3": "It is important to let us know if you want to keep using Parro at your school.",
      "paragraph4": "Have you already informed us about your plans for upcoming school year? Then you do not have to take any more action.",
      "paragraph5": "Team Parro",
      "status_text": "Free version is ending"
    },
    "group_announcements": {
      "confirm_archived_text": "The group may have been archived. Check with Synchronize & Manage to see if you are associated with this group.",
      "confirm_archived_title": "Group not found",
      "confirm_delete_other_text": "Only {name} may remove the announcement.",
      "confirm_delete_text": "You're about to delete this announcement. Are you sure?",
      "confirm_delete_title": "Delete announcement",
      "delete_announcement": "Delete",
      "deleted_announcement_other": "This announcement has been removed",
      "deleted_announcement_you": "You have removed this announcement",
      "dummy1": "We are looking for two more volunteers that can drive to the soccer tournament next week Wednesday between 3 and 3:30 PM. Who can help us? Send me a message.",
      "dummy2": "The trip to the playground was very successful! I want to thank everyone that helped prepare and on the day itself. As you can see everyone had a great time.",
      "dummy3": "Rik has lost his schoolbag! Has anyone found it? The bag is yellow with a red zipper. At the back there's an image of a very pretty parrot.",
      "dummy_guardian": "Welcome in the group! Here you'll receive the announcements that the teacher sends to the group. You will only see announcements that were sent after you coupled with this group. Shown below are examples of possible announcements. These disappear automatically once you receive a real one.",
      "dummy_teacher": "Shown below are examples of possible announcements. The examples disappear automatically once you have received or written an announcement. Are you ready? Press the button in the top right to compose and send your first announcement.",
      "edit_announcement": "Edit",
      "edited_date": "(edited on {date})",
      "edited_name_date": "(edited by {name} on {date})",
      "like": "Like",
      "like_information": "Recipients can give \"a like\"",
      "new_announcement": "New announcement",
      "new_announcement_recipients": "Choose recipients",
      "new_scheduled_announcements": "{amount, plural, =0 {No scheduled announcements} =1 {# scheduled announcements} other {# scheduled announcements}}",
      "readby": "{read, plural, other {#}} of {total, plural, =1 {# parent} other {# parents}}",
      "repeat_announcement": "Resend",
      "repeat_date": " - Repeat of {date}",
      "schedule_time": "When will the announcement be send?",
      "scheduled": "Scheduled on",
      "sender": "the sender",
      "write_label": "Announcement *",
      "write_placeholder": "Write an announcement to {numberOfRecipients, plural, =1 {# recipient} other {# recipients}} *",
      "write_title": "Title (optional)"
    },
    "group_contacts": {
      "header": "Manage parents",
      "no_contacts_found1": "No contacts",
      "no_contacts_found2": "This group has no contacts. Place children in this group in ParnasSys and synchronize this with Parro."
    },
    "group_settings": {
      "delete_group": "Announcements and photos longer available",
      "delete_group_header": "Data availability",
      "delete_group_modal": "After many reactions from parents, we have decided to keep the groups of school year {schoolYear} longer available. So even after August 1 2019 you can look back at the announcements and photos from that school year.",
      "footnote": "Enable parents to start 1-on-1 conversations to easily stay in touch with you and other parents.",
      "header": "Parents of this group may...",
      "modal_option_text": "<strong>Are you sure you want to leave this group? You will no longer be able to:</strong> <br/><br/> &#8226 Receive or view announcements, pictures, or calendar items. <br/><br/> &#8226 Contact teachers or parents of this group.",
      "modal_title": "Leave group",
      "option_start_me": "Start a conversation with me",
      "option_start_other": "Start a conversation with another parent"
    },
    "infinity": {
      "key_announcements": "announcements",
      "key_contacts": "contacts",
      "key_conversations": "conversations",
      "key_messages": "messages",
      "key_participants": "participants",
      "load_more": "Show more {key}",
      "loaded_all": "» All {key} are being shown «",
      "loading_more": "Retrieving more items..."
    },
    "interval": {
      "every_x_days": "{x, plural, =1 {Every day} other {Every # days}}",
      "every_x_weeks": "{x, plural, =1 {Every week} other {Every # weeks}}",
      "never": "No reminder"
    },
    "invite_contacts": {
      "amount_invited": "{amount, plural, =1 {# parent} other {# parents}}",
      "description_decoupled": "The following parents have left the Parro group or have been removed from the group. Select them here to invite them anyway. <a href={url} target='_blank'>More info...</a>",
      "description_no_email": "The following parents cannot be invited, because no email address is known in ParnasSys. Register an email address in ParnasSys to be able to invite these parents anyway. <a href={url} target='_blank'>More info...</a>",
      "description_no_permission": "The following parents have access restrictions according to ParnasSys. Select them here to invite them anyway. <a href={url} target='_blank'>More info...</a>",
      "header_decoupled": "Give unconnected parents access",
      "header_no_email": "Email address unknown",
      "header_no_permission": "Lift access restrictions",
      "modal_no_email": "There is no e-mail address known yet. Add an e-mail address in ParnasSys. After that you can invite {name}.",
      "modal_no_email_title": "Add e-mail address in ParnasSys",
      "modal_not_allowed_text": "{guardian} cannot be invited for {child}. In ParnasSys is set that {guardian} is not allowed to be invited. Check the parent settings in ParnasSys.",
      "modal_not_allowed_title": "No permission to invite",
      "modal_start_description": "Select the parents you want to add to the group.",
      "modal_title": "Grant access",
      "no_guardians_text": "Check in ParnasSys whether the data is correct.",
      "no_guardians_title": "No parents",
      "placeholder_no_invitable_text": "All parents in this group are linked to a child.",
      "placeholder_no_invitable_title": "All parents are reachable",
      "placeholder_success_text": "Selected parents have been invited and can use Parro.",
      "placeholder_success_title": "Parents are invited",
      "placeholder_text": "Every child has at least 1 connected parent. Connect additional parents manually.",
      "placeholder_title": "Congratulations!",
      "suffix_added": "{amount, plural, =1 {has} other {have}} been added to the group",
      "suffix_invited": "{amount, plural, =1 {has} other {have}} been invited by mail for Parro",
      "suffix_problem": "could not be given access. Look below to see how you can invite them."
    },
    "login": {
      "choose_login_as": "Choose the school to log in",
      "create_new_account": "Create a new Parro account",
      "make_your_choice": "Choose whether you are a teacher or parent",
      "not_a_guardian": "I have no parent account and want to ",
      "not_a_teacher": "I have no teacher account and want to ",
      "placeholder": "Username / email address",
      "privacy_policy": "PRIVACY POLICY PARRO",
      "teacher": "Log in as teacher",
      "thumbnail_logging_in": "Parro is getting ready!",
      "thumbnail_logging_out": "Your account is being logged out",
      "title_suffix": "in Parro",
      "title_undefined": "Log in as..",
      "want_to_know_more": "Want to know more about Parro? Continue reading at <a href=\"https://www.parro.com\" target=\"_blank\">www.parro.com</a>"
    },
    "login_error": {
      "account_forgotten_description": "You have canceled your Parro account. You have the option to undo the deletion until <strong><u> {date} </u> </strong>. After this, your data will be permanently deleted. ",
      "account_forgotten_description_old": "You have canceled your Parro account. This is in the 30-day reflection period. After this period, your data will be permanently deleted. If you want to cancel the cancellation, please contact support@parro.com.",
      "account_forgotten_header": "Account canceled",
      "account_inactive_description": "Your account has been blocked. If you wish to reverse this, please contact support@parro.com.",
      "account_inactive_header": "Account blocked",
      "account_lab_description": "You're trying to login with a LAB account. You can only log in with an employee or parent account. Go to ParnasSys and log out.",
      "account_lab_header": "Wrong login",
      "guardian_account_not_allowed_description": "You're trying to login as teacher with a parent account. If you're already logged in to the 'Ouderportaal', you must first logout before you can log in to Parro.",
      "guardian_account_not_allowed_header": "Wrong login",
      "no_organisation_for_user_description": "Your account has no active 'aanstellingen'. Check in ParnasSys if you have active 'aanstellingen' and also check if you're not a monitor of the 'aanstellingen'.",
      "no_organisation_for_user_header": "No active 'aanstellingen' ",
      "parnassys_not_available_description": "ParnasSys currently has maintenance. Please try again later.",
      "parnassys_not_available_header": "ParnasSys is not available"
    },
    "menu": {
      "absence_guardian": "Report absence",
      "absence_teacher": "Absence overview",
      "absence_teacher_subtitle": "{amount, plural, =1 {# absentee} other {# absentees}} today",
      "account_parentverified": "Parent account verified",
      "account_registered": "Account registered",
      "account_registration": "Account registration",
      "account_verification": "Account verification",
      "actions": "Actions",
      "announcement_recipients": "Announcement read",
      "calendar": "Calendar",
      "calendar_info": "See how the calendar works",
      "calendar_item_cancelled": "Calendar item is cancelled",
      "calendar_item_child_committed": "Booked on {data}",
      "calendar_item_closed": "Registrations closed",
      "calendar_item_concept": "Concept",
      "calendar_item_deadline": "You can't register anymore",
      "calendar_item_finished": "Gotcha! All volunteers and materials are completed",
      "calendar_item_no_planned_items": "Today: no items planned",
      "calendar_item_open": "Registrations opened",
      "calendar_item_planned": "Registrations planned",
      "calendar_item_present": "Are you attending?",
      "calendar_mark_as_read": "Mark calendar as read",
      "calendar_none_button": "Create new calendar item",
      "calendar_none_descriptionguardian": "The teachers have not yet created calendar items for your groups.",
      "calendar_none_descriptionteacher": "Do you want to create a calendar item for your group(s)?",
      "calendar_none_title": "No calendar items",
      "calendar_planning_info": "See how it works",
      "calendar_question_available": "Are you present?",
      "calendar_question_both": "Can you help?",
      "calendar_question_commit": "Register",
      "calendar_question_material": "Can you help with materials?",
      "calendar_question_volunteer": "Can you help with volunteers?",
      "calendar_scroll_button": "Scroll to today",
      "calendar_text_both": "You are helping with volunteers and materials",
      "calendar_text_material": "You are helping with materials",
      "calendar_text_volunteer": "You are helping with volunteers",
      "content_search_pilot_info": "We are currently testing search functionality for Parro. This is a temporary available feature.",
      "conversation_messages": "Messages",
      "conversation_participants": "Participants",
      "conversation_participants_amount": "Participants: {value}",
      "conversations": "Conversations",
      "conversations_edit_title": "Edit title",
      "conversations_none_descriptionguardian": "Do you want to start a conversation?",
      "conversations_none_descriptionguardian_nope": "You have no active conversations. This will the place to look once a teacher starts a conversation with you.",
      "conversations_none_descriptionteacher": "Do you want to start a conversation with (for example) some colleagues?",
      "conversations_none_title": "No active conversations",
      "conversations_reactions": "Reactions from parents",
      "conversations_reactions_off": "Turn off reactions of parents",
      "conversations_reactions_on": "Turn on reactions of parents",
      "group_add_new_groups": "Time to add new groups to your Parro-account.",
      "group_announcements": "Announcements",
      "group_colleagues": "Colleagues",
      "group_newyear": "It's the beginning of a new school year!",
      "group_none_chosen": "No new groups have been chosen",
      "group_selection": "Select new groups",
      "group_settings": "Settings",
      "groups": "Groups",
      "groups_none_button": "Import from ParnasSys",
      "groups_none_descriptioncalendar": "Go to synchronize & manage to add groups.",
      "groups_none_descriptioncode": "You are not yet part of a group in the current school year. Wait for an invitation.",
      "groups_none_descriptionguardian": "Ask the teacher to add you to a group.",
      "groups_none_descriptionteacher": "Shall we search for your groups?",
      "groups_none_schoolyear_title": "No children in school year {year}/{nextyear}",
      "groups_none_title": "No groups found...",
      "news": "Parro news",
      "reset_password": "Request new password",
      "settings": "Settings",
      "settings_feedback_support": "Feedback and support",
      "settings_logout_confirm_header": "Logout",
      "settings_logout_confirm_text": "Are you sure you want to logout?",
      "settings_markallasread": "Mark all items as read",
      "settings_markallasread_amount": "{amount} unread items",
      "settings_new": "New",
      "settings_pilot": "Pilot",
      "settings_policy": "Privacy policy Parro",
      "settings_privacy": "Privacy centrum",
      "settings_school": "School settings",
      "settings_settings": "Settings",
      "settings_yearbook": "Parro Yearbook",
      "settings_yearbook_disabled": "Parents can't create a yearbook",
      "settings_yearbook_enabled": "Parents can create a yearbook",
      "share_feedback": "Share your feedback here."
    },
    "messages": {
      "confirm_close": "Your changes will be lost. Close anyway?",
      "confirm_close_title": "Close",
      "contribution_updated": "Your contribution is saved.",
      "save_succcessful": "Saved changes."
    },
    "multi_account": {
      "switch_to": "You are redirected to the account: {account}",
      "switching_to": "Redirecting to "
    },
    "news": {
      "intro_header": "What's new",
      "intro_text": "Updates on new Parro features, maintenance and future topics.",
      "no_items": "There is no news at the moment. When there is, you will see them here.",
      "no_title": "No title"
    },
    "pagetitle": {
      "404": "Oops...",
      "absence": "Absence",
      "accounts": "Accounts",
      "add_account": "Add an account",
      "add_group": "Connect with group",
      "announcements": "Announcements",
      "authenticate": "Authenticate",
      "calendar": "Calendar item",
      "colleagues": "Colleagues",
      "delete": "Delete",
      "donotdisturb": "Silent mode",
      "getting_started": "Getting started",
      "ideas_forum": "Submit an idea",
      "import": "Import",
      "index": "Welcome",
      "invitees": "Invitees",
      "license": "License",
      "login": "Log in",
      "login_as_guardian": "Log in as parent",
      "login_as_teacher": "Log in as teacher",
      "news": "Parro News",
      "notifications": "Notifications",
      "pilot": "Pilot",
      "pilot_start": "Start a pilot",
      "privacy_preferences": "Privacy preferences",
      "profile": "Profile",
      "recover_account": "Recover account",
      "scheduled_announcements": "Scheduled announcements",
      "settings": "Settings",
      "support": "Parro Support",
      "timeslot": "Registration details",
      "volunteers": "Volunteers",
      "yearbook": "Yearbook",
      "yearbook_enable": "Enable Parro Jaarboek",
      "yearbook_parro": "Parro Yearbook",
      "yearbook_type": "Choose book type"
    },
    "parent": {
      "children": "My children",
      "groups": "My groups"
    },
    "parro": {
      "choose_number": "Choose number",
      "colleagues": "Colleagues",
      "coming_soon": "Coming soon",
      "deleted_at": "Deleted at {date} by {name}",
      "example": "Example",
      "groups": "Groups",
      "guardian": "Parent",
      "guardians": "Parents",
      "inactive": "(inactive)",
      "last_minute": "Last minute",
      "modified_at": "modified at",
      "new": "new!",
      "school_year": "School year {year}",
      "teacher": "Teacher",
      "teachers": "Teachers",
      "this_is_you-capitalized": "You",
      "this_is_you-long": "this would be you",
      "this_is_you-short": "you",
      "this_is_you-short2": "you",
      "unknown": "unknown"
    },
    "photoswipe": {
      "close_button": "Close (Esc)",
      "download_image_button": "Download image",
      "fullscreen_button": "Toggle fullscreen",
      "next_button": "Next (arrow right)",
      "no_image_error": "<a href=\"%url%\" target=\"_blank\">The image</a> could not be loaded.",
      "options_button": "Options",
      "prev_button": "Previous (arrow left)",
      "zoom_button": "Zoom in/out"
    },
    "pilot": {
      "connect_parents": "Parents of {amount} children are ready to be contacted",
      "connect_parents_description": "Start a pilot to contact the parents today. <a href={url}>Read more...</a>.",
      "during_pilot": "During the pilot",
      "during_pilot_description": "Do you have any questions or do you want to know how something works? Here you find all the <a href={url} target='_blank'>information</a>.",
      "end_date": "End date:",
      "modal_header_information1": "Try out Parro",
      "modal_header_information2": "Invite parents later",
      "modal_information1": "Gather you colleagues in Parro and try it for free.<br/> <br/> Are you ready to invite the parents? Start a Parro pilot.",
      "modal_information2": "You can always start the pilot for free via settings > pilot.",
      "no_end_date": "No end date",
      "notification_ended": "The pilot has ended!",
      "notification_started": "The pilot has started!",
      "parro_pilot": "Parro pilot",
      "period": "Period",
      "period_description": "The pilot will apply for the whole school and has a duration of 2 months.",
      "pilot_ended": "Pilot Ended",
      "pilot_ended_description": "The pilot ends automatically. Do you like Parro and you want to keep being in touch with the parents? Then choose for Parro.",
      "prepare": "Prepare the school",
      "prepare_description": "Download: the starter package with tips & tricks, a guide, presentations for colleagues and parents, instruction videos and more. <br/> <br/> <a href={url} target='_blank'>parro.com/tools-teacher</a>",
      "pricing_plan_ended_error": "The pilot has ended",
      "read_more_subtext_parent": "Ask your school how the teacher parent communication will take place in the future.",
      "read_more_subtext_teacher": "You can still use Parro with colleagueas. Do you want to communicate with parents again?",
      "read_more_text": "On this moment your school has no active Parro-license. You will no longer be able to communicate between parents and teachers.",
      "start": "Ready to invite the parents?",
      "start_a": "Start a",
      "start_alternative": "Other start date or wishes",
      "start_alternative_description": "Are there any specific wishes? Use the special <a href={url} target='_blank'>application form</a>",
      "start_description": "Try Parro for 2 months free with the parents.<br/> <br/> The pilot will apply for the whole school. All functions are available and the amount of groups is unlimited.",
      "start_error": "The pilot has started. Maybe a colleague has started the pilot.",
      "started": "{amount} days remaining",
      "started2": "{amount} days remaining",
      "started_no": "Not yet started",
      "starter_kit": "Starter kit",
      "starter_kit_description": "Inform colleagues and parent with the useful <a href={url} target='_blank'>starter kit</a>.",
      "terms_agreement": "Terms of Agreement",
      "terms_agreement_accept": "I accept the terms",
      "terms_agreement_description": "By starting the pilot you agree with the <a href={url} target='_blank'>Terms of Agreement</a> of Parro."
    },
    "pilot_status_bar": {
      "calendar_ended": "The {organisation} has no license",
      "calendar_teacher_ended": "You are only able to create calendar items for the colleague group.",
      "invite_parents_ended": "You can not invite parents anymore, because you no longer have a license.",
      "license_parent_ended": "The school license has expired.",
      "license_teacher_ended": "The license has expired.",
      "select_group_ended": "You can only select the colleague group."
    },
    "placeholder": {
      "add_attachment": "Add message to attachment",
      "at": "At",
      "choose_groups": "Choose group(s)",
      "choose_reason": "Choose a ParnasSys reason",
      "choose_recipients": "Choose recipients",
      "date": "Date",
      "date_from": "Start date",
      "date_range": "Start  |  End",
      "date_till": "End date",
      "description": "Description",
      "display_guardian": "Display parents",
      "display_name": "Display name",
      "email": "E-mail address",
      "explanation": "Explanation",
      "explanation_add": "Would you like to add something extra?",
      "external_url": "External url",
      "extra_information": "Extra information",
      "fill_in_date": "Choose a date",
      "firstname": "Your first name",
      "location": "Location",
      "new_announcement": "Write an announcement ...",
      "new_message": "Write a message ...",
      "new_message_enter": "Write a message. Use Shift + Enter for a new line.",
      "new_note": "Add a note",
      "note": "Note",
      "or": "OR",
      "password": "Password",
      "password_parnassys": "ParnasSys password",
      "reason": "ParnasSys reason",
      "reminder": "Reminder",
      "repeat": "Repeat",
      "slot_duration": "Fill in the duration (5 - 180 min)",
      "slot_number": "Fill in the number (max 30)",
      "slot_pause": "Fill in the duration (0 - 180 min)",
      "surname": "Your surname",
      "time": "Time",
      "time_choose": "Choose a time",
      "time_from": "Start time",
      "time_till": "End time",
      "title": "Fill in a title",
      "username_guardian": "E-mail address",
      "username_parnassys": "ParnasSys username"
    },
    "placeholder_required": {
      "choose_groups": "Choose group(s) *",
      "choose_recipients": "Choose recipients *",
      "date": "Date *",
      "date_from": "Start date *",
      "date_till": "End date *",
      "description": "Description *",
      "email": "E-mail address *",
      "equipment": "Specify equipment *",
      "firstname": "Your first name *",
      "group_subject": "Groupsubject",
      "interval": "Interval *",
      "location": "Location *",
      "new_announcement": "Write an announcement ... *",
      "password": "Password (min. 8 characters) *",
      "recipients": "Recipients *",
      "repeatemail": "Repeat E-mail addres *",
      "repeatpassword": "Repeat password (min. 8 characters) *",
      "subject": "Subject *",
      "surname": "Your surname *",
      "time": "Tijd *",
      "time_end": "End time *",
      "time_start": "Start time *",
      "title": "Title *",
      "volunteer": "Specify volunteer *"
    },
    "print": {
      "activity_comment": "Comment",
      "activity_name": "Name",
      "activity_time": "Time"
    },
    "privacy": {
      "consent_no": "No",
      "consent_unknown": "Not registered by parents",
      "consent_yes": "Yes",
      "guardian_description_closed": "Editing is disabled",
      "guardian_description_closed_period": "Editable from {date}",
      "guardian_description_open_period": "Editable till {date}",
      "header_guardians": "Parents may edit preferences:",
      "header_more_info": "More information?",
      "option_now": "Now editable",
      "option_period": "Editable during period",
      "overview": "Privacy preferences overview",
      "placeholder_colleague_text": "The colleague group does not contain parents. If you want to see the privacy preferences add a group with parents. This gives you insight into what parents have given permission for and who is allowed in the photo. Parents can set their preferences through Parro, which is automatically registered in ParnasSys.",
      "placeholder_colleague_title": "Colleagues",
      "placeholder_no_children_text": "Add children to these group(s) in ParnasSys, so parents can set the privacy preferences. This gives you insight into what parents have given permission for and who is allowed in the photo. Parents can set their preferences through Parro, which is automatically registered in ParnasSys.",
      "placeholder_no_children_title": "No children found",
      "placeholder_no_guardians_text": "Privacy preferences can only be shown if the conversation contains parents.",
      "placeholder_no_guardians_title": "No parents found",
      "placeholder_no_preferences_text": "Wondering who is allowed on the photo? Privacy preferences give you insight what parents have given permission for. Parents can set their preferences through Parro, which is automatically registered in ParnasSys.",
      "placeholder_no_preferences_title": "No privacy preferences found",
      "placeholder_no_selection_text": "Wondering who is allowed on the photo? Privacy preferences give you insight what parents have given permission for. Choose which privacy preference you want to see above.",
      "placeholder_no_selection_title": "No privacy preference selected",
      "placeholder_selection": "Choose a privacy preference",
      "preference_error": "Something went wrong when retrieving the privacy preferences. Contact the Parro support.",
      "preferences": "Privacy preferences",
      "teacher_description_closed": "Parents can't edit",
      "teacher_description_closed_period": "Parents can edit from {date}",
      "teacher_description_open": "Parents can edit",
      "teacher_description_open_period": "Parents can edit until {date}",
      "text_edited_by": "Last edited: {name}",
      "text_more_info": "Do you have any questions or do you want to know how it works? You find <a href=\"https://support.parro.com/hc/nl/articles/360005441071-Parro-in-actie-Privacy-voorkeuren-registeren-door-ouders\" target\"_blank\">here</a> all the information.",
      "text_school": "These settings applies to the entire school.",
      "title": "Privacy",
      "title_modal": "Who is allowed on the photo?"
    },
    "profile_children": {
      "privacy_preferences": "Privacy preferences",
      "privacy_preferences_text": "Set the privacy preferences.",
      "title": "My Children"
    },
    "profile_personal": {
      "currentpassword": "Current password",
      "deactivate": "I want to <a href={url}>deactivate</a> my account.",
      "edit_email": "Contact your school to change your email.",
      "edit_in_parnassys": "Log in to <a href={url} target='_blank'>ParnasSys</a> to change your personal data.",
      "edit_name": "Contact your school to change your name.",
      "edit_password": "You can change your password in ParnasSys.",
      "email": "E-mailaddress",
      "email_warning": "Note: after changing you'll also have to use this new e-mail address to log in to Parro.",
      "firstname": "First name",
      "name": "Name",
      "newpassword": "New password",
      "password": "Password",
      "password_check": "Password for verification",
      "repeatpassword": "Repeat new password",
      "surname": "Surname",
      "title_contact": "Account details",
      "title_login": "Login details Parro"
    },
    "profile_school": {
      "no_license": "No license",
      "parro_license": "Parro license",
      "plan_free": "Communicate",
      "plan_organize": "Communicate & Organize",
      "plan_pilot": "Pilot",
      "school": "School",
      "title": "School data"
    },
    "recovery": {
      "code_not_found_header": "Code not found",
      "code_not_found_text": "The code may have already been used or may be incorrect. Check if the code in your e-mail matches the code in the address bar.",
      "code_not_valid_header": "Code is not valid",
      "code_not_valid_text": "The deliberation period has expired. Your account has been deleted.",
      "error_default_header": "Recovery failed",
      "error_default_text": "Please try again later. If this problem persists, please contact <a href=\"mailto:support@parro.com?subject=Account%20onterecht%20vergeten\" target=\"_blank\">Parro Support</a>",
      "success_header": "Recovery successful!",
      "success_text": "Your account has been recovered. You can log in to Parro again."
    },
    "screensaver": {
      "powered_by": "® Powered by Parro",
      "screensaver": "Screensaver"
    },
    "search": {
      "announcements": "Search announcement",
      "chatmessages": "Search chatmessage",
      "no_results": "Parro couldn't find anything for your search request. Try again with another searchterm."
    },
    "status": {
      "loading": "The data is being loaded"
    },
    "synchronize_manage": {
      "group_import_button": "Grant parents access",
      "group_import_info": "Parro is able to grant access to most of the parents automatically. Convenient! You are able to manually manage family and parents.",
      "group_import_new": "Connect {amount} parent(s)",
      "group_import_updated": "All guardians are up to date",
      "manage": "Manage",
      "manage_error": "An error occurred when try to save the groups. Try again later.",
      "manage_groups": "Manage groups",
      "no_groups_error": "An error occurred while retrieving groups from ParnasSys. Please try again later.",
      "no_groups_found": "No groups found from ParnasSys",
      "synchronization_time": "Synchronized: {time}",
      "synchronize": "Synchronize",
      "synchronize_error": "Synchronization failed",
      "synchronize_manage": "Synchronize & manage"
    },
    "yearbook_cover": {
      "alert_quality": "Low quality photo. We recommend to choose another photo.",
      "header": "Put together your cover",
      "header_extra": "You can always change your cover. You can find the color you choose in the whole book.",
      "label_color": "Color",
      "label_title": "Title",
      "placeholder_title": "Type your title here",
      "text": "Yearbook from grade 2018 / 2019",
      "title": "Choose cover photo"
    },
    "yearbook_dialogs": {
      "bad_quality_header": "Low quality",
      "bad_quality_text": "This photo has low quality. We recommend to choose another photo.",
      "delete_header": "Delete yearbook",
      "delete_text": "Are you sure you want to delete this yearbook?",
      "limit_header": "Limit reached",
      "limit_min_header": "Not enough photos",
      "limit_min_text": "You didn't select enough photos. Select {amount, plural, =1 {# photo} other {# photos}} to complete the yearbook.",
      "limit_squarefold": "You've reached the maximum amount of photos (22 - Square fold). Do you want to add more photos? Upgrade your yearbook.",
      "limit_text": "You've reached the maximum amount of photos (62). Do you want to add more photos? Change the selected photos or consider buying an extra book.",
      "not_available_header": "Feature not available",
      "not_available_text": "This feature isn't available yet. ",
      "payment_mollie": "We are going to redirect you to our payment portal (Mollie).",
      "promo_header": "Order only",
      "promo_text": "Your school is participating in a yearbook promotion. This yearbook has been specially designed for you, and you can order it right away.",
      "return_parro_header": "Return to Parro",
      "return_parro_text": "Are you sure you want to return to Parro?",
      "text_cover": "We are going to put together the cover",
      "text_editor": "We are going to choose the content of the yearbook."
    },
    "yearbook_drawing": {
      "header": "Do you want Parro drawings in your yearbook?",
      "title": "Turn drawing on / off"
    },
    "yearbook_editor": {
      "choose_photo_header": "Choose a photo",
      "choose_photo_text": "You can only select the text when there is a photo selected from this announcement.",
      "filter": "Navigate to date, choose child and year",
      "header": "Make your own yearbook",
      "loading_selection_header": "We pick up where you left off!",
      "loading_selection_text": "Parro is searching. This may take a while...",
      "no_announcements": "No announcements to load",
      "select_all": "Select all photos",
      "selected_images": "{amount1} of {amount2} selected",
      "text": "Choose photos and preview your yearbook",
      "title": "Edit",
      "title_images": "Photos announcement"
    },
    "yearbook_filter": {
      "choose_month": "Choose a month",
      "header_children": "For which children you want to create a yearbook?",
      "header_schoolyears": "And for which schoolyears?",
      "no_children": "No children found",
      "no_groups": "No groups found",
      "no_schoolyears": "No schoolyears found",
      "tabs_child": "Child",
      "tabs_date": "To",
      "tabs_group": "Group",
      "tabs_schoolyear": "Schoolyear",
      "title_children": "Choose children",
      "title_schoolyears": "Choose schoolyears"
    },
    "yearbook_image_selection": {
      "all_groups": "All groups",
      "placeholder_selection": "Select a group",
      "upload_image": "Or upload your own photo"
    },
    "yearbook_login": {
      "text": "You will be redirected to the Parro Yearbook login."
    },
    "yearbook_menu": {
      "choose": "Choose book type",
      "cover": "Change cover",
      "drawings_off": "Turn on drawings",
      "drawings_on": "Turn off drawings",
      "feedback": "Feedback",
      "home_page": "Back to home page",
      "information": "Extra information / help",
      "parro": "Back to Parro",
      "tip": "Tip!",
      "title": "Menu"
    },
    "yearbook_order": {
      "header": "Your information",
      "label_method": "Payment method",
      "method_ideal": "iDEAL",
      "method_klarna": "Klarna afterpay",
      "pay": "Pay",
      "placeholder_city": "City",
      "placeholder_country": "Country",
      "placeholder_firstname": "First name",
      "placeholder_housenumber": "House nr.",
      "placeholder_lastname": "Last name",
      "placeholder_postalcode": "Postal code",
      "placeholder_street": "Street",
      "post_pay": "Postpay",
      "text": "We'll deliver the yearbook to the following address",
      "text_design": "You've checked and approved the <a href={url}>design</a>",
      "text_design2": "I still want to order, even when my <a href={url}>design</a> contains low quality photos.",
      "text_terms": "And agree with the <a target='_blank' href={url}>terms and conditions</a>.",
      "title": "Order"
    },
    "yearbook_overview": {
      "create_new": "Or create a new yearbook",
      "edited": "Edited: {date}",
      "header": "Continue editing your yearbook",
      "header_extra": "Any questions? Take a look at the <a target='_blank' href={url}>support page</a>",
      "header_new": "New yearbook",
      "no_title": "No title",
      "ordered": "Ordered: {amount, plural, =1 {# time} other {# times}}",
      "status_canceled": "Canceled",
      "status_delivered": "Delivered",
      "status_editing": "Editable",
      "status_on_order": "On order",
      "status_ordered": "Ordered",
      "status_paid": "Paid",
      "status_payment_pending": "Not paid",
      "status_problem": "Problem",
      "status_promo": "Promotion",
      "status_refund_pending": "Refund in progress",
      "status_refund_requested": "Refund requested",
      "status_refunded": "Refunded",
      "status_sent": "Sent",
      "text_new": "Create your yearbook quick and easy with the smart Parro configurator.",
      "usp": "Tip: you can choose photos from all previous school years and design your own personal cover."
    },
    "yearbook_preview": {
      "header": "Check your yearbook",
      "spread_back": "Back",
      "spread_front": "Front",
      "spread_inside": "Inside",
      "text": "So you're certain that your order is as desired",
      "title": "Preview"
    },
    "yearbook_settings": {
      "agreed_by": "Akkoord door: {name}",
      "disable_header": "Schakel jaarboek uit",
      "disable_text1": "De lopende bestellingen gaan door, ouders kunnen geen nieuwe jaarboeken meer bestellen.",
      "disable_text2": "We registeren je naam en datum waarop je deze dienst intrekt.",
      "disabled_by": "Uitgeschakeld door: {name}",
      "enable_modal_header": "Akkoord met voorwaarden",
      "enable_modal_text": "Je bevestigt directeur of gemachtigd te zijn namens de school.",
      "more_information_header": "Meer informatie",
      "more_information_text": "Je kunt als school een jaarboek met foto's en berichten aanbieden aan de ouders. Meer weten? Bekijk <a href={url} target='_blank'>de meest gestelde vragen.</a>",
      "offer_text": "Wat leuk dat je het jaarboek aan ouders wilt aanbieden. Daarvoor gelden <a href={url} target='_blank'>aanvullende voorwaarden</a>",
      "popover_images": "Nieuw! Maak van jouw foto's nu een <u>boekje</u>.",
      "status_disabled": "Status: uitgeschakeld",
      "status_disabled_text": "Jouw school biedt op dit moment geen jaarboek aan.",
      "status_enabled": "Status: ingeschakeld",
      "status_enabled_text": "Je school biedt het jaarboek aan.",
      "whole_school": "Deze instelling geldt voor de hele school."
    },
    "yearbook_shopping": {
      "alert_max_amount": "Too many photos are selected. Please choose another book type or deselect some photos.",
      "alert_placeholders": "Choose {amount, plural, =1 {# more photo} other {# more photos}}",
      "alert_replacements": "You can still choose {amount, plural, =1 {# photo} other {# photos}} for the same price!",
      "batch_order": "Do you want to order a book for the whole group? <a href={url} target=\"blank\">Read more</a>",
      "extra_pages": "Extra pages: {amount}",
      "label_amount": "Amount",
      "label_btw": "incl. VAT & shipping",
      "label_total": "Total",
      "max_amount": "Max. {amount}",
      "pages": "({amount, plural, =1 {# page} other {# pages}})",
      "title": "Shopping cart"
    },
    "yearbook_status": {
      "amount_pages": "({amount} pages, max {max})",
      "deliver_date": "The expected delivery is within 2 weeks.",
      "editing_header": "You're not done yet.",
      "editing_text": "If you haven't ordered yet. Press the button below.",
      "editing_title": "Yearbook not finished",
      "feedback": "Click the button below to give feedback.",
      "free": "Free",
      "pending_header": "Parro is waiting for your payment",
      "pending_text": "The payment is still processing or you haven't paid yet. When the payment is finished, you will receive an e-mail and Parro is getting everything ready for you.",
      "pending_text2": "If you haven't paid yet. Press the button below.",
      "pending_title": "Payment not received",
      "promo_header": "Yearbook promotion",
      "promo_text": "Your school is participating in a yearbook promotion. This yearbook has been specially designed for you, and you can order it right away.",
      "promo_title": "Status",
      "success_header": "Parro is getting everything ready for you",
      "success_text": "In the overview you can see whether the payment was successful. If you have any questions, view the <a target='_blank' href={url}>support page</a>. Give us feedback via the <a target='_blank' href={url2}>feedback form</a>.",
      "success_title": "Thanks for your order",
      "tracking_url": "You can track the order with this <a href={url} target=\"_blank\">link</a>."
    },
    "yearbook_templates": {
      "intro": "Foreword",
      "my_yearbook": "This is the yearbook of",
      "outro_1": "Last but not least...",
      "outro_2": "Free space for nice memories..."
    },
    "yearbook_type": {
      "description_pages": "{min} to {max} pages",
      "description_price": "From",
      "error_too_many": "You've chosen too many photos, max {amount}",
      "hardcover_text1": "Onze aanrader: het luxe Jaarboek met harde kaft. De harde kaft beschermt de pagina’s tegen oneffenheden en zorgt ervoor dat het boek jaren mooi blijft.",
      "hardcover_text2": "<strong>Tip:</strong> Op de rug van het boek staat de titel, zo kan je hem nog sneller terug vinden in de boekenkast!",
      "header": "Choose one of these 3 beautiful book types (21x21cm)",
      "label_populair": "Popular",
      "softcover_text": "Jaarboek met zachte kaft, welke je kan ombuigen. De zachte kaft geeft de look ’n feel van een magazine.",
      "squarefold_text1": "Jaarboek met nietjes. Dit jaarboek is licht en geschikt voor compacte boekjes.",
      "squarefold_text2": "<strong>Let op:</strong> Dit jaarboek is geschikt voor weinig foto’s. Wil je veel foto’s kiezen? Kies dan een soft- of hardcover. Jouw keuze voor het type boekje kan je in het proces nog veranderen",
      "text": "Prices incl. VAT + shipping costs, extra pages € 1,50 per 4 pages.",
      "type_hardcover": "Hardcover",
      "type_softcover": "Softcover",
      "type_squarefold": "Square fold"
    }
  };
  _exports.default = _default;
});