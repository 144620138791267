define("parro-web/models/time", ["exports", "ember", "parro-web/utils/timeUtil"], function (_exports, _ember, _timeUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Sources: http://stackoverflow.com/a/7536768 and http://stackoverflow.com/a/19333629
  const TIME_REGEX = '([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]).([0-9][0-9][0-9])';
  const {
    assert,
    isPresent
  } = _ember.default;
  /**
   * Custom datatype based on Ember Object - NOT Ember Data model! However it's referenced by other Ember Data
   * models, so it likely has to stay in this folder. Probably. Maybe. I think so any way.
   * It also has a custom transformer in transforms/time so transformation from REST data works.
   */

  const Time = _ember.default.Object.extend({
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,

    getTimeRegex() {
      return TIME_REGEX;
    },

    setValues(hours, minutes, seconds, milliseconds) {
      this.set('hours', hours);
      this.set('minutes', minutes);
      this.set('seconds', seconds);
      this.set('milliseconds', milliseconds);
      this.toString(); // validates

      return this;
    },

    before(timeOrDate) {
      return _timeUtil.default.toTicks(this) < _timeUtil.default.toTicks(timeOrDate);
    },

    after(timeOrDate) {
      return _timeUtil.default.toTicks(this) > _timeUtil.default.toTicks(timeOrDate);
    },

    toString() {
      assert('Hours must be numeric', !isNaN(this.get('hours')));
      assert('minutes must be numeric', !isNaN(this.get('minutes')));
      assert('seconds must be numeric', !isNaN(this.get('seconds')));
      assert('milliseconds must be numeric', !isNaN(this.get('milliseconds')));
      const hours = String.pad(this.get('hours'), 2);
      const minutes = String.pad(this.get('minutes'), 2);
      const seconds = String.pad(this.get('seconds'), 2);
      const milliseconds = String.pad(this.get('milliseconds'), 3);
      const string = hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
      assert('Time regex unmatched for string: ' + string, isPresent(string.match(TIME_REGEX)));
      return string;
    }

  });

  Time.asTime = function (time) {
    if (!isPresent(time)) {
      return undefined;
    }

    const hours = _ember.default.get(time, 'hours');

    const minutes = _ember.default.get(time, 'minutes');

    return isNaN(hours) || isNaN(minutes) ? undefined : Time.create({}).setValues(hours, minutes, 0, 0);
  };

  var _default = Time;
  _exports.default = _default;
});