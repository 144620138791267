define("parro-web/services/promise-service", ["exports", "ember", "parro-web/objects/DataProvider"], function (_exports, _ember, _DataProvider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    RSVP: {
      Promise
    }
  } = _ember.default;

  var _default = _ember.default.Service.extend({
    processSequentially(provider, results, resolve, reject) {
      assert('provider must be of type DataProvider', provider instanceof _DataProvider.default);
      assert('results must be of type Array', results instanceof Array);
      assert('resolve must be of type Function', resolve instanceof Function);
      assert('reject must be of type Function', reject instanceof Function);

      if (!provider.hasNext()) {
        return resolve(results);
      }

      const result = provider.next();

      const onResult = result => {
        results.push(result);
        this.processSequentially(provider, results, resolve, reject);
      };

      return result instanceof Promise ? result.then(onResult, reject) : onResult(result);
    }

  });

  _exports.default = _default;
});