define("parro-web/components/yearbook/yearbook-templates-item", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed,
    isEmpty,
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    yearbookService: service(),
    router: service(),
    templateClassNames: computed('itemIndex', 'chunkIndex', 'item.template.className', function () {
      const chunkIndex = this.get('chunkIndex');
      const itemIndex = this.get('itemIndex');
      let side;

      if (isPresent(chunkIndex)) {
        side = chunkIndex === 0 || itemIndex === 1 ? 'right' : 'left';
      } else {
        side = itemIndex % 2 === 0 ? 'right' : 'left';
      }

      const className = this.get('item.template.className');
      return `${side} ${className}`;
    }),
    templateName: computed('item.template.name', function () {
      const prefix = 'yearbook/templates/';
      const className = this.get('item.template.name');

      if (isEmpty(className)) {
        return prefix + 'template-blank';
      }

      return prefix + className;
    }),
    content: computed('item.images', 'item.content.text', function () {
      const images = this.get('item.images');
      const text = this.get('item.content');
      const figure = this.get('item.figure');
      const sortDate = this.get('item.sortDate');
      const announcementId = this.get('item.announcementId');
      return _ember.default.Object.create({
        image: isPresent(images) ? images[0] : undefined,
        figure: figure,
        text: text,
        sortDate: sortDate,
        announcementId: announcementId
      });
    })
  });

  _exports.default = _default;
});