define("parro-web/models/child", ["exports", "ember-data", "ember", "parro-web/models/identity", "ember-inflector"], function (_exports, _emberData, _ember, _identity, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _identity.default.extend({
    gender: _emberData.default.attr('string'),
    guardiancount: _emberData.default.attr('number'),
    unavailable: _emberData.default.attr('boolean'),
    isUsingParro: computed.gt('guardiancount', 0)
  });
  /* Afwijkende Ember-data meervoudsvorm: '/child/1' ipv '/children/1' */


  _exports.default = _default;

  _emberInflector.default.inflector.irregular('child', 'child');
});