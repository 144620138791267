define("parro-web/models/schoolyear", ["exports", "ember-data", "ember", "ember-inflector"], function (_exports, _emberData, _ember, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'identity.RSchoolyear'
    }),
    schoolyear: _emberData.default.attr('number'),
    description: computed('schoolyear', function () {
      const schoolYear = parseInt(this.get('schoolyear'));
      const nextYear = schoolYear + 1;
      return schoolYear + ' - ' + nextYear;
    })
  });
  /* Ember-Data vertellen wat de meervoudsvorm van de modelnaam is */


  _exports.default = _default;

  _emberInflector.default.inflector.irregular('schoolyear', 'schoolyear');
});