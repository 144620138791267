define("parro-web/templates/components/navigation/nav-choice-tab", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span>{{#if tab.icon}}<i class="{{tab.icon}}"></i>{{/if}} {{t-exists tab.name}}</span>
  
  */
  {
    "id": "nVQvQCgp",
    "block": "[[[10,1],[12],[41,[33,1,[\"icon\"]],[[[10,\"i\"],[15,0,[29,[[33,1,[\"icon\"]]]]],[12],[13]],[]],null],[1,\" \"],[1,[28,[35,2],[[33,1,[\"name\"]]],null]],[13],[1,\"\\n\"]],[],false,[\"if\",\"tab\",\"t-exists\"]]",
    "moduleName": "parro-web/templates/components/navigation/nav-choice-tab.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});