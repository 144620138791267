define("parro-web/models/identity", ["exports", "ember-data", "ember", "parro-web/models/person", "ember-inflector"], function (_exports, _emberData, _ember, _person, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _person.default.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'identity.RIdentity'
    }),
    cachedAvatarUrl: _emberData.default.attr('string'),
    teachers: _emberData.default.hasMany('teacher', {
      async: false
    }),
    guardians: _emberData.default.hasMany('guardian', {
      async: false
    }),
    linkedIdentityHash: _emberData.default.attr('string'),
    isGuardian: computed.gt('guardians.length', 0),
    isTeacher: computed.gt('teachers.length', 0)
  });
  /* Ember-Data vertellen wat de meervoudsvorm van de modelnaam is */


  _exports.default = _default;

  _emberInflector.default.inflector.irregular('identity', 'identity');
});