define("parro-web/routes/yearbook/schoolyear", ["exports", "ember", "parro-web/mixins/routeMixin"], function (_exports, _ember, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP,
    Route,
    inject: {
      service
    },
    isEmpty
  } = _ember.default;

  var _default = Route.extend(_routeMixin.default, {
    yearbookAjaxService: service(),
    yearbookService: service(),
    accountService: service('account'),
    session: service(),

    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },

    model(params) {
      return RSVP.hash({
        navConfig: this._getConfig(),
        yearbook: this.get('yearbookAjaxService').getYearbook(params.yearbook),
        schoolYears: this.get('accountService').getSchoolYears()
      });
    },

    afterModel(model, transition) {
      if (isEmpty(model) || isEmpty(model.yearbook)) {
        return this.refresh();
      }

      const routeGuard = this.get('yearbookService').getRouteGuard(this.get('routeName'), model.yearbook);
      this.useRouteGuard(routeGuard, transition);
    },

    _getConfig() {
      return _ember.default.Object.create({
        title: 'yearbook_filter.title_schoolyears',
        headerClass: 'header-toolbar--left',
        icon: 'icon-previous',
        toNavChoice: () => this.toNavChoice()
      });
    },

    toNavChoice() {
      window.history.back();
    }

  });

  _exports.default = _default;
});