define("parro-web/transforms/array", ["exports", "ember", "ember-data"], function (_exports, _ember, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function (serialized) {
      if (_ember.default.isArray(serialized)) {
        return _ember.default.A(serialized);
      } else {
        return _ember.default.A();
      }
    },
    serialize: function (deserialized) {
      if (_ember.default.isArray(deserialized)) {
        return _ember.default.A(deserialized);
      } else {
        return _ember.default.A();
      }
    }
  });

  _exports.default = _default;
});