define("parro-web/mixins/indexRedirectRouteMixin", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    inject: {
      service
    },
    isPresent
  } = _ember.default;
  const assign = _ember.default.assign;
  /** [#117125171] Mixin to redirect from index page to specific item page (i.e. last opened conversation). */

  var _default = _ember.default.Mixin.create({
    session: service(),
    modelFindAllInStore: undefined,
    modelFindArgs: undefined,
    modelComparator: undefined,
    afterModelTransitionTo: undefined,
    afterModelTransitionToFallback: undefined,

    model() {
      return this.getModel();
    },

    afterModel(result) {
      if (result) {
        this.transitionTo(this.get('afterModelTransitionTo'), result);
      } else {
        this.transitionTo(this.get('afterModelTransitionToFallback'));
      }
    },

    getModel() {
      const type = this.get('modelFindAllInStore');

      if (!type) {
        return undefined;
      }

      const comparator = this.get('modelComparator');
      assert("Must provide 'modelComparator' with 'modelFindAllInStore'", typeof comparator === 'function');
      let args = {
        disableAjaxLoader: true
      };

      if (this.get('modelFindArgs')) {
        args = assign(args, this.get('modelFindArgs'));
      }

      const onSuccess = results => {
        const array = Array.orEmpty(results); // Use first result available.

        const first = array.map(a => a).sort(comparator).find(result => result);
        return isPresent(first) ? first.id : undefined;
      };

      const onError = () => undefined;

      return this.store.query(type, args).then(onSuccess, onError);
    }

  });

  _exports.default = _default;
});