define("parro-web/templates/components/person/profile-picture", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if icon}}
    <figure class="profile-picture-{{size}} {{color}}" style={{backgroundImageStyle}}>
      <i class={{icon}}></i>
    </figure>
  {{else if config.loading}}
    <figure class="profile-picture-{{size}} infinity-loader--spinner"></figure>
  {{else if onClickAction}}
    <figure class="is-clickable profile-picture-{{size}} {{if config.showParro 'profile-picture-parro' ''}} {{config.color}} {{hover}}" style={{backgroundImageStyle}} {{action onClickAction}}>
      {{config.character}}
      {{yield}}
    </figure>
    {{#if camera}}<figure class="profile-picture-camera-{{size}}-{{camera}}"></figure>{{/if}}
  {{else if inactiveColor}}
    <figure class="profile-picture-{{size}} {{if config.showParro 'profile-picture-parro' ''}} {{inactiveColor}} {{hover}}" style={{backgroundImageStyle}}>
      {{config.character}}
      {{yield}}
    </figure>
    {{#if camera}}<figure class="profile-picture-camera-{{size}}-{{camera}}"></figure>{{/if}}
  {{else}}
    <figure class="profile-picture-{{size}} {{if config.showParro 'profile-picture-parro' ''}} {{config.color}} {{hover}}" style={{backgroundImageStyle}}>
      {{config.character}}
      {{yield}}
    </figure>
    {{#if camera}}<figure class="profile-picture-camera-{{size}}-{{camera}}"></figure>{{/if}}
  
  {{/if}}
  */
  {
    "id": "gcVAc01G",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,\"figure\"],[15,0,[29,[\"profile-picture-\",[36,2],\" \",[36,3]]]],[15,5,[36,4]],[12],[1,\"\\n    \"],[10,\"i\"],[15,0,[36,1]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[33,5,[\"loading\"]],[[[1,\"  \"],[10,\"figure\"],[15,0,[29,[\"profile-picture-\",[36,2],\" infinity-loader--spinner\"]]],[12],[13],[1,\"\\n\"]],[]],[[[41,[33,6],[[[1,\"  \"],[11,\"figure\"],[16,0,[29,[\"is-clickable profile-picture-\",[36,2],\" \",[52,[33,5,[\"showParro\"]],\"profile-picture-parro\",\"\"],\" \",[33,5,[\"color\"]],\" \",[36,7]]]],[16,5,[36,4]],[4,[38,8],[[30,0],[33,6]],null],[12],[1,\"\\n    \"],[1,[33,5,[\"character\"]]],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[41,[33,10],[[[10,\"figure\"],[15,0,[29,[\"profile-picture-camera-\",[36,2],\"-\",[36,10]]]],[12],[13]],[]],null],[1,\"\\n\"]],[]],[[[41,[33,11],[[[1,\"  \"],[10,\"figure\"],[15,0,[29,[\"profile-picture-\",[36,2],\" \",[52,[33,5,[\"showParro\"]],\"profile-picture-parro\",\"\"],\" \",[36,11],\" \",[36,7]]]],[15,5,[36,4]],[12],[1,\"\\n    \"],[1,[33,5,[\"character\"]]],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[41,[33,10],[[[10,\"figure\"],[15,0,[29,[\"profile-picture-camera-\",[36,2],\"-\",[36,10]]]],[12],[13]],[]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"figure\"],[15,0,[29,[\"profile-picture-\",[36,2],\" \",[52,[33,5,[\"showParro\"]],\"profile-picture-parro\",\"\"],\" \",[33,5,[\"color\"]],\" \",[36,7]]]],[15,5,[36,4]],[12],[1,\"\\n    \"],[1,[33,5,[\"character\"]]],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[41,[33,10],[[[10,\"figure\"],[15,0,[29,[\"profile-picture-camera-\",[36,2],\"-\",[36,10]]]],[12],[13]],[]],null],[1,\"\\n\\n\"]],[]]]],[]]]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"icon\",\"size\",\"color\",\"backgroundImageStyle\",\"config\",\"onClickAction\",\"hover\",\"action\",\"yield\",\"camera\",\"inactiveColor\"]]",
    "moduleName": "parro-web/templates/components/person/profile-picture.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});