define("parro-web/serializers/guardianchild", ["exports", "parro-web/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    _parseLinkables(hash) {
      hash.id = this._getLinkableIdFromSelfLink(hash, 'koppeling');
      hash.identityID = this._getLinkableIdFromSelfLink(hash, 'identiteit');
      hash.childID = this._getLinkableIdFromSelfLink(hash, 'self');

      this._parseAndFlattenLinkables(hash, true);
    },

    normalize(type, hash, prop) {
      hash.state = undefined;
      hash.editableFrom = undefined;
      hash.editableTill = undefined; // Update these values if provided by additionalObjects.

      if (hash.additionalObjects.privacycentresetting) {
        hash.state = hash.additionalObjects.privacycentresetting.state;
        hash.editableFrom = hash.additionalObjects.privacycentresetting.editableFrom;
        hash.editableTill = hash.additionalObjects.privacycentresetting.editableTill;
        delete hash.additionalObjects.privacycentresetting;
      }

      return this._super(type, hash, prop);
    }

  });

  _exports.default = _default;
});