define("parro-web/components/photo/photo-swipe", ["exports", "photoswipe", "photoswipe/dist/photoswipe-ui-default", "ember", "parro-web/mixins/routeMixin"], function (_exports, _photoswipe, _photoswipeUiDefault, _ember, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const run = _ember.default.run;
  const {
    observer
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    didInsertElement() {
      this._super(...arguments);

      _ember.default.run.scheduleOnce('afterRender', this, function () {
        const element = $(this.element);
        this.set('pswpEl', element.find('.pswp')[0]);
        this.set('pswpTheme', _photoswipeUiDefault.default);

        this._buildOptions();

        if (this.get('items')) {
          return this._initItemGallery();
        }
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      $(this.get('pswpEl')).remove();
    },

    _buildOptions(getThumbBoundsFn) {
      this._super(getThumbBoundsFn);

      const localizedError = this.get('intl').t('photoswipe.no_image_error');
      const options = {
        errorMsg: '<div class="pswp__error-msg">' + localizedError + '</div>',
        showAnimationDuration: 0,
        hideAnimationDuration: 0
      };
      this.set('options', _ember.default.assign(options, this.get('options')));
    },

    pswpObserver: _ember.default.observer('pswpEl', function () {
      const pswpEl = this.get('pswpEl');
      $('#detail-content').append(pswpEl);
    }),

    click(evt) {
      if (evt.target.className === 'pswp__button pswp__button--close' || evt.target.className === 'pswp__zoom-wrap') {
        this.get('gallery').close();
      }

      this._super(evt);
    },

    _initItemGallery() {
      this.set('gallery', new _photoswipe.default(this.get('pswpEl'), this.get('pswpTheme'), this.get('items'), this.get('options')));

      this._reInitOnClose();
    },

    _reInitOnClose() {
      const component = this;
      this.get('gallery').listen('close', function () {
        run.next(function () {
          component._initItemGallery();
        });
      });
    },

    _getBounds(i) {
      const element = $(this.element);
      const img = element.find('img').get(i);
      const position = element.find(img).position();
      const width = element.find(img).width();
      return {
        x: position.left,
        y: position.top,
        w: width
      };
    },

    itemObserver: observer('items', function () {
      this._initItemGallery();
    }),
    actions: {
      launchGallery(item) {
        this._buildOptions(this._getBounds.bind(this));

        if (item !== undefined) {
          const index = this.get('items').indexOf(item);
          this.set('options.index', index);
        }

        const pSwipe = new _photoswipe.default(this.get('pswpEl'), this.get('pswpTheme'), this.get('items'), this.get('options'));
        this.set('gallery', pSwipe);
        this.get('gallery').init();
      },

      download() {
        window.open(this.get('gallery.currItem.src'), '_blank');
      }

    }
  });

  _exports.default = _default;
});