define("parro-web/initializers/intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'intl',
    initialize: function (application) {
      application.inject('route', 'intl', 'service:intl');
      application.inject('controller', 'intl', 'service:intl');
      application.inject('component', 'intl', 'service:intl');
      application.inject('model', 'intl', 'service:intl');
    }
  };
  _exports.default = _default;
});