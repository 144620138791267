define("parro-web/instance-initializers/setup-pikaday-i18n", ["exports", "ember", "moment"], function (_exports, _ember, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // https://github.com/edgycircle/ember-pikaday#localization
  var _default = {
    name: 'setup-pikaday-i18n',
    after: 'moment',

    initialize(instance) {
      const toUpperCase = string => string.charAt(0).toUpperCase() + string.slice(1);

      const months = Array.orEmpty(_moment.default.localeData()._months).map(month => toUpperCase.apply(this, [month]));

      const weekdays = _moment.default.localeData()._weekdays;

      const weekdaysShort = _moment.default.localeData()._weekdaysShort;

      const i18n = _ember.default.Object.extend({
        months: months,
        weekdays: weekdays,
        weekdaysShort: weekdaysShort
      });

      instance.register('pikaday-i18n:main', i18n, {
        singleton: true
      });
      instance.application.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
      instance.application.inject('component:pikaday-picker', 'i18n', 'pikaday-i18n:main');
    }

  };
  _exports.default = _default;
});