define("parro-web/templates/components/yearbook/yearbook-templates", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if yearbook.isSquareFold}}
  
    <div class="yearbook--generate {{if isHidden 'is-hidden' ''}}">
      {{#each total as |item index|}}
        {{yearbook/yearbook-templates-item item=item itemIndex=index yearbook=yearbook}}
      {{/each}}
    </div>
  
  {{else}}
  
    <div class="yearbook--generate {{if isHidden 'is-hidden' ''}}">
      {{#each cover as |item index|}}
        {{yearbook/yearbook-templates-item item=item itemIndex=index yearbook=yearbook}}
      {{/each}}
  
      <section class="yearbook--cover-back bleed {{if isMagenta 'is-magenta' 'is-blue'}}">
        {{#if yearbook.isHardCover}}
          <p>{{yearbook.name}}    -    Schooljaar {{yearRange.first}} - {{yearRange.last}}</p>
        {{/if}}
      </section>
    </div>
  
    <div class="yearbook--generate {{if isHidden 'is-hidden' ''}}">
      {{#each content as |item index|}}
        {{yearbook/yearbook-templates-item item=item itemIndex=index yearbook=yearbook}}
      {{/each}}
    </div>
  
  {{/if}}
  
  */
  {
    "id": "bjXzRmSy",
    "block": "[[[41,[33,1,[\"isSquareFold\"]],[[[1,\"\\n  \"],[10,0],[15,0,[29,[\"yearbook--generate \",[52,[33,2],\"is-hidden\",\"\"]]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],null,[[[1,\"      \"],[1,[28,[35,6],null,[[\"item\",\"itemIndex\",\"yearbook\"],[[30,1],[30,2],[33,1]]]]],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\\n\"]],[]],[[[1,\"\\n  \"],[10,0],[15,0,[29,[\"yearbook--generate \",[52,[33,2],\"is-hidden\",\"\"]]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,7]],null]],null],null,[[[1,\"      \"],[1,[28,[35,6],null,[[\"item\",\"itemIndex\",\"yearbook\"],[[30,3],[30,4],[33,1]]]]],[1,\"\\n\"]],[3,4]],null],[1,\"\\n    \"],[10,\"section\"],[15,0,[29,[\"yearbook--cover-back bleed \",[52,[33,8],\"is-magenta\",\"is-blue\"]]]],[12],[1,\"\\n\"],[41,[33,1,[\"isHardCover\"]],[[[1,\"        \"],[10,2],[12],[1,[33,1,[\"name\"]]],[1,\"    -    Schooljaar \"],[1,[33,9,[\"first\"]]],[1,\" - \"],[1,[33,9,[\"last\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[15,0,[29,[\"yearbook--generate \",[52,[33,2],\"is-hidden\",\"\"]]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,10]],null]],null],null,[[[1,\"      \"],[1,[28,[35,6],null,[[\"item\",\"itemIndex\",\"yearbook\"],[[30,5],[30,6],[33,1]]]]],[1,\"\\n\"]],[5,6]],null],[1,\"  \"],[13],[1,\"\\n\\n\"]],[]]]],[\"item\",\"index\",\"item\",\"index\",\"item\",\"index\"],false,[\"if\",\"yearbook\",\"isHidden\",\"each\",\"-track-array\",\"total\",\"yearbook/yearbook-templates-item\",\"cover\",\"isMagenta\",\"yearRange\",\"content\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-templates.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});