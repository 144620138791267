define("parro-web/components/navigation/nav-choice-tab", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: 'li',
    classNameBindings: ['isSelected', 'tab.isInactive', 'tab.isGroep'],
    isSelected: computed('tab.name', 'tab.route', 'selected.name', 'selected.route', function () {
      return this.get('tab.name') === this.get('selected.name') && this.get('tab.route') === this.get('selected.route');
    })
  });

  _exports.default = _default;
});