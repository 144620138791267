define("parro-web/templates/components/form/input-number", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="number--input">
    <a {{action 'minus'}} class="{{if isMinusEnabled '' 'is-inactive'}}"><i class="icon-minus"></i></a>
    {{input type='number' value=value}}
    <a {{action 'add'}} class="{{if isPlusEnabled '' 'is-inactive'}}"><i class="icon-plus"></i></a>
  </div>
  
  <span class="text--light text--extra-small">Max. {{max}}</span>
  */
  {
    "id": "InZaHRra",
    "block": "[[[10,0],[14,0,\"number--input\"],[12],[1,\"\\n  \"],[11,3],[16,0,[29,[[52,[33,1],\"\",\"is-inactive\"]]]],[4,[38,2],[[30,0],\"minus\"],null],[12],[10,\"i\"],[14,0,\"icon-minus\"],[12],[13],[13],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"type\",\"value\"],[\"number\",[33,4]]]]],[1,\"\\n  \"],[11,3],[16,0,[29,[[52,[33,5],\"\",\"is-inactive\"]]]],[4,[38,2],[[30,0],\"add\"],null],[12],[10,\"i\"],[14,0,\"icon-plus\"],[12],[13],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,1],[14,0,\"text--light text--extra-small\"],[12],[1,\"Max. \"],[1,[34,6]],[13]],[],false,[\"if\",\"isMinusEnabled\",\"action\",\"input\",\"value\",\"isPlusEnabled\",\"max\"]]",
    "moduleName": "parro-web/templates/components/form/input-number.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});