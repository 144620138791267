define("parro-web/services/attachments-service", ["exports", "parro-web/objects/AttachmentsUploadConfig", "ember", "parro-web/config/environment", "parro-web/objects/FileUploadInfo", "parro-web/objects/ImageDimensions", "parro-web/mixins/metricsMixin"], function (_exports, _AttachmentsUploadConfig, _ember, _environment, _FileUploadInfo, _ImageDimensions, _metricsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    inject: {
      service
    },
    isEmpty,
    isPresent,
    RSVP: {
      Promise
    },
    computed
  } = _ember.default;
  const {
    embrah: {
      namespace
    }
  } = _environment.default;

  var _default = _ember.default.Service.extend(_metricsMixin.default, {
    s3Service: service('s3Upload'),
    ajaxService: service('ajax'),
    intl: service(),
    versionService: service(),
    host: computed.readOnly('versionService.host'),

    getFileUploadInfo(uploadUrl, contentTypes = []) {
      return new Promise((resolve, reject) => {
        assert('contentTypes must be of type Array', contentTypes instanceof Array);

        if (contentTypes.find(type => isEmpty(type)) === '') {
          return reject('error.unknown_error');
        }

        const toParameter = contentType => '&contentType=' + contentType;

        const parameters = '?client=EMBER' + contentTypes.map(toParameter).join('');
        const url = isPresent(uploadUrl) ? uploadUrl : this.get('host') + '/' + namespace + '/fileupload' + parameters;
        const request = {
          type: 'GET',
          url: url,
          disableAjaxLoader: true
        };
        const ajaxService = this.get('ajaxService');
        ajaxService.executeRequest(request).then(json => resolve(Array.orEmpty(json.items).map(info => _FileUploadInfo.default.create(info)))).catch(jqXHR => reject(jqXHR));
      });
    },

    createImageEntry(blob, fui, dimensions) {
      assert('blob must be of type Blob', blob instanceof Blob);
      assert('fui must be of type FileUploadInfo', fui instanceof _FileUploadInfo.default);
      assert('dimensions must be of type ImageDimensions', dimensions instanceof _ImageDimensions.default);
      const bucket = fui.bucket || fui.baseUrl.replace('https://', '').replace('.s3.amazonaws.com/', '');
      return {
        dtype: 'attachment.RAttachmentImageEntry',
        contentType: fui.contentType,
        bucket: bucket,
        url: fui.key,
        size: blob.size,
        width: dimensions.width,
        height: dimensions.height
      };
    },

    createDocumentEntry(file, fui) {
      assert('file must be of type File or Blob', file instanceof File || file instanceof Blob);
      assert('fui must be of type FileUploadInfo', fui instanceof _FileUploadInfo.default);
      const bucket = fui.bucket || fui.baseUrl.replace('https://', '').replace('.s3.amazonaws.com/', '');
      return {
        dtype: 'attachment.RAttachmentDocumentEntry',
        contentType: fui.contentType,
        bucket: bucket,
        url: fui.key,
        size: file.size,
        filename: file.name
      };
    },

    createYearbookAttachments(yearbook, extraUploadInfo, documents, images) {
      const config = _AttachmentsUploadConfig.default.create({
        maxPictures: 1,
        dtype: 'attachment.RYearbookAttachment',
        addIndex: false,
        recycler: () => {},
        extraUploadInfo: extraUploadInfo
      });

      return this.createAttachments(undefined, _ember.default.A([]), images, config);
    },

    createAttachments(attachedToID, documents, images, config) {
      return new Promise((resolve, reject) => {
        assert('config must be of type AttachmentsUploadConfig', config instanceof _AttachmentsUploadConfig.default);

        if (isEmpty(documents) && isEmpty(images)) {
          return resolve([]);
        }

        const maxPictures = config.get('maxPictures');
        const dtype = config.get('dtype');
        const addIndex = config.get('addIndex');
        const recycler = config.get('recycler');
        const extraUploadInfo = config.get('extraUploadInfo');

        if (isPresent(images) && images.length > maxPictures) {
          return reject(this.get('intl').t('error.too_many_attachments', {
            maxPictures: maxPictures
          }));
        } // Split files between recycling existing attachments or creating a new one.


        const filterFile = (file, existingArray, newArray) => {
          if (isPresent(attachedToID) && isPresent(file.get) && file.get('dtype') === dtype) {
            existingArray.push(recycler.apply(this, [attachedToID, file]));
          } else if (file instanceof File || file instanceof Blob) {
            newArray.push(file);
          }
        };

        const s3Service = this.get('s3Service');
        const attachments = [];
        const newImages = [];
        const newDocuments = [];
        images.forEach(image => filterFile(image, attachments, newImages));
        documents.forEach(document => filterFile(document, attachments, newDocuments));
        s3Service.uploadFiles(newImages, newDocuments, dtype, extraUploadInfo).then(newAttachments => Promise.resolve(attachments.concat(newAttachments))).then(attachments => {
          // Set indices on all the attachments
          for (let i = 0; addIndex && i < attachments.length; i++) {
            attachments[i].set('index', i);
          }

          resolve(attachments);
        }).catch(error => reject(error));
      });
    }

  });

  _exports.default = _default;
});