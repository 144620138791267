define("parro-web/templates/components/form/ios8-switch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <input type='checkbox' id='{{elementId}}' checked={{checked}} disabled={{isDisabled}}>
  {{#unless isDisabled}}
    <label for='{{elementId}}' {{action toggle bubbles=false}}></label>
  {{else}}
    <label for='{{elementId}}' class="is-disabled"></label>
  {{/unless}}
  */
  {
    "id": "EVKv98IY",
    "block": "[[[10,\"input\"],[15,1,[29,[[36,0]]]],[15,\"checked\",[36,1]],[15,\"disabled\",[36,2]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\"],[41,[51,[33,2]],[[[1,\"  \"],[11,\"label\"],[16,\"for\",[29,[[36,0]]]],[4,[38,4],[[30,0],[33,5]],[[\"bubbles\"],[false]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"label\"],[15,\"for\",[29,[[36,0]]]],[14,0,\"is-disabled\"],[12],[13],[1,\"\\n\"]],[]]]],[],false,[\"elementId\",\"checked\",\"isDisabled\",\"unless\",\"action\",\"toggle\"]]",
    "moduleName": "parro-web/templates/components/form/ios8-switch.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});