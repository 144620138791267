define("parro-web/mixins/metricsMixin", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const canUseMetrics = typeof window !== 'undefined' && window.doNotTrack !== '1' && typeof navigator !== 'undefined' && navigator.doNotTrack !== '1';
  const {
    inject: {
      service
    }
  } = _ember.default;
  /**
   * Convenience mixin to more easily and consistently log metric events.
   */

  var _default = _ember.default.Mixin.create({
    metrics: service(),
    account: service(),
    adapters: [{
      name: 'GoogleAnalytics',
      environments: ['production'],
      config: {
        id: 'UA-85665566-2',
        anonymizeIp: true,
        forceSSL: true
      }
    }],

    setupGoogleAnalytics() {
      if (canUseMetrics) {
        this.metrics.activateAdapters(this.get('adapters'));
      }
    },

    trackPage(page) {
      if (canUseMetrics) {
        this.metrics.trackPage({
          page: page
        });
      }
    },

    trackEvent(category, action) {
      if (!canUseMetrics) {
        return;
      }

      const url = window.location.hash.replace('#/', '');
      const regexNumbers = /^\d+$/;
      const regexNumbersLetters = /((?:[-=a-zA-Z]+[0-9]|[0-9]+[-=a-zA-Z])[a-zA-Z0-9-=]*)/g;
      const urlSegments = url.split('/');
      let newUrl = url;
      urlSegments.forEach(segment => {
        if (segment.match(regexNumbers) || segment.match(regexNumbersLetters)) {
          newUrl = newUrl.replace(segment, ':id');
        }
      });
      this.metrics.trackEvent({
        category: category,
        action: action || '-',
        label: newUrl
      });
    }

  });

  _exports.default = _default;
});