define("parro-web/components/photo/photo-swipe-item", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    String: {
      htmlSafe
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: 'div',
    classNames: ['gallery--item'],
    style: computed('item', function () {
      const url = this.get('item.msrc');
      return htmlSafe('background-image: url(' + url + ')');
    })
  });

  _exports.default = _default;
});