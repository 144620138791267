define("parro-web/components/modal/modal-overlay", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    String: {
      htmlSafe
    },
    isPresent
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    zIndexStyle: computed('zIndex', function () {
      return isPresent(this.zIndex) ? htmlSafe('z-index: ' + (this.zIndex - 1)) : undefined;
    })
  });

  _exports.default = _default;
});