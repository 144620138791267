define("parro-web/components/divider/list-divider", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    tagName: 'li',
    classNames: ['list--divider']
  });

  _exports.default = _default;
});