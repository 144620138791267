define("parro-web/helpers/external-url", ["exports", "ember", "parro-web/config/environment"], function (_exports, _ember, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    externalUrls
  } = _environment.default;
  /**
   * Transforms the given key to the value @ config/environment > ENV.externalUrls.
   */

  var _default = _ember.default.Helper.extend({
    compute([key]) {
      return externalUrls[key];
    }

  });

  _exports.default = _default;
});