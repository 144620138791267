define("parro-web/components/submenu/item-options-menu", ["exports", "parro-web/mixins/ClickElsewhereMixin", "ember"], function (_exports, _ClickElsewhereMixin, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isPresent,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend(_ClickElsewhereMixin.default, {
    tagName: 'label',
    classNameBindings: ['isInvisible', 'isTop', 'isRight'],
    ScrollService: service(),

    didInsertElement() {
      this._super(...arguments);

      _ember.default.run.schedule('routerTransitions', this, () => this.set('register', this));
    },

    setupOutsideClickListener() {
      this._super();

      if (this.rootElement) {
        this.rootElement.classList.add('item-options-menu-selected');
      }
    },

    removeOutsideClickListener() {
      this._super();

      if (this.rootElement) {
        this.rootElement.classList.remove('item-options-menu-selected');
      }
    },

    handleOutsideClick() {
      // Prevents submenu from closing because the long press triggers handleOutsideClick
      if (this.isLongPress) {
        this.set('isLongPress', false);
        return;
      }

      if (!this.isDestroying && !this.isDestroyed) {
        this.send('toggle');
      } else {
        this.removeOutsideClickListener();
      }
    },

    _determinePosition() {
      const container = isPresent(this.containerClass) ? $(this.containerClass) : $('.l-container');
      const element = $(this.element);
      const contentHeight = element && element.find('div') ? element.outerHeight() + element.find('div').outerHeight() : undefined;
      const contentWidth = element && element.find('div') ? element.find('div').outerWidth() - element.outerWidth() : undefined;
      this.set('isTop', this.ScrollService.isTargetOutsideOfContainer(container, element, contentHeight).bottom);
      this.set('isRight', this.ScrollService.isTargetOutsideOfContainer($(window), element, contentWidth).left);
      this.set('isInvisible', false);
    },

    actions: {
      toggle() {
        this.set('isInvisible', true);
        this.toggleProperty('optionsVisible');

        if (this.optionsVisible) {
          this.setupOutsideClickListener();

          _ember.default.run.later(this, () => this._determinePosition(), 1);
        } else {
          this.removeOutsideClickListener();
        }
      }

    }
  });

  _exports.default = _default;
});