define("parro-web/templates/components/yearbook/yearbook-overview-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yearbook/templates/template-front yearbook=yearbook classNames='front right' isPreview=true hideSchoolYear=true}}
  
  <div>
    <p class="header--normal">{{#if yearbook.name}}{{yearbook.name}}{{else}}{{t 'yearbook_overview.no_title'}}{{/if}}</p>
    {{#if lastModifiedAt}}<span>{{t 'yearbook_overview.edited' date=lastModifiedAt}}</span>{{/if}}
    {{#if (and orders statusText (not yearbook.isEditing))}}<span>{{t 'yearbook_overview.ordered' amount=orders}}</span>{{/if}}
    {{#if statusText}}<label class="label--yearbook">{{statusText}}</label>{{/if}}
  </div>
  
  <i class="icon-more"></i>
  
  */
  {
    "id": "w31CncIQ",
    "block": "[[[1,[28,[35,0],null,[[\"yearbook\",\"classNames\",\"isPreview\",\"hideSchoolYear\"],[[33,1],\"front right\",true,true]]]],[1,\"\\n\\n\"],[10,0],[12],[1,\"\\n  \"],[10,2],[14,0,\"header--normal\"],[12],[41,[33,1,[\"name\"]],[[[1,[33,1,[\"name\"]]]],[]],[[[1,[28,[35,3],[\"yearbook_overview.no_title\"],null]]],[]]],[13],[1,\"\\n  \"],[41,[33,4],[[[10,1],[12],[1,[28,[35,3],[\"yearbook_overview.edited\"],[[\"date\"],[[33,4]]]]],[13]],[]],null],[1,\"\\n  \"],[41,[28,[37,5],[[33,6],[33,7],[28,[37,8],[[33,1,[\"isEditing\"]]],null]],null],[[[10,1],[12],[1,[28,[35,3],[\"yearbook_overview.ordered\"],[[\"amount\"],[[33,6]]]]],[13]],[]],null],[1,\"\\n  \"],[41,[33,7],[[[10,\"label\"],[14,0,\"label--yearbook\"],[12],[1,[34,7]],[13]],[]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"i\"],[14,0,\"icon-more\"],[12],[13],[1,\"\\n\"]],[],false,[\"yearbook/templates/template-front\",\"yearbook\",\"if\",\"t\",\"lastModifiedAt\",\"and\",\"orders\",\"statusText\",\"not\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-overview-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});