define("parro-web/components/navigation/nav-header-yearbook", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    tagName: ''
  });

  _exports.default = _default;
});