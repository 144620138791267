define("parro-web/templates/components/yearbook/yearbook-announcement-contents", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{action 'toggle'}} class="yearbook--announcement-content {{if disabled 'is-not-clickable' 'is-clickable'}}">
    {{form/input-checkbox checked=selected disabled=disabled}}
    <p>{{contents}}</p>
  </div>
  
  */
  {
    "id": "eR2R6WA0",
    "block": "[[[11,0],[16,0,[29,[\"yearbook--announcement-content \",[52,[33,1],\"is-not-clickable\",\"is-clickable\"]]]],[4,[38,2],[[30,0],\"toggle\"],null],[12],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"checked\",\"disabled\"],[[33,4],[33,1]]]]],[1,\"\\n  \"],[10,2],[12],[1,[34,5]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"disabled\",\"action\",\"form/input-checkbox\",\"selected\",\"contents\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-announcement-contents.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});