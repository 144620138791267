define("parro-web/models/grouprecipient", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'event.RGroupRecipient'
    }),
    target: _emberData.default.attr('string'),
    group: _emberData.default.belongsTo('grouprecipientgroup', {
      async: false
    })
  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('grouprecipient', 'grouprecipient');
});