define("parro-web/models/services/dialogs", ["exports", "parro-web/models/navigation/confirmationdialogconfig", "parro-web/models/navigation/yearbookdialogconfig"], function (_exports, _confirmationdialogconfig, _yearbookdialogconfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = Dialogs;

  function Dialogs() {}

  Dialogs.closeConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      text: 'messages.confirm_close',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.closeConfirmationNewConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'messages.confirm_close_title',
      text: 'messages.confirm_close',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.noGuardianConversationsConfig = function () {
    return _confirmationdialogconfig.default.create({
      text: 'conversations.no_guardian_communication',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.ok'
    });
  };

  Dialogs.teacherNoGuardianConversationsConfig = function () {
    return _confirmationdialogconfig.default.create({
      text: 'conversations.no_teacher_communication',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.ok'
    });
  };

  Dialogs.teacherNoGuardianConversationsv2Config = function () {
    return _confirmationdialogconfig.default.create({
      text: 'conversations.no_teacher_communication2',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.ok'
    });
  };

  Dialogs.activityCancelConfirmationConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      title: 'activity.modal_delete_title',
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel_activity',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.cancel_activity_no',
      checkboxText: 'activity.modal_warning_recipients2',
      checked: true
    });
  };

  Dialogs.activityOpenConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      text: 'activity.confirm_registration_open',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.open',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.cancel',
      input: true
    });
  };

  Dialogs.activityCloseConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      text: 'activity.confirm_registration_close',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.close',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.cancel'
    });
  };

  Dialogs.activityDirectOpenConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      text: 'activity.confirm_direct_open',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.open',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.cancel'
    });
  };

  Dialogs.deactivateConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      text: 'deactivate.confirm_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.activitySaveConceptConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      text: 'activity.modal_save_concept',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.done',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.cancel'
    });
  };

  Dialogs.activityDeleteDayConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      text: 'activity_v3.confirm_delete_day',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.activityRecipientsConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'activity.modal_save_title',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.save',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.back',
      checkboxText: 'activity.modal_warning_recipients2'
    });
  };

  Dialogs.announcementDeleteConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'group_announcements.confirm_delete_title',
      text: 'group_announcements.confirm_delete_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.announcementDeleteOtherConfirmationConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      title: 'group_announcements.confirm_delete_title',
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.colleagueConversationConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'contacts.start_conversation_title',
      text: 'contacts.start_conversation',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.guardianConversationConfirmationConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.decoupleGuardianConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'contacts.decouple_title',
      text: 'contacts.decouple_confirmation',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.decouple',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.cancel'
    });
  };

  Dialogs.coupleGuardianConfirmationConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'contacts.couple_title',
      text: 'contacts.couple_confirmation',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.invite',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.memberAddConfirmationConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      title: 'conversations.confirm_add_title',
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.add',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.cancel'
    });
  };

  Dialogs.memberDeleteConfirmationConfig = function (text, title, okText) {
    return _confirmationdialogconfig.default.create({
      title: title,
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: okText,
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.cancel'
    });
  };

  Dialogs.continueExistingConversationConfirmationConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      title: 'conversations.continue_existing_conversation',
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.continue',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.back'
    });
  };

  Dialogs.requestAccessConfirmationConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      title: 'conversations.chatroom_exists_title',
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.request_access',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.cancel'
    });
  };

  Dialogs.groupCadeauDialogConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      title: 'group_settings.delete_group_header',
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.yearbookCoverDialogConfig = function () {
    return _yearbookdialogconfig.default.create({
      name: 'yearbookCoverDialogConfig',
      text: 'yearbook_dialogs.text_cover',
      image: 'figure--yearbook-preview-cover',
      breadcrumbs: [{
        title: '1',
        isDone: false,
        isActive: true
      }, {
        title: '2',
        isDone: false,
        isActive: false
      }],
      buttonText: 'button.next2'
    });
  };

  Dialogs.yearbookEditorDialogConfig = function () {
    return _yearbookdialogconfig.default.create({
      name: 'yearbookEditorDialogConfig',
      text: 'yearbook_dialogs.text_editor',
      image: 'figure--yearbook-preview-editor',
      breadcrumbs: [{
        title: '1',
        isDone: true,
        isActive: false
      }, {
        title: '2',
        isDone: false,
        isActive: true
      }],
      buttonText: 'button.next2'
    });
  };

  Dialogs.yearbookFeatureDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_dialogs.not_available_header',
      text: 'yearbook_dialogs.not_available_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.yearbookDeleteDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_dialogs.delete_header',
      text: 'yearbook_dialogs.delete_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.yearbookPaymentDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_order.title',
      text: 'yearbook_dialogs.payment_mollie',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.next2',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.back'
    });
  };

  Dialogs.yearbookTypeDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_dialogs.limit_header',
      text: 'yearbook_dialogs.limit_squarefold',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.upgrade',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.back'
    });
  };

  Dialogs.yearbookAmountDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_dialogs.limit_header',
      text: 'yearbook_dialogs.limit_text',
      buttonCancelClass: 'btn--primary',
      buttonCancelText: 'button.back'
    });
  };

  Dialogs.yearbookMinAmountDialogConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_dialogs.limit_min_header',
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.yearbookEnableTextDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_editor.choose_photo_header',
      text: 'yearbook_editor.choose_photo_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.yearbookBadQualityDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_dialogs.bad_quality_header',
      text: 'yearbook_dialogs.bad_quality_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.edit',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.back'
    });
  };

  Dialogs.yearbookBadQualityOkDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_dialogs.bad_quality_header',
      text: 'yearbook_dialogs.bad_quality_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.yearbookPromoDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_dialogs.promo_header',
      text: 'yearbook_dialogs.promo_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.yearbookScrollToDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_editor.loading_selection_header',
      text: 'yearbook_editor.loading_selection_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel'
    });
  };

  Dialogs.contactNoEmailDialogConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      title: 'invite_contacts.modal_no_email_title',
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.contactNotAllowedDialogConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      title: 'invite_contacts.modal_not_allowed_title',
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.conversationHideDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'conversations.hide_conversation_title',
      text: 'conversations.hide_conversation_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.absenceDeleteDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'absence.confirm_delete_title',
      text: 'absence.confirm_delete_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.absenceMaxAmountDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'absence.confirm_error_amount_title',
      text: 'absence.confirm_error_amount_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.groupArchivedDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'group_announcements.confirm_archived_title',
      text: 'group_announcements.confirm_archived_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.cancel2'
    });
  };

  Dialogs.decoupleGuardianGroupDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'group_settings.modal_title',
      text: 'group_settings.modal_option_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.leave_group',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.cancel'
    });
  };

  Dialogs.noConversationAllowedDialogConfig = function (text) {
    return _confirmationdialogconfig.default.create({
      text: text,
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.ok'
    });
  };

  Dialogs.logoutDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'menu.settings_logout_confirm_header',
      text: 'menu.settings_logout_confirm_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };

  Dialogs.returnToParroDialogConfig = function () {
    return _confirmationdialogconfig.default.create({
      title: 'yearbook_dialogs.return_parro_header',
      text: 'yearbook_dialogs.return_parro_text',
      buttonOkClass: 'btn--primary',
      buttonOkText: 'button.yes',
      buttonCancelClass: 'btn--destructive',
      buttonCancelText: 'button.no'
    });
  };
});