define("parro-web/templates/components/modal/confirmation-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if visible}}
    <section class="modal modal--sub" id="modal-start">
      <div class="modal--container">
  
        <div class="modal--content">
          {{#if config.title}}<h2 class="{{if config.subtitle 'has-subtitle' ''}}">{{t-exists config.title}}</h2>{{/if}}
          {{#if config.subtitle}}<small>{{t-exists config.subtitle}}</small>{{/if}}
          {{#if config.text}}<p>{{t-exists config.text htmlSafe=true}}</p>{{/if}}
          {{#if config.checkboxText}}{{form/input-checkbox checked=config.checked description=config.checkboxText}}{{/if}}
        </div>
  
        <div class="modal--footer">
          {{#if config.buttonCancelText}} <button {{action (action 'close')}} class="{{config.buttonCancelClass}}">{{t-exists config.buttonCancelText}}</button> {{/if}}
          {{#if config.buttonOkText}} <button {{action (action 'ok')}} class="{{config.buttonOkClass}}" type="submit">{{t-exists config.buttonOkText}}</button> {{/if}}
        </div>
  
      </div>
    </section>
    <div id="modal--overlay" class="modal--overlay modal--overlay-sub"></div>
  {{/if}}
  */
  {
    "id": "/u1HXf/g",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,\"section\"],[14,0,\"modal modal--sub\"],[14,1,\"modal-start\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal--container\"],[12],[1,\"\\n\\n      \"],[10,0],[14,0,\"modal--content\"],[12],[1,\"\\n        \"],[41,[33,2,[\"title\"]],[[[10,\"h2\"],[15,0,[29,[[52,[33,2,[\"subtitle\"]],\"has-subtitle\",\"\"]]]],[12],[1,[28,[35,3],[[33,2,[\"title\"]]],null]],[13]],[]],null],[1,\"\\n        \"],[41,[33,2,[\"subtitle\"]],[[[10,\"small\"],[12],[1,[28,[35,3],[[33,2,[\"subtitle\"]]],null]],[13]],[]],null],[1,\"\\n        \"],[41,[33,2,[\"text\"]],[[[10,2],[12],[1,[28,[35,3],[[33,2,[\"text\"]]],[[\"htmlSafe\"],[true]]]],[13]],[]],null],[1,\"\\n        \"],[41,[33,2,[\"checkboxText\"]],[[[1,[28,[35,4],null,[[\"checked\",\"description\"],[[33,2,[\"checked\"]],[33,2,[\"checkboxText\"]]]]]]],[]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"modal--footer\"],[12],[1,\"\\n        \"],[41,[33,2,[\"buttonCancelText\"]],[[[1,\" \"],[11,\"button\"],[16,0,[29,[[33,2,[\"buttonCancelClass\"]]]]],[4,[38,5],[[30,0],[28,[37,5],[[30,0],\"close\"],null]],null],[12],[1,[28,[35,3],[[33,2,[\"buttonCancelText\"]]],null]],[13],[1,\" \"]],[]],null],[1,\"\\n        \"],[41,[33,2,[\"buttonOkText\"]],[[[1,\" \"],[11,\"button\"],[16,0,[29,[[33,2,[\"buttonOkClass\"]]]]],[24,4,\"submit\"],[4,[38,5],[[30,0],[28,[37,5],[[30,0],\"ok\"],null]],null],[12],[1,[28,[35,3],[[33,2,[\"buttonOkText\"]]],null]],[13],[1,\" \"]],[]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,1,\"modal--overlay\"],[14,0,\"modal--overlay modal--overlay-sub\"],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"visible\",\"config\",\"t-exists\",\"form/input-checkbox\",\"action\"]]",
    "moduleName": "parro-web/templates/components/modal/confirmation-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});