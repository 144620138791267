define("parro-web/serializers/schoolyear", ["exports", "parro-web/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'schoolyear',
    attrs: {},

    normalize(type, hash, prop) {
      if (!hash.schoolyear) {
        hash.schoolyear = 0;
      }

      return this._super(type, hash, prop);
    },

    _parseLinkables(hash) {
      this._parseAndFlattenLinkables(hash, false);
    }

  });

  _exports.default = _default;
});