define("parro-web/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{loader/loading-mask-spinner isLoading=isLoading}}
  {{flashmessage/flash-message-queue}}
  
  {{#if session.isAuthenticated}}
    <div class="l-container">
      {{modal/confirmation-dialog register=confirmationDialog}}
      {{outlet}}
    </div>
  {{else}}
  
    <section class="authenticator--wrapper">
      <div class="authenticator--body l-xsmall">
        {{outlet}}
      </div>
    </section>
  {{/if}}
  
  */
  {
    "id": "F6NjkFmb",
    "block": "[[[1,[28,[35,0],null,[[\"isLoading\"],[[33,1]]]]],[1,\"\\n\"],[1,[34,2]],[1,\"\\n\\n\"],[41,[33,4,[\"isAuthenticated\"]],[[[1,\"  \"],[10,0],[14,0,\"l-container\"],[12],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"register\"],[[33,6]]]]],[1,\"\\n    \"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"\\n  \"],[10,\"section\"],[14,0,\"authenticator--wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"authenticator--body l-xsmall\"],[12],[1,\"\\n      \"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"loader/loading-mask-spinner\",\"isLoading\",\"flashmessage/flash-message-queue\",\"if\",\"session\",\"modal/confirmation-dialog\",\"confirmationDialog\",\"component\",\"-outlet\"]]",
    "moduleName": "parro-web/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});