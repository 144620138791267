define("parro-web/components/photo/yearbook-photo-swipe", ["exports", "parro-web/components/photo/photo-swipe", "parro-web/models/services/dialogs"], function (_exports, _photoSwipe, _dialogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _photoSwipe.default.extend({
    actions: {
      showWarning() {
        this.requestConfirmationNew(_dialogs.default.yearbookBadQualityOkDialogConfig());
      }

    }
  });

  _exports.default = _default;
});