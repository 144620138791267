define("parro-web/components/person/profile-picture", ["exports", "parro-web/models/services/colors", "ember"], function (_exports, _colors, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    String: {
      htmlSafe
    },
    isEmpty
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    size: 'small',
    config: computed('avatar', 'name', 'parro', function () {
      const avatar = this.get('avatar');
      const name = this.get('name');
      const parro = this.get('parro');

      const config = _ember.default.Object.create({});

      if (isEmpty(avatar)) {
        config.loading = false;
        config.showParro = parro || isEmpty(name);
        config.color = parro || isEmpty(name) ? undefined : _colors.default.getColor(name);
        config.character = parro || isEmpty(name) ? undefined : name.trim().charAt(0).toUpperCase();
        config.style = htmlSafe('');
      } else {
        config.loading = true;
        config.showParro = false;
        config.color = undefined;
        config.character = undefined;
        config.style = htmlSafe("background-image: url('" + avatar + "')"); // [#140180521] Toon Parro als spinner te lang blijft staan.

        const showParroAfterDelay = _ember.default.run.later(this, () => {
          if (!this.isDestroying && !this.isDestroyed && !this.get('config.showParro') && !this.get('config.style.string')) {
            this.set('config.loading', false);
            this.set('config.showParro', true);
          }
        }, 5000);

        const img = new Image();

        img.onload = () => {
          if (!this.isDestroying && !this.isDestroyed) {
            _ember.default.run.cancel(showParroAfterDelay);

            this.set('config.loading', false);
            this.set('config.showParro', false);
          }
        };

        img.onerror = () => {
          if (!this.isDestroying && !this.isDestroyed) {
            _ember.default.run.cancel(showParroAfterDelay);

            this.set('config.loading', false);
            this.set('config.showParro', true);
          }
        };

        img.src = avatar;
      }

      return config;
    }),
    backgroundImageStyle: computed('config.style', function () {
      const style = this.get('config.style');
      return htmlSafe(style || '');
    })
  });

  _exports.default = _default;
});