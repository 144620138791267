define("parro-web/helpers/t-exists", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    isNone
  } = _ember.default;
  /**
   * Performs the 'intl.t' operation if the key exists. Otherwise it'll return the key.
   * Can be useful when using a mix of intl-key and already-translated messages.
   *
   * Sadly no support for additional arguments :(
   */

  var _default = _ember.default.Helper.extend({
    intl: service(),

    compute(params, args) {
      const intl = this.get('intl');
      const key = params[0];
      const htmlSafe = args && args.htmlSafe;

      if (key) {
        return isNone(key) ? key : intl.exists(key) ? intl.t(key, {
          htmlSafe: htmlSafe
        }) : key;
      }

      return '';
    }

  });

  _exports.default = _default;
});