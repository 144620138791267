define("parro-web/components/modal/account-choice-dialog", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    tagName: '',
    actions: {
      selectChoice(item) {
        const onChoice = this.get('config.onChoice');

        if (onChoice) {
          onChoice.apply(this, [item]);
        }

        this.set('config.visible', false);
      },

      cancel() {
        const onCancel = this.get('config.onCancel');

        if (onCancel) {
          onCancel.apply(this);
        }

        this.set('config.visible', false);
      }

    }
  });

  _exports.default = _default;
});