define("parro-web/components/modal/confirmation-dialog", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    tagName: '',
    ok: undefined,
    cancel: undefined,
    config: undefined,
    visible: false,
    checked: false,

    didInsertElement() {
      this._super(...arguments);

      _ember.default.run.schedule('routerTransitions', this, () => this.set('register', this));
    },

    close() {
      this.set('visible', false);
      this.set('ok', undefined);
      this.set('cancel', undefined);
      this.set('config', undefined);
    },

    confirm(config, ok, cancel) {
      this.set('config', config);
      this.set('ok', ok);
      this.set('cancel', cancel);
      this.set('visible', true);
    },

    actions: {
      close() {
        this.flashClear();

        if (this.get('cancel')) {
          this.get('cancel').apply(this);
        }

        this.close();
      },

      ok() {
        this.flashClear();

        if (this.get('ok')) {
          if (this.get('config.checkboxText') && this.get('config.checked') !== undefined) {
            this.get('ok').apply(this, [this.get('config.checked')]);
          } else {
            this.get('ok').apply(this);
          }
        }

        this.close();
      }

    }
  });

  _exports.default = _default;
});