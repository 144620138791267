define("parro-web/templates/components/yearbook/yearbook-image-selection", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="panel--list yearbook--image-selection">
  
    {{#if selectGroup}}
      <div class="suite-form--fields">
        {{#form/dropdown-menu placeholder=(t 'yearbook_image_selection.placeholder_selection') currentValue=selectedGroup.name}}
          <a {{action selectGroup}} class="is-clickable">{{t 'yearbook_image_selection.all_groups'}}</a>
          {{#each groups as |group|}}
            <a {{action selectGroup group}} class="is-clickable {{if (eq selectedGroup group) 'is-selected' ''}}">{{group.nameAndSchoolYear}}</a>
          {{/each}}
        {{/form/dropdown-menu}}
      </div>
  
      {{yearbook/yearbook-image-select addImage=selectImage}}
    {{/if}}
  
    <div class="suite-gallery">
  
      {{#if images}}
        {{photo/yearbook-photo-swipe items=images selected=selected selectImage=selectImage requestConfirmation=requestConfirmation}}
      {{/if}}
  
    </div>
  </div>
  
  */
  {
    "id": "B6VxzGQn",
    "block": "[[[10,0],[14,0,\"panel--list yearbook--image-selection\"],[12],[1,\"\\n\\n\"],[41,[33,1],[[[1,\"    \"],[10,0],[14,0,\"suite-form--fields\"],[12],[1,\"\\n\"],[6,[39,2],null,[[\"placeholder\",\"currentValue\"],[[28,[37,3],[\"yearbook_image_selection.placeholder_selection\"],null],[33,4,[\"name\"]]]],[[\"default\"],[[[[1,\"        \"],[11,3],[24,0,\"is-clickable\"],[4,[38,5],[[30,0],[33,1]],null],[12],[1,[28,[35,3],[\"yearbook_image_selection.all_groups\"],null]],[13],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[33,8]],null]],null],null,[[[1,\"          \"],[11,3],[16,0,[29,[\"is-clickable \",[52,[28,[37,9],[[33,4],[30,1]],null],\"is-selected\",\"\"]]]],[4,[38,5],[[30,0],[33,1],[30,1]],null],[12],[1,[30,1,[\"nameAndSchoolYear\"]]],[13],[1,\"\\n\"]],[1]],null]],[]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,10],null,[[\"addImage\"],[[33,11]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"suite-gallery\"],[12],[1,\"\\n\\n\"],[41,[33,12],[[[1,\"      \"],[1,[28,[35,13],null,[[\"items\",\"selected\",\"selectImage\",\"requestConfirmation\"],[[33,12],[33,14],[33,11],[33,15]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"group\"],false,[\"if\",\"selectGroup\",\"form/dropdown-menu\",\"t\",\"selectedGroup\",\"action\",\"each\",\"-track-array\",\"groups\",\"eq\",\"yearbook/yearbook-image-select\",\"selectImage\",\"images\",\"photo/yearbook-photo-swipe\",\"selected\",\"requestConfirmation\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-image-selection.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});