define("parro-web/components/yearbook/yearbook-payment-method", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;
  const {
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: 'div',
    yearbookService: service(),
    isActive: computed('method', 'order.paymentMethod', function () {
      return this.get('method.method') === this.get('order.paymentMethod');
    }),
    image: computed('method', function () {
      const method = this.get('method.method');

      switch (method) {
        case 'IDEAL':
          return 'figure--ideal';

        case 'KLARNA_PAY_LATER':
          return 'figure--klarna';
      }

      return undefined;
    }),
    name: computed('method', function () {
      const method = this.get('method.method');

      switch (method) {
        case 'IDEAL':
          return 'yearbook_order.method_ideal';

        case 'KLARNA_PAY_LATER':
          return 'yearbook_order.method_klarna';
      }

      return undefined;
    }),
    paymentFee: computed('method.paymentFee', function () {
      const paymentFee = this.get('method.paymentFee');

      if (paymentFee === 0) {
        return 'yearbook_status.free';
      }

      return this.get('yearbookService').convertPriceToDisplay(this.get('method.paymentFee'));
    })
  });

  _exports.default = _default;
});