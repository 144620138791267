define("parro-web/components/modal/yearbook/yearbook-dialog-options", ["exports", "ember", "parro-web/models/services/dialogs", "parro-web/mixins/routeMixin"], function (_exports, _ember, _dialogs, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend(_routeMixin.default, {
    tagName: '',
    yearbookAjaxService: service(),
    yearbookService: service(),
    store: service(),
    yearbook: undefined,
    visible: false,
    featureDialogConfig: _dialogs.default.yearbookFeatureDialogConfig(),
    deleteDialogConfig: _dialogs.default.yearbookDeleteDialogConfig(),

    didInsertElement() {
      this._super(...arguments);

      _ember.default.run.schedule('routerTransitions', this, () => this.set('register', this));
    },

    show(yearbook) {
      this.set('yearbook', yearbook);
      this.set('visible', true);
      this.get('yearbookService').addEmoticonStyling(this, '.modal');
    },

    actions: {
      close() {
        this.flashClear();
        this.set('visible', false);
        this.set('yearbook', undefined);
      },

      edit() {
        const yearbook = this.get('yearbook');
        this.transitionTo('yearbook.editor', yearbook.get('id'));
      },

      preview() {
        const yearbook = this.get('yearbook');
        this.transitionTo('yearbook.preview', yearbook.get('id'));
      },

      info() {
        const yearbook = this.get('yearbook');
        this.transitionTo('yearbook.order-status', yearbook.get('id'));
      },

      pay() {
        const yearbook = this.get('yearbook');
        const order = this.get('yearbook.yearbookOrder');

        const onSuccess = url => this.requestConfirmation(_dialogs.default.yearbookPaymentDialogConfig(), () => window.location.assign(url));

        const onError = error => this.flashError(error);

        this.get('yearbookAjaxService').getYearbookPaymentUrl(yearbook, order).then(onSuccess, onError);
      },

      delete() {
        this.requestConfirmation(this.get('deleteDialogConfig'), () => {
          const yearbook = this.get('yearbook');

          const onSuccess = () => this.send('close');

          const onError = error => this.flashError(error);

          this.get('yearbookAjaxService').deleteYearbook(yearbook.get('id')).then(onSuccess, onError);
        });
      }

    }
  });

  _exports.default = _default;
});