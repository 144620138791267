define("parro-web/serializers/event", ["exports", "parro-web/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      senders: {
        embedded: 'always',
        noKey: true
      },
      attachments: {
        embedded: 'always'
      },
      group: {
        embedded: 'always'
      },
      recipients: {
        embedded: 'always'
      },
      yearbook: {
        embedded: 'always'
      },
      owner: {
        embedded: 'always',
        noKey: true
      },
      childAvatars: {
        embedded: 'always'
      },
      lastEditedBy: {
        embedded: 'always'
      }
    },

    normalize(type, hash, prop) {
      if (hash.yearbookContentSelection) {
        hash.yearbook = hash.yearbookContentSelection;
        delete hash.yearbookContentSelection;
      }

      return this._super(type, hash, prop);
    },

    _parseLinkables(hash) {
      // False ivm embedded records 'senders' @ event
      hash.eventId = this._getLinkableIdFromSelfLink(hash, 'eventId');

      this._parseAndFlattenLinkables(hash, false);
    },

    _getLinkableIdFromSelfLink(object, type) {
      /* [#117423091] Fix special case situations with EVENT, because it's the coupling with GROUP that matters!
       * •  Ember-Data needs a unique ID (for caching / storing). However we can have
       *    the same event for different groups, so we need to use the coupling ID!
       * •  We need the original ID when we update the read status of the event. */
      if (object.dtype === 'event.RAnnouncementEvent' || object.dtype === 'event.RAnnouncementEventPrimer' || object.dtype === 'event.RYearbookAnnouncementEvent' || object.dtype === 'event.RYearbookAnnouncementEventPrimer') {
        return type === 'eventId' ? this._super(object, 'self') : this._super(object, 'koppeling');
      }

      return this._super(object, type);
    }

  });

  _exports.default = _default;
});