define("parro-web/templates/components/navigation/nav-breadcrumb", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span>
    {{#if breadcrumb.isDone}}
      <i class="icon-checkmark"></i>
    {{else}}
      {{t-exists breadcrumb.title}}
    {{/if}}
  </span>
  
  */
  {
    "id": "0DJCLRhA",
    "block": "[[[10,1],[12],[1,\"\\n\"],[41,[33,1,[\"isDone\"]],[[[1,\"    \"],[10,\"i\"],[14,0,\"icon-checkmark\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],[[33,1,[\"title\"]]],null]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"breadcrumb\",\"t-exists\"]]",
    "moduleName": "parro-web/templates/components/navigation/nav-breadcrumb.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});