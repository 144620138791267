define("parro-web/routes/logoff", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Route.extend({
    session: service(),
    authenticationService: service(),

    setupController() {
      this._super(...arguments);

      const idToken = this.get('session.data.authenticated.id_token');
      this.set('session.data.authenticated.invalidated', true);
      this.session.store.persist(this.get('session.data'));
      this.session.invalidate().then(() => {
        this.authenticationService.logout(idToken).then(url => window.location.assign(url));
      });
    }

  });

  _exports.default = _default;
});