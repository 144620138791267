define("parro-web/adapters/attachment", ["exports", "ember", "parro-web/adapters/application", "parro-web/config/environment"], function (_exports, _ember, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;
  const {
    embrah: {
      namespace
    }
  } = _environment.default;

  var _default = _application.default.extend({
    versionService: service(),
    host: computed.readOnly('versionService.host'),

    buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType === 'query' && query.attachmentType && query.groups) {
        const attachmentType = query.attachmentType;
        const groups = query.groups;
        const groupArgs = groups.map(group => 'group=' + group).join('&');
        delete query.attachmentType;
        delete query.groups;
        return this.get('host') + '/' + namespace + '/attachment?attachmentType=' + attachmentType + '&' + groupArgs;
      }

      return this._super(modelName, id, snapshot, requestType, query);
    },

    handleResponse(status, headers, payload) {
      return this._super(status, headers, payload, 20);
    }

  });

  _exports.default = _default;
});