define("parro-web/routes/yearbook/order-status", ["exports", "ember", "parro-web/models/services/dialogs"], function (_exports, _ember, _dialogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP,
    Route,
    inject: {
      service
    }
  } = _ember.default;

  var _default = Route.extend({
    yearbookAjaxService: service(),
    session: service(),

    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },

    model(params) {
      return this.get('yearbookAjaxService').getYearbook(params.yearbook).then(yearbook => {
        return RSVP.hash({
          navConfig: this._getConfig(yearbook),
          yearbook: yearbook
        });
      });
    },

    _getTitle(yearbook) {
      if (yearbook.get('isEditing')) {
        return 'yearbook_status.editing_title';
      } else if (yearbook.get('isPendingPayment')) {
        return 'yearbook_status.pending_title';
      } else if (yearbook.get('isPromo')) {
        return 'yearbook_status.promo_title';
      }

      return 'yearbook_status.success_title';
    },

    _getConfig(yearbook) {
      return _ember.default.Object.create({
        title: this._getTitle(yearbook),
        headerClass: 'header-toolbar--right',
        icon: 'icon-cross-thin',
        toNavChoice: () => this.toNavChoice()
      });
    },

    toNavChoice() {
      this.transitionTo('yearbook.overview');
    },

    actions: {
      pay() {
        const yearbook = this.get('controller.model.yearbook');
        const order = this.get('controller.model.yearbook.yearbookOrder');

        const onSuccess = url => this.send('requestConfirmation', _dialogs.default.yearbookPaymentDialogConfig(), () => window.location.assign(url));

        const onError = error => this.flashError(error);

        this.get('yearbookAjaxService').getYearbookPaymentUrl(yearbook, order).then(onSuccess, onError);
      },

      toNavShoppingCart() {
        this.transitionTo('yearbook.shopping-cart', this.get('controller.model.yearbook.id'));
      }

    }
  });

  _exports.default = _default;
});