define("parro-web/routes/yearbook/editor-images", ["exports", "ember", "parro-web/mixins/routeMixin"], function (_exports, _ember, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP,
    Route,
    inject: {
      service
    },
    isEmpty
  } = _ember.default;

  var _default = Route.extend(_routeMixin.default, {
    yearbookAjaxService: service(),
    yearbookService: service(),
    session: service(),

    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },

    model(params) {
      return this.get('yearbookAjaxService').getYearbook(params.yearbook).then(yearbook => {
        return RSVP.hash({
          navConfig: this._getConfig(),
          announcement: this.get('store').queryRecord('event', {
            id: params.announcement,
            yearbook: yearbook.get('id'),
            group: params.group
          }),
          yearbook: yearbook,
          selection: this.get('yearbookAjaxService').getContentSelection(yearbook.get('id')),
          prices: this.get('yearbookAjaxService').getYearbookPrices(yearbook),
          isLoading: false
        });
      });
    },

    afterModel(model, transition) {
      if (isEmpty(model) || isEmpty(model.yearbook)) {
        return this.refresh();
      }

      const routeGuard = this.get('yearbookService').getRouteGuard(this.get('routeName'), model.yearbook);
      this.useRouteGuard(routeGuard, transition);
    },

    _getConfig() {
      return _ember.default.Object.create({
        title: 'yearbook_editor.title_images',
        headerClass: 'header-toolbar--left',
        icon: 'icon-previous',
        toNavChoice: () => this.toNavChoice()
      });
    },

    toNavChoice() {
      if (this.get('controller.model.isLoading')) {
        return;
      }

      const id = this.get('controller.model.yearbook.id');
      this.transitionTo('yearbook.editor', id);
    },

    actions: {
      showTypeDialog(config) {
        this.send('requestConfirmation', config, () => {
          const id = this.get('controller.model.yearbook.id');
          this.transitionTo('yearbook.type', id);
        });
      }

    }
  });

  _exports.default = _default;
});