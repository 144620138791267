define("parro-web/components/yearbook/yearbook-preview-item", ["exports", "ember", "parro-web/components/yearbook/yearbook-templates-item", "parro-web/mixins/routeMixin", "parro-web/models/services/dialogs"], function (_exports, _ember, _yearbookTemplatesItem, _routeMixin, _dialogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    isPresent
  } = _ember.default;

  var _default = _yearbookTemplatesItem.default.extend(_routeMixin.default, {
    isClickable: computed('content.image', function () {
      return isPresent(this.get('content.image')) || this.get('templateName') === 'yearbook/templates/template-front';
    }),
    actions: {
      selectTemplate() {
        const yearbookId = this.get('yearbook.id');
        const isPromo = this.get('yearbook.isPromo');

        if (isPromo) {
          return _ember.default.run.later(this, () => this.requestConfirmation(_dialogs.default.yearbookPromoDialogConfig()), 100);
        }

        if (this.get('templateName') === 'yearbook/templates/template-front') {
          return this.transitionTo('yearbook.front', yearbookId);
        }

        const announcementId = this.get('item.announcementId');
        const groupId = this.get('item.groupId');
        this.transitionTo('yearbook.editor-images', yearbookId, announcementId, groupId);
      }

    }
  });

  _exports.default = _default;
});