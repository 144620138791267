define("parro-web/models/_attachment", ["exports", "ember-data", "ember"], function (_exports, _emberData, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _emberData.default.Model.extend({
    attachmentType: _emberData.default.attr('string'),
    type: computed.readOnly('file.type'),
    name: computed.readOnly('file.name'),
    file: computed('entries', function () {
      const entry = this.get('entries').find(() => true);

      if (!entry || this.get('attachmentType') === 'IMAGE') {
        return undefined;
      }

      const expiresAt = entry.get('expiresAt');
      return {
        type: entry.get('contentType'),
        url: entry.get('url'),
        name: entry.get('filename'),
        size: entry.get('size'),
        hasExpired: expiresAt && expiresAt < new Date(),
        expiresAt: expiresAt
      };
    }).readOnly(),
    smallestImage: computed('entries', function () {
      const self = this;
      return this._find(this.get('entries'), (entryA, entryB) => {
        const surfaceA = self._calculateSurface(entryA, Number.MAX_VALUE);

        const surfaceB = self._calculateSurface(entryB, Number.MAX_VALUE);

        return surfaceA < surfaceB ? entryA : entryB;
      });
    }).readOnly(),
    largestImage: computed('entries', function () {
      const self = this;
      return this._find(this.get('entries'), (entryA, entryB) => {
        const surfaceA = self._calculateSurface(entryA, Number.MIN_VALUE);

        const surfaceB = self._calculateSurface(entryB, Number.MIN_VALUE);

        return surfaceA > surfaceB ? entryA : entryB;
      });
    }).readOnly(),

    _find(entries, comparator) {
      // The 'comparator' decides whether A or B is used for each value.
      let found;
      entries.forEach(entry => {
        found = comparator(found, entry);
      });
      return found;
    },

    _calculateSurface(entry, fallback) {
      if (entry) {
        const height = entry.get('height');
        const width = entry.get('width');
        return height && width ? height * width : fallback;
      }

      return fallback;
    }

  });

  _exports.default = _default;
});