define("parro-web/mixins/hasAttachmentsMixin", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    isNone
  } = _ember.default;
  /**
   * Provides common logic for models that have attachments - i.e. chatmessage and event.
   */

  var _default = _ember.default.Mixin.create({
    _getFiles(attachments) {
      assert('Must provide attachments', !isNone(attachments));

      const criteria = att => att.get('attachmentType') !== 'IMAGE' && att.get('entries').length > 0;

      const comparator = (a, b) => a.get('index') - b.get('index');

      const toFile = att => att.get('file');

      const isFile = file => file !== undefined && file !== null;

      return attachments.filter(criteria).sort(comparator).map(toFile).filter(isFile);
    },

    _getFilesTTL(files) {
      assert('Must provide files', !isNone(files));
      return files.reduce((initial, file) => {
        const ttl = file.expiresAt;
        const before = !initial || initial > ttl;
        return before ? ttl : initial;
      }, undefined);
    },

    _getImages(attachments) {
      assert('Must provide attachments', !isNone(attachments));

      const criteria = att => att.get('attachmentType') === 'IMAGE' && att.get('entries').length > 0;

      const comparator = (a, b) => a.get('index') - b.get('index');

      return attachments.filter(criteria).sort(comparator);
    },

    _hasImages(images) {
      assert('Must provide images', !isNone(images));
      return this.get('getImages').length > 0;
    }

  });

  _exports.default = _default;
});