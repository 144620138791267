define("parro-web/templates/components/form/dropdown-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if label}}<label class="{{if currentValue 'is-active' ''}} suite-form--fields-label">{{label}}</label>{{/if}}
  {{#if (and (not currentValue) placeholder)}}
    <div class="is-clickable is-inactive" {{action 'toggle' bubbles=false}}>
      <span>{{t-exists placeholder}}</span>
      <i class="{{if optionsVisible 'icon-caret-up' 'icon-caret-down'}}"></i>
    </div>
  {{else if (and (not disabled) currentValue)}}
    <div class="is-clickable" {{action 'toggle' bubbles=false}}>
      <span>{{t-exists currentValue}}</span>
    {{#if (not showClearAction)}}<i class="{{if optionsVisible 'icon-caret-up' 'icon-caret-down'}}"></i>{{/if}}
      {{#if (and showClearAction onClearAction)}}<i {{action onClearAction bubbles=false}} class="icon-cross-thin"></i>{{/if}}
    </div>
  {{else}}
    <div class="is-not-clickable">{{t-exists currentValue}}</div>
  {{/if}}
  
  {{#if optionsVisible}}
    <div class="dropdown">
      <div class="dropdown--menu">
        {{yield}}
      </div>
    </div>
  {{/if}}
  
  */
  {
    "id": "PryO/uH8",
    "block": "[[[41,[33,1],[[[10,\"label\"],[15,0,[29,[[52,[33,2],\"is-active\",\"\"],\" suite-form--fields-label\"]]],[12],[1,[34,1]],[13]],[]],null],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[33,2]],null],[33,5]],null],[[[1,\"  \"],[11,0],[24,0,\"is-clickable is-inactive\"],[4,[38,6],[[30,0],\"toggle\"],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,7],[[33,5]],null]],[13],[1,\"\\n    \"],[10,\"i\"],[15,0,[29,[[52,[33,8],\"icon-caret-up\",\"icon-caret-down\"]]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[28,[37,4],[[33,9]],null],[33,2]],null],[[[1,\"  \"],[11,0],[24,0,\"is-clickable\"],[4,[38,6],[[30,0],\"toggle\"],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,7],[[33,2]],null]],[13],[1,\"\\n  \"],[41,[28,[37,4],[[33,10]],null],[[[10,\"i\"],[15,0,[29,[[52,[33,8],\"icon-caret-up\",\"icon-caret-down\"]]]],[12],[13]],[]],null],[1,\"\\n    \"],[41,[28,[37,3],[[33,10],[33,11]],null],[[[11,\"i\"],[24,0,\"icon-cross-thin\"],[4,[38,6],[[30,0],[33,11]],[[\"bubbles\"],[false]]],[12],[13]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"is-not-clickable\"],[12],[1,[28,[35,7],[[33,2]],null]],[13],[1,\"\\n\"]],[]]]],[]]],[1,\"\\n\"],[41,[33,8],[[[1,\"  \"],[10,0],[14,0,\"dropdown\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dropdown--menu\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"label\",\"currentValue\",\"and\",\"not\",\"placeholder\",\"action\",\"t-exists\",\"optionsVisible\",\"disabled\",\"showClearAction\",\"onClearAction\",\"yield\"]]",
    "moduleName": "parro-web/templates/components/form/dropdown-menu.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});