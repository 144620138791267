define("parro-web/templates/components/yearbook/templates/template-placeholder", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPreview}}<div class="yearbook--shadow"></div>{{/if}}
  {{#if isPreview}}<div class="bg bg--placeholder"></div>{{/if}}
  */
  {
    "id": "aa/e9cs1",
    "block": "[[[41,[33,1],[[[10,0],[14,0,\"yearbook--shadow\"],[12],[13]],[]],null],[1,\"\\n\"],[41,[33,1],[[[10,0],[14,0,\"bg bg--placeholder\"],[12],[13]],[]],null]],[],false,[\"if\",\"isPreview\"]]",
    "moduleName": "parro-web/templates/components/yearbook/templates/template-placeholder.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});