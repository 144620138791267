define("parro-web/components/navigation/nav-header", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    classNameBindings: ['isNavSubtitle'],
    account: service(),
    isNavSubtitle: computed.or('account.isAccountSwitcher', 'showSubtitle'),
    currentAccount: computed.readOnly('account.currentAccount')
  });

  _exports.default = _default;
});