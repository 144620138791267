define("parro-web/routes/yearbook/type", ["exports", "ember", "parro-web/mixins/routeMixin"], function (_exports, _ember, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP,
    Route,
    inject: {
      service
    },
    isPresent,
    isEmpty
  } = _ember.default;

  var _default = Route.extend(_routeMixin.default, {
    yearbookAjaxService: service(),
    yearbookService: service(),
    session: service(),

    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },

    model(params) {
      return RSVP.hash({
        navConfig: this._getConfig(),
        yearbook: this.get('yearbookAjaxService').getYearbook(params.yearbook),
        types: this.get('yearbookAjaxService').getTypes(),
        selection: this.get('yearbookAjaxService').getContentSelection(params.yearbook)
      });
    },

    afterModel(model, transition) {
      if (isEmpty(model) || isEmpty(model.yearbook)) {
        return this.refresh();
      }

      const routeGuard = this.get('yearbookService').getRouteGuard(this.get('routeName'), model.yearbook);
      this.useRouteGuard(routeGuard, transition);
    },

    _getConfig() {
      return _ember.default.Object.create({
        title: 'pagetitle.yearbook_type',
        headerClass: 'header-toolbar--left',
        icon: 'icon-previous',
        toNavChoice: () => this.toNavChoice()
      });
    },

    toNavChoice() {
      const historyRoute = this.get('yearbookService.historyRoute');
      this.set('yearbookService.historyRoute', undefined);

      if (isPresent(historyRoute) && historyRoute !== this.get('routeName')) {
        return this.transitionTo(historyRoute, this.get('controller.model.yearbook.id'));
      }

      this.transitionTo('yearbook.overview');
    }

  });

  _exports.default = _default;
});