define("parro-web/mixins/flashMessageMixin", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    inject: {
      service
    },
    isPresent
  } = _ember.default;
  const ACTIONS_WHITELIST = ['removeMessage', 'showLoader', 'onClose'];
  /**
   *  @deprecated Will deprecate this mixin in favor of Ember Octane, use FlashMessageService
   *
   * • For slightly more convenient default flash message options.
   * • Initializer adds this mixin to every Route, Controller & Component.
   * • Combined with ActionHandler to automatically clear flash messages after an action.
   */

  var _default = _ember.default.Mixin.create(_ember.default.ActionHandler, {
    flashMessages: service(),
    FlashMessageService: service(),

    send(actionName) {
      // Clear flash messages - except when it's a whitelisted action.
      if (!this._isWhiteListedAction(actionName)) {
        this.flashClear();
      }

      this._super(...arguments);
    },

    flashClear() {
      this.FlashMessageService.flashClear();
    },

    flashInfo(message, args) {
      this.FlashMessageService.flashInfo(message, args);
    },

    flashInfoSticky(message, args) {
      this.FlashMessageService.flashInfoSticky(message, args);
    },

    flashWarning(message, args) {
      this.FlashMessageService.flashWarning(message, args);
    },

    flashError(message, args) {
      this.FlashMessageService.flashError(message, args);
    },

    flashSuccess(message, args) {
      this.FlashMessageService.flashSuccess(message, args);
    },

    _isWhiteListedAction(actionName) {
      assert('Must provide actionName', isPresent(actionName));
      return isPresent(ACTIONS_WHITELIST.find(white => white === actionName));
    }

  });

  _exports.default = _default;
});