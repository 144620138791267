define("parro-web/helpers/format-date-polyfill", ["exports", "ember", "parro-web/mixins/dateFormatPolyfillMixin"], function (_exports, _ember, _dateFormatPolyfillMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    getOwner
  } = _ember.default;
  /**
   * A polyfill helper for date formatting in Safari
   */

  var _default = _ember.default.Helper.extend(_dateFormatPolyfillMixin.default, {
    intl: null,

    init() {
      this._super(...arguments);

      this.intl = getOwner(this).lookup('service:intl');
      this.intl.on('localeChanged', this, this.recompute);
    },

    destroy() {
      this._super(...arguments);

      this.intl.off('localeChanged', this, this.recompute);
    },

    compute([date], options) {
      return this.formatDate(date, options);
    }

  });

  _exports.default = _default;
});