define("parro-web/components/button/button", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    title: undefined,
    icon: undefined,
    isLoading: false,
    isDisabled: false,
    className: 'btn--primary',
    isNotClickable: computed('isLoading', 'isDisabled', function () {
      return this.get('isLoading') || this.get('isDisabled');
    })
  });

  _exports.default = _default;
});