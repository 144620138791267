define("parro-web/templates/components/yearbook/templates/template-outro-1", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isPreview}}<div class="yearbook--shadow"></div>{{/if}}
  <article>
    <h2>{{t-exists content.text}}</h2>
    <div class="bg bg--text-normal" style={{image}}></div>
  </article>
  
  */
  {
    "id": "7FWf/dSb",
    "block": "[[[41,[33,1],[[[10,0],[14,0,\"yearbook--shadow\"],[12],[13]],[]],null],[1,\"\\n\"],[10,\"article\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,2],[[33,3,[\"text\"]]],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"bg bg--text-normal\"],[15,5,[36,4]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"isPreview\",\"t-exists\",\"content\",\"image\"]]",
    "moduleName": "parro-web/templates/components/yearbook/templates/template-outro-1.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});