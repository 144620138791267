define("parro-web/components/yearbook/templates/template-blank", ["exports", "parro-web/components/yearbook/templates/template-main"], function (_exports, _templateMain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _templateMain.default.extend({});

  _exports.default = _default;
});