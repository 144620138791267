define("parro-web/routes/yearbook/overview", ["exports", "ember", "parro-web/utils/mobileUtil", "parro-web/models/services/dialogs"], function (_exports, _ember, _mobileUtil, _dialogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP,
    Route,
    inject: {
      service
    }
  } = _ember.default;

  var _default = Route.extend({
    yearbookAjaxService: service(),
    session: service(),

    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },

    model() {
      return RSVP.hash({
        navConfig: this._getConfig(),
        yearbooks: this.get('yearbookAjaxService').getYearbooks()
      });
    },

    _getConfig() {
      return _ember.default.Object.create({
        title: 'applicationtitle',
        parroIcon: 'icon-parro',
        headerClass: 'header-toolbar--left',
        icon: 'icon-previous',
        toNavChoice: () => this.toNavChoice()
      });
    },

    toNavChoice() {
      this.send('requestConfirmation', _dialogs.default.returnToParroDialogConfig(), () => {
        _mobileUtil.default.terminateYearbookWebview();

        window.open('https://talk.parro.com', '_self');
      });
    }

  });

  _exports.default = _default;
});