define("parro-web/templates/yearbook", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="l-flex-content yearbook" id="detail-content">
    {{outlet}}
  </div>
  */
  {
    "id": "hkbSQB0L",
    "block": "[[[10,0],[14,0,\"l-flex-content yearbook\"],[14,1,\"detail-content\"],[12],[1,\"\\n  \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "parro-web/templates/yearbook.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});