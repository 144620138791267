define("parro-web/templates/components/navigation/nav-breadcrumbs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each breadcrumbs as |breadcrumb|}}
    {{navigation/nav-breadcrumb breadcrumb=breadcrumb}}
  {{/each}}
  
  */
  {
    "id": "1/tuhEBs",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"breadcrumb\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[\"breadcrumb\"],false,[\"each\",\"-track-array\",\"breadcrumbs\",\"navigation/nav-breadcrumb\"]]",
    "moduleName": "parro-web/templates/components/navigation/nav-breadcrumbs.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});