define("parro-web/components/error/application-error", ["exports", "ember", "@ember/component"], function (_exports, _ember, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    config: _ember.default.Object.create({
      title: 'pagetitle.404'
    })
  });

  _exports.default = _default;
});