define("parro-web/components/development/environment-select", ["exports", "parro-web/config/environment", "parro-web/utils/browserUtil", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking"], function (_exports, _environment, _browserUtil, _component, _service, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EnvironmentSelect = (_class = class EnvironmentSelect extends _component.default {
    get environment() {
      return _environment.default.environment;
    }

    get version() {
      return _environment.default.APP.version;
    }

    get host() {
      return this.versionService.host;
    }

    get value() {
      return this.versionService.name;
    }

    get restVersion() {
      return this.versionService.restVersion;
    }

    set restVersion(value) {
      this.versionService.set('restVersion$', value);

      _browserUtil.default.setItem('rest-version', value);
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "versionService", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "FlashMessageService", _descriptor3, this);

      _initializerDefineProperty(this, "environments", _descriptor4, this);

      this._setupHosts();
    }

    _setupHosts() {
      const environment = _environment.default.environments[_environment.default.environment];
      const acceptance = _environment.default.environments.acceptance;
      const acceptanceMig = _environment.default.environments.acceptance_mig;

      const onSuccess = environments => {
        environments.unshift(this._createEnvironment(environment));
        environments.unshift(this._createEnvironment(acceptance));
        environments.unshift(this._createEnvironment(acceptanceMig));
        this.environments = environments;
      };

      const onError = () => this.FlashMessageService.flashError('error.unknown_error');

      this.versionService.getEnvironments().then(onSuccess, onError);
    }

    _createEnvironment(environment) {
      return {
        name: environment.name,
        rest: environment.host,
        loginUrl: environment.loginUrl,
        parnassysRestUrl: environment.parnassysRestUrl,
        ouderportaalUrl: environment.ouderportaalUrl
      };
    }

    _setEnvironment(environment) {
      this.versionService.set('host$', environment.rest);
      this.versionService.set('loginUrl$', environment.loginUrl);
      this.versionService.set('parnassysRestUrl$', environment.parnassysRestUrl);
      this.versionService.set('ouderportaalUrl$', environment.ouderportaalUrl);
      this.versionService.set('name$', environment.name);
    }

    onChange(environment) {
      _browserUtil.default.setItem('environment', environment);

      this._setEnvironment(environment);
    }

    clearEnvironment() {
      _browserUtil.default.removeItem('environment');

      const defaultEnv = _environment.default.environments[_environment.default.environment];

      const environment = this._createEnvironment(defaultEnv);

      this._setEnvironment(environment);
    }

    clearVersion() {
      this.versionService.set('restVersion$', _environment.default.REST_version);

      _browserUtil.default.removeItem('rest-version');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "versionService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "FlashMessageService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "environments", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearEnvironment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearEnvironment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearVersion", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearVersion"), _class.prototype)), _class);
  _exports.default = EnvironmentSelect;
});