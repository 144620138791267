define("parro-web/templates/components/form/input-radio-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{input type="radio" checked=checked name=name}}
  <span class="radio-button--checkmark"></span>
  <label>{{label}}</label>
  
  */
  {
    "id": "r3MC/qqj",
    "block": "[[[1,[28,[35,0],null,[[\"type\",\"checked\",\"name\"],[\"radio\",[33,1],[33,2]]]]],[1,\"\\n\"],[10,1],[14,0,\"radio-button--checkmark\"],[12],[13],[1,\"\\n\"],[10,\"label\"],[12],[1,[34,3]],[13],[1,\"\\n\"]],[],false,[\"input\",\"checked\",\"name\",\"label\"]]",
    "moduleName": "parro-web/templates/components/form/input-radio-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});