define("parro-web/components/yearbook/templates/template-3", ["exports", "ember", "parro-web/components/yearbook/templates/template-main"], function (_exports, _ember, _templateMain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _templateMain.default.extend({
    isBadQuality: computed('content.image', function () {
      return this.determineBadQuality(106, 184);
    })
  });

  _exports.default = _default;
});