define("parro-web/templates/components/yearbook/yearbook-cover-images", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yearbook/yearbook-image-selection images=images yearbook=yearbook selectImage=(action 'selectImage') groups=groups selectedGroup=selectedGroup selected=yearbook.frontCover requestConfirmation=requestConfirmation selectGroup=(action 'selectGroup')}}
  
  {{#if (and (not showPlaceholder) attachments)}}
    {{#infinity-loader infinityModel=attachments triggerOffset=100 hideOnInfinity=true}}
      {{loader/infinity-loader-text infinityModel=attachments}}
    {{/infinity-loader}}
  {{/if}}
  */
  {
    "id": "NU5H/jCN",
    "block": "[[[1,[28,[35,0],null,[[\"images\",\"yearbook\",\"selectImage\",\"groups\",\"selectedGroup\",\"selected\",\"requestConfirmation\",\"selectGroup\"],[[33,1],[33,2],[28,[37,3],[[30,0],\"selectImage\"],null],[33,4],[33,5],[33,2,[\"frontCover\"]],[33,6],[28,[37,3],[[30,0],\"selectGroup\"],null]]]]],[1,\"\\n\\n\"],[41,[28,[37,8],[[28,[37,9],[[33,10]],null],[33,11]],null],[[[6,[39,12],null,[[\"infinityModel\",\"triggerOffset\",\"hideOnInfinity\"],[[33,11],100,true]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,13],null,[[\"infinityModel\"],[[33,11]]]]],[1,\"\\n\"]],[]]]]]],[]],null]],[],false,[\"yearbook/yearbook-image-selection\",\"images\",\"yearbook\",\"action\",\"groups\",\"selectedGroup\",\"requestConfirmation\",\"if\",\"and\",\"not\",\"showPlaceholder\",\"attachments\",\"infinity-loader\",\"loader/infinity-loader-text\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-cover-images.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});