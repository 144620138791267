define("parro-web/services/validation-service", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Regex source: http://stackoverflow.com/a/46181
  const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const URL_REGEX = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
  const {
    inject: {
      service
    },
    isPresent
  } = _ember.default;

  var _default = _ember.default.Service.extend({
    fileService: service(),

    isValidEmailaddress(email) {
      return isPresent(email) && EMAIL_REGEX.test(email);
    },

    isValidURL(url) {
      return isPresent(url) && URL_REGEX.test(url);
    },

    isValidFile(file, mimeType) {
      return this.fileService.getFileDefinition(file).mime === mimeType;
    },

    getFileValidations() {
      const convert = size => size * 1024;

      return {
        maxDefaultSize: convert(5120),
        maxVideoSize: convert(102400),
        maxAudioSize: convert(51200),
        maxImageSize: convert(5120),
        maxNameLength: 100
      };
    }

  });

  _exports.default = _default;
});