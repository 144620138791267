define("parro-web/models/navigation/navchoicemodel", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Object.extend({
    name: '',
    descriptions: undefined,
    url: undefined,
    // use for external URLs
    route: undefined,
    type: undefined,
    onClick: undefined,
    model: undefined,
    isInactive: false,
    isGroep: false,
    isGesprek: false,
    profile: undefined,
    icon: undefined,
    indicator: undefined,
    hasDescription: _ember.default.computed('description', function () {
      return this.get('description');
    }),
    hasUnread: false,
    isForPath: function (path, param) {
      const modelId = this.get('model') ? this.get('model.id') || this.get('model') : undefined;
      return this.get('route') === path && modelId === param;
    }
  });

  _exports.default = _default;
});