define("parro-web/templates/components/navigation/nav-header-yearbook", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#navigation/nav-header description=config.title classNames='header-toolbar' icon=config.parroIcon}}
  
    {{#if config.showMenu}}
      <div {{action config.showMenu}} class="header-toolbar--left header-toolbar--yearbook-menu">
        <div><i class="icon-menu"></i><span>Menu</span></div>
      </div>
  
    {{else if (and config.icon config.headerClass config.toNavChoice)}}
      <div {{action config.toNavChoice}} class="{{config.headerClass}}">
        <a><i class="{{config.icon}}"></i></a>
      </div>
    {{/if}}
  
    {{#if config.onFinish}}
      <div {{action config.onFinish}} class="header-toolbar--right header-toolbar--yearbook-action"><i class="icon-shopping-cart"></i></div>
    {{/if}}
  
  {{/navigation/nav-header}}
  */
  {
    "id": "yNZJMP43",
    "block": "[[[6,[39,0],null,[[\"description\",\"classNames\",\"icon\"],[[33,1,[\"title\"]],\"header-toolbar\",[33,1,[\"parroIcon\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[33,1,[\"showMenu\"]],[[[1,\"    \"],[11,0],[24,0,\"header-toolbar--left header-toolbar--yearbook-menu\"],[4,[38,3],[[30,0],[33,1,[\"showMenu\"]]],null],[12],[1,\"\\n      \"],[10,0],[12],[10,\"i\"],[14,0,\"icon-menu\"],[12],[13],[10,1],[12],[1,\"Menu\"],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"]],[]],[[[41,[28,[37,4],[[33,1,[\"icon\"]],[33,1,[\"headerClass\"]],[33,1,[\"toNavChoice\"]]],null],[[[1,\"    \"],[11,0],[16,0,[29,[[33,1,[\"headerClass\"]]]]],[4,[38,3],[[30,0],[33,1,[\"toNavChoice\"]]],null],[12],[1,\"\\n      \"],[10,3],[12],[10,\"i\"],[15,0,[29,[[33,1,[\"icon\"]]]]],[12],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]],[1,\"\\n\"],[41,[33,1,[\"onFinish\"]],[[[1,\"    \"],[11,0],[24,0,\"header-toolbar--right header-toolbar--yearbook-action\"],[4,[38,3],[[30,0],[33,1,[\"onFinish\"]]],null],[12],[10,\"i\"],[14,0,\"icon-shopping-cart\"],[12],[13],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"navigation/nav-header\",\"config\",\"if\",\"action\",\"and\"]]",
    "moduleName": "parro-web/templates/components/navigation/nav-header-yearbook.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});