define("parro-web/components/yearbook/yearbook-drawing", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    yearbookAjaxService: service(),
    ScrollService: service(),

    didInsertElement() {
      this._super(...arguments);

      this.ScrollService.setScrollTop($('html'), false);
    },

    actions: {
      toggle() {
        const yearbook = this.get('yearbook');
        yearbook.toggleProperty('parroDrawingsEnabled');

        const onSuccess = () => {};

        const onError = () => this.flashError('error.unknown_error');

        this.get('yearbookAjaxService').editYearbook(yearbook).then(onSuccess, onError);
      }

    }
  });

  _exports.default = _default;
});