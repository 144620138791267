define("parro-web/templates/components/development/environment-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="suite-form">
    <li>
      {{#form/dropdown-menu currentValue=this.value onClearAction=(action 'clearEnvironment') showClearAction=true}}
        {{#each this.environments as |env|}}
          <a class="is-clickable" {{action this.onChange env}}>{{env.name}}</a>
        {{/each}}
      {{/form/dropdown-menu}}
    </li>
    <li>{{form/form-field-text value=this.restVersion maxLength=215 onClearAction=(action 'clearVersion') showClearAction=true}}</li>
  </ul>
  
  <small class="text--emphasis">({{this.version}}) {{this.environment}}</small>
  <small>{{this.host}}</small>
  
  */
  {
    "id": "qWGxRDPj",
    "block": "[[[10,\"ul\"],[14,0,\"suite-form\"],[12],[1,\"\\n  \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"currentValue\",\"onClearAction\",\"showClearAction\"],[[30,0,[\"value\"]],[28,[37,1],[[30,0],\"clearEnvironment\"],null],true]],[[\"default\"],[[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"environments\"]]],null]],null],null,[[[1,\"        \"],[11,3],[24,0,\"is-clickable\"],[4,[38,1],[[30,0],[30,0,[\"onChange\"]],[30,1]],null],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[1]],null]],[]]]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,\"li\"],[12],[1,[28,[35,4],null,[[\"value\",\"maxLength\",\"onClearAction\",\"showClearAction\"],[[30,0,[\"restVersion\"]],215,[28,[37,1],[[30,0],\"clearVersion\"],null],true]]]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"small\"],[14,0,\"text--emphasis\"],[12],[1,\"(\"],[1,[30,0,[\"version\"]]],[1,\") \"],[1,[30,0,[\"environment\"]]],[13],[1,\"\\n\"],[10,\"small\"],[12],[1,[30,0,[\"host\"]]],[13],[1,\"\\n\"]],[\"env\"],false,[\"form/dropdown-menu\",\"action\",\"each\",\"-track-array\",\"form/form-field-text\"]]",
    "moduleName": "parro-web/templates/components/development/environment-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});