define("parro-web/components/form/input-number", ["exports", "ember", "parro-web/mixins/metricsMixin"], function (_exports, _ember, _metricsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _ember.default.Component.extend(_metricsMixin.default, {
    tagName: 'div',
    classNames: ['number--input-container'],
    value: 0,
    min: 0,
    max: 10,
    isMinusEnabled: computed('value', 'min', function () {
      return this.get('value') > this.get('min');
    }),
    isPlusEnabled: computed('value', 'max', function () {
      return this.get('value') < this.get('max');
    }),
    valueObserver: _ember.default.observer('value', function () {
      _ember.default.run.scheduleOnce('afterRender', this, () => {
        const value = parseInt(this.get('value'));
        const min = this.get('min');
        const max = this.get('max');

        if (value > max) {
          this.set('value', max);
        } else if (isNaN(value)) {
          this.set('value', min);
        }
      });
    }),
    actions: {
      minus() {
        if (this.get('isMinusEnabled')) {
          this.decrementProperty('value');
        }
      },

      add() {
        if (this.get('isPlusEnabled')) {
          this.incrementProperty('value');
        }
      }

    }
  });

  _exports.default = _default;
});