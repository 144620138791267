define("parro-web/models/sender", ["exports", "ember-data", "ember"], function (_exports, _emberData, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    isPresent
  } = _ember.default;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'event.RGroupRecipient'
    }),
    group: _emberData.default.belongsTo('grouprecipientgroup', {
      async: true
    }),
    identity: _emberData.default.belongsTo('identity', {
      async: true
    }),
    name: computed('group', 'identity', function () {
      const identity = this.get('identity');
      const group = this.get('group');
      return isPresent(identity) ? identity.get('name') : isPresent(group) ? group.get('name') : '';
    })
  });

  _exports.default = _default;
});