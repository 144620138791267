define("parro-web/services/format-datetime-service", ["exports", "ember", "parro-web/mixins/dateFormatPolyfillMixin", "moment"], function (_exports, _ember, _dateFormatPolyfillMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    isEmpty
  } = _ember.default;

  var _default = _ember.default.Service.extend(_dateFormatPolyfillMixin.default, {
    intl: service(),

    formatTimeRange(range) {
      if (Date.isValid(range?.startTime) && Date.isValid(range?.endTime)) {
        const startTime = (0, _moment.default)(range.startTime).format('LT');
        const endTime = (0, _moment.default)(range.endTime).format('LT');
        return startTime + ' - ' + endTime;
      }

      if (Date.isValid(range?.startTime)) {
        return (0, _moment.default)(range.startTime).format('LT');
      }

      return undefined;
    },

    formatTime(hours, minutes, is12HourClock) {
      const getDoubleDigits = str => ('00' + str).slice(-2);

      let ampm = '';

      if (is12HourClock) {
        ampm = ' ' + (Math.floor(hours / 12) ? 'PM' : 'AM');
        hours = hours % 12;

        if (hours === 0) {
          hours = '12';
        }
      }

      return getDoubleDigits(hours) + ':' + getDoubleDigits(minutes) + ampm;
    },

    formatDate(date, options = {}) {
      if (isEmpty(date)) {
        return undefined;
      }

      if (options.useRelative && Date.isToday(date)) {
        return this.intl.t('date.today');
      }

      if (options.useRelative && Date.isTomorrow(date)) {
        return this.intl.t('date.tomorrow');
      }

      return String.capitalizeFirstLetter(this.intl.formatDate(date, options));
    }

  });

  _exports.default = _default;
});