define("parro-web/initializers/override-Array", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assert,
    isPresent
  } = _ember.default;
  var _default = {
    name: 'override-Array',

    initialize() {
      assert('Array.isSimilarToArray is already declared!', !Array.isSimilarToArray);

      Array.isSimilarToArray = function (array) {
        return array instanceof Object && array.filter instanceof Function && array.find instanceof Function && array.forEach instanceof Function && array.indexOf instanceof Function && array.map instanceof Function;
      };

      assert('Array.orEmpty is already declared!', !Array.orEmpty);

      Array.orEmpty = function (array) {
        return Array.isSimilarToArray(array) ? array : [];
      };

      assert('Array.previousValue is already declared!', !Array.previousValue);

      Array.previousValue = function (array, index, property) {
        const newArray = array.get && array.get('content') ? array.map(i => i) : array;
        const previous = newArray instanceof Array && index > 0 ? newArray[index - 1] : undefined;

        if (previous instanceof Object && isPresent(property)) {
          return isPresent(previous.get) ? previous.get(property) : previous[property];
        }

        return previous;
      };

      assert('Array.nextValue is already declared!', !Array.nextValue);

      Array.nextValue = function (array, index, property) {
        const next = array instanceof Array && index <= array.length - 1 ? array[index + 1] : undefined;

        if (next instanceof Object && isPresent(property)) {
          return isPresent(next.get) ? next.get(property) : next[property];
        }

        return next;
      };

      assert('Array.unique is already declared!', !Array.unique);

      Array.unique = function (array, property) {
        return array.reduce((unique, o) => {
          const isUnique = unique.find(item => {
            if (property && item.get && o.get && item.get(property) && o.get(property)) {
              const isDate1 = item.get(property) instanceof Date;
              const isDate2 = o.get(property) instanceof Date;
              return isDate1 && isDate2 && item.get(property).getTime() === o.get(property).getTime() || item.get(property) === o.get(property);
            }

            const isDate1 = item instanceof Date;
            const isDate2 = o instanceof Date;
            return isDate1 && isDate2 && item.getTime() === o.getTime() || o === item;
          });

          if (!isUnique) {
            unique.push(o);
          }

          return unique;
        }, []);
      };

      assert('Array.groupBy is already declared!', !Array.groupBy);

      Array.groupBy = function (arr, property) {
        return arr.reduce(function (memo, x) {
          if (!memo[x[property]]) {
            memo[x[property]] = [];
          }

          memo[x[property]].push(x);
          return memo;
        }, {});
      };

      assert('Array.toEnumeratedString is already declared!', !Array.toEnumeratedString);

      Array.toEnumeratedString = function (array, property, intl) {
        const properties = array.map(p => p.get(property));
        const and = intl.t('conditional.and');

        if (properties.length > 1) {
          return properties.slice(0, -1).join(', ') + ' ' + and + ' ' + properties.slice(-1);
        } else {
          return properties[0];
        }
      };

      assert('Array.isEqual is already declared!', !Array.isEqual);

      Array.isEqual = function (arr1, arr2) {
        if (arr1 === arr2) {
          return true;
        }

        if (arr1.length !== arr2.length) {
          return false;
        }

        for (let i = 0; i < arr1.length; ++i) {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }

        return true;
      };
    }

  };
  _exports.default = _default;
});