define("parro-web/models/guardianchild", ["exports", "ember", "ember-data", "parro-web/models/person", "ember-inflector"], function (_exports, _ember, _emberData, _person, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = _ember.default;

  var _default = _person.default.extend({
    intl: service(),
    dtype: _emberData.default.attr('string', {
      defaultValue: 'identity.RGuardianChildPrimer'
    }),
    lastModifiedAt: _emberData.default.attr('date'),
    gender: _emberData.default.attr('string'),
    cachedAvatarUrl: _emberData.default.attr('string'),
    guardianId: _emberData.default.attr('number'),
    guardianName: _emberData.default.attr('string'),
    guardianCachedAvatarUrl: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    editableFrom: _emberData.default.attr('date'),
    editableTill: _emberData.default.attr('date'),
    // ID, identityID and childID are set via serializer
    identityID: _emberData.default.attr('number'),
    childID: _emberData.default.attr('number')
  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('guardianchild', 'guardianchild');
});