define("parro-web/serializers/teacher", ["exports", "parro-web/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      organisation: {
        embedded: 'always'
      },
      doNotDisturb: {
        embedded: 'always'
      }
    },

    _parseLinkables(hash) {
      hash.identityId = this._getLinkableIdFromSelfLink(hash, 'identiteit');

      this._parseAndFlattenLinkables(hash, false);
    }

  });

  _exports.default = _default;
});