define("parro-web/models/organisation", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'auth.ROrganisationPrimer'
    }),
    name: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    pricingPlan: _emberData.default.attr('string'),
    code: _emberData.default.attr('string')
  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('organisation', 'organisation');
});