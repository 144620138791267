define("parro-web/adapters/guardian", ["exports", "ember", "parro-web/adapters/application", "parro-web/config/environment"], function (_exports, _ember, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;
  const {
    embrah: {
      namespace
    }
  } = _environment.default;

  var _default = _application.default.extend({
    versionService: service(),
    host: computed.readOnly('versionService.host'),

    buildURL(modelName, id, snapshot, requestType, query) {
      if (modelName === 'guardian' && requestType === 'query' && query.child && query.guardianHasAccount) {
        const child = query.child;
        const guardianHasAccount = query.guardianHasAccount;
        delete query.child;
        delete query.guardianHasAccount;
        return this.get('host') + '/' + namespace + '/guardian?child=' + child + '&guardianHasAccount=' + guardianHasAccount;
      }

      return this._super(modelName, id, snapshot, requestType, query);
    }

  });

  _exports.default = _default;
});