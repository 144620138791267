define("parro-web/helpers/is-authenticated", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Convenience helper to check if the current session is authenticated (logged in).
   */
  var _default = _ember.default.Helper.extend({
    session: _ember.default.inject.service(),

    compute() {
      return this.get('session.isAuthenticated');
    }

  });

  _exports.default = _default;
});