define("parro-web/components/form/dropdown-menu", ["exports", "ember", "parro-web/mixins/ClickElsewhereMixin", "@ember/utils"], function (_exports, _ember, _ClickElsewhereMixin, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend(_ClickElsewhereMixin.default, {
    tagName: 'div',
    classNames: ['dropdown--container'],
    classNameBindings: ['isError', 'isInactive', 'isFocused', 'isFilled'],
    ScrollService: service(),
    isInactive: computed.bool('disabled'),
    isFocused: computed.bool('optionsVisible'),
    isFilled: computed('currentValue', function () {
      return (0, _utils.isPresent)(this.currentValue);
    }),

    setupOutsideClickListener() {
      this._super();
    },

    removeOutsideClickListener() {
      this._super();
    },

    handleOutsideClick() {
      if (!this.isDestroying && !this.isDestroyed) {
        this.send('toggle');
      } else {
        this.removeOutsideClickListener();
      }
    },

    _determinePosition() {
      const anchor = this.get('anchor') || $(this.element);
      const dropdown = anchor.find('.dropdown');
      const offset = anchor[0]?.getBoundingClientRect();
      dropdown.addClass('is-invisible');
      dropdown.css('position', 'fixed');
      dropdown.css('left', offset?.left + 'px');
      dropdown.css('top', offset?.top + anchor.outerHeight() + 10 + 'px');
      dropdown.css('width', anchor.outerWidth());

      if (this.ScrollService.isTargetOutsideOfContainer($(window), dropdown, dropdown.height()).bottom) {
        dropdown.css('top', offset?.top - dropdown.height() - 10 + 'px');
      }

      dropdown.removeClass('is-invisible');
      const option = dropdown.find('a:contains(' + this.get('currentValue') + ')');
      this.ScrollService.scrollToTarget(dropdown, option, false);
    },

    actions: {
      toggle() {
        this.toggleProperty('optionsVisible');

        if (this.get('optionsVisible')) {
          this.setupOutsideClickListener();

          if (this.get('onClickAction') && !this.isDestroying && !this.isDestroyed) {
            this.get('onClickAction').apply();
          }
        } else {
          this.removeOutsideClickListener();

          if (this.get('onLeaveAction') && !this.isDestroying && !this.isDestroyed) {
            this.get('onLeaveAction').apply();
          }
        }

        _ember.default.run.later(this, () => this._determinePosition(), 1);
      }

    }
  });

  _exports.default = _default;
});