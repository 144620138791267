define("parro-web/models/attachmententry", ["exports", "parro-web/models/_attachmententry", "ember-data"], function (_exports, _attachmententry, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _attachmententry.default.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'attachment.RAttachmentImageEntry'
    }),
    contentType: _emberData.default.attr('string'),
    filename: _emberData.default.attr('string'),
    expiresAt: _emberData.default.attr('date')
  });

  _exports.default = _default;
});