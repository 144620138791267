define("parro-web/services/account", ["exports", "ember", "parro-web/config/environment"], function (_exports, _ember, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    computed: {
      equal,
      readOnly
    },
    inject: {
      service
    },
    isEmpty,
    isPresent,
    RSVP: {
      Promise
    }
  } = _ember.default;
  const {
    embrah: {
      customMediaType,
      namespace
    }
  } = _environment.default;
  const assign = _ember.default.assign;

  var _default = _ember.default.Service.extend({
    intl: service(),
    store: service(),
    session: service(),
    fileService: service(),
    dataprovider: service(),
    ajaxService: service('ajax'),
    attachmentsService: service(),
    s3Service: service('s3-upload'),
    versionService: service(),
    formatDatetimeService: service(),
    host: computed.readOnly('versionService.host'),
    applicationError: undefined,
    type: readOnly('session.data.authenticated.accountType'),
    isTeacher: equal('type', 'TEACHER'),
    isGuardian: equal('type', 'GUARDIAN'),
    identity: computed('type', 'session.data.authenticated.identity', 'session.data.authenticated.authrole', function () {
      const identity = this.get('session.data.authenticated.identity') || {};
      const firstname = identity.firstname || '';
      const surname = identity.surname || '';
      const name = firstname + ' ' + surname;
      const email = identity.email;
      const linkedIdentityHash = identity.linkedIdentityHash;
      const accountType = this.get('session.data.authenticated.accountType');
      const authRole = this.get('session.data.authenticated.authrole');

      const id = v => this.get('dataprovider').findId(v, 'self');

      let personas = [];

      if (accountType === 'TEACHER') {
        personas = Array.orEmpty(identity.teachers).map(v => assign(_ember.default.Object.create({}), v));
        personas.forEach(p => {
          if (isPresent(p.organisation)) {
            p.set('organisation.id', id(p.organisation));
          }
        });
      } else if (accountType === 'GUARDIAN') {
        personas = Array.orEmpty(identity.guardians).map(v => assign(_ember.default.Object.create({}), v));
      }

      personas.forEach(p => p.set('id', id(p)));
      personas.forEach(p => p.set('authRole', accountType + ':' + p.id));
      const currentPersona = personas.find(p => p.get('authRole') === authRole);
      return _ember.default.Object.create({
        id: this.get('dataprovider').findId(identity, 'self'),
        firstname: firstname,
        surname: surname,
        name: name,
        email: email,
        linkedIdentityHash: linkedIdentityHash,
        personas: personas,
        currentPersona: currentPersona
      });
    }),
    lastExternalSynchronize: computed.readOnly('session.data.authenticated.lastExternalSynchronize'),
    noSync: computed('lastExternalSynchronize', function () {
      return this.get('isTeacher') && isEmpty(this.get('lastExternalSynchronize'));
    }),
    hasMultipleAccounts: computed.gt('identity.personas.length', 1),
    isAccountSwitcher: computed.gt('identity.personas.length', 1),
    currentAccount: computed.readOnly('identity.currentPersona.organisation.name'),

    isMe(person) {
      if (person === null || person === undefined) {
        return false;
      }

      const identity = this.get('identity.id');
      const linkedIdentityHash = this.get('identity.linkedIdentityHash');

      if (typeof person === 'object') {
        if (this.isIdentity(this.getValue(person, 'linkedIdentityHash'), linkedIdentityHash)) {
          return true;
        }

        if (this.isIdentity(this.getValue(person, 'identity.id'), identity)) {
          return true;
        }

        if (this.isIdentity(this.getValue(person, 'identityId'), identity)) {
          return true;
        }

        if (this.isIdentity(this.getValue(person, 'personId'), identity)) {
          return true;
        }

        if (this.isIdentity(this.getValue(person, 'id'), identity)) {
          return true;
        }

        const personPerson = this.getValue(person, 'person');

        if (isPresent(personPerson)) {
          return this.isMe(personPerson);
        }

        const personTeacher = this.getValue(person, 'teacher');

        if (isPresent(personTeacher)) {
          return this.isMe(personTeacher);
        }

        const personGuardian = this.getValue(person, 'guardian');

        if (isPresent(personGuardian)) {
          return this.isMe(personGuardian);
        }
      }

      return this.isIdentity(person, identity);
    },

    isIdentity(a, b) {
      return isPresent(a) && (a === b || parseInt(a) === b);
    },

    getValue(object, field) {
      return object.get ? object.get(field) : object[field];
    },

    getAccount(options) {
      return new Promise((resolve, reject) => {
        const request = {
          timeout: 10000,
          type: 'GET',
          url: this.get('host') + '/' + namespace + '/account/me',
          contentType: customMediaType,
          beforeSend: xhr => this.get('ajaxService').addDefaultHeaders(xhr, options.tokens.access_token)
        };
        this.get('ajaxService').executeRequest(request).then(json => resolve(json)).catch(jqXHR => reject(jqXHR));
      });
    },

    meSync(accessToken) {
      return new Promise(resolve => {
        const request = {
          type: 'PUT',
          url: this.get('host') + '/' + namespace + '/account/me/sync',
          disableAjaxLoader: true,
          timeout: 10000,
          beforeSend: xhr => this.get('ajaxService').addDefaultHeaders(xhr, accessToken)
        }; // We dont want this call to be blocking, we know the token is valid otherwise this
        // call would not be made. So worst case our user info is not in sync. This will be
        // fixed with the next login or when a colleague uses the sync button and banaan

        this.get('ajaxService').executeRequest(request).then(() => resolve()).catch(() => resolve());
      });
    },

    createAuthRole(person, type) {
      return type + ':' + person.get('id');
    },

    getSchoolYears() {
      return this.get('store').findAll('schoolyear');
    }

  });

  _exports.default = _default;
});