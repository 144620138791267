define("parro-web/models/teacher", ["exports", "ember-data", "ember", "parro-web/models/person", "ember-inflector"], function (_exports, _emberData, _ember, _person, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _person.default.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'identity.RTeacher'
    }),
    organisation: _emberData.default.belongsTo('organisation', {
      async: false
    }),
    doNotDisturb: _emberData.default.belongsTo('donotdisturb', {
      async: false
    }),
    identity: _emberData.default.belongsTo('identity', {
      async: true
    }),
    cachedAvatarUrl: _emberData.default.attr('string'),
    calendarCredits: _emberData.default.attr('number'),
    identityId: _emberData.default.attr('number'),
    pricingPlan: _emberData.default.attr('string'),
    allowguardiancontactteacher: _emberData.default.attr('boolean'),
    isGuardian: false,
    isTeacher: true,
    isUsingParro: computed.bool('accountActive')
  });
  /* ember-data vertellen wat de meervoudsvorm van de modelnaam is */


  _exports.default = _default;

  _emberInflector.default.inflector.irregular('teacher', 'teacher');
});