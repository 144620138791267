define("parro-web/templates/components/form/select-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if header}}
  
    <select onchange={{action (mut value) value="target.value"}} class={{selectClass}} disabled={{disabled}}>
      <optgroup label="{{t-exists header}}">
        {{form/select-value min=min max=max value=value values=values}}
      </optgroup>
    </select>
  
  {{else}}
  
    <select onchange={{action (mut value) value="target.value"}} class={{selectClass}} disabled={{disabled}}>
      {{form/select-value min=min max=max value=value values=values}}
    </select>
  
  {{/if}}
  */
  {
    "id": "kzaYJ2Zv",
    "block": "[[[41,[33,1],[[[1,\"\\n  \"],[10,\"select\"],[15,\"onchange\",[28,[37,2],[[30,0],[28,[37,3],[[33,4]],null]],[[\"value\"],[\"target.value\"]]]],[15,0,[36,5]],[15,\"disabled\",[36,6]],[12],[1,\"\\n    \"],[10,\"optgroup\"],[15,\"label\",[29,[[28,[37,7],[[33,1]],null]]]],[12],[1,\"\\n      \"],[1,[28,[35,8],null,[[\"min\",\"max\",\"value\",\"values\"],[[33,9],[33,10],[33,4],[33,11]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]],[[[1,\"\\n  \"],[10,\"select\"],[15,\"onchange\",[28,[37,2],[[30,0],[28,[37,3],[[33,4]],null]],[[\"value\"],[\"target.value\"]]]],[15,0,[36,5]],[15,\"disabled\",[36,6]],[12],[1,\"\\n    \"],[1,[28,[35,8],null,[[\"min\",\"max\",\"value\",\"values\"],[[33,9],[33,10],[33,4],[33,11]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]]]],[],false,[\"if\",\"header\",\"action\",\"mut\",\"value\",\"selectClass\",\"disabled\",\"t-exists\",\"form/select-value\",\"min\",\"max\",\"values\"]]",
    "moduleName": "parro-web/templates/components/form/select-dropdown.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});