define("parro-web/models/childavatar", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'identity.RAvatar'
    }),
    firstname: _emberData.default.attr('string'),
    cachedAvatarUrl: _emberData.default.attr('string')
  });

  _exports.default = _default;

  _emberInflector.default.inflector.irregular('childavatar', 'childavatar');
});