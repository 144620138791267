define("parro-web/services/scroll-service", ["exports", "@ember/service", "@ember/utils"], function (_exports, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ScrollService extends _service.default {
    setScrollTop(container, isAnimated) {
      if ((0, _utils.isPresent)(container[0])) {
        this._scroll(container, 0, isAnimated);
      }
    }

    setScrollBottom(container) {
      if ((0, _utils.isPresent)(container[0])) {
        this._scroll(container, container[0]?.scrollHeight, false);
      }
    }

    scrollToTarget(container, target, isAnimated) {
      if ((0, _utils.isPresent)(container[0]) && (0, _utils.isPresent)(target[0])) {
        const topOffset = container.scrollTop() + target.position().top;

        this._scroll(container, topOffset, isAnimated);
      }
    }

    _scroll(container, topOffset, isAnimated) {
      if (isAnimated) {
        container.animate({
          scrollTop: topOffset
        });
      } else {
        container.scrollTop(topOffset);
      }
    }

    isTargetOutsideOfContainer(container, target, offsetLimit) {
      if (!container[0] || !target[0]) {
        return {};
      }

      return {
        left: target.offset().left < offsetLimit,
        bottom: container.height() - target.offset().top < offsetLimit
      };
    }

  }

  _exports.default = ScrollService;
});