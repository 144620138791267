define("parro-web/templates/components/modal/yearbook/yearbook-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if visible}}
    <section class="modal modal--yearbook" id="modal-start">
      <div class="modal--container">
  
        <div class="modal--content">
  
          {{navigation/nav-breadcrumbs breadcrumbs=config.breadcrumbs}}
  
          <div class="modal--image">
            <figure class="{{config.image}}"></figure>
          </div>
  
          <p>{{t-exists config.text}}</p>
        </div>
  
        <div class="modal--footer">
          <button {{action 'close'}} class="btn--primary">{{t-exists config.buttonText}}</button>
        </div>
      </div>
    </section>
  
    <div {{action 'close'}} id="modal--overlay" class="modal--overlay"></div>
  {{/if}}
  */
  {
    "id": "vJV9dof0",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,\"section\"],[14,0,\"modal modal--yearbook\"],[14,1,\"modal-start\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal--container\"],[12],[1,\"\\n\\n      \"],[10,0],[14,0,\"modal--content\"],[12],[1,\"\\n\\n        \"],[1,[28,[35,2],null,[[\"breadcrumbs\"],[[33,3,[\"breadcrumbs\"]]]]]],[1,\"\\n\\n        \"],[10,0],[14,0,\"modal--image\"],[12],[1,\"\\n          \"],[10,\"figure\"],[15,0,[29,[[33,3,[\"image\"]]]]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,2],[12],[1,[28,[35,4],[[33,3,[\"text\"]]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"modal--footer\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn--primary\"],[4,[38,5],[[30,0],\"close\"],null],[12],[1,[28,[35,4],[[33,3,[\"buttonText\"]]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,0],[24,1,\"modal--overlay\"],[24,0,\"modal--overlay\"],[4,[38,5],[[30,0],\"close\"],null],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"visible\",\"navigation/nav-breadcrumbs\",\"config\",\"t-exists\",\"action\"]]",
    "moduleName": "parro-web/templates/components/modal/yearbook/yearbook-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});