define("parro-web/models/services/comparators", ["exports", "parro-web/utils/comparator"], function (_exports, _comparator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = Comparators;

  function Comparators() {}

  Comparators.group = function (a, b) {
    return _comparator.default.create({
      a: a,
      b: b
    }).compare('schooljaar:desc', true).compare('type').compare('name').compare('id').compare('active').compare('stamgroep').result();
  };

  Comparators.yearbookPages = function (a, b) {
    return _comparator.default.create({
      a: a,
      b: b
    }).compare('announcement.sortDate:asc').result();
  };
});