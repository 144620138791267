define("parro-web/translations/nl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "404": {
      "button_logout": "uitloggen",
      "button_report": "melden bij Parro",
      "iAmStuck": "Ik zit vast en wil",
      "reportError1": "Ik krijg keer op keer deze foutmelding en wil deze",
      "reportError2": "(Maak een ticket met wat fout ging + deze foutmelding.)",
      "whoops": "Oops, er ging iets fout. Misschien is de pagina even niet beschikbaar of bestaat deze niet meer. Bezoek een andere pagina of probeer het later nog eens!"
    },
    "absence": {
      "all_afternoon": "hele middag",
      "all_day": "hele dag",
      "all_morning": "hele ochtend",
      "child_report_disabled": "Geen absenties via Parro",
      "confirm_delete_text": "Weet je zeker dat je deze absentie wilt verwijderen?",
      "confirm_delete_title": "Absentie verwijderen",
      "confirm_error_amount_text": "Het maximaal aantal redenen (20) is bereikt.",
      "confirm_error_amount_title": "Teveel absentieredenen",
      "enable_text": "Hier kan je instellen of ouders absenties mogen melden via Parro, aan welke voorwaarden dit moet voldoen en welke redenen ze mogen doorgeven. De absentiemeldingen worden automatisch verwerkt in ParnasSys.",
      "enable_title": "Absenties melden via Parro",
      "error_disallowed_child": "Melding kan niet gedaan worden. Actie is niet toegestaan voor dit kind.",
      "error_endtime_required": "Melding kan niet gedaan worden. Een eindtijd ontbreekt.",
      "error_endtime_starttime": "Melding kan niet gedaan worden. De eindtijd van de periode ligt voor de starttijd.",
      "error_inactive_reason": "De reden is inactief in ParnasSys. Kies een andere.",
      "error_missing_end_daypart": "Melding kan niet gedaan worden. Een einde van de periode ontbreekt.",
      "error_missing_endtime": "Melding kan niet gedaan worden. Een eindtijd ontbreekt.",
      "error_no_url": "Er is geen link ingesteld. Neem contact op met de leerkracht.",
      "error_not_authorized": "Je kunt geen detail informatie zien, want je bent niet bevoegd in ParnasSys.",
      "error_overlap_report": "Melding kan niet gedaan worden. Er staat al een absentiemelding open binnen deze periode. Neem contact op met de school.",
      "error_parnassys": "Melding kan niet gedaan worden. Het schooladministratie kan melding niet verwerken. Neem contact op met de school.",
      "error_role_not_found": "Melding kan niet gedaan worden. Rol is onbekend.",
      "error_unenrolled": "Melding kan niet gedaan worden. Het kind staat niet ingeschreven binnen deze periode. Neem contact op met de school.",
      "info_text": "Heb je vragen of wil je graag weten hoe het werkt? Je vindt <a href=\"{url}\" target=\"_blank\">hier</a> alle informatie.",
      "info_title": "Meer informatie?",
      "menu_disabled": "Ouders mogen geen absenties melden",
      "menu_enabled": "Ouders mogen absenties melden",
      "modal_absence_reason_title": "Absentiereden",
      "modal_add_absence_text": "Voor geplande absenties. Bijvoorbeeld een artsbezoek.",
      "modal_add_absence_title": "Absentiemelding",
      "modal_add_end_required": "Ouders moeten ook een eindtijd doorgeven",
      "modal_add_external_text": "Als je de ouder wil doorverwijzen naar bijv. een verlofformulier.",
      "modal_add_external_title": "Doorverwijzing",
      "modal_add_sick_text": "Als het nog niet duidelijk is wanneer de leerling weer op school komt.",
      "modal_add_sick_title": "Ziekmelding",
      "modal_children_title": "Wie wil je absent melden?",
      "modal_contact_me": "Ik wil graag dat de leerkracht contact met mij opneemt",
      "modal_contact_person": "{name} wil graag contact",
      "modal_description_title": "Geef hier een korte toelichting aan de leerkracht",
      "modal_external_text": "Klik op de onderstaande knop om naar de informatie van jouw school te gaan.",
      "modal_external_title": "We vragen je deze absentie via een andere weg te melden",
      "modal_filter_select_all": "Alles selecteren",
      "modal_filter_title": "Filter",
      "modal_reason_title": "Wat is de reden voor de absentie?",
      "modal_report_end": "Is {name} weer terug op school? Vul hier de einddatum in om de absentie in ParnasSys af te melden.",
      "modal_summary_afternoon": "In de middag absent.",
      "modal_summary_day": "Hele dag absent.",
      "modal_summary_header1": "Wanneer: ",
      "modal_summary_header2": "Reden: ",
      "modal_summary_header3": "Korte toelichting: ",
      "modal_summary_morning": "In de ochtend absent.",
      "modal_summary_other": "{date}.",
      "modal_summary_title": "Samenvatting",
      "modal_summary_today": "Vandaag, {date}.",
      "modal_summary_tomorrow": "Morgen, {date}.",
      "modal_time_afternoon": "Middag",
      "modal_time_choose": "Kies een tijd",
      "modal_time_day": "Hele dag",
      "modal_time_morning": "Ochtend",
      "modal_time_title": "Hoelang duurt de absentie?",
      "modal_time_today": "Vandaag: {date}",
      "modal_time_tomorrow": "Morgen: {date}",
      "modal_title": "Absentie",
      "modal_type_absence_text": "Voor geplande absenties. Bijvoorbeeld een artsbezoek.",
      "modal_type_absence_title": "Absentiemelding",
      "modal_type_external_text": "Als je de ouder wil doorverwijzen naar bijv. een verlofformulier.",
      "modal_type_external_title": "Doorverwijzing",
      "modal_type_sick_text": "Als het nog niet duidelijk is wanneer de leerling weer op school komt.",
      "modal_type_sick_title": "Ziekmelding",
      "modal_type_title": "Nieuwe reden",
      "modal_when_other": "Andere dag",
      "modal_when_title": "Wanneer is {name} afwezig?",
      "placeholder_ended_text": "Op dit moment heeft jouw school geen actieve Parro-licentie, daarom is deze functie niet beschikbaar. Wil je volledig gebruik maken van Parro, vernieuw dan je licentie. <a href=\"{url}\">Lees meer...</a>",
      "placeholder_ended_title": "Pilot verlopen",
      "placeholder_filter_text": "Er zijn geen absenties op basis van je huidige filter. Pas je filter aan om alle absenties te zien.",
      "placeholder_no_children_text": "Vraag de leerkracht om jou te koppelen aan een groep.",
      "placeholder_no_children_title": "Geen kinderen",
      "placeholder_no_reasons_text": "Voeg een absentie reden toe, zodat ouders via Parro hun kind absent kunnen melden",
      "placeholder_no_reasons_text2": "Er zijn op dit moment geen absentie redenen. Controleer dit op een later moment weer.",
      "placeholder_no_reasons_title": "Nog geen absentie redenen",
      "placeholder_no_reports_text": "Er zijn op dit moment geen absenties. Controleer dit op een later moment weer.",
      "placeholder_no_reports_title": "Geen absenties",
      "reason_list_title": "Absentieredenen",
      "reason_no_name": "Geen naam ingesteld",
      "success_send": "Absentie is verstuurd!",
      "toggle_setting": "Ouders mogen absenties melden",
      "unknown": "onbekend",
      "warning_url": "De url kan fout zijn"
    },
    "account": {
      "contact_us": "Heb je nog vragen over Parro? Neem gerust <a href={url}>contact</a> met ons op!"
    },
    "actions": {
      "create": "Maak"
    },
    "activity": {
      "activity_accept": "Aanwezig",
      "activity_decline": "Afwezig",
      "activity_rsvp": "Nog geen keuze gemaakt",
      "activity_tentative": "Misschien",
      "cancelled": "Agenda-item is geannuleerd",
      "cancelled_at": "Geannuleerd op {date} {time}",
      "closed": "Inschrijvingen zijn gesloten",
      "committer": "Ingeschreven door: {name}",
      "concept": "Dit concept is alleen zichtbaar voor jou en jouw collega's",
      "confirm_cancellation": "Let op: Het item wordt bij alle ontvangers geannuleerd. Dit kun je <u>NIET</u> ongedaan maken.",
      "confirm_cancellation2": "Weet je zeker dat je dit agenda item wil verwijderen?",
      "confirm_direct_open": "Je staat op het punt om de inschrijvingen voor iedereen te openen. Weet je het zeker?",
      "confirm_registration_close": "Weet je zeker dat je de inschrijvingen wil gaan sluiten? Ouders kunnen hierna geen wijzigingen meer maken.",
      "confirm_registration_open": "Je staat op het punt om de inschrijvingen te openen. Als je ouders met meerdere kinderen eerder wil laten inschrijven, vul het aantal uren hieronder in.",
      "edit_cancellation": "Verwijder agenda-item",
      "edit_details": "Wijzig details",
      "expired": "Agenda-item is gaande of geweest",
      "has_accepted": "Aanwezig",
      "has_declined": "Afwezig",
      "has_rsvp": "Nog geen keuze gemaakt",
      "has_tentative": "Misschien aanwezig",
      "location": "Locatie",
      "modal_additional_info": "Aanvullende informatie?",
      "modal_amount_invalid": "Aantal bij vrijwilliger of materiaal moet tussen 1 en 100 liggen.",
      "modal_choice_activity": "Zet een activiteit in de agenda van één of meerdere groepen.",
      "modal_choice_import": "Maak meerdere activiteiten aan vanuit een export bestand.",
      "modal_choice_timeslots": "Plan tijdstippen waarop ouders zich kunnen inschrijven",
      "modal_choice_volunteers": "Maak een activiteit en vraag om vrijwilligers en materialen.",
      "modal_date_exceeds_year": "Je kunt alleen items aanmaken voor het huidige schooljaar. Schooljaar start op 1 augustus",
      "modal_date_too_long": "Het agenda-item mag niet langer duren dan 21 dagen",
      "modal_delete_title": "Agenda-item verwijderen",
      "modal_edit_calendaritem": "Bewerk agenda-item",
      "modal_equipment": "Materiaal",
      "modal_new_calendaritem": "Nieuw agenda-item",
      "modal_organize": "Organiseer vrijwilligers/materialen?",
      "modal_plan": "Plan je agenda item",
      "modal_request_rsvp": "Vraag om aanwezigheid",
      "modal_save_concept": "De gespreksplanner wordt als concept aan jouw agenda toegevoegd. Ben je klaar met evalueren, vergeet dan niet de inschrijvingen te openen.",
      "modal_save_title": "Wijziging opslaan",
      "modal_send_info": "Als je op \"opslaan\" klikt, ontvangen ouders het item direct. Gebruik de uitstuuropties om dit te wijzigen.",
      "modal_send_info_time": "Als je op \"opslaan\" klikt, ontvangen ouders het item op {time}. Gebruik de uitstuuropties om dit te wijzigen.",
      "modal_send_reminder": "Automatisch vooraf herinnering sturen voor opgegeven vrijwilligers/materialen",
      "modal_send_request": "Automatisch bericht sturen indien er nog vrijwilligers/materialen nodig zijn",
      "modal_title_activity": "Activiteit maken",
      "modal_title_import": "Agenda importeren",
      "modal_title_timeslots": "Gesprekken plannen",
      "modal_title_timeslots_v3": "Gespreksplanner",
      "modal_title_volunteers": "Vrijwilligers vragen",
      "modal_title_whole_day": "Voor de hele dag",
      "modal_volunteer": "Vrijwilligers",
      "modal_warning_delete": "Bij deze uitschrijving wordt ook je tekst bij \"Wat wil je bespreken?\" verwijderd.",
      "modal_warning_recipients1": "Wil je de ontvangers op de hoogte stellen van de wijziging?",
      "modal_warning_recipients2": "Verstuur notificatie naar ontvangers",
      "modal_what_is_needed": "Wat heb je nodig?",
      "modal_who_participates": "Ontvangers",
      "modified_at": "Gewijzigd op {date} {time}",
      "modified_by": "Laatst gewijzigd door: {name}",
      "open_scheduledate": "Uitgestuurd naar ouders met meerdere kinderen, de rest volgt op {date}.",
      "organizer": "Door: {name}",
      "rsvp_expired": "RSVP kan niet langer opgegeven worden",
      "seenby": "hebben dit agenda-item gezien",
      "status_accepted": "Jij bent aanwezig",
      "status_declined": "Jij bent afwezig",
      "status_present": "{present, plural, other {#}}/{total, plural, other {#}}",
      "status_tentative": "Jij bent misschien aanwezig",
      "volunteers_equipment": "Benodigdheden"
    },
    "activity_credits": {
      "error_no_credits": "Je hebt alle gratis agenda-items voor ouders gebruikt",
      "free_none": "Je kan geen agenda-items meer aan ouders sturen",
      "free_remaining": "Je kan nog {number} gratis agenda-items aan ouders sturen"
    },
    "activity_import": {
      "confirmation_text": "Je staat op het punt om {amount, plural, =1 {# agenda-item} other {# agenda-items}} aan te maken. Controleer de onderstaande agenda-items.",
      "date_empty": "Datum moet ingevuld zijn",
      "description_empty_error": "Beschrijving moet minimaal 1 teken bevatten",
      "description_length_error": "Beschrijving mag maximaal 512 tekens bevatten",
      "download_text": "Download template om zelf in te vullen",
      "error_groups": "Kon de groep(en) niet vinden in het huidige schooljaar. Koppel de groepen aan jouw account.",
      "error_import": "Er is iets fout gegaan bij het importeren. Controleer de datums en tijden.",
      "error_import_header": "Fout bij importeren",
      "error_import_text": "Er ging iets fout bij het importeren. Probeer het nog eens. Als deze fout blijft ontstaan, neem dan contact op met Parro support.",
      "finished_header": "Het importeren is gelukt!",
      "finished_text": "De aangemaakte agenda-items zijn verstuurd en worden getoond in de agenda.",
      "help_text": "Hulp nodig of meer informatie?",
      "import_exists_header": "Bestaande import",
      "import_exists_text": "Je bent op {date} begonnen met importeren. Ga verder waar je was gebleven of verwijder het bestand en begin opnieuw.",
      "intro": "Parro maakt het mogelijk om meerdere agenda-items in één keer aan te maken. Importeer de agenda vanuit ParnasSys of maak zelf een lijst aan de hand van onze template",
      "label_date_from": "Datum vanaf",
      "label_date_till": "Datum tot",
      "label_description": "Beschrijving",
      "label_time_from": "Tijd vanaf",
      "label_time_till": "Tijd tot",
      "label_title": "Titel",
      "no_import_header": "Geen agenda-items",
      "no_import_text": "Er zijn geen agenda-items. Ga terug naar het vorige scherm.",
      "no_notification": "Ontvangers krijgen geen notificatie",
      "parnasSys_text": "Exporteer het jaarplan uit ParnasSys",
      "rule_text_1": "Wil je een agenda-item versturen naar collega’s, dan moet het ‘Groepen’ veld leeg zijn.",
      "rule_text_2": "De titel van het agenda-item wordt opgebouwd uit de velden ‘Categorie’ en ‘Subcategorie’.",
      "rule_text_3": "De informatie in het veld ‘Vak’ wordt geplaatst in de omschrijving van agenda-items.",
      "rules_header": "Dit zijn de spelregels:",
      "time_empty": "Tijd moet ingevuld zijn",
      "title": "Importeer agenda-item",
      "title_empty_error": "Titel moet minimaal 1 teken bevatten",
      "title_length_error": "Titel mag maximaal 255 tekens bevatten",
      "upload_text": "Upload bestand (.xlsx)"
    },
    "activity_invitees": {
      "no_invitees": "Er zijn geen genodigden - de groepen zijn leeg.",
      "no_invitees_yet": "Je ziet hier alle genodigden zodra alle inschrijvingen geopend zijn.",
      "no_invitees_yet1": "Je ziet hier de eerste genodigden zodra de eerste inschrijvingen geopend zijn. Resterende genodigden volgen zodra alle inschrijvingen geopend zijn.",
      "no_invitees_yet2": "Je ziet hier de eerste genodigden. Resterende genodigden volgen zodra alle inschrijvingen geopend zijn.",
      "sending_invites1": "Eerste inschrijvingen geopend",
      "sending_invites2": "Alle inschrijvingen geopend"
    },
    "activity_organize": {
      "cannot_open_conversation1": "De organisator van het agenda-item kan een gesprek starten met alle vrijwilligers zodra er vrijwilligers zijn.",
      "cannot_open_conversation2": "Je kan deelnemen aan het gesprek zodra je vrijwilliger bent.",
      "cannot_start_conversation": "Je kan het gesprek starten zodra er vrijwilligers zijn. Nieuwe vrijwilligers worden automatisch aan het gesprek toegevoegd.",
      "error_amount_too_small": "Het opgegeven aantal is te klein; kies een hoger aantal.",
      "error_commitment_full": "Maximum aantal is bereikt; kies een lager aantal.",
      "error_item_expired": "Agenda-item is verlopen, deelname opgeven is niet langer mogelijk.",
      "new_volunteer_conversation": "Nieuwe vrijwilligers worden automatisch aan het gesprek toegevoegd.",
      "no_commitments_yet": "Er zijn nog geen aanmeldingen",
      "select_amount": "Selecteer een aantal",
      "select_contribution": "Selecteer jouw bijdrage",
      "your_contribution": "Jouw bijdrage"
    },
    "activity_plan": {
      "date_to_send": "Datum van uitsturen",
      "deadline_deadline": "Deadline",
      "deadline_none": "Geen",
      "deadline_reminder": "Verstuur herinnering totdat ingeschreven",
      "deadline_to_respond": "Deadline inschrijvingen",
      "duration": "Duur {duration, plural, other {# minuten}}",
      "error_date": "Datum kan niet in het verleden liggen",
      "error_duration": "Duur tijdstip moet tussen 5 en 180 minuten zijn",
      "error_number": "Aantal tijdstippen moet tussen 1 en 30 zijn",
      "error_pause": "Pauze moet tussen tussen 0 en 180 minuten zijn",
      "invitees_all": "Alle inschrijvingen",
      "invitees_first": "Eerste inschrijvingen",
      "invitees_large_families": "Inschrijvingen voor ouders met meerdere kinderen",
      "new_plan": "Nieuw gesprek",
      "planned_by_teacher": "Door {name} ingeschreven",
      "planned_by_you": "Door jou ingeschreven",
      "please_deadline": "Datums moeten voor de deadline liggen.",
      "please_future_date": "Datums moeten in de toekomst liggen.",
      "please_interval": "Datums moeten voor het eerste tijdstip liggen.",
      "please_invitees_all": "'Inschrijven vanaf' is verplicht bij gebruik van 'Inschrijven ouders met meerdere kinderen vanaf'.",
      "please_invitees_large": "'Inschrijven vanaf' moet gelijk zijn of na 'Inschrijven ouders met meerdere kinderen vanaf' liggen.",
      "please_no_overlap": "Verwijder overlappende tijdstippen.",
      "please_no_overlap_dates": "Wijzig de overlappende datums.",
      "registration": "Uitstuuropties",
      "select_timeslot": "Kies tijdstip",
      "send_immediately": "Per direct",
      "share_with_parents": "Deel resultaten met ouders",
      "slot_duration": "Duur tijdstip *",
      "slot_free": "Beschikbaar",
      "slot_number": "Aantal tijdstippen *",
      "slot_pause": "Pauze na een tijdstip",
      "slot_reserved": "Bezet",
      "timeslots": "Tijdstippen",
      "unplanned": "Nog niet ingeschreven",
      "unplanned_no_response": "Ongepland en geen reactie ouders"
    },
    "activity_timeslot": {
      "already_reserved_child": "Sorry, dit kind is reeds ingeschreven.",
      "already_reserved_slot": "Sorry, dit tijdstip is ondertussen bezet.",
      "button_close_information": "De inschrijvingen worden automatisch gesloten wanneer het eerst tijdstip start.",
      "commitment_changed": "Een collega heeft dit agenda-item zojuist gewijzigd.",
      "error_children_timeslots": "Let op: er zijn niet genoeg tijdstippen ({timeslots}) voor het aantal kinderen ({children})",
      "error_save_V1_V2": "Je kan deze gespreksplanner helaas niet wijzigen.",
      "guardians_what": "De ouders willen dit bespreken",
      "hint_children_group": "Tip: er zitten {amount} kinderen in jouw geselecteerde groep",
      "must_choose_timeslot": "Je moet nog een tijdstip kiezen",
      "no_comment": "Geen opmerking",
      "no_guardians_found": "Geen ouders gevonden",
      "register_childs": "{amount, plural, =0 {Alle kinderen zijn ingeschreven} other {# niet ingeschreven}}",
      "register_for": "Schrijf je in voor",
      "register_what": "Wat wil je bespreken?",
      "register_when": "Kies een tijdstip",
      "registration": "Inschrijven",
      "registration_closed": "Inschrijvingen zijn gesloten",
      "registration_closed2": "Inschrijvingen gesloten",
      "registration_only_teacher": "Inschrijving kan alleen aangepast worden door de leerkracht",
      "registration_start": "Inschrijven mogelijk vanaf",
      "success_children_timeslots": "Top, je hebt voldoende tijdstippen!"
    },
    "activity_v3": {
      "button_add_day": "Dag toevoegen",
      "button_add_daypart": "+ Dagdeel toevoegen",
      "button_add_timeslot": "+ Tijdvak toevoegen",
      "button_delete_day": "Dag verwijderen",
      "confirm_delete_day": "Er zijn al kinderen ingeschreven. Weet je zeker dat je deze dag wil verwijderen?",
      "confirmation_reminder": "De herinnering is succesvol verstuurd.",
      "error_add_timeslots": "Kan geen tijdvakken deze dag meer toevoegen",
      "error_merged_timeslots": "Tijdvakken samengevoegd",
      "error_no_timeslots": "Er zijn geen tijdvakken",
      "error_same_day": "Datum bestaat al",
      "error_time_daypart": "Dagdelen moeten in de toekomst liggen",
      "error_v2": "Je kan deze wijziging niet toepassen op een oudere versie",
      "header_status": "{nChildren, plural, =0 {# kinderen} =1 {# kind} other {# kinderen}} / {nTimeslots, plural, =0 {# tijdvakken} =1 {# tijdvak} other {# tijdvakken}}",
      "header_status_registrations": "{nTimeslots, plural, =0 {# open tijdvakken} =1 {# open tijdvak} other {# open tijdvakken}} / {nRegistrations} niet ingeschreven",
      "label_choose_time": "Kies een nieuwe tijd",
      "label_edit": "Bewerken",
      "label_from": "Van",
      "label_group": "{name}",
      "label_location": "Locatie",
      "label_location2": "Locatie {name}",
      "label_message": "Bericht",
      "label_multi_child_date": "Op {date}",
      "label_no_group": "Geen groep gevonden",
      "label_opened_since": "Inschrijving geopend op",
      "label_priority_multi": "Ouders met meerdere kinderen een voorsprong geven",
      "label_subject": "Onderwerp *",
      "label_subscribe": "Kind inschrijven",
      "label_time": "Gespreksduur *",
      "label_to": "Tot",
      "minutes": "{amount, plural, =1 {# minuut} other {# minuten}}",
      "option_timeslot": "{date} {startTime} - {endTime}",
      "options_days": "{nDays, plural, =0 {# dagen eerder} =1 {# dag eerder} other {# dagen eerder}}",
      "placeholder_all_registered": "Alle kinderen zijn ingeschreven",
      "placeholder_location": "Vul een locatie in",
      "placeholder_message": "Vul een bericht in",
      "placeholder_no_description": "Geen bericht",
      "placeholder_no_location": "Geen locatie",
      "placeholder_no_timeslots_text": "Ga terug naar het vorige scherm om nieuwe tijdvakken aan te maken.",
      "placeholder_no_timeslots_title": "Geen tijdvakken gevonden",
      "placeholder_subject": "Vul een onderwerp in",
      "select_all_children": "Alle leerlingen (de)selecteren",
      "select_children": "Alle kinderen (de)selecteren",
      "select_minutes": "{amount} minuten",
      "timeslot_available": "Beschikbaar",
      "timeslot_paused": "Pauze",
      "title_available": "Wanneer ben je beschikbaar?",
      "title_children": "Voor welke kinderen?",
      "title_children_assign": "Schrijf kind in",
      "title_children_unregistered": "Niet ingeschreven kinderen",
      "title_deadline": "Inschrijvingen sluiten",
      "title_deadline_edit": "Sluitingsdatum wijzigen",
      "title_deadline_new": "Wanneer wil je de inschrijving sluiten?",
      "title_deadline_reopen": "Tot wanneer wil je de inschrijvingen heropenen?",
      "title_default": "{name} - Oudergesprekken",
      "title_group": "Voor welke groep?",
      "title_move_child": "Verplaats {child}",
      "title_options": "Wat wil je doen?",
      "title_overview": "Overzicht",
      "title_schedule": "Inschrijvingen openen",
      "title_schedule_edit": "Openingsdatum wijzigen",
      "title_schedule_new": "Vanaf wanneer mogen ouders zich inschrijven?",
      "title_subject": "Waar wil je over praten?",
      "title_timeslot_info": "Informatie",
      "title_timeslots": "Tijdvakken",
      "warning_merged_timeslots": "Er waren tijdvakken met dezelfde tijd. Deze tijdvakken zijn samengevoegd."
    },
    "activity_v3_status": {
      "banner_closed": "Inschrijving gesloten",
      "subtext_cancelled": "Niemand kan wijzigingen maken.",
      "subtext_closed": "Alleen leerkrachten kunnen nog wijzigingen maken.",
      "subtext_concept": "Geen openingsdatum ingesteld.",
      "subtext_open": "Ouders kunnen zich inschrijven.",
      "subtext_planned": "De inschrijvingen staan klaar om verstuurd te worden.",
      "title_cancelled": "Status: {date} geannuleerd",
      "title_closed": "Status: Inschrijving gesloten",
      "title_concept": "Status: Concept",
      "title_open": "Status: Inschrijving geopend",
      "title_planned": "Status: Inschrijving ingepland"
    },
    "activity_v3_wizard": {
      "header_item1": "Ouders met meerdere kinderen",
      "header_item2": "Inschrijving openen",
      "header_item3": "Automatisch herinnering",
      "header_item4": "Inschrijving sluiten",
      "header_item5": "Inschrijving heropenen",
      "label_from": "Vanaf",
      "label_nvt": "n.v.t",
      "label_required": "*",
      "label_until": "Tot",
      "option_text_item1": "Ouders voorsprong geven",
      "option_text_item3": "Automatische herinnering versturen",
      "text_item1": "Wil je ouders met meerdere kinderen een voorsprong geven?",
      "text_item2": "Vanaf welk moment mogen alle ouders zich inschrijven?",
      "text_item3": "Je kunt hier instellen dat er automatisch een herinnering wordt verstuurd naar ouders die zich nog niet hebben ingeschreven voor een bepaald tijdstip.",
      "text_item4": "Je kunt hier een tijdstip instellen waarop de inschrijving wordt gesloten voor ouders. Indien gewenst kun je de inschrijving op een later moment handmatig weer openen.",
      "text_item5": "De inschrijvingsmogelijkheid wordt per direct geopend. Wanneer wil je deze sluiten?",
      "title": "Inschrijvingen openen"
    },
    "applicationtitle": "Parro",
    "attachments": {
      "accepts_file": "Accepteert jpg, png, pdf, Word of Excel bestand van maximaal 5 Mb",
      "accepts_picture": "Accepteert jpg of png bestand van maximaal 5 Mb",
      "attachments": "{amount, plural, =1 {Bijlage } other {Bijlagen }}",
      "description": "{size}",
      "description_less": "Minder dan 1MB",
      "expires_in": "{amount, plural, =1 {verloopt} other {verlopen}} {time}",
      "has_expired": "download is verlopen"
    },
    "button": {
      "add": "Toevoegen",
      "add_account": "Account toevoegen",
      "add_account_new": "Nieuw account aanmaken",
      "add_first_reason": "Voeg de eerste reden toe",
      "add_group": "Koppel met groep",
      "add_timeslots": "Voeg tijdstippen toe",
      "agreement": "Akkoord",
      "allow": "Toestaan",
      "back": "Terug",
      "beta": "Probeer de beta",
      "buy": "Koop Parro",
      "cancel": "Annuleer",
      "cancel2": "Ik snap het",
      "cancel_activity": "Verwijder item",
      "cancel_activity_no": "Nee, behoud",
      "cancel_plural": "Annuleren",
      "cancel_teacher": "Rondkijken",
      "change_password": "Wijzig wachtwoord",
      "change_password_parnassys": "Je kan je wachtwoord wijzigen in ParnasSys",
      "choose_image": "Kies foto",
      "choose_parro": "Kies Parro",
      "choose_plan": "Geef je keuze door",
      "close": "Sluiten",
      "complete": "Voltooien",
      "configure": "Stel in",
      "confirm": "Bevestigen",
      "continue": "Voortzetten",
      "copy": "Kopiëren",
      "create": "Aanmaken",
      "create_account": "Maak een account aan",
      "create_new_account": "Maak nieuwe Parro-account",
      "deactivate": "Account opzeggen",
      "decouple": "Ontkoppel",
      "delete": "Verwijder",
      "delete_account": "Loskoppelen",
      "delete_account2": "Account verwijderen",
      "delete_plural": "Verwijderen",
      "disable": "Uitschakelen",
      "done": "Gereed",
      "edit": "Wijzigen",
      "edit2": "Bewerken",
      "edit_data": "Wijzig data",
      "edit_deadline": "Wijzig sluitingsdatum",
      "edit_schedule_date": "Wijzig openingsdatum",
      "goto_login": "Ga naar inloggen",
      "goto_yearbook": "Ga terug naar jaarboek",
      "hide_conversation": "Verberg dit gesprek",
      "i_am_guardian": "Ik ben een ouder",
      "i_am_teacher": "Ik ben een leerkracht",
      "import": "Importeren ({amount})",
      "invite": "Uitnodigen",
      "invite_guardian": "Ouders uitnodigen",
      "is_me": "Klopt",
      "leave_conversation": "Verlaat gesprek",
      "leave_group": "Groep verlaten",
      "login": "Inloggen",
      "login_as_guardian": "Inloggen als ouder",
      "login_as_teacher": "Inloggen als leerkracht",
      "login_existing_account": "Inloggen met je bestaande Parro-account",
      "login_guardian": "Inloggen als ouder",
      "login_microsoft": "Inloggen met Microsoft schoolportaal",
      "login_ouderportaal": "Ga naar ouderportaal",
      "login_parnasSys": "Inloggen met ParnasSys",
      "login_problem": "Problemen met inloggen",
      "login_rety": "Opnieuw inloggen",
      "login_teacher": "Inloggen als leerkracht",
      "logout": "Uitloggen",
      "mail_code": "Mail code",
      "mark_read": "Markeer als gelezen",
      "mark_unread": "Markeer als ongelezen",
      "more_info": "Meer info",
      "move": "Verplaatsen",
      "next": "Volgende",
      "next2": "Ga verder",
      "no": "Nee",
      "no2": "Niet nu",
      "not_guardian_but_teacher": "Je bent geen ouder maar leerkracht",
      "not_me": "Ben ik niet",
      "not_right": "Klopt niet",
      "note": "Notitie",
      "ok": "Ok",
      "open": "Open",
      "open_conversation": "Open vrijwilligersgesprek",
      "order": "Parro bestellen",
      "order2": "Bestel",
      "order_finish": "Bestelling afronden",
      "order_info": "Bestelinformatie",
      "order_yearbook": "Bestel dit jaarboek",
      "parent": "Ouder",
      "pilot": "Proefperiode starten",
      "pilot2": "Pilot starten",
      "plan_pilot": "Pilot inplannen",
      "previous": "Vorige",
      "privacy_always": "Altijd",
      "privacy_never": "Niet",
      "privacy_period": "Periode",
      "problem": "Probleem melden",
      "promo": "Actie bekijken",
      "read_less": "lees minder",
      "read_more": "lees meer...",
      "read_more2": "Lees meer",
      "recover_account": "Herstellen",
      "registrations_close": "Sluiten",
      "registrations_direct_open": "Direct openen",
      "registrations_open": "Inplannen",
      "registrations_reopen": "Inschrijving heropenen",
      "reject": "Afwijzen",
      "reminder": "Herinnering",
      "remove_avatar": "Verwijder afbeelding",
      "report": "Aanwezig melden",
      "request_access": "Vraag toegang",
      "resend": "Opnieuw versturen",
      "return_to_parro": "Terug naar Parro",
      "save": "Opslaan",
      "save_concept": "Concept opslaan",
      "schedule": "Plan",
      "send": "Verstuur",
      "skip": "Overslaan",
      "start": "De app downloaden",
      "start_as_guardian": "Je bent geen leerkracht maar ouder",
      "start_as_teacher": "Log in als leerkracht",
      "start_conversation": "Start vrijwilligersgesprek",
      "start_immediately": "Direct beginnen",
      "take_me": "Breng mij erheen",
      "teacher": "Leerkracht",
      "today": "Vandaag",
      "try_app_decline": "Web blijven gebruiken",
      "try_app_success": "App uitproberen",
      "unsubscribe": "Uitschrijven",
      "upgrade": "Upgrade",
      "use_selection": "Kies",
      "use_web": "Web versie gebruiken",
      "yearbook_agreement": "Akkoord met voorwaarden",
      "yearbook_create": "Maak nieuw jaarboek",
      "yearbook_disable": "Schakel jaarboek uit",
      "yearbook_enable": "Jaarboek aanbieden",
      "yes": "Ja"
    },
    "conditional": {
      "and": "en",
      "comma": ",",
      "until": "t/m"
    },
    "contacts": {
      "choose_parent": "Kies ouder",
      "converse_with_guardian": "Weet je zeker dat je een gesprek met {name} wilt starten?",
      "couple_confirmation": "Weet je zeker dat je deze ouder wil uitnodigen? De ouder zal een mail ontvangen.",
      "couple_success": "Er is een mail gestuurd naar {email}",
      "couple_title": "Ouder uitnodigen",
      "coupled_children": "{percentage, plural, other {#%}} van de gezinnen bereikbaar.",
      "coupled_to_child": "Gekoppelde ouders",
      "decouple_confirmation": "Weet je zeker dat je deze ouder wil ontkoppelen? De ouder zal geen mededelingen meer ontvangen van deze groep.",
      "decouple_title": "Ouder ontkoppelen",
      "invitation_expired": "Uitnodiging verlopen",
      "invitation_sent": "Uitnodiging verstuurd",
      "invite": "Uitnodigen",
      "invited_none": "Geen gekoppelde ouders",
      "invited_number": "{number, plural, =1 {# gekoppelde ouder} other {# gekoppelde ouders}}",
      "no_coupled": "Er zijn nog geen ouders gekoppeld aan {name}.",
      "no_parents": "Geen ouders gevonden",
      "number_of_guardians": "{number, plural, =1 {# Ouder} other {# Ouders}}",
      "participation": "Volgt onderwijs {date}",
      "send_invitation": "Stuur uitnodiging",
      "start_conversation": "Weet je zeker dat je een gesprek wilt starten?",
      "start_conversation_title": "Gesprek starten",
      "teacher_is_using_parro": "Gekoppeld aan Parro",
      "teacher_not_using_parro": "Maakt nog geen gebruik van Parro"
    },
    "conversations": {
      "about_child": "Gesprek over {name}",
      "add_chatroommember": "Voeg persoon toe",
      "already_chatroommember": "Neemt al deel aan het gesprek",
      "and_leave_conversation": "Verlaat en vervolgens verberg dit gesprek als je niet meer wilt deelnemen.",
      "and_you": " en jij",
      "bot_conversation": "App",
      "button_start_conversation": "Start gesprek",
      "cannot_add_picture": "Foto's versturen in gesprekken is onderdeel van het betaalde plan. Foto's van ouders ontvangen kan altijd. <a href={url} target='_blank'>Meer informatie.</a>",
      "change_title_header": "Onderwerp aanpassen",
      "change_title_text": "Kies het onderwerp voor dit gesprek",
      "chatroom_about_child": "{value, plural, =1 {Ouder} other {Ouders}} {name}",
      "chatroom_about_child_short": "{value, plural, =1 {Leerkracht} other {Leerkrachten}} {name}",
      "chatroom_admin_required": "Er is geen beheerder beschikbaar voor dit gesprek",
      "chatroom_exists_ask_permission": "<strong>Er bestaat al een kindgesprek voor {child}.</strong> Stuur een verzoek om toegevoegd te worden. Een leerkracht zal je verzoek bekijken.",
      "chatroom_exists_title": "Kindgesprek bestaat al",
      "chatroom_request_exists": "Er staat nog een toegangsverzoek open",
      "chatroommember_admin": "beheerder",
      "chatroommember_already_present": "Persoon maakt al deel uit van het gesprek",
      "chatroommember_left": "gesprek verlaten",
      "chatroomname": "{name1} en {name2}",
      "chatroomname+": "{name1}, {name2} {remaining, plural, =0 {} =1 {en {name3}} other {en # anderen}}",
      "chatroomname+3": "{name1}, {name2} en {name3}",
      "chatroomname_group": "Groepsgesprek",
      "chatroomname_you": "Jezelf",
      "child_conversation": "Kindgesprek",
      "child_conversation_continue": "Kindgesprek voortzetten",
      "child_conversation_info": "In een kindgesprek kun je (samen met je partner) met meerdere leerkrachten van je kind contact houden.",
      "child_conversation_tip": "Toevoegen van andere ouders en duo leerkrachten is mogelijk.",
      "child_conversation_unavailable": "Er bestaat al een kindgesprek waar jullie niet allebei in zitten. Selecteer het privégesprek om verder te gaan.",
      "child_guardian_description": "Ouder van {name}",
      "choose_persons_to_add": "Kies personen om toe te voegen aan het gesprek",
      "chosen_group": "{group} gekozen op het vorige scherm",
      "confirm_add_member": "Weet je zeker dat je {name} wil toevoegen? <br/><br/><strong>Let op:</strong> deelnemers die je toevoegt zien de gesprekshistorie.",
      "confirm_add_members": "Weet je zeker dat je deze deelnemers wil toevoegen? <br/><br/><strong>Let op:</strong> deelnemers die je toevoegt zien de gesprekshistorie.",
      "confirm_add_title": "Toevoegen",
      "confirm_archive": "Weet je zeker dat je dit gesprek wilt verbergen?",
      "confirm_delete_member": "Weet je zeker dat je {name} uit het gesprek wil verwijderen?",
      "confirm_delete_self": "Weet je zeker dat je gesprek wil verlaten?",
      "confirm_delete_title": "Verwijderen",
      "confirm_delete_title_self": "Verlaten",
      "continue_and_add": "Voeg {suggestions} toe op het instellingen scherm.",
      "continue_and_request_add": "Vraag {admin} om {suggestions} toe te voegen aan dit gesprek.",
      "continue_conversation_about": "Wil je doorgaan met het gesprek over {child}?",
      "continue_existing_conversation": "Kindgesprek bestaat al",
      "continue_existing_with": "Doorgaan met bestaand gesprek, met de volgende deelnemers: {members}",
      "continue_with_also_present": "Let op: {participants} {value, plural, =1 {is ook een deelnemer} other {zijn ook deelnemers}}.",
      "conversation_about_short": "Over {childname}",
      "conversation_continue": "Gesprek voortzetten",
      "conversation_with": "Gesprek met",
      "conversation_with_short": "Met {name}",
      "deactivated": "Dit gesprek is inactief.&nbsp;<a>Start een nieuw gesprek.</a>",
      "deactivated_send": "De beheerders hebben dit gesprek verlaten. Start een nieuw gesprek.",
      "error_left": "Je maakt geen deel meer uit van dit gesprek.",
      "error_options": "Bericht versturen mislukt",
      "error_options_delete": "Verwijderen",
      "error_options_retry": "Opnieuw versturen",
      "error_paused": "Het versturen van reacties is uitgeschakeld.",
      "existing_conversations": "Je hebt bestaande gesprekken met deze personen",
      "existing_conversations_list": "Verder met {value, plural, =1 {bestaand gesprek} other {bestaande gesprekken}}",
      "group_subject": "Groepsonderwerp",
      "guardian_of": "Gesprek met ouder uit",
      "hide_conversation_text": "Weet je zeker dat je het gesprek wilt verbergen? Dit kan je niet ongedaan maken.",
      "hide_conversation_title": "Gesprek verbergen",
      "inactive": "Inactief",
      "inactive_guardian": "Er zijn geen actieve deelnemers",
      "inactive_guardian_send": "Je bekijkt de geschiedenis van een gedeactiveerd gesprek",
      "interaction_access_conflict": "Een collega heeft dit verzoek al afgehandeld",
      "interaction_access_denied": "Het verzoek van {name} om deel te nemen aan dit gesprek is afgewezen door {interactor}",
      "interaction_access_granted": "Het verzoek van {name} om deel te nemen aan dit gesprek is goedgekeurd door {interactor}",
      "interaction_access_request_lk": "{name} wil deelnemen aan dit gesprek",
      "interaction_access_request_ou": "{name} heeft toegang gevraagd aan de leerkrachten in dit gesprek",
      "interaction_access_requested": "Je verzoek is verstuurd!",
      "message_delete": "Bericht verwijderen",
      "message_deleted_other": "Dit bericht is verwijderd",
      "message_deleted_you": "Je hebt dit bericht verwijderd",
      "new_conversation": "Nieuw gesprek",
      "new_member": "Toevoegen persoon",
      "new_message": "Nieuw bericht",
      "new_unread_messages": "{number, plural, =1 {# nieuw bericht} other {# nieuwe berichten}}",
      "no_conversation_participants": "Geen deelnemers",
      "no_conversations": "Er zijn nog geen gesprekken gaande met jou. Zodra een gesprek gestart is, kun je hier de berichten en deelnemers zien!",
      "no_guardian_communication": "Wil je graag een gesprek voeren met deze ouders? Vraag dan de leerkracht van de groep om \"Met een andere ouder een gesprek starten\" in te schakelen.",
      "no_members_group_guardians": "Zijn de ouders al gekoppeld? Via het groepenoverzicht kan je de ouders uitnodigen.",
      "no_members_guardian_children1": "Geen kinderen",
      "no_members_guardian_children2": "Er zijn geen kinderen gevonden. Vraag de leerkracht om een jou te koppelen aan een groep.",
      "no_members_guardian_guardians1": "Geen ouders",
      "no_members_guardian_guardians2": "Er zijn geen ouders gevonden. Waarschijnlijk moeten ze eerst nog koppelen met Parro.",
      "no_members_guardian_teachers1": "Geen leerkrachten",
      "no_members_guardian_teachers2": "Er zijn geen leerkrachten gevonden. Waarschijnlijk moeten ze eerst nog koppelen met Parro.",
      "no_members_guardian_teachers3": "Er zijn geen leerkrachten gekoppeld aan {name}.",
      "no_members_teacher_guardians1": "Geen ouders",
      "no_members_teacher_guardians2": "Nodig de ouders uit voor Parro en wacht tot zij ook een account hebben.",
      "no_members_teacher_teachers1": "Geen collega's",
      "no_members_teacher_teachers2": "Heb je wel de Collega's groep? Zorg er dan voor dat je collega's ook Parro gaan gebruiken! Beheer je groepen in het groepenoverzicht.",
      "no_messages": "De leerkracht heeft jou nog geen bericht gestuurd. Zodra de leerkracht je een bericht stuurt, is dit de plek om te kijken!",
      "no_teacher_communication": "Deze leerkracht heeft gesprekken uitgeschakeld",
      "no_teacher_communication2": "Wil je graag een gesprek voeren met deze Leerkracht? Vraag dan de leerkracht om \"ouders mogen contact met mij opnemen\" in te schakelen.",
      "number_of_participants": "{number, plural, =1 {# deelnemer} other {# deelnemers}}",
      "number_of_volunteers": "{number, plural, =1 {# vrijwilliger} other {# vrijwilligers}}",
      "pause": "Reacties van ouders",
      "paused_teachers": "{number, plural, =1 {{name} is} other {# leerkrachten zijn}} nu niet beschikbaar.",
      "private_conversation": "Privégesprek",
      "private_conversation_group": "Privégesprek met ouder uit",
      "private_conversation_school": "Privégesprek met medewerker",
      "private_conversation_with": "Privégesprek met",
      "question_teacher": "Ik geef les aan {childname}:",
      "school_staff": "Schoolmedewerker",
      "select_person": "Selecteer een persoon",
      "select_persons": "Selecteer personen",
      "selected_persons": "Geselecteerd: {persons}",
      "start_a_conversation": "Start een gesprek",
      "start_a_conversation_footer": "Kies personen om een gesprek mee te starten",
      "start_a_conversation_guardian": "Wil je een gesprek beginnen met deze ouders?",
      "start_a_conversation_n_others": "en {value, plural, =1 {# ander…} other {# anderen…}}",
      "start_a_conversation_selected": "{selected} en {value, plural, =1 {# ander…} other {# anderen…}}",
      "start_conversation": "Gesprek starten",
      "start_group": "Start groepsgesprek",
      "start_one_to_one": "Start 1-op-1 gesprek",
      "start_with_parent": "Start gesprek met ouder",
      "start_with_teacher": "Start gesprek met leerkracht",
      "status_archived": "Dit gesprek is gearchiveerd. Stuur een bericht om het weer actief te maken.",
      "status_bot": "De reacties zijn uitgeschakeld",
      "status_changed_title": "{name} heeft het onderwerp aangepast",
      "status_entered": "{name} neemt nu deel aan het gesprek",
      "status_left": "{name} heeft het gesprek verlaten",
      "status_left_bar": "Je maakt geen deel meer uit van dit gesprek",
      "status_paused": "{name} heeft reacties van ouders gepauzeerd",
      "status_paused_bar": "De reacties zijn gepauzeerd",
      "status_start": "Dit is het begin van je gesprek met {name}",
      "status_unknown": "» Oops, status {status} is nog niet vertaald «",
      "status_unpaused": "{name} heeft reacties van ouders hervat",
      "status_update_app": "Om dit bericht goed te tonen, dien je de app te updaten",
      "subtitle_group_default": "Klik voor deelnemers",
      "subtitle_single_default": "Klik voor informatie",
      "superteam_complete": "Jouw superteam is compleet!",
      "superteam_complete_subtext": "Alle duo-leerkrachten en ouders van {child} zijn toegevoegd",
      "teachers_about": "Gesprek met leerkracht(en) over",
      "teachers_of": "Gesprek met leerkracht(en) van",
      "write_message": "Schrijf een bericht aan {recipients}"
    },
    "conversations_dialog": {
      "contact_not_possible": "Sorry, het is niet mogelijk om een gesprek met deze persoon te starten.",
      "guardian_contact_not_allowed": "Wil je graag een gesprek voeren met {name}? Vraag dan de leerkracht van de groep waar {name} aan is gekoppeld om \"Met een andere ouder een gesprek starten\" in te schakelen.",
      "teacher_contact_not_allowed": "Wil je graag een gesprek voeren met je leerkracht? Vraag {name} om \"Met mij een gesprek starten\" in te schakelen."
    },
    "date": {
      "today": "Vandaag",
      "tomorrow": "Morgen",
      "yesterday": "Gisteren"
    },
    "daysofweek": {
      "friday": "Vr",
      "monday": "Ma",
      "saturday": "Za",
      "sunday": "Zo",
      "thursday": "Do",
      "tuesday": "Di",
      "wednesday": "Wo"
    },
    "delete": {
      "confirmation_text": "Het account is gedeactiveerd en wordt binnen 30 dagen verwijderd. Over 5 seconden wordt je uitgelogd.",
      "header": "Account permanent verwijderen",
      "header2": "Verwijder mijn account",
      "terms": "Ik begrijp dat dit proces niet ongedaan te maken is en dat de toegang tot mijn Parro-account permanent ontzegd wordt.",
      "text": "Wanneer je het account verwijdert, zul je direct geen toegang meer hebben tot Parro. Ook zul je geen mails meer van parro ontvangen. Je gegevens worden na de algemeen geldende bewaartermijn verwijderd.",
      "toggle_header": "Wat gebeurt er precies bij het permanent verwijderen?",
      "toggle_text1": "Je persoonsgegevens worden verwijderd. Hieronder vallen je voor- en achternaam, e-mailadres en profielfoto (indien je deze hebt ingesteld).",
      "toggle_text2": "Je bent niet meer gekoppeld aan je kind (indien je een ouder-account hebt), je groepen of je school. Hierdoor zijn jouw gelezenstatussen van mededelingen niet meer inzichtelijk voor anderen.",
      "toggle_text3": "Binnen 30 dagen wordt het verwijder-proces in gang gezet. Het kan daarna tot 30 dagen duren voordat deze gegevens ook uit onze back-ups worden verwijderd.",
      "toggle_text4": "Informatie die jij expliciet naar anderen hebt gestuurd blijft wel bestaan. Dit omvat bijvoorbeeld berichten in gesprekken. Op deze plekken wordt je naam weergegeven als ‘Verwijderde gebruiker’.",
      "toggle_text5": "Je zult geen toegang meer hebben tot foto’s en andere belangrijke informatie die via Parro gedeeld is. De foto’s die je wilt bewaren, moet je eerst downloaden en buiten Parro opslaan voor eigen gebruik.",
      "toggle_text6": "Let op, dit proces is niet ongedaan te maken en je toegang tot jouw Parro-account wordt permanent ontzegd."
    },
    "developmentinfo": {
      "parroUrl": "Parro backend URL:",
      "title": "Ontwikkelaarsinformatie"
    },
    "digest": {
      "option_1": "Direct",
      "option_2": "Dagelijks",
      "option_3": "Nooit",
      "text": "Je ontvangt een samenvatting van gemiste meldingen per e-mail.",
      "text_disable": "De optie om de e-mail volledig uit te schakelen is beschikbaar in de <a href=\"https://parro.com/android\" target=\"_blank\">Android</a> of <a href=\"https://parro.com/ios\" target=\"_blank\">iOS</a> app.",
      "title": "Herinnering per e-mail"
    },
    "donotdisturb": {
      "benefit_1": "Geen pushnotificatie: dus geen geluidje, wel een tellertje",
      "benefit_2": "Ouders en collega's kunnen jouw stiltemodus inzien",
      "benefit_title": "Buiten deze uren:",
      "days": "Dagen",
      "description": "Je ontvangt meldingen tijdens onderstaande uren.",
      "hours": "Uren",
      "no_days": "Geen dagen gekozen",
      "show_availability": "Toon beschikbaarheid",
      "what_is": "Je kan {name} op onderstaande tijden bereiken. Buiten deze tijden komen je berichten nog steeds aan, maar notificaties volgen achteraf."
    },
    "emoji": {
      "activities": "Activiteiten",
      "animals": "Dieren",
      "flags": "Vlaggen",
      "food": "Eten",
      "not_found": "Geen emoji's gevonden",
      "objects": "Objecten",
      "people": "Mensen",
      "recents": "Recent",
      "smileys": "Smileys",
      "symbols": "Symbolen",
      "travel": "Reizen"
    },
    "error": {
      "absence_settings": "Er is wat fout gegaan bij het ophalen van de absentie instellingen",
      "account": {
        "already_linked_invite_code": "Je bent al gekoppeld aan dit kind en de bijbehorende groep.",
        "email_already_in_use": "Er is al een account actief met dit e-mailadres.",
        "email_invalid": "Het opgegeven e-mailadres is onjuist.",
        "email_required": "E-mailadres is verplicht.",
        "emails_not_the_same": "De ingevulde e-mailadressen komen niet overeen.",
        "forgot_password": "Er is een onverwachte systeemfout opgetreden.",
        "incomplete_account_data": "De ingevulde accountgegevens zijn onvolledig.",
        "no_valid_verification_code": "De verificatiecode is ongeldig.",
        "parnassys_email_not_send": "Het versturen van de e-mail is mislukt. Probeer het later nog eens",
        "reset_password_code_invalid": "Misschien is je code al gebruikt of verlopen.",
        "weak_password": "Het gekozen wachtwoord moet uit minimaal 8 tekens bestaan."
      },
      "amazon_upload_failure": "Bestand uploaden is mislukt. Controleer of het bestand niet beschadigd is en probeer het opnieuw.",
      "authentication": {
        "account_for_guardian_error": "Je kan niet met je ouder-account inloggen als leerkracht.",
        "account_for_teacher_error": "Je kan niet met je leerkracht-account inloggen als ouder.",
        "account_ie10_error": "Onbekende combinatie van gebruikersnaam en wachtwoord. Heb je 3x achtereenvolgens geprobeerd in te loggen? Dan kun je uit veiligheidsoverwegingen 10 minuten niet inloggen.",
        "account_locked": "Je account is momenteel geblokkeerd. Probeer het opnieuw over {minutes, plural, =1 {# minuut} other {# minuten}} of gebruik 'Wachtwoord vergeten?'",
        "account_retries": "Onbekende combinatie van gebruikersnaam en wachtwoord, nog {number, plural, =1 {# poging} other {# pogingen}} over.",
        "not_authorized": "Dit account heeft onvoldoende rechten.",
        "parnassys_credentials_error": "ParnasSys-gegevens zijn incorrect.",
        "parnassys_email_conflict": "Je e-mailadres is al gekoppeld aan een bestaande account. Wijzig je e-mailadres in ParnasSys.",
        "parnassys_not_available": "Kan ParnasSys niet bereiken. Probeer het later nog eens.",
        "parnassys_username_conflict": "Gekozen gebruikersnaam is al bezet.",
        "timeout": "Het inloggen duurde te lang. Probeer het later nog eens.",
        "token_error": "Token onbekend / gebruiker niet gevonden. Log opnieuw in.",
        "token_unavailable": "(Parro)token niet beschikbaar",
        "user_unavailable": "Account niet gevonden of account is gedeactiveerd.",
        "username_password_error": "Onbekende combinatie van gebruikersnaam en wachtwoord.",
        "username_password_not_available": "Gebruikersnaam en wachtwoord zijn beide verplicht."
      },
      "back_to_login": "Opnieuw inloggen",
      "browser_text": "Deze browser wordt niet meer ondersteund. Dit betekent dat deze browser niet meer veilig is en sommige functionaliteiten zullen niet meer werken. Gebruik <a href=\"https://www.google.com/intl/nl_nl/chrome/\">Google Chrome</a> of <a href=\"microsoft-edge:https://talk.parro.com\">Microsoft Edge</a>.",
      "browser_title": "Browser wordt niet ondersteund",
      "commitment_status_deadline": "Verwijder eerst de tijdvakken die al verstreken zijn",
      "default": "Oops, er ging wat fout.",
      "failed_add_body": "Fout bij aanmaken jaarboek: ADD_CONTENT_ERROR.",
      "failed_blob": "Fout bij aanmaken jaarboek: HTML_ERROR.",
      "failed_css_file": "Fout bij aanmaken jaarboek: GET_CSS_ERROR",
      "failed_empty_body": "Fout bij aanmaken jaarboek: EMPTY_CONTENT_ERROR.",
      "failed_get_body": "Fout bij aanmaken jaarboek: GET_CONTENT_ERROR.",
      "failed_iframe": "Fout bij aanmaken jaarboek: TEMPLATE_ERROR",
      "failed_styling": "Fout bij aanmaken jaarboek: ADD_CSS_ERROR",
      "feature_disabled": "Deze functionaliteit is momenteel uitgeschakeld.",
      "import_groups_not_found": "De rood omlijnde groepen konden niet worden gevonden. Klik deze weg, en selecteer de juiste groepen",
      "import_no_groups": "Dit agenda-item heeft geen groepen. Kies de groepen door middel van het plus-icoon.",
      "invalid_attachment_listsize": "Er mogen niet meer dan 30 bijlagen toegevoegd worden.",
      "invalid_filetype": "Accepteert alleen jpg, png, pdf, Word, Excel of video bestanden.",
      "invalid_filetype_import": "Accepteert alleen Excel bestanden.",
      "invalid_filetype_new": "Accepteert alleen jpg, png, pdf, Word, Excel, video of audio bestanden.",
      "invalid_image": "Een afbeelding kon niet worden toegevoegd.",
      "invalid_max_filesize": "Bestand is te groot. ({amount})",
      "invalid_max_picturesize": "Afbeelding is te groot (max. 5MB).",
      "invalid_min_filesize": "Bestand is leeg.",
      "invalid_min_picturesize": "Afbeelding is leeg.",
      "invalid_name_length": "Bestandsnaam mag maximaal 100 tekens lang zijn.",
      "invalid_picture_listsize": "Er mogen niet meer dan 30 afbeeldingen toegevoegd worden.",
      "invalid_picturetype": "Accepteert alleen jpg of png afbeeldingen.",
      "invalid_time": "Ingevulde tijd is ongeldig",
      "localstorage": "Deze browser ondersteunt niet de functionaliteiten om in te loggen. Gebruik een andere browser.",
      "maillength_exceeded": "Emailadres mag maximaal 50 tekens lang zijn.",
      "maxlength_exceeded": "Invoer mag maximaal 80 tekens lang zijn.",
      "no_internet": "Het lijkt erop dat je geen internetverbinding hebt.",
      "nonunique_file": "Bestand is reeds toegevoegd.",
      "nonunique_picture": "Afbeelding is reeds toegevoegd.",
      "privacy_settings": "Er is wat fout gegaan bij het ophalen van de privacy instellingen",
      "refresh": "Vernieuw de pagina",
      "startdate_enddate": "Eind datum moet op of na de begin datum liggen",
      "startdate_now": "Begin datum moet in de toekomst liggen",
      "starttime_endtime": "Eindtijd moet op of na de begintijd liggen",
      "starttime_endtime2": "Eindtijd moet na de begintijd liggen",
      "starttime_now": "Begintijd moet in de toekomst liggen",
      "support_xlsx": "Importeren wordt niet ondersteund in deze browser.",
      "support_xlsx_populate": "Downloaden van een .xlsx bestand wordt niet ondersteund in deze browser.",
      "timeslot_date_now": "Begintijd van de gesprekken moeten in de toekomst liggen",
      "too_many_attachments": "Je mag maximaal {maxPictures, plural, =1 {# afbeelding} other {# afbeeldingen}} per keer versturen.",
      "unauthorized": "Oops, het lijkt erop dat je niet bevoegd bent om deze actie uit te voeren.",
      "unknown_error": "Onverwachte fout opgetreden.",
      "userdata": {
        "notfound": "Gebruikersgegevens niet gevonden"
      },
      "validation": {
        "all_fields_required": "Alle velden met een '*' zijn verplicht.",
        "current_password_incorrect": "Het huidige wachtwoord is incorrect.",
        "email_too_long": "Het e-mailadres is te lang.",
        "invalid_values": "Er zijn ongeldige waard(en) ingevuld",
        "old_password_incorrect": "Het oude wachtwoord is incorrect.",
        "password_incorrect": "Het wachtwoord is incorrect.",
        "passwords_new_not_the_same": "De ingevulde nieuwe wachtwoorden komen niet overeen.",
        "passwords_not_the_same": "De ingevulde wachtwoorden komen niet overeen.",
        "value_too_long": "De ingevulde waarde is te lang."
      },
      "yearbook_housenumber": "Controleer je huisnummer. Een huisnummer moet beginnen met een numerieke waarde.",
      "yearbook_order_exists": "De bestelling bestaat al. Refresh de pagina.",
      "yearbook_postalcode": "Controleer je postcode. Een postcode bestaat uit 4 cijfers en 2 letters.",
      "yearbook_unknown_error": "Onverwachte fout opgetreden. Probeer het later nog eens."
    },
    "free_dialog": {
      "paragraph1": "Beste Parro gebruiker,",
      "paragraph2": "Met ingang van 1 augustus a.s. gaat er iets veranderen in het gebruik van Parro. De huidige gratis licentie zal per die datum komen te vervallen. Hierover ben je wellicht al geïnformeerd via één van onze serviceberichten.",
      "paragraph3": "Wil je als school gebruik blijven maken van Parro dan is het belangrijk dit aan ons door te geven.",
      "paragraph4": "Heb je ons al laten weten wat je plannen zijn voor volgend schooljaar? Dan hoef je geen actie meer te ondernemen.",
      "paragraph5": "Het Parro Team",
      "status_text": "Gratis versie stopt."
    },
    "group_announcements": {
      "confirm_archived_text": "De groep is mogelijk gearchiveerd. Controleer bij Synchroniseer & Beheer of je aan deze groep bent gekoppeld.",
      "confirm_archived_title": "Groep niet gevonden",
      "confirm_delete_other_text": "Alleen {name} mag de mededeling verwijderen.",
      "confirm_delete_text": "Je staat op het punt om deze mededeling te verwijderen. Weet je het zeker?",
      "confirm_delete_title": "Mededeling verwijderen",
      "delete_announcement": "Verwijderen",
      "deleted_announcement_other": "Deze mededeling is verwijderd",
      "deleted_announcement_you": "Je hebt deze mededeling verwijderd",
      "dummy1": "We zoeken nog twee vrijwilligers die volgende week woensdag tussen 3 en half 4 kunnen rijden naar het voetbaltoernooi. Wie zou ons kunnen helpen? Stuur mij een berichtje.",
      "dummy2": "Het uitje naar de speeltuin was heel geslaagd! Ik wil iedereen bedanken die geholpen heeft, zowel van te voren als op de dag zelf. Zoals je ziet heeft iedereen het enorm naar zijn zin gehad.",
      "dummy3": "Rik is zijn schooltas helaas kwijt! Heeft iemand hem misschien gevonden? De tas is geel met een rode rits. Op de achterkant staat afbeelding van een hele mooie papegaai.",
      "dummy_guardian": "Welkom in de groep! Je ontvangt hier alle mededelingen die de leerkracht naar de groep stuurt. Je ziet dus alleen mededelingen die zijn geschreven nadat je bent gekoppeld. We hebben hieronder wat voorbeelden geplaatst van mogelijke mededelingen. Deze verdwijnen automatisch zodra je een echte ontvangt.",
      "dummy_teacher": "Hieronder zie je voorbeelden van mogelijke mededelingen. De voorbeelden verdwijnen automatisch zodra je mededelingen hebt ontvangen of geschreven. Ben je er klaar voor? Druk op de knop rechtsboven om je eerste mededeling te versturen.",
      "edit_announcement": "Wijzigen",
      "edited_date": "(aangepast op {date})",
      "edited_name_date": "(aangepast door {name} op {date})",
      "like": "Vind ik leuk",
      "like_information": "Ontvangers kunnen een \"vind-ik-leuk\" geven",
      "new_announcement": "Nieuwe mededeling",
      "new_announcement_recipients": "Kies ontvangers",
      "new_scheduled_announcements": "{amount, plural, =0 {Geen geplande mededelingen} =1 {# geplande mededeling} other {# geplande mededelingen}}",
      "readby": "{read, plural, other {#}} van {total, plural, =1 {# ouder} other {# ouders}}",
      "repeat_announcement": "Opnieuw versturen",
      "repeat_date": " - Herhaling van {date}",
      "schedule_time": "Wanneer wil je dat de mededeling verstuurd wordt?",
      "scheduled": "Gepland op",
      "sender": "de verzender",
      "write_label": "Mededeling *",
      "write_placeholder": "Schrijf een mededeling aan {numberOfRecipients, plural, =1 {# ontvanger} other {# ontvangers}} *",
      "write_title": "Titel (optioneel)"
    },
    "group_contacts": {
      "header": "Ouders beheren",
      "no_contacts_found1": "Geen contacten",
      "no_contacts_found2": "Deze groep heeft geen contacten. Plaats in ParnasSys leerlingen in deze groep en synchroniseer dit met Parro."
    },
    "group_settings": {
      "delete_group": "Mededelingen en foto's langer beschikbaar",
      "delete_group_header": "Beschikbaarheid gegevens",
      "delete_group_modal": "Na veel reacties van ouders hebben wij besloten de groepen van schooljaar {schoolYear} langer beschikbaar te houden. Dus ook na 1 augustus 2019 kun jij terugkijken naar de mededelingen en foto's uit dat schooljaar.",
      "footnote": "Stel ouders in staat 1-op-1 gesprekken te starten om jou en andere ouders eenvoudig op de hoogte te houden.",
      "header": "Ouders van deze groep mogen...",
      "modal_option_text": "<strong>Weet je zeker dat je deze groep wilt verlaten? Je kunt het volgende niet meer doen:</strong> <br/><br/> &#8226 Ontvangen of inzien van mededelingen, foto's of agenda-items van deze groep. <br/><br/> &#8226 Contact opnemen met leerkrachten of ouders van deze groep.",
      "modal_title": "Groep verlaten",
      "option_start_me": "Met mij een gesprek starten",
      "option_start_other": "Met een andere ouder een gesprek starten"
    },
    "infinity": {
      "key_announcements": "mededelingen",
      "key_contacts": "contacten",
      "key_conversations": "gesprekken",
      "key_messages": "berichten",
      "key_participants": "deelnemers",
      "load_more": "Toon meer {key}",
      "loaded_all": "» Alle {key} worden getoond «",
      "loading_more": "Ophalen van meer items..."
    },
    "interval": {
      "every_x_days": "{x, plural, =1 {Elke dag} other {Elke # dagen}}",
      "every_x_weeks": "{x, plural, =1 {Elke week} other {Elke # weken}}",
      "never": "Geen herinnering"
    },
    "invite_contacts": {
      "amount_invited": "{amount, plural, =1 {# ouder} other {# ouders}}",
      "description_decoupled": "De volgende ouders hebben de Parro groep verlaten of zijn uit de groep verwijderd. Selecteer ze hier om ze toch uit te nodigen. <a href={url} target='_blank'>Meer info...</a>",
      "description_no_email": "De volgende ouders kunnen niet uitgenodigd worden, omdat er in ParnasSys geen emailadres bekend is. Registreer een e-mailadres in ParnasSys om deze ouders toch uit te kunnen nodigen <a href={url} target='_blank'>Meer info...</a>",
      "description_no_permission": "De volgende ouders hebben volgens ParnasSys toegangsbeperkingen. Selecteer ze hier om ze toch uit te nodigen. <a href={url} target='_blank'>Meer info...</a>",
      "header_decoupled": "Ontkoppelde ouders toegang geven",
      "header_no_email": "E-mailadres onbekend",
      "header_no_permission": "Toegangsbeperkingen opheffen",
      "modal_no_email": "Er is nog geen e-mailadres bekend. Voeg een e-mailadres toe in ParnasSys. Daarna kun je {name} uitnodigen.",
      "modal_no_email_title": "Voeg e-mailadres toe in ParnasSys",
      "modal_not_allowed_text": "{guardian} kan niet worden uitgenodigd voor {child}. In ParnasSys is voor {guardian} ingesteld dat dit niet is toegestaan. Controleer de ouder instellingen in ParnasSys.",
      "modal_not_allowed_title": "Geen toestemming om uit te nodigen",
      "modal_start_description": "Selecteer de ouders die je wil toevoegen aan de groep.",
      "modal_title": "Toegang geven",
      "no_guardians_text": "Controleer in ParnasSys of de gegevens kloppen.",
      "no_guardians_title": "Geen ouders",
      "placeholder_no_invitable_text": "Alle ouders van deze groep zijn gekoppeld aan een kind.",
      "placeholder_no_invitable_title": "Alle ouders zijn bereikbaar",
      "placeholder_success_text": "De geselecteerde ouders zijn uitgenodigd en kunnen gebruik maken van Parro.",
      "placeholder_success_title": "Ouders zijn uitgenodigd",
      "placeholder_text": "Aan alle kinderen is minimaal 1 ouder gekoppeld. Koppel extra ouders handmatig.",
      "placeholder_title": "Gefeliciteerd!",
      "suffix_added": "{amount, plural, =1 {is} other {zijn}} toegevoegd aan de groep",
      "suffix_invited": "{amount, plural, =1 {is} other {zijn}} per e-mail uitgenodigd voor Parro",
      "suffix_problem": "kon geen toegang worden gegeven. Kijk hieronder om te zien hoe je ze alsnog uit kan nodigen."
    },
    "login": {
      "choose_login_as": "Kies de school om op in te loggen",
      "create_new_account": "Een nieuw Parro-account aanmaken",
      "make_your_choice": "Kies hier of je een leerkracht of ouder bent",
      "not_a_guardian": "Ik heb geen ouder-account en wil ",
      "not_a_teacher": "Ik heb geen leerkracht-account en wil ",
      "placeholder": "Gebruikersnaam / e-mailadres",
      "privacy_policy": "PRIVACYBELEID PARRO",
      "teacher": "Log in als leerkracht",
      "thumbnail_logging_in": "Parro wordt opgestart!",
      "thumbnail_logging_out": "Je account wordt uitgelogd",
      "title_suffix": "in Parro",
      "title_undefined": "Inloggen als..",
      "want_to_know_more": "Wil je meer weten over Parro? Lees verder op <a href=\"https://www.parro.com\" target=\"_blank\">www.parro.com</a>"
    },
    "login_error": {
      "account_forgotten_description": "Je hebt je Parro account opgezegd. Je hebt de mogelijkheid om de verwijdering ongedaan te maken tot <strong><u>{date}</u></strong>. Hierna zijn je gegevens permanent verwijderd.",
      "account_forgotten_description_old": "Je hebt je Parro account opgezegd. Deze bevindt zich in de 30 dagen bedenk periode. Na deze periode zijn je gegevens permanent verwijderd. Als je de opzegging ongedaan wilt maken, neem dan contact op met support@parro.com.",
      "account_forgotten_header": "Account opgezegd",
      "account_inactive_description": "Jouw account is geblokkeerd. Als je dit ongedaan wilt maken, neem dan contact op met support@parro.com.",
      "account_inactive_header": "Account geblokkeerd",
      "account_lab_description": "Je probeert met een LAB account in te loggen. Je kan alleen inloggen met een medewerker of ouder account. Ga naar ParnasSys en log daar uit.",
      "account_lab_header": "Verkeerde login",
      "guardian_account_not_allowed_description": "Je probeert met een ouder account in te loggen als leerkracht. Als je bent ingelogd in het ouderportaal, dan moet je daar eerst uitloggen, voordat je in Parro kan inloggen.",
      "guardian_account_not_allowed_header": "Verkeerde login",
      "no_organisation_for_user_description": "Je account heeft geen aanstellingen. Controleer in ParnasSys of jouw aanstellingen actief zijn en of je geen monitor bent van de aanstellingen.",
      "no_organisation_for_user_header": "Geen actieve aanstellingen",
      "parnassys_not_available_description": "Op dit moment heeft ParnasSys onderhoud. Probeer het later nog eens.",
      "parnassys_not_available_header": "ParnasSys is niet beschikbaar"
    },
    "menu": {
      "absence_guardian": "Absentie melden",
      "absence_teacher": "Absentie-overzicht",
      "absence_teacher_subtitle": "{amount, plural, =1 {# afwezige} other {# afwezigen}} vandaag",
      "account_parentverified": "Account verifieren",
      "account_registered": "Account geregistreerd",
      "account_registration": "Account registreren",
      "account_verification": "Account verifieren",
      "actions": "Acties",
      "announcement_recipients": "Mededeling gelezen",
      "calendar": "Agenda",
      "calendar_info": "Bekijk hoe de agenda werkt",
      "calendar_item_cancelled": "Agenda-item is geannuleerd",
      "calendar_item_child_committed": "Ingeschreven op {data}",
      "calendar_item_closed": "Inschrijvingen gesloten",
      "calendar_item_concept": "Concept",
      "calendar_item_deadline": "Je kunt niet meer inschrijven",
      "calendar_item_finished": "Hebbes! Alle vrijwilligers / materialen compleet",
      "calendar_item_no_planned_items": "Vandaag: geen items gepland",
      "calendar_item_open": "Inschrijvingen geopend",
      "calendar_item_planned": "Inschrijvingen ingepland",
      "calendar_item_present": "Ben jij aanwezig?",
      "calendar_mark_as_read": "Markeer agenda als gelezen",
      "calendar_none_button": "Maak nieuw agenda-item",
      "calendar_none_descriptionguardian": "De leerkrachten hebben nog geen agenda-items gemaakt voor je groepen.",
      "calendar_none_descriptionteacher": "Wil je een item maken voor je groep(en)?",
      "calendar_none_title": "Geen agenda-items",
      "calendar_planning_info": "Bekijk hoe het werkt",
      "calendar_question_available": "Ben jij aanwezig?",
      "calendar_question_both": "Kun jij helpen?",
      "calendar_question_commit": "Schrijf je in",
      "calendar_question_material": "Kun jij helpen met materialen?",
      "calendar_question_volunteer": "Kun jij helpen met vrijwilligers?",
      "calendar_scroll_button": "Scroll naar vandaag",
      "calendar_text_both": "Jij helpt met vrijwilligers en materialen",
      "calendar_text_material": "Jij helpt met materialen",
      "calendar_text_volunteer": "Jij helpt met vrijwilligers",
      "content_search_pilot_info": "Op dit moment testen wij zoeken in Parro. Dit is een tijdelijk beschikbare functionaliteit.",
      "conversation_messages": "Berichten",
      "conversation_participants": "Deelnemers",
      "conversation_participants_amount": "Deelnemers: {value}",
      "conversations": "Gesprekken",
      "conversations_edit_title": "Wijzig titel",
      "conversations_none_descriptionguardian": "Wil je een gesprek starten?",
      "conversations_none_descriptionguardian_nope": "Je hebt nog geen lopende gesprekken. Zodra de leerkracht een gesprek start, is dit de plek om te kijken.",
      "conversations_none_descriptionteacher": "Wil je een gesprek starten met (bijvoorbeeld) enkele collega's?",
      "conversations_none_title": "Geen lopende gesprekken",
      "conversations_reactions": "Reacties van ouders",
      "conversations_reactions_off": "Zet de reacties van ouders uit ",
      "conversations_reactions_on": "Zet de reacties van ouders aan",
      "group_add_new_groups": "Tijd om je nieuwe groepen aan jouw Parro-account toe te voegen.",
      "group_announcements": "Mededelingen",
      "group_colleagues": "Collega's",
      "group_newyear": "Nieuw schooljaar van start!",
      "group_none_chosen": "Nog geen nieuwe groepen gekozen",
      "group_selection": "Kies nieuwe groepen",
      "group_settings": "Instellingen",
      "groups": "Groepen",
      "groups_none_button": "Importeer uit ParnasSys",
      "groups_none_descriptioncalendar": "Ga naar synchroniseer & beheer om groepen toe te voegen.",
      "groups_none_descriptioncode": "Je bent nog niet toegevoegd aan een groep van het huidige schooljaar. Wacht op een uitnodiging.",
      "groups_none_descriptionguardian": "Vraag de leerkracht om jou te koppelen aan een groep.",
      "groups_none_descriptionteacher": "Zullen we op zoek gaan naar jouw groepen?",
      "groups_none_schoolyear_title": "Geen kinderen in schooljaar {year}/{nextyear}",
      "groups_none_title": "Nog geen groepen gevonden...",
      "news": "Parro nieuws",
      "reset_password": "Nieuw wachtwoord aanvragen",
      "settings": "Instellingen",
      "settings_feedback_support": "Feedback en ondersteuning",
      "settings_logout_confirm_header": "Uitloggen",
      "settings_logout_confirm_text": "Weet je zeker dat je wilt uitloggen?",
      "settings_markallasread": "Markeer alle items als gelezen",
      "settings_markallasread_amount": "{amount} ongelezen items",
      "settings_new": "Nieuw",
      "settings_pilot": "Pilot",
      "settings_policy": "Privacybeleid Parro",
      "settings_privacy": "Privacy centrum",
      "settings_school": "Schoolbrede instellingen",
      "settings_settings": "Instellingen",
      "settings_yearbook": "Parro Jaarboek",
      "settings_yearbook_disabled": "Ouders kunnen geen jaarboek maken",
      "settings_yearbook_enabled": "Ouders kunnen een jaarboek maken",
      "share_feedback": "Deel hier je feedback."
    },
    "messages": {
      "confirm_close": "Je wijzigingen gaan verloren. Toch sluiten?",
      "confirm_close_title": "Sluiten",
      "contribution_updated": "Je bijdrage is opgeslagen.",
      "save_succcessful": "Wijzigingen zijn opgeslagen."
    },
    "multi_account": {
      "switch_to": "Je bent doorgeschakeld naar het account: {account}",
      "switching_to": "Doorschakelen naar "
    },
    "news": {
      "intro_header": "Wat is er nieuw",
      "intro_text": "Updates over nieuwe Parro functionaliteit, onderhoud en toekomstige onderwerpen.",
      "no_items": "Er zijn op dit moment nog geen nieuwtjes. Wanneer deze er zijn, zie je deze hier staan.",
      "no_title": "Geen titel"
    },
    "pagetitle": {
      "404": "Oops...",
      "absence": "Absenties",
      "accounts": "Accounts",
      "add_account": "Account toevoegen",
      "add_group": "Koppel met groep",
      "announcements": "Mededelingen",
      "authenticate": "Authenticeren",
      "calendar": "Agenda-item",
      "colleagues": "Collega's",
      "delete": "Verwijderen",
      "donotdisturb": "Stiltemodus",
      "getting_started": "Aan de slag",
      "ideas_forum": "Ideeënforum",
      "import": "Importeren",
      "index": "Welkom",
      "invitees": "Genodigden",
      "license": "Licentie",
      "login": "Inloggen",
      "login_as_guardian": "Inloggen als ouder",
      "login_as_teacher": "Inloggen als leerkracht",
      "news": "Parro Nieuws",
      "notifications": "Meldingen",
      "pilot": "Pilot",
      "pilot_start": "Pilot starten",
      "privacy_preferences": "Privacy-voorkeuren",
      "profile": "Profiel",
      "recover_account": "Account herstellen",
      "scheduled_announcements": "Geplande mededelingen",
      "settings": "Instellingen",
      "support": "Parro Support",
      "timeslot": "Inschrijving details",
      "volunteers": "Vrijwilligers",
      "yearbook": "Jaarboek",
      "yearbook_enable": "Parro Jaarboek aanbieden",
      "yearbook_parro": "Parro Jaarboek",
      "yearbook_type": "Kies boeksoort"
    },
    "parent": {
      "children": "Mijn kinderen",
      "groups": "Mijn groepen"
    },
    "parro": {
      "choose_number": "Kies aantal",
      "colleagues": "Collega's",
      "coming_soon": "Binnenkort",
      "deleted_at": "Verwijderd op {date} door {name}",
      "example": "Voorbeeld",
      "groups": "groepen",
      "guardian": "Ouder",
      "guardians": "Ouders",
      "inactive": "(inactief)",
      "last_minute": "Afgelopen minuut",
      "modified_at": "aangepast op",
      "new": "Nieuw!",
      "school_year": "Schooljaar {year}",
      "teacher": "Leerkracht",
      "teachers": "Leerkrachten",
      "this_is_you-capitalized": "Jij",
      "this_is_you-long": "hé, dit ben jij",
      "this_is_you-short": "jij",
      "this_is_you-short2": "jou",
      "unknown": "onbekend"
    },
    "photoswipe": {
      "close_button": "Sluiten (Esc)",
      "download_image_button": "Download afbeelding",
      "fullscreen_button": "Wissel van/naar volledig scherm",
      "next_button": "Volgende (rechterpijltoets)",
      "no_image_error": "<a href=\"%url%\" target=\"_blank\">De afbeelding</a> kon niet geladen worden.",
      "options_button": "Opties",
      "prev_button": "Vorige (linkerpijltoets)",
      "zoom_button": "Zoom in/uit"
    },
    "pilot": {
      "connect_parents": "Ouders van {amount} kinderen staan klaar om in contact te komen",
      "connect_parents_description": "Start een pilot om vandaag nog met ouders samen te werken. <a href={url}>Lees meer...</a>.",
      "during_pilot": "Tijdens de pilot",
      "during_pilot_description": "Heb je vragen verzameld of wil je weten hoe iets werkt? Je vindt hier alle <a href={url} target='_blank'>informatie</a>.",
      "end_date": "Einddatum:",
      "modal_header_information1": "Parro uitproberen",
      "modal_header_information2": "Ouders later uitnodigen",
      "modal_information1": "Verzamel je collega’s in Parro en probeer gratis uit.<br/> <br/> Ben je klaar om ouders uit te nodigen? Start een Parro pilot.",
      "modal_information2": "Via instellingen > pilot is de pilot met ouders te allen tijde gratis te starten.",
      "no_end_date": "Geen einddatum",
      "notification_ended": "De pilot is ten einde!",
      "notification_started": "De pilot is van start!",
      "parro_pilot": "Parro pilot",
      "period": "Periode",
      "period_description": "De pilot geldt voor de hele school en heeft een duur van 2 maanden.",
      "pilot_ended": "Einde pilot",
      "pilot_ended_description": "De pilot stopt automatisch. Bevalt Parro en wil je contact met ouders blijven houden?",
      "prepare": "De school voorbereiden",
      "prepare_description": "Download: het startpakket met tips & tricks, een stappenplan, presentaties voor collega's en ouders, instructiefilmpjes en meer.<br/> <br/> <a href={url} target='_blank'>parro.com/hulpmiddelen-leerkracht</a>",
      "pricing_plan_ended_error": "De pilot is afgelopen",
      "read_more_subtext_parent": "Vraag jouw school hoe de leerkracht-oudercommunicatie voortaan plaats zal vinden.",
      "read_more_subtext_teacher": "Je kunt Parro nog wel gebruiken met collega’s. Wil je toch weer communiceren met ouders?",
      "read_more_text": "Op dit moment heeft jouw school geen actieve Parro-licentie, daarom is communicatie tussen ouders en leerkrachten niet langer mogelijk.",
      "start": "Klaar om ouders uit te nodigen?",
      "start_a": "Start een",
      "start_alternative": "Andere startdatum of wensen",
      "start_alternative_description": "Zijn er specifieke wensen? Maak gebruik van het speciale <a href={url} target='_blank'>aanvraag formulier</a>",
      "start_description": "Probeer Parro 2 maanden gratis samen met ouders uit.<br/> <br/> De pilot geldt voor de hele school. Alle functies zijn beschikbaar en het aantal groepen is onbeperkt.",
      "start_error": "De pilot is al begonnen. Wellicht heeft een collega de pilot gestart.",
      "started": "Nog {amount} dagen",
      "started2": "{amount} dagen resterend",
      "started_no": "Nog niet begonnen",
      "starter_kit": "Startpakket",
      "starter_kit_description": "Informeer collega’s en ouders met dit handige <a href={url} target='_blank'>start pakket</a>.",
      "terms_agreement": "Algemene voorwaarden",
      "terms_agreement_accept": "Ik accepteer de voorwaarden",
      "terms_agreement_description": "Door de pilot te starten ga je akkoord met de <a href={url} target='_blank'>Algemene Voorwaarden</a> van Parro."
    },
    "pilot_status_bar": {
      "calendar_ended": "De {organisation} heeft geen licentie",
      "calendar_teacher_ended": "Je mag alleen agenda-items aanmaken voor de collega groep.",
      "invite_parents_ended": "Je mag geen ouders meer uitnodigen, omdat je geen licentie meer hebt.",
      "license_parent_ended": "De schoollicentie is verlopen. ",
      "license_teacher_ended": "De licentie is verlopen. ",
      "select_group_ended": "Je kan alleen de collega groep selecteren."
    },
    "placeholder": {
      "add_attachment": "Bericht toevoegen aan bijlage",
      "at": "Om",
      "choose_groups": "Kies groep(en)",
      "choose_reason": "Kies een ParnasSys reden",
      "choose_recipients": "Kies ontvangers",
      "date": "Datum",
      "date_from": "Begindatum",
      "date_range": "Begin  |  Eind",
      "date_till": "Einddatum",
      "description": "Omschrijving",
      "display_guardian": "Weergave ouders",
      "display_name": "Weergave naam",
      "email": "E-mailadres",
      "explanation": "Toelichting",
      "explanation_add": "Wil je nog iets extra's kwijt?",
      "external_url": "Externe url",
      "extra_information": "Extra informatie",
      "fill_in_date": "Kies een datum",
      "firstname": "Jouw voornaam",
      "location": "Locatie",
      "new_announcement": "Schrijf een mededeling ...",
      "new_message": "Schrijf een bericht ...",
      "new_message_enter": "Schrijf een bericht. Gebruik Shift + Enter voor een nieuwe regel.",
      "new_note": "Voeg een notitie toe",
      "note": "Notitie",
      "or": "OF",
      "password": "Wachtwoord",
      "password_parnassys": "ParnasSys wachtwoord",
      "reason": "ParnasSys reden",
      "reminder": "Herinnering",
      "repeat": "Herhaal",
      "slot_duration": "Vul de duur in (5 - 180 min)",
      "slot_number": "Vul het aantal in (max 30)",
      "slot_pause": "Vul de duur in (0 - 180 min)",
      "surname": "Jouw achternaam",
      "time": "Tijd",
      "time_choose": "Kies een tijd",
      "time_from": "Begintijd",
      "time_till": "Eindtijd",
      "title": "Vul een titel in",
      "username_guardian": "E-mailadres",
      "username_parnassys": "ParnasSys gebruikersnaam"
    },
    "placeholder_required": {
      "choose_groups": "Kies groep(en) *",
      "choose_recipients": "Kies ontvangers *",
      "date": "Datum *",
      "date_from": "Begindatum *",
      "date_till": "Einddatum *",
      "description": "Omschrijving *",
      "email": "E-mailadres *",
      "equipment": "Vul een materiaal in *",
      "firstname": "Jouw voornaam *",
      "group_subject": "Groepsonderwerp",
      "interval": "Interval *",
      "location": "Locatie *",
      "new_announcement": "Schrijf een mededeling ... *",
      "password": "Wachtwoord (min. 8 tekens) *",
      "recipients": "Ontvangers *",
      "repeatemail": "Herhaal E-mailadres *",
      "repeatpassword": "Herhaal wachtwoord (min. 8 tekens) *",
      "subject": "Onderwerp *",
      "surname": "Jouw achternaam *",
      "time": "Tijd *",
      "time_end": "Eindtijd *",
      "time_start": "Begintijd *",
      "title": "Titel *",
      "volunteer": "Vul een taak in *"
    },
    "print": {
      "activity_comment": "Opmerking",
      "activity_name": "Naam",
      "activity_time": "Tijd"
    },
    "privacy": {
      "consent_no": "Nee",
      "consent_unknown": "Niet aangegeven door ouders",
      "consent_yes": "Ja",
      "guardian_description_closed": "Bewerken is uitgeschakeld",
      "guardian_description_closed_period": "Bewerkbaar vanaf {date}",
      "guardian_description_open_period": "Bewerkbaar t/m {date}",
      "header_guardians": "Ouders mogen voorkeuren bewerken:",
      "header_more_info": "Meer informatie?",
      "option_now": "Nu bewerkbaar",
      "option_period": "Bewerkbaar in periode",
      "overview": "Overzicht privacy-voorkeuren",
      "placeholder_colleague_text": "De collega groep bevat geen ouders. Als je de privacy-voorkeuren wilt inzien, voeg een groep met ouders toe. Zo krijg je inzicht waar ouders toestemming voor hebben gegeven en wie er op de foto mag. Ouders kunnen via Parro hun voorkeuren doorgeven, waarop dit automatisch in ParnasSys geregistreerd wordt.",
      "placeholder_colleague_title": "Collega's",
      "placeholder_no_children_text": "Voeg kinderen toe aan deze groep(en) in ParnasSys, zodat ouders de privacy-voorkeuren kunnen instellen. Zo krijg je inzicht waar ouders toestemming voor hebben gegeven en wie er op de foto mag. Ouders kunnen via Parro hun voorkeuren doorgeven, waarop dit automatisch in ParnasSys geregistreerd wordt.",
      "placeholder_no_children_title": "Geen kinderen gevonden",
      "placeholder_no_guardians_text": "Er kunnen alleen privacy-voorkeuren getoond worden als er ouders in een gesprek zitten.",
      "placeholder_no_guardians_title": "Geen ouders gevonden",
      "placeholder_no_preferences_text": "Benieuwd wie er op de foto mag? Privacy-voorkeuren geven je inzicht en vertellen je waar ouders toestemming voor hebben gegeven. Ouders kunnen via Parro hun voorkeuren doorgeven, waarop dit automatisch in ParnasSys geregistreerd wordt.",
      "placeholder_no_preferences_title": "Geen privacy-voorkeuren gevonden",
      "placeholder_no_selection_text": "Benieuwd wie er op de foto mag? Privacy-voorkeuren geven je inzicht en vertellen je waar ouders toestemming voor hebben gegeven. Kies hierboven welke privacy-voorkeur je wilt zien.",
      "placeholder_no_selection_title": "Geen privacy-voorkeur geselecteerd",
      "placeholder_selection": "Kies een privacy-voorkeur",
      "preference_error": "Er is iets fout gegaan bij het ophalen van de privacy-voorkeuren. Neem contact op met de Parro support.",
      "preferences": "Privacy-voorkeuren",
      "teacher_description_closed": "Ouders mogen niet bewerken",
      "teacher_description_closed_period": "Ouders mogen bewerken vanaf {date}",
      "teacher_description_open": "Ouders mogen bewerken",
      "teacher_description_open_period": "Ouders mogen bewerken t/m {date}",
      "text_edited_by": "Laatst gewijzigd door: {name}",
      "text_more_info": "Heb je vragen of wil je graag weten hoe het werkt? Je vindt <a href=\"https://support.parro.com/hc/nl/articles/360005441071-Parro-in-actie-Privacy-voorkeuren-registeren-door-ouders\" target\"_blank\">hier</a> alle informatie.",
      "text_school": "Deze instellingen gelden voor de hele school.",
      "title": "Privacy",
      "title_modal": "Wie mogen er op de foto?"
    },
    "profile_children": {
      "privacy_preferences": "Privacy voorkeuren",
      "privacy_preferences_text": "Stel de privacy voorkeuren in.",
      "title": "Mijn kinderen"
    },
    "profile_personal": {
      "currentpassword": "Huidig wachtwoord",
      "deactivate": "Ik wil mijn account <a href={url}>opzeggen</a>.",
      "edit_email": "Neem contact op met je school om je e-mailadres aan te passen.",
      "edit_in_parnassys": "Log in bij <a href={url} target='_blank'>ParnasSys</a> om je persoonsgegevens aan te passen.",
      "edit_name": "Neem contact op met je school om je naam aan te passen.",
      "edit_password": "Je kan je wachtwoord aanpassen in ParnasSys.",
      "email": "E-mailadres",
      "email_warning": "Let op: na wijzigen moet je dit nieuwe e-mailadres ook gebruiken om bij Parro in te loggen.",
      "firstname": "Voornaam",
      "name": "Naam",
      "newpassword": "Nieuw wachtwoord",
      "password": "Wachtwoord",
      "password_check": "Wachtwoord ter controle",
      "repeatpassword": "Herhaal nieuw wachtwoord",
      "surname": "Achternaam",
      "title_contact": "Accountgegevens",
      "title_login": "Inloggegevens Parro"
    },
    "profile_school": {
      "no_license": "Geen licentie",
      "parro_license": "Parro licentie",
      "plan_free": "Communiceren",
      "plan_organize": "Communiceren & Organiseren",
      "plan_pilot": "Pilot",
      "school": "School",
      "title": "Schoolgegevens"
    },
    "recovery": {
      "code_not_found_header": "Code niet gevonden",
      "code_not_found_text": "De code is mogelijk al gebruikt of onjuist. Controleer de code in jouw e-mail of die overeenkomt met de code in de adresbalk.",
      "code_not_valid_header": "Code is niet geldig",
      "code_not_valid_text": "De bedenktijd is verstreken. Je account is verwijderd.",
      "error_default_header": "Herstellen is mislukt",
      "error_default_text": "Probeer het later nog eens. Blijft dit probleem zich voordoen, neem dan contact op met <a href=\"mailto:support@parro.com?subject=Account%20onterecht%20vergeten\" target=\"_blank\">Parro Support</a>",
      "success_header": "Herstellen is gelukt!",
      "success_text": "Je account is geactiveerd. Je kan weer inloggen in Parro."
    },
    "screensaver": {
      "powered_by": "® Powered by Parro",
      "screensaver": "Screensaver"
    },
    "search": {
      "announcements": "Zoek mededeling",
      "chatmessages": "Zoek chatbericht",
      "no_results": "Parro kon niks vinden voor jouw zoekopdracht. Probeer het eens met een andere zoekterm."
    },
    "status": {
      "loading": "De data wordt geladen"
    },
    "synchronize_manage": {
      "group_import_button": "Ouders toegang geven",
      "group_import_info": "Parro kan de meeste ouders automatisch toegang geven. Handig! Hieronder kun je gezinnen en ouders handmatig beheren.",
      "group_import_new": "{amount} Ouder(s) koppelen",
      "group_import_updated": "Alle ouders zijn up to date",
      "manage": "Beheer",
      "manage_error": "Er is een fout opgetreden tijdens het opslaan van de groepen. Probeer het later nog eens.",
      "manage_groups": "Beheer groepen",
      "no_groups_error": "Er is een fout opgetreden tijdens het ophalen van de groepen uit ParnasSys. Probeer het later nog eens.",
      "no_groups_found": "Er zijn geen groepen gevonden vanuit ParnasSys",
      "synchronization_time": "Gesynchroniseerd: {time}",
      "synchronize": "Synchroniseer",
      "synchronize_error": "Synchroniseren mislukt",
      "synchronize_manage": "Synchroniseer & beheer"
    },
    "yearbook_cover": {
      "alert_quality": "Lage kwaliteit foto. We raden aan een andere foto te kiezen.",
      "header": "Stel hier je cover samen",
      "header_extra": "De cover kan je altijd aanpassen. De kleur die je hier kiest, is in het hele boek terug te vinden.",
      "label_color": "Kleur",
      "label_title": "Titel",
      "placeholder_title": "Typ hier jouw titel",
      "text": "Jaarboek van leerjaar 2018 / 2019",
      "title": "Kies cover foto"
    },
    "yearbook_dialogs": {
      "bad_quality_header": "Lage kwaliteit",
      "bad_quality_text": "Deze bronfoto is van lage kwaliteit. We raden aan een andere foto te kiezen.",
      "delete_header": "Verwijder jaarboek",
      "delete_text": "Weet je zeker dat je dit jaarboek wil verwijderen?",
      "limit_header": "Limiet bereikt",
      "limit_min_header": "Te weinig foto's",
      "limit_min_text": "Je hebt te weinig foto's. Selecteer {amount, plural, =1 {# foto} other {# foto's}} om het jaarboek op te vullen.",
      "limit_squarefold": "Je hebt de maximale aantal foto's bereikt (22 - Square fold). Wil je meer foto's? Upgrade dan jouw jaarboek.",
      "limit_text": "Je hebt de maximale aantal foto's bereikt (62). Wil je meer foto's? Wissel dan met geselecteerde foto's of overweeg een extra boek.",
      "not_available_header": "Functionaliteit niet beschikbaar",
      "not_available_text": "Deze functionaliteit is op dit moment nog niet beschikbaar.",
      "payment_mollie": "We gaan je doorsturen naar ons betaalportaal (Mollie).",
      "promo_header": "Alleen bestellen",
      "promo_text": "Jouw school doet me aan een actie voor het jaarboek. Dit jaarboek is speciaal voor je samengesteld, en kan je meteen bestellen.",
      "return_parro_header": "Terug naar Parro",
      "return_parro_text": "Weet je zeker dat je terug wilt gaan naar Parro?",
      "text_cover": "We gaan de voorkant samenstellen.",
      "text_editor": "We gaan de inhoud van het jaarboek kiezen."
    },
    "yearbook_drawing": {
      "header": "Wil je tekeningen van Parro in je jaarboek?",
      "title": "Zet tekeningen aan / uit"
    },
    "yearbook_editor": {
      "choose_photo_header": "Kies een foto",
      "choose_photo_text": "Je kan alleen de tekst selecteren als een foto van deze mededeling is geselecteerd.",
      "filter": "Navigeer naar datum, kies kind en jaar",
      "header": "Stel jouw jaarboek samen",
      "loading_selection_header": "We gaan verder waar je gebleven was!",
      "loading_selection_text": "Parro is aan het zoeken voor je. Dit kan even duren....",
      "no_announcements": "Geen mededelingen om te laden",
      "select_all": "Selecteer alle foto's",
      "selected_images": "{amount1} van {amount2} geselecteerd",
      "text": "Kies foto's en bekijk het voorbeeld.",
      "title": "Bewerken",
      "title_images": "Foto's mededeling"
    },
    "yearbook_filter": {
      "choose_month": "Kies een maand",
      "header_children": "Van welke kinderen wil jij een jaarboek maken?",
      "header_schoolyears": "En van welke schooljaren?",
      "no_children": "Geen kinderen gevonden",
      "no_groups": "Geen groepen gevonden",
      "no_schoolyears": "Geen schooljaren gevonden",
      "tabs_child": "Kind",
      "tabs_date": "Naar",
      "tabs_group": "Groep",
      "tabs_schoolyear": "Schooljaar",
      "title_children": "Kies kinderen",
      "title_schoolyears": "Kies schooljaren"
    },
    "yearbook_image_selection": {
      "all_groups": "Alle groepen",
      "placeholder_selection": "Selecteer een groep",
      "upload_image": "Of upload eigen foto"
    },
    "yearbook_login": {
      "text": "Je wordt doorgestuurd naar Parro Jaarboek login."
    },
    "yearbook_menu": {
      "choose": "Kies boeksoort",
      "cover": "Cover veranderen",
      "drawings_off": "Zet tekeningen uit",
      "drawings_on": "Zet tekeningen aan",
      "feedback": "Feedback",
      "home_page": "Terug naar hoofdpagina",
      "information": "Extra informatie / hulp",
      "parro": "Terug naar Parro",
      "tip": "Tip!",
      "title": "Menu"
    },
    "yearbook_order": {
      "header": "Jouw gegevens",
      "label_method": "Betaalwijze",
      "method_ideal": "iDEAL",
      "method_klarna": "Klarna achteraf betalen",
      "pay": "Betalen",
      "placeholder_city": "Plaats",
      "placeholder_country": "Land",
      "placeholder_firstname": "Voornaam",
      "placeholder_housenumber": "Huisnr.",
      "placeholder_lastname": "Achternaam",
      "placeholder_postalcode": "Postcode",
      "placeholder_street": "Straat",
      "post_pay": "Achteraf betalen",
      "text": "Wij bezorgen het jaarboek op het onderstaand adres.",
      "text_design": "Je hebt het <a href={url}>ontwerp gecontroleerd</a> en akkoord bevonden",
      "text_design2": "Je hebt lage kwaliteit foto's in het <a href={url}>ontwerp</a>, maar gaat toch akkoord.",
      "text_terms": "En gaat akkoord met de <a target='_blank' href={url}>algemene voorwaarden</a>.",
      "title": "Bestellen"
    },
    "yearbook_overview": {
      "create_new": "Of maak een nieuw jaarboek",
      "edited": "Bewerkt: {date}",
      "header": "Ga verder met jouw jaarboek",
      "header_extra": "Heb je vragen? Bekijk dan onze <a target='_blank' href={url}>support pagina</a>",
      "header_new": "Nieuw jaarboek",
      "no_title": "Geen titel",
      "ordered": "Besteld: {amount, plural, =1 {# keer} other {# keer}}",
      "status_canceled": "Geannuleerd",
      "status_delivered": "Afgeleverd",
      "status_editing": "Bewerkbaar",
      "status_on_order": "In bestelling",
      "status_ordered": "Besteld",
      "status_paid": "Betaald",
      "status_payment_pending": "Niet betaald",
      "status_problem": "Probleem",
      "status_promo": "Actie",
      "status_refund_pending": "Bezig met terugbetalen",
      "status_refund_requested": "Terugbetaling aangevraagd",
      "status_refunded": "Terugbetaald",
      "status_sent": "Verzonden",
      "text_new": "Stel het jaarboek makkelijk en snel samen met de slimme configurator van Parro",
      "usp": "Tip: je kunt foto's kiezen uit alle voorgaande schooljaren en je eigen persoonlijke cover inrichten."
    },
    "yearbook_preview": {
      "header": "Controleer jouw jaarboek",
      "spread_back": "Achterkant",
      "spread_front": "Voorkant",
      "spread_inside": "Binnenkant",
      "text": "Zodat je zeker weet dat jouw bestelling naar wens is.",
      "title": "Voorbeeld"
    },
    "yearbook_settings": {
      "agreed_by": "Akkoord door: {name}",
      "disable_header": "Schakel jaarboek uit",
      "disable_text1": "De lopende bestellingen gaan door, ouders kunnen geen nieuwe jaarboeken meer bestellen.",
      "disable_text2": "We registeren je naam en datum waarop je deze dienst intrekt.",
      "disabled_by": "Uitgeschakeld door: {name}",
      "enable_modal_header": "Akkoord met voorwaarden",
      "enable_modal_text": "Je bevestigt directeur of gemachtigd te zijn namens de school.",
      "more_information_header": "Meer informatie",
      "more_information_text": "Je kunt als school een jaarboek met foto's en berichten aanbieden aan de ouders. Meer weten? Bekijk <a href={url} target='_blank'>de meest gestelde vragen.</a>",
      "offer_text": "Wat leuk dat je het jaarboek aan ouders wilt aanbieden. Daarvoor gelden <a href={url} target='_blank'>aanvullende voorwaarden</a>",
      "popover_images": "Nieuw! Maak van jouw foto's nu een <u>boekje</u>.",
      "status_disabled": "Status: uitgeschakeld",
      "status_disabled_text": "Jouw school biedt op dit moment geen jaarboek aan.",
      "status_enabled": "Status: ingeschakeld",
      "status_enabled_text": "Je school biedt het jaarboek aan.",
      "whole_school": "Deze instelling geldt voor de hele school."
    },
    "yearbook_shopping": {
      "alert_max_amount": "Er zijn teveel foto's geselecteerd. Kies een andere boeksoort of deselecteer een aantal foto's.",
      "alert_placeholders": "Kies nog {amount, plural, =1 {# foto} other {# foto's}}",
      "alert_replacements": "Je kan nog {amount, plural, =1 {# foto} other {# foto's}} kiezen voor dezelfde prijs!",
      "batch_order": "Wil je een boek voor de hele groep bestellen? <a href={url} target=\"blank\">Lees meer</a>",
      "extra_pages": "Extra pagina's: {amount}",
      "label_amount": "Aantal",
      "label_btw": "incl. BTW & verzending",
      "label_total": "Totaal",
      "max_amount": "Max. {amount}",
      "pages": "({amount, plural, =1 {# pagina} other {# pagina's}})",
      "title": "Winkelwagen"
    },
    "yearbook_status": {
      "amount_pages": "({amount} pagina's, max {max})",
      "deliver_date": "De verwachte levering is binnen 2 weken.",
      "editing_header": "Je bent nog niet klaar",
      "editing_text": "Heb je nog niet besteld. Druk op de onderstaande knop.",
      "editing_title": "Jaarboek nog niet afgerond",
      "feedback": "Klik op de onderstaande knop om je feedback te geven. ",
      "free": "Gratis",
      "pending_header": "Parro wacht nog op jouw betaling",
      "pending_text": "De betaling is nog aan het verwerken of je hebt nog niet betaald. Wanneer de betaling binnen is, ontvang je een e-mail en gaat Parro voor je aan de slag.",
      "pending_text2": "Heb je nog niet betaald? Druk op de onderstaande knop.",
      "pending_title": "Betaling niet ontvangen",
      "promo_header": "Jaarboek actie",
      "promo_text": "Jouw school doet me aan een actie voor het jaarboek. Dit jaarboek is speciaal voor je samengesteld, en kan je meteen bestellen.",
      "promo_title": "Status",
      "success_header": "Parro gaat voor je aan de slag",
      "success_text": "Je kunt in het overzicht zien of de betaling geslaagd is. Heb je vragen, bekijk dan de <a target='_blank' href={url}>support pagina</a>. Heb je feedback, vul ons <a target='_blank' href={url2}>feedback formulier</a> in.",
      "success_title": "Bedankt voor jouw bestelling",
      "tracking_url": "Je kunt de bestelling volgen via deze <a href={url} target=\"_blank\">link</a>."
    },
    "yearbook_templates": {
      "intro": "Voorwoord",
      "my_yearbook": "Dit is het jaarboek van",
      "outro_1": "Tot Slot...",
      "outro_2": "Extra ruimte voor mooie herinneringen..."
    },
    "yearbook_type": {
      "description_pages": "{min} tot {max} pagina's",
      "description_price": "Vanaf",
      "error_too_many": "Je hebt teveel foto's, max {amount}",
      "hardcover_text1": "Onze aanrader: het luxe Jaarboek met harde kaft. De harde kaft beschermt de pagina’s tegen oneffenheden en zorgt ervoor dat het boek jaren mooi blijft.",
      "hardcover_text2": "<strong>Tip:</strong> Op de rug van het boek staat de titel, zo kan je hem nog sneller terug vinden in de boekenkast!",
      "header": "Maak je keuze uit 3 prachtige boeksoorten (21x21cm)",
      "label_populair": "Populair",
      "softcover_text": "Jaarboek met zachte kaft, welke je kan ombuigen. De zachte kaft geeft de look ’n feel van een magazine.",
      "squarefold_text1": "Jaarboek met nietjes. Dit jaarboek is licht en geschikt voor compacte boekjes.",
      "squarefold_text2": "<strong>Let op:</strong> Dit jaarboek is geschikt voor weinig foto’s. Wil je veel foto’s kiezen? Kies dan een soft- of hardcover. Jouw keuze voor het type boekje kan je in het proces nog veranderen",
      "text": "Prijzen incl. BTW + verzendkosten, extra pagina's € 1,50 per 4 pagina's.",
      "type_hardcover": "Hardcover",
      "type_softcover": "Softcover",
      "type_squarefold": "Square fold"
    }
  };
  _exports.default = _default;
});