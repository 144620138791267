define("parro-web/instance-initializers/intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'intl',
    initialize: function (instance) {
      const intl = instance.lookup('service:intl');
      let locale = (navigator.language || navigator.userLanguage).startsWith('nl') ? 'nl' : 'en'; // Set language to english when loaded in Testcafe

      if (window['%hammerhead%']) {
        locale = 'en';
      }

      if (window.Intl && window.Intl.__disableRegExpRestore) {
        window.Intl.__disableRegExpRestore();
      }

      intl.setLocale(locale);
    }
  };
  _exports.default = _default;
});