define("parro-web/components/yearbook/yearbook-templates", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Component.extend({
    tagName: '',
    yearbookService: service(),
    store: service(),
    isBlue: computed.equal('yearbook.color', '#2499BA'),
    isMagenta: computed.equal('yearbook.color', '#C9496A'),
    total: computed('selection.@each', 'yearbook.type', function () {
      const pages = this.get('yearbookService').getSelectionPages(this.get('yearbook'));
      return this.get('yearbookService').getYearbookPages(this.get('yearbook'), pages);
    }),
    cover: computed('total.[]', 'yearbook.type', function () {
      const preview = this.get('total');

      const onFilter = (_, index) => index === 0 || preview.length - 1 === index;

      return preview.filter(onFilter).reverse();
    }),
    content: computed('total.[]', 'yearbook.type', function () {
      const preview = this.get('total');

      if (this.get('yearbook.isSquareFold')) {
        const onFilter = (_, index) => index > 0 && preview.length - 1 > index;

        return preview.filter(onFilter);
      }

      const onFilter2 = (_, index) => index > 1 && preview.length - 2 > index;

      return preview.filter(onFilter2);
    }),
    yearRange: computed('selection.@each', function () {
      const selection = this.get('yearbookService').getSelectionPages(this.get('yearbook'));

      const onFilter = s => !isNaN(s.get('schoolYear'));

      const onMap = s => s.get('schoolYear');

      const onSort = (a, b) => a - b;

      const years = Array.unique(selection.filter(onFilter).map(onMap).sort(onSort));

      if (years.length > 1) {
        const first = years[0];
        const last = parseInt(years[years.length - 1]) + 1;
        return _ember.default.Object.create({
          first,
          last
        });
      }

      if (years.length === 1) {
        const first = years[0];
        const last = parseInt(first) + 1;
        return _ember.default.Object.create({
          first,
          last
        });
      }

      const first = Date.getCurrentSchoolyearStart().getFullYear();
      const last = first + 1;
      return _ember.default.Object.create({
        first,
        last
      });
    })
  });

  _exports.default = _default;
});