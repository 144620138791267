define("parro-web/templates/components/yearbook/yearbook-drawing", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="panel yearbook--drawing">
    <h3 class="header--section">{{t 'yearbook_drawing.header'}}</h3>
  
    <div class="yearbook--drawing-container">
      <div {{action 'toggle'}} class="yearbook--drawing-option {{if (eq yearbook.parroDrawingsEnabled false) 'is-selected' ''}}">
        <figure class="figure--drawing-disabled"></figure>
        {{form/input-radio-button checked=(eq yearbook.parroDrawingsEnabled false) label=(t-exists 'button.no') onChange=(action 'toggle')}}
      </div>
  
      <div class="yearbook--drawing-option {{if (eq yearbook.parroDrawingsEnabled true) 'is-selected' ''}}">
        <figure {{action 'toggle'}} class="figure--drawing-enabled"></figure>
        {{form/input-radio-button checked=(eq yearbook.parroDrawingsEnabled true) label=(t-exists 'button.yes') onChange=(action 'toggle')}}
      </div>
    </div>
  </div>
  */
  {
    "id": "ui12TUHo",
    "block": "[[[10,0],[14,0,\"panel yearbook--drawing\"],[12],[1,\"\\n  \"],[10,\"h3\"],[14,0,\"header--section\"],[12],[1,[28,[35,0],[\"yearbook_drawing.header\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"yearbook--drawing-container\"],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[\"yearbook--drawing-option \",[52,[28,[37,2],[[33,3,[\"parroDrawingsEnabled\"]],false],null],\"is-selected\",\"\"]]]],[4,[38,4],[[30,0],\"toggle\"],null],[12],[1,\"\\n      \"],[10,\"figure\"],[14,0,\"figure--drawing-disabled\"],[12],[13],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"checked\",\"label\",\"onChange\"],[[28,[37,2],[[33,3,[\"parroDrawingsEnabled\"]],false],null],[28,[37,6],[\"button.no\"],null],[28,[37,4],[[30,0],\"toggle\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[\"yearbook--drawing-option \",[52,[28,[37,2],[[33,3,[\"parroDrawingsEnabled\"]],true],null],\"is-selected\",\"\"]]]],[12],[1,\"\\n      \"],[11,\"figure\"],[24,0,\"figure--drawing-enabled\"],[4,[38,4],[[30,0],\"toggle\"],null],[12],[13],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"checked\",\"label\",\"onChange\"],[[28,[37,2],[[33,3,[\"parroDrawingsEnabled\"]],true],null],[28,[37,6],[\"button.yes\"],null],[28,[37,4],[[30,0],\"toggle\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"t\",\"if\",\"eq\",\"yearbook\",\"action\",\"form/input-radio-button\",\"t-exists\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-drawing.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});