define("parro-web/initializers/infinity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'infinity',
    initialize: function (application) {
      application.inject('route', 'infinity', 'service:infinity');
      application.inject('controller', 'infinity', 'service:infinity');
      application.inject('component', 'infinity', 'service:infinity');
    }
  };
  _exports.default = _default;
});