define("parro-web/adapters/groupchild", ["exports", "ember", "parro-web/adapters/application", "parro-web/config/environment"], function (_exports, _ember, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = _ember.default;
  const {
    embrah: {
      namespace
    }
  } = _environment.default;

  var _default = _application.default.extend({
    versionService: service(),
    host: computed.readOnly('versionService.host'),

    buildURL(modelName, id, snapshot, requestType, query) {
      if (modelName === 'groupchild' && requestType === 'query' && query.group && query.invitable) {
        const group = query.group;
        delete query.group;
        delete query.invitable;
        return this.host + '/' + namespace + '/group/' + group + '/groupchild/invitableguardians';
      }

      if (modelName === 'groupchild' && requestType === 'query' && query.group) {
        const group = query.group;
        delete query.group;
        return this.host + '/' + namespace + '/group/' + group + '/groupchild';
      }

      if (modelName === 'groupchild' && requestType === 'query') {
        return this.host + '/' + namespace + '/child';
      }

      if (modelName === 'groupchild' && requestType === 'findRecord' && id) {
        return this.host + '/' + namespace + id;
      }

      if (modelName === 'groupchild' && requestType === 'queryRecord' && query.query) {
        const suffix = query.query;
        delete query.query;
        return this.host + '/' + namespace + suffix;
      }

      return this._super(modelName, id, snapshot, requestType, query);
    }

  });

  _exports.default = _default;
});