define("parro-web/objects/FileUploadInfo", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Object.extend({
    dtype: undefined,
    acl: undefined,
    algoritm: undefined,
    baseUrl: undefined,
    bucket: undefined,
    contentType: undefined,
    credential: undefined,
    date: undefined,
    key: undefined,
    policy: undefined,
    redirect: undefined,
    signature: undefined,
    contentDisposition: undefined
  });

  _exports.default = _default;
});