define("parro-web/components/yearbook/yearbook-overview-item", ["exports", "ember", "parro-web/mixins/dateFormatPolyfillMixin"], function (_exports, _ember, _dateFormatPolyfillMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _ember.default.Component.extend(_dateFormatPolyfillMixin.default, {
    tagName: 'div',
    classNameBindings: ['isOk', 'isError', 'isWarning'],
    orders: computed.readOnly('yearbook.yearbookOrder.copies'),
    isOk: computed('yearbook.status', function () {
      const yearbook = this.get('yearbook');
      const isEditing = yearbook.get('isEditing');
      const isPaid = yearbook.get('isPaid');
      const isOnOrder = yearbook.get('isOnOrder');
      const isOrdered = yearbook.get('isOrdered');
      const isSent = yearbook.get('isSent');
      const isDelivered = yearbook.get('isDelivered');
      const isPromo = yearbook.get('isPromo');
      return isPaid || isOnOrder || isOrdered || isSent || isEditing || isDelivered || isPromo;
    }),
    isError: computed('yearbook.status', function () {
      const yearbook = this.get('yearbook');
      const isProblem = yearbook.get('isProblem');
      const isPendingPayment = yearbook.get('isPendingPayment');
      return isProblem || isPendingPayment;
    }),
    isWarning: computed('yearbook.status', function () {
      const yearbook = this.get('yearbook');
      const isRefunded = yearbook.get('isRefunded');
      const isRefunding = yearbook.get('isRefunding');
      const isRefundRequested = yearbook.get('isRefundRequested');
      return isRefunded || isRefunding || isRefundRequested;
    }),
    statusText: computed('yearbook.status', function () {
      const yearbook = this.get('yearbook');

      if (yearbook.get('isEditing')) {
        return this.intl.t('yearbook_overview.status_editing');
      } else if (yearbook.get('isPaid')) {
        return this.intl.t('yearbook_overview.status_paid');
      } else if (yearbook.get('isOnOrder')) {
        return this.intl.t('yearbook_overview.status_on_order');
      } else if (yearbook.get('isOrdered')) {
        return this.intl.t('yearbook_overview.status_ordered');
      } else if (yearbook.get('isSent')) {
        return this.intl.t('yearbook_overview.status_sent');
      } else if (yearbook.get('isProblem')) {
        return this.intl.t('yearbook_overview.status_problem');
      } else if (yearbook.get('isCanceled')) {
        return this.intl.t('yearbook_overview.status_canceled');
      } else if (yearbook.get('isDelivered')) {
        return this.intl.t('yearbook_overview.status_delivered');
      } else if (yearbook.get('isPendingPayment')) {
        return this.intl.t('yearbook_overview.status_payment_pending');
      } else if (yearbook.get('isRefunded')) {
        return this.intl.t('yearbook_overview.status_refunded');
      } else if (yearbook.get('isRefunding')) {
        return this.intl.t('yearbook_overview.status_refund_pending');
      } else if (yearbook.get('isRefundRequested')) {
        return this.intl.t('yearbook_overview.status_refund_requested');
      } else if (yearbook.get('isPromo')) {
        return this.intl.t('yearbook_overview.status_promo');
      }

      return undefined;
    }),
    lastModifiedAt: computed('yearbook.lastModifiedAt', function () {
      const lastModifiedAt = this.get('yearbook.lastModifiedAt');
      return this.formatDate(lastModifiedAt, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      });
    })
  });

  _exports.default = _default;
});