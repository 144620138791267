define("parro-web/components/photo/photo-slider", ["exports", "ember", "ember-cli-slick/components/slick-slider"], function (_exports, _ember, _slickSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = _ember.default;

  var _default = _slickSlider.default.extend({
    iconBack: 'icon-back',
    iconNext: 'icon-next',

    didInsertElement() {
      this._super(...arguments);

      _ember.default.run.schedule('routerTransitions', this, () => this.set('register', this));
    },

    willDestroyElement() {
      this._super(...arguments);

      $(this.element).slick('unslick');
    },

    prevArrow: computed('iconBack', function () {
      const icon = this.get('iconBack');
      return '<i class=' + icon + '></i>';
    }),
    nextArrow: computed('iconNext', function () {
      const icon = this.get('iconNext');
      return '<i class=' + icon + '></i>';
    }),
    actions: {
      removeImage(index) {
        $(this.element).slick('slickRemove', index);
      },

      clearImages() {
        $(this.element).slick('unslick');
      }

    }
  });

  _exports.default = _default;
});