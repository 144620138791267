define("parro-web/templates/components/form/form-field-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="{{if showLabel 'is-active' ''}} suite-form--fields-label">{{t-exists label}}</label>
  {{input maxlength=maxLength type=type class=inputClassName value=value placeholder=(t-exists placeholder) tabindex=tabIndex name=name autocapitalize="none" autocorrect="off" disabled=isDisabled enter=(action 'submit')}}
  {{#if (and showAmount (not error))}}<span class="text--small">{{amountOfCharacters}} / {{maxLength}}</span>{{/if}}
  {{#if error}}<span class="suite-form--fields-error">{{t-exists error}}</span>{{/if}}
  {{#if warning}}<span class="text--small">{{t-exists warning}}</span>{{/if}}
  {{#if (and showClearAction onClearAction)}}<i {{action onClearAction bubbles=false}} class="icon-cross-thin"></i>{{/if}}
  
  */
  {
    "id": "7ztSxC2c",
    "block": "[[[10,\"label\"],[15,0,[29,[[52,[33,1],\"is-active\",\"\"],\" suite-form--fields-label\"]]],[12],[1,[28,[35,2],[[33,3]],null]],[13],[1,\"\\n\"],[1,[28,[35,4],null,[[\"maxlength\",\"type\",\"class\",\"value\",\"placeholder\",\"tabindex\",\"name\",\"autocapitalize\",\"autocorrect\",\"disabled\",\"enter\"],[[33,5],[33,6],[33,7],[33,8],[28,[37,2],[[33,9]],null],[33,10],[33,11],\"none\",\"off\",[33,12],[28,[37,13],[[30,0],\"submit\"],null]]]]],[1,\"\\n\"],[41,[28,[37,14],[[33,15],[28,[37,16],[[33,17]],null]],null],[[[10,1],[14,0,\"text--small\"],[12],[1,[34,18]],[1,\" / \"],[1,[34,5]],[13]],[]],null],[1,\"\\n\"],[41,[33,17],[[[10,1],[14,0,\"suite-form--fields-error\"],[12],[1,[28,[35,2],[[33,17]],null]],[13]],[]],null],[1,\"\\n\"],[41,[33,19],[[[10,1],[14,0,\"text--small\"],[12],[1,[28,[35,2],[[33,19]],null]],[13]],[]],null],[1,\"\\n\"],[41,[28,[37,14],[[33,20],[33,21]],null],[[[11,\"i\"],[24,0,\"icon-cross-thin\"],[4,[38,13],[[30,0],[33,21]],[[\"bubbles\"],[false]]],[12],[13]],[]],null],[1,\"\\n\"]],[],false,[\"if\",\"showLabel\",\"t-exists\",\"label\",\"input\",\"maxLength\",\"type\",\"inputClassName\",\"value\",\"placeholder\",\"tabIndex\",\"name\",\"isDisabled\",\"action\",\"and\",\"showAmount\",\"not\",\"error\",\"amountOfCharacters\",\"warning\",\"showClearAction\",\"onClearAction\"]]",
    "moduleName": "parro-web/templates/components/form/form-field-text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});