define("parro-web/models/event", ["exports", "ember-data", "ember", "parro-web/mixins/hasAttachmentsMixin", "ember-inflector", "parro-web/mixins/dateFormatPolyfillMixin"], function (_exports, _emberData, _ember, _hasAttachmentsMixin, _emberInflector, _dateFormatPolyfillMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    isPresent
  } = _ember.default;

  var _default = _emberData.default.Model.extend(_hasAttachmentsMixin.default, _dateFormatPolyfillMixin.default, {
    dtype: _emberData.default.attr('string', {
      defaultValue: 'event.RAnnouncementEvent'
    }),
    eventId: _emberData.default.attr('number'),
    // The 'real' event ID, see serializer and #117423091
    title: _emberData.default.attr('string'),
    contents: _emberData.default.attr('string'),
    sortDate: _emberData.default.attr('date'),
    lastEditedAt: _emberData.default.attr('date'),
    lastEditedBy: _emberData.default.belongsTo('teacher', {
      async: false
    }),
    repeatedFromDate: _emberData.default.attr('date'),
    scheduleDate: _emberData.default.attr('date'),
    readBy: _emberData.default.attr('string'),
    senders: _emberData.default.hasMany('sender', {
      async: false
    }),
    owner: _emberData.default.belongsTo('teacher', {
      async: false
    }),
    readCount: _emberData.default.attr('number'),
    totalCount: _emberData.default.attr('number'),
    group: _emberData.default.belongsTo('eventgroup', {
      async: false
    }),
    recipients: _emberData.default.hasMany('grouprecipient', {
      async: false
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: false
    }),
    likeCount: _emberData.default.attr('number'),
    liked: _emberData.default.attr('boolean'),
    likeable: _emberData.default.attr('boolean'),
    deleted: _emberData.default.attr('boolean'),
    childAvatars: _emberData.default.hasMany('childavatar', {
      async: false
    }),
    // YEARBOOK
    yearbook: _emberData.default.belongsTo('yearbookcontentselection'),
    month: computed('sortDate', function () {
      const date = this.get('sortDate');
      const month = Date.isValid(date) ? this.formatDate(date, {
        month: 'long',
        year: 'numeric'
      }) : undefined;
      return isPresent(month) ? String.capitalizeFirstLetter(month) : undefined;
    }).readOnly(),
    getFiles: computed('attachments.[]', function () {
      return this._getFiles(this.get('attachments'));
    }).readOnly(),
    getFilesTTL: computed('getFiles', function () {
      return this._getFilesTTL(this.get('getFiles'));
    }).readOnly(),
    getImages: computed('attachments.[]', function () {
      return this._getImages(this.get('attachments'));
    }).readOnly(),
    hasImages: computed('getImages', function () {
      return this._hasImages(this.get('getImages'));
    }).readOnly()
  });
  /* Ember Data vertellen wat de meervoudsvorm van de modelnaam is */


  _exports.default = _default;

  _emberInflector.default.inflector.irregular('event', 'event');
});