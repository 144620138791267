define("parro-web/models/services/CanvasTransformations", ["exports", "ember", "parro-web/objects/ImageDimensions"], function (_exports, _ember, _ImageDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = CanvasTransformations;
  const {
    assert
  } = _ember.default;
  const IMAGE_DIMENSION_MAX = 2100;
  const THUMBNAIL_DIMENSION_MAX = 900;
  const PREVIEW_DIMENSIONS_MAX = 300;

  function CanvasTransformations() {}

  CanvasTransformations.ORIGINAL_SIZE = function (image) {
    assert('image must be of type Image', image instanceof Image);
    return _ImageDimensions.default.create({
      width: image.width,
      height: image.height
    });
  };

  CanvasTransformations.LARGE_SIZE = function (image) {
    assert('image must be of type Image', image instanceof Image); // Determine ratio based on largest dimension - http://stackoverflow.com/a/35203244
    // Retain the original dimensions if we would upscale the image (ratio > 1).

    const largestDimension = Math.max(image.width, image.height);
    const aspectRatio = Math.min(IMAGE_DIMENSION_MAX / largestDimension, 1);
    const resizedWidth = image.width * aspectRatio;
    const resizedHeight = image.height * aspectRatio;
    return _ImageDimensions.default.create({
      width: resizedWidth,
      height: resizedHeight
    });
  };

  CanvasTransformations.THUMBNAIL_SIZE = function (image) {
    assert('image must be of type Image', image instanceof Image); // Determine ratio based on largest dimension - http://stackoverflow.com/a/35203244
    // Retain the original dimensions if we would upscale the image (ratio > 1).

    const largestDimension = Math.max(image.width, image.height);
    const aspectRatio = Math.min(THUMBNAIL_DIMENSION_MAX / largestDimension, 1);
    const resizedWidth = image.width * aspectRatio;
    const resizedHeight = image.height * aspectRatio;
    return _ImageDimensions.default.create({
      width: resizedWidth,
      height: resizedHeight
    });
  };

  CanvasTransformations.PREVIEW_SIZE = function (image) {
    assert('image must be of type Image', image instanceof Image); // Determine ratio based on largest dimension - http://stackoverflow.com/a/35203244
    // Retain the original dimensions if we would upscale the image (ratio > 1).

    const largestDimension = Math.max(image.width, image.height);
    const aspectRatio = Math.min(PREVIEW_DIMENSIONS_MAX / largestDimension, 1);
    const resizedWidth = image.width * aspectRatio;
    const resizedHeight = image.height * aspectRatio;
    return _ImageDimensions.default.create({
      width: resizedWidth,
      height: resizedHeight
    });
  };
  /**
   * Does nothing with image orientation.
   * @param {Image} image
   * @return {number} -1 (not defined)
   */


  CanvasTransformations.NO_ORIENTATION = function () {
    return -1;
  };
  /**
   * Determines orientation. Based on http://stackoverflow.com/a/32490603
   * @return {number} -2 (not JPEG), -1 (not defined) or actual orientation
   */


  CanvasTransformations.CORRECT_ORIENTATION = function (e) {
    const view = new DataView(e.target.result);

    if (view.getUint16(0, false) !== 0xFFD8) {
      return -2; // not JPEG
    }

    const length = view.byteLength;
    let offset = 2;

    while (offset < length) {
      const marker = view.getUint16(offset, false);
      offset += 2;

      if (marker === 0xFFE1) {
        if (view.getUint32(offset += 2, false) !== 0x45786966) {
          return -1; // not defined
        }

        const little = view.getUint16(offset += 6, false) === 0x4949;
        offset += view.getUint32(offset + 4, little);
        const tags = view.getUint16(offset, little);
        offset += 2;

        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            return view.getUint16(offset + i * 12 + 8, little);
          }
        }
      } else if ((marker & 0xFF00) !== 0xFF00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }

    return -1; // not defined
  };
});