define("parro-web/templates/components/yearbook/yearbook-image-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="file-picker">
    <a><label for="fileInput">{{t 'yearbook_image_selection.upload_image'}}</label></a>
    <input type="file" id="fileInput" multiple="false" accept=".jpg, .jpeg, .png" class="file-picker__input">
  </div>
  
  
  */
  {
    "id": "0ngIfZJX",
    "block": "[[[10,0],[14,0,\"file-picker\"],[12],[1,\"\\n  \"],[10,3],[12],[10,\"label\"],[14,\"for\",\"fileInput\"],[12],[1,[28,[35,0],[\"yearbook_image_selection.upload_image\"],null]],[13],[13],[1,\"\\n  \"],[10,\"input\"],[14,1,\"fileInput\"],[14,\"multiple\",\"false\"],[14,\"accept\",\".jpg, .jpeg, .png\"],[14,0,\"file-picker__input\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[],false,[\"t\"]]",
    "moduleName": "parro-web/templates/components/yearbook/yearbook-image-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});