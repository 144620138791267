define("parro-web/models/group", ["exports", "ember-data", "ember", "ember-inflector"], function (_exports, _emberData, _ember, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    isPresent
  } = _ember.default;

  var _default = _emberData.default.Model.extend({
    dtype: _emberData.default.attr('string', {
      defaultValue: 'identity.RGroupPrimer'
    }),
    name: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    koppelingId: _emberData.default.attr('number'),
    memberArchived: _emberData.default.attr('boolean'),
    stamgroep: _emberData.default.attr('boolean'),
    type: _emberData.default.attr('string'),
    schooljaar: _emberData.default.attr('number'),
    unreadCount: _emberData.default.attr('number'),
    numberOfTeachers: _emberData.default.attr('number'),
    numberOfChildren: _emberData.default.attr('number'),
    numberOfGuardians: _emberData.default.attr('number'),
    allowGuardianContactTeacher: _emberData.default.attr('boolean'),
    allowGuardianContactGuardians: _emberData.default.attr('boolean'),
    externalId: _emberData.default.attr('number'),
    pricingPlan: _emberData.default.attr('string'),
    childavatars: _emberData.default.hasMany('childavatar', {
      async: false
    }),
    checked: _emberData.default.attr('boolean'),
    isArchived: computed('active', 'memberArchived', function () {
      return this.get('type') === 'NORMAL' && !this.get('active');
    }),
    schooljaarText: computed('schooljaar', function () {
      const jaar = this.get('schooljaar');
      return jaar + '/' + (jaar + 1);
    }),
    nameAndSchoolYear: computed('schooljaar', 'name', function () {
      const jaar = this.get('schooljaar');
      const name = this.get('name');
      return isPresent(jaar) ? name + ' (' + jaar + '/' + (jaar + 1) + ')' : name;
    })
  });
  /* Afwijkende Ember-data meervoudsvorm: '/group/1' ipv '/groups/1' */


  _exports.default = _default;

  _emberInflector.default.inflector.irregular('group', 'group');
});