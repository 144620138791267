define("parro-web/services/flash-message-service", ["exports", "@ember/service", "@ember/object", "@ember/debug", "@ember/utils"], function (_exports, _service, _object, _debug, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MESSAGES_BLACKLIST = ['Assertion Failed', 'Adapter operation failed', 'Calling set on destroyed object', 'Cannot read property ', 'Maximum call stack size exceeded', ' is niet gedefinieerd', ' is not a function', 'Attempted to assign to readonly property', "You can't call", 'Ember Data Request GET', 'PAGE_NOT_FOUND', 'UNAUTHORIZED', 'HARD_UNAUTHORIZED', '{"code":', '[Exception', 'The adapter operation was aborted'];
  let FlashMessageService = (_class = class FlashMessageService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }

    flashClear() {
      (0, _object.get)(this, 'flashMessages').clearMessages();
    }

    flashInfo(message, args) {
      if (message) {
        const translated = this._getTranslatedMessage(message, args);

        this._flashMessage({
          content: translated,
          type: 'info',
          sticky: true
        });
      }
    }

    flashInfoSticky(message, args) {
      if (message) {
        const translated = this._getTranslatedMessage(message, args);

        this._flashMessage({
          content: translated,
          type: 'info',
          sticky: true
        });
      }
    }

    flashWarning(message, args) {
      if (message) {
        const translated = this._getTranslatedMessage(message, args);

        this._flashMessage({
          content: translated,
          type: 'warning',
          sticky: true
        });
      }
    }

    flashError(message, args) {
      if (message) {
        const translated = this._getTranslatedMessage(message, args);

        this._flashMessage({
          content: translated,
          type: 'error',
          sticky: true
        });
      }
    }

    flashSuccess(message, args) {
      if (message) {
        const translated = this._getTranslatedMessage(message, args);

        this._flashMessage({
          content: translated,
          type: 'success',
          sticky: true
        });
      }
    }

    _flashMessage(properties) {
      (true && !((0, _utils.isPresent)(properties)) && (0, _debug.assert)('Must provide properties', (0, _utils.isPresent)(properties)));
      (true && !((0, _utils.isPresent)(properties.content)) && (0, _debug.assert)('Must provide properties.content', (0, _utils.isPresent)(properties.content)));
      (true && !((0, _utils.isPresent)(properties.type)) && (0, _debug.assert)('Must provide properties.type', (0, _utils.isPresent)(properties.type))); // Add flash message to the queue - except when it's blacklisted or already in the queue (duplicate).

      if (!this._isBlackListedMessage(properties.content)) {
        this._addFlashMessage(properties);
      }
    }

    _addFlashMessage(properties) {
      (0, _object.get)(this, 'flashMessages').add({
        type: properties.type,
        message: properties.content,
        timeout: properties.duration,
        sticky: properties.sticky,
        extendedTimeout: properties.extendedTimeout,
        link: properties.link
      });
    }

    _getTranslatedMessage(message, args) {
      if (typeof message === 'object' && Object.keys(message).find(key => key === 'message')) {
        message = message.message;
      }

      return this.intl.exists(message) ? this.intl.t(message, args) : message;
    }

    _isBlackListedMessage(message) {
      console.log(message);
      (true && !((0, _utils.isPresent)(message)) && (0, _debug.assert)('Must provide message', (0, _utils.isPresent)(message)));
      return (0, _utils.isPresent)(MESSAGES_BLACKLIST.find(black => message.includes(black)));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = FlashMessageService;
});