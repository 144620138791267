define("parro-web/mixins/LoginMixin", ["exports", "ember", "parro-web/config/environment", "parro-web/mixins/metricsMixin"], function (_exports, _ember, _environment, _metricsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = _ember.default;
  const AUTHENTICATOR = _environment.default['ember-simple-auth'].authenticator;
  /**
   * We have multiple login points. This mixin has the combined logic to make login work consistently
   * on all those points. Relies on function overrides to handle specific situations.
   */

  var _default = _ember.default.Mixin.create(_metricsMixin.default, {
    session: service(),
    _handleUnknownError: () => {},

    loginOAuth(options) {
      return this.get('session').authenticate(AUTHENTICATOR, options).then(() => {}).catch(() => {
        return this._handleUnknownError();
      });
    }

  });

  _exports.default = _default;
});