define("parro-web/templates/yearbook/editor-images", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <header class="header" id="content-header">
    {{navigation/nav-header-yearbook config=model.navConfig }}
    {{yearbook/yearbook-status-bar yearbook=model.yearbook selection=model.selection prices=model.prices}}
  </header>
  
  <section class="content-wrapper">
    <div class="content">
      {{yearbook/yearbook-announcement-images announcement=model.announcement yearbook=model.yearbook selection=model.selection isLoading=model.isLoading onShowDialog=(route-action 'showTypeDialog') requestConfirmation=(route-action 'requestConfirmation')}}
    </div>
  </section>
  
  */
  {
    "id": "n+txh5Td",
    "block": "[[[10,\"header\"],[14,0,\"header\"],[14,1,\"content-header\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"config\"],[[33,1,[\"navConfig\"]]]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"yearbook\",\"selection\",\"prices\"],[[33,1,[\"yearbook\"]],[33,1,[\"selection\"]],[33,1,[\"prices\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"content-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"announcement\",\"yearbook\",\"selection\",\"isLoading\",\"onShowDialog\",\"requestConfirmation\"],[[33,1,[\"announcement\"]],[33,1,[\"yearbook\"]],[33,1,[\"selection\"]],[33,1,[\"isLoading\"]],[28,[37,4],[\"showTypeDialog\"],null],[28,[37,4],[\"requestConfirmation\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"navigation/nav-header-yearbook\",\"model\",\"yearbook/yearbook-status-bar\",\"yearbook/yearbook-announcement-images\",\"route-action\"]]",
    "moduleName": "parro-web/templates/yearbook/editor-images.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});