define("parro-web/services/groups-service", ["exports", "parro-web/models/services/comparators", "ember", "parro-web/mixins/metricsMixin", "parro-web/mixins/dateFormatPolyfillMixin"], function (_exports, _comparators, _ember, _metricsMixin, _dateFormatPolyfillMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    isPresent,
    computed
  } = _ember.default;
  const assign = _ember.default.assign;

  var _default = _ember.default.Service.extend(_metricsMixin.default, _dateFormatPolyfillMixin.default, {
    accountService: service('account'),
    ajaxService: service('ajax'),
    store: service(),
    versionService: service(),
    host: computed.readOnly('versionService.host'),

    getGroups(disableAjaxLoader) {
      const args = this.getArguments(undefined, disableAjaxLoader);

      const onResults = results => {
        const groups = Array.orEmpty(results).map(g => g).sort((a, b) => _comparators.default.group(a, b));
        return groups;
      };

      return this.queryGroups(this.get('store').query('group', args)).then(onResults);
    },

    getGroupsWithArgs(args) {
      const onResults = results => {
        const groups = Array.orEmpty(results).map(g => g).sort((a, b) => _comparators.default.group(a, b));
        groups.forEach(group => delete group.checked);
        return groups;
      };

      return this.queryGroups(this.get('store').query('group', args)).then(onResults);
    },

    queryGroups(promise) {
      const onResults = results => {
        const groups = Array.orEmpty(results).map(g => g).sort((a, b) => _comparators.default.group(a, b));
        groups.forEach(group => delete group.checked);
        return groups;
      };

      return promise.then(onResults);
    },

    getArguments(group, disableAjaxLoader) {
      let args = {};
      args = assign(args, isPresent(group) && isPresent(group.get('id')) ? {
        id: group.get('id')
      } : {});
      args = assign(args, {
        disableAjaxLoader: disableAjaxLoader || false
      });
      args = assign(args, this.get('accountService.isGuardian') ? {
        additional: 'childavatar'
      } : {});
      args = assign(args, {
        scope: 'ALL'
      });
      return args;
    }

  });

  _exports.default = _default;
});