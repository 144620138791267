define("parro-web/templates/components/button/back-button-mobile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if route}}
    <a {{action (route-action 'toNavChoice' route undefined false) bubbles=false}} class="icon-previous is-shown-on-mobile" id="back-to-menu-button"></a>
  {{else}}
    <a {{action (route-action 'hideContent') bubbles=false}} class="icon-previous is-shown-on-mobile" id="back-to-menu-button"></a>
  {{/if}}
  
  
  */
  {
    "id": "jIOGSMFS",
    "block": "[[[41,[33,1],[[[1,\"  \"],[11,3],[24,0,\"icon-previous is-shown-on-mobile\"],[24,1,\"back-to-menu-button\"],[4,[38,2],[[30,0],[28,[37,3],[\"toNavChoice\",[33,1],[27],false],null]],[[\"bubbles\"],[false]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,3],[24,0,\"icon-previous is-shown-on-mobile\"],[24,1,\"back-to-menu-button\"],[4,[38,2],[[30,0],[28,[37,3],[\"hideContent\"],null]],[[\"bubbles\"],[false]]],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[],false,[\"if\",\"route\",\"action\",\"route-action\"]]",
    "moduleName": "parro-web/templates/components/button/back-button-mobile.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});