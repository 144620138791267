define("parro-web/serializers/application", ["exports", "ember-data", "ember"], function (_exports, _emberData, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const assign = _ember.default.assign;
  const {
    assert,
    inject: {
      service
    },
    isArray,
    isNone,
    isPresent
  } = _ember.default;

  var _default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    dataprovider: service(),

    serializeIntoHash(hash, type, snapshot, options) {
      return assign(hash, this.serialize(snapshot, options));
    },

    serialize(record, options) {
      const json = this._super(record, options);

      const id = record.id; // get the id of the record and create a self-link with that id

      if (isPresent(id)) {
        json.links = [{
          id: id,
          rel: 'self'
        }];
      } // check each relationship
      // replace belongTo relationships with an object with self-links containing the id
      // replace hasMany relationships with an array of self-links containing the id


      record.eachRelationship((name, relationship) => {
        if (relationship.kind === 'hasMany') {
          json[name] = [];
          const subRecords = record.hasMany(name);

          if (isPresent(subRecords)) {
            subRecords.forEach(subRecord => json[name].push(this.serialize(subRecord, options)));
          }
        } else {
          json[name] = null; // Store the subrecords I'm serializing, to detect & prevent infinite loops (one-to-one).

          const serializing = record.record.serializing = Array.orEmpty(record.record.serializing);
          const subRecord = record.belongsTo(name);

          if (isPresent(subRecord) && serializing.indexOf(name) === -1) {
            serializing.push(name);
            json[name] = this.serialize(subRecord, options);
          }
        }
      });
      return json;
    },

    normalize(type, hash, prop) {
      hash.lastLoaded = new Date().toISOString();

      this._parseLinkables(hash);

      return this._super(type, hash, prop);
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const resourceName = primaryModelClass.modelName;

      if (payload.items) {
        payload[resourceName] = payload.items;
        delete payload.items;
        return this._super(store, primaryModelClass, payload, id, requestType);
      } else {
        const payloadEmber = {};
        payloadEmber[resourceName] = payload;
        return this._super(store, primaryModelClass, payloadEmber, id, requestType);
      }
    },

    _parseLinkables(hash) {
      this._parseAndFlattenLinkables(hash, true);
    },

    _parseAndFlattenLinkables(hash, flattenEmbedded) {
      // check properties of hash for more linkables
      if (isNone(hash) || !isArray(hash) && typeof hash !== 'object') {
        return;
      }

      if (isPresent(hash.additionalObjects)) {
        // Verplaats additionalObjects naar het bovenliggende object
        Object.keys(hash.additionalObjects).forEach(key => {
          hash[key] = hash.additionalObjects[key];
        });
        delete hash.additionalObjects;
      }

      if (isPresent(hash.permissions)) {
        delete hash.permissions;
      } // highest object is the root model so get id from self link and place on root


      if (isPresent(hash.links) && isNone(hash.id)) {
        hash.id = this._getLinkableIdFromSelfLink(hash, 'self');
        delete hash.links;
      } // look inside the hash properties for relations in the form of an array or object containing links


      if (flattenEmbedded) {
        Object.keys(hash).forEach(key => {
          if (key === 'links' || key === 'id') {
            return;
          }

          let object = hash[key];

          if (isNone(object) || !isArray(object) && typeof object !== 'object') {
            return;
          } // if the object is an array with relations, get all the ids and place them in an array
          // then replace the object array with the array with ids so Ember can figure out the relation
          //
          // if the object in an object with a self link, get the id of the self link and replace the object
          // with the id so Ember can figure out the relation


          if (isArray(object)) {
            const idArray = [];
            object.forEach(arrayObj => {
              const id = this._getLinkableIdFromSelfLink(arrayObj, 'self');

              if (isPresent(id)) {
                idArray.push(id);
              }
            });
            object = idArray;
          } else if (typeof object === 'object') {
            const id = this._getLinkableIdFromSelfLink(object, 'self');

            if (!isPresent(id)) {
              object = id;
            }
          }

          hash[key] = object;
        });
      }
    },

    _getLinkableIdFromSelfLink(object, type) {
      return this.get('dataprovider').findId(object, type);
    },

    _getAdditionalObject(hash, name) {
      assert('Must provide hash', isPresent(hash));
      assert('Must provide name', isPresent(name));

      if (isPresent(hash.additionalObjects) && isPresent(hash.additionalObjects[name])) {
        const additional = hash.additionalObjects[name];

        if (additional instanceof Array) {
          return additional;
        }

        if (additional instanceof Object && additional.items instanceof Array) {
          return additional.items;
        }
      }

      return undefined;
    }

  });

  _exports.default = _default;
});