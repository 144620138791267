define("parro-web/mixins/routeMixin", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isPresent,
    inject: {
      service
    }
  } = _ember.default;

  var _default = _ember.default.Mixin.create({
    router: service(),

    transitionTo(route, ...params) {
      const router = this.get('router');

      if (isPresent(route)) {
        return router.transitionTo(route, ...params);
      }

      router.transitionTo('oops.default');
    },

    useRouteGuard(routeGuard, transition) {
      if (!routeGuard.allowed) {
        transition.abort();

        if (isPresent(routeGuard.params)) {
          this.transitionTo(routeGuard.route, ...routeGuard.params);
        } else {
          this.transitionTo(routeGuard.route);
        }
      }
    },

    getLocation() {
      try {
        const params = this.get('router._router.currentState.routerJsState.params');
        const names = this.get('router._router.currentState.router.recognizer.names');
        const targetName = this.get('router._router.currentState.router.activeTransition.targetName');
        let route;

        for (const [key, value] of Object.entries(names)) {
          if (targetName === key) {
            route = value;
          }
        }

        const currentHandler = route.handlers.find(h => h.handler === targetName);
        const transitionParams = params[currentHandler.handler];
        return {
          route: targetName,
          params: currentHandler.names.map(name => transitionParams[name])
        };
      } catch (e) {
        return undefined;
      }
    }

  });

  _exports.default = _default;
});