define("parro-web/mixins/dateFormatPolyfillMixin", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = _ember.default;
  /**
   * @deprecated Will deprecate this mixin in favor of Ember Octane, use formatDatetimeService
   **/

  var _default = _ember.default.Mixin.create({
    formatDatetimeService: service(),

    formatDate(date, options) {
      return this.formatDatetimeService.formatDate(date, options);
    }

  });

  _exports.default = _default;
});