define("parro-web/components/form/input-radio-button", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    tagName: 'label',
    classNames: ['radio-button--container'],
    name: 'radio',

    didInsertElement() {
      this._super(...arguments);

      $(this.element).change(() => {
        if (this.get('onChange')) {
          this.get('onChange').apply(this, [this.get('checked')]);
        }
      });

      this._setPropertyChecked();
    },

    checkedObserver: _ember.default.observer('checked', function () {
      this._setPropertyChecked();
    }),

    _setPropertyChecked() {
      const element = $(this.element);
      const input = element.find('input');

      if (input) {
        input.prop('checked', this.get('checked'));
      }
    }

  });

  _exports.default = _default;
});