define("parro-web/templates/components/photo/yearbook-photo-swipe-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isBadQuality}}<i {{action showWarning}} class="icon-warning"></i>{{/if}}
  <i {{action launchGallery item}} class="icon-eye-open"></i>
  
  <a data-width={{item.w}} data-height={{item.h}}>
  
    {{#if (and toNavImages)}}
      {{#if (gt items.length 1)}}
        <div {{action toNavImages bubble=false}} class="gallery--counter-mobile"><span>{{items.length}}</span></div>
        <div {{action toNavImages bubble=false}} class="gallery--counter-desktop"><span>{{items.length}}</span></div>
      {{/if}}
    {{else}}
      {{#if (gt items.length 1)}}
        <div {{action launchGallery item}} class="gallery--counter-mobile"><span>{{items.length}}</span></div>
        <div {{action launchGallery item}} class="gallery--counter-desktop"><span>{{items.length}}</span></div>
      {{/if}}
    {{/if}}
  
    <div {{action selectImage item }} class="suite-gallery-item" style={{style}}>
      {{#if isSelected}}<div class="suite-gallery-item-checkmark"><i class="icon-checkmark"></i></div>{{/if}}
      {{#if isIndeterminate}}<div class="suite-gallery-item-checkmark"><i class="icon-minus"></i></div>{{/if}}
    </div>
  </a>
  
  */
  {
    "id": "ARV/A6I5",
    "block": "[[[41,[33,1],[[[11,\"i\"],[24,0,\"icon-warning\"],[4,[38,2],[[30,0],[33,3]],null],[12],[13]],[]],null],[1,\"\\n\"],[11,\"i\"],[24,0,\"icon-eye-open\"],[4,[38,2],[[30,0],[33,4],[33,5]],null],[12],[13],[1,\"\\n\\n\"],[10,3],[15,\"data-width\",[33,5,[\"w\"]]],[15,\"data-height\",[33,5,[\"h\"]]],[12],[1,\"\\n\\n\"],[41,[28,[37,6],[[33,7]],null],[[[41,[28,[37,8],[[33,9,[\"length\"]],1],null],[[[1,\"      \"],[11,0],[24,0,\"gallery--counter-mobile\"],[4,[38,2],[[30,0],[33,7]],[[\"bubble\"],[false]]],[12],[10,1],[12],[1,[33,9,[\"length\"]]],[13],[13],[1,\"\\n      \"],[11,0],[24,0,\"gallery--counter-desktop\"],[4,[38,2],[[30,0],[33,7]],[[\"bubble\"],[false]]],[12],[10,1],[12],[1,[33,9,[\"length\"]]],[13],[13],[1,\"\\n\"]],[]],null]],[]],[[[41,[28,[37,8],[[33,9,[\"length\"]],1],null],[[[1,\"      \"],[11,0],[24,0,\"gallery--counter-mobile\"],[4,[38,2],[[30,0],[33,4],[33,5]],null],[12],[10,1],[12],[1,[33,9,[\"length\"]]],[13],[13],[1,\"\\n      \"],[11,0],[24,0,\"gallery--counter-desktop\"],[4,[38,2],[[30,0],[33,4],[33,5]],null],[12],[10,1],[12],[1,[33,9,[\"length\"]]],[13],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"\\n  \"],[11,0],[24,0,\"suite-gallery-item\"],[16,5,[36,10]],[4,[38,2],[[30,0],[33,11],[33,5]],null],[12],[1,\"\\n    \"],[41,[33,12],[[[10,0],[14,0,\"suite-gallery-item-checkmark\"],[12],[10,\"i\"],[14,0,\"icon-checkmark\"],[12],[13],[13]],[]],null],[1,\"\\n    \"],[41,[33,13],[[[10,0],[14,0,\"suite-gallery-item-checkmark\"],[12],[10,\"i\"],[14,0,\"icon-minus\"],[12],[13],[13]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"isBadQuality\",\"action\",\"showWarning\",\"launchGallery\",\"item\",\"and\",\"toNavImages\",\"gt\",\"items\",\"style\",\"selectImage\",\"isSelected\",\"isIndeterminate\"]]",
    "moduleName": "parro-web/templates/components/photo/yearbook-photo-swipe-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});